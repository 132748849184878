import React from 'react';
import { connect } from 'react-redux';

import { treatmentGroupActions, alertActions, placeActions } from '../_actions';
import { SubmitButton } from '../_components';

class PriceEditPage extends React.Component {

    constructor(props) {
        super(props);

        this.handleBackClick = this.handleBackClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.placeChanged = this.placeChanged.bind(this);
        this.onPlaceChanged = this.onPlaceChanged.bind(this);
        this.populateData = this.populateData.bind(this);

        if (!props.selectedPrice) {
            this.state = {
                newPrice: {},
                submitted: false,
                startDate: null,
                endDate: null,
                focusedInputStart: false,
                focusedInputEnd: false
            };
            this.props.history.push("/prices");
        } else {
            this.state = {
                newPrice: {
                    id: props.selectedPrice.id,
                    duration: props.selectedPrice.duration / (60 * 1000),
                    price: props.selectedPrice.price,
                    placeId: props.selectedPrice.placeId,
                    treatmentId: props.selectedPrice.treatmentId
                },
                submitted: false,
                startDate: null,
                endDate: null,
                focusedInputStart: false,
                focusedInputEnd: false
            };
        }
    }

    componentDidMount() {
        const { history, loggedAs } = this.props;
        if (!loggedAs) {
            history.push("/login");
        }
        this.populateData();
    }

    componentDidUpdate() {
        if (!this.props.loggedAs) {
            this.props.history.push("/login");
        }
        this.populateData();
    }

    populateData() {
        const { dispatch, place } = this.props;
        if (!place.places && !place.requestingPlaces) {
            dispatch(placeActions.getPlaces());
        } else if (place.places && this.state.newPrice.placeId === -1) {
            // Let's preselect a first non customer place
            const places = place.places;
            for (var i = 0; i < places.length; i++) {
                if (!places[i].customerAddress) {
                    this.placeChanged(places[i].id);
                    break;
                }
            }
        }
    }

    handleBackClick() {
        this.props.history.push("/prices");
    }

    handleSubmit(e) {
        e.preventDefault();
        var form = document.getElementById('priceForm');
        if (form.checkValidity() === false) {
            form.classList.add('was-validated');
            e.stopPropagation();
            return;
        }

        const { dispatch } = this.props;
        dispatch(alertActions.clear());
        dispatch(treatmentGroupActions.updatePrice(this.state.newPrice));
    }

    handleChange(e) {
        const { name, value } = e.target;
        const { newPrice } = this.state;
        this.setState({
            newPrice: {
                ...newPrice,
                [name]: value
            }
        });
    }

    placeChanged(placeId) {
        var place = this.props.place.places.find(function(place) {
            return place.id === placeId;
        }, placeId);
        const { newPrice } = this.state;
        this.setState({
            newPrice: {
                ...newPrice,
                placeId: place.id
            }
        });
    }

    onPlaceChanged(event) {
        if (event.currentTarget.value) {
            this.placeChanged(event.currentTarget.value);
        }
    }

    render() {
        const { savingPrice, place } = this.props;
        const { newPrice } = this.state;
            return (
                <div className="col-lg-8">
                    <h2 className="text-center">Edit price</h2>
                    <div className="card card-primary card-transparent">
                        <div className="card-body">
                            <form id="priceForm" noValidate className="needs-validation my-4" onSubmit={this.handleSubmit}>
                                <div className="form-group row">
                                    <label className="col-4 col-md-3 col-xl-2 col-form-label" htmlFor="heading">Id</label>
                                    <div className="col-8">
                                        <input type="text" className="form-control-plaintext font-weight-bold" name="heading" value={newPrice.id} readOnly/>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-4 col-md-3 col-xl-2 col-form-label" htmlFor="duration">Duration (min)</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" name="duration" value={newPrice.duration} required onChange={this.handleChange}/>
                                        <div className="invalid-feedback">
                                            Please enter duration
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-4 col-md-3 col-xl-2 col-form-label" htmlFor="price">Amount</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" name="price" value={newPrice.price} required onChange={this.handleChange}/>
                                        <div className="invalid-feedback">
                                            Please enter amount
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-4 col-md-3 col-xl-2 col-form-label" htmlFor="place">Place</label>
                                    <div className="col-sm-8">
                                        {(!place || place.requestingPlaces) && <span>Loading places ....</span>}
                                        {place && place.places && !place.requestingPlaces && <select class="form-control" name="place" id="place" value={newPrice.placeId ? newPrice.placeId : -1} onChange={this.onPlaceChanged}>
                                                {place.places.map((item, index) =>
                                                    <option value={item.id}>{item.name}</option>
                                                )}
                                            </select>
                                        }
                                        <div className="invalid-feedback">
                                            Please pick a place
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row align-items-center ml-1">
                                    <button type="button" className="btn btn-secondary col-md-2 col-xl-1 mr-2 mb-2" onClick={this.handleBackClick}>Back</button>
                                    <SubmitButton updating={savingPrice} label="Save price" labelLoading="Saving ..." className="col-md-3 col-xl-2 mr-2 mb-2"/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            );
    }
}

function mapStateToProps(state) {
    const { loggedAs } = state.user;
    const { savingPrice, selectedPrice } = state.treatmentGroup;
    const { place } = state;

    return {
        loggedAs,
        savingPrice,
        selectedPrice,
        place
    };
}

const connectedPriceEditPage = connect(mapStateToProps)(PriceEditPage);
export { connectedPriceEditPage as PriceEditPage };
