import { placeConstants, reservationConstants } from '../_constants';
import { placeService } from '../_services';
import { alertActions } from './';
import { http } from '../_helpers';

export const placeActions = {
    getPlaces,
    selectPlace,
    resetPlace,
    getRooms,
    selectRoom,
    resetRoom
};

function getPlaces() {
  return dispatch => {
      dispatch(request());

      placeService.getPlaces()
          .then(
              places => dispatch(success(places)),
              error => {
                  var message = http.handleServiceError(error);
                  dispatch(failure(message));
                  dispatch(alertActions.error(message));
              }
          );
  };

  function request() { return { type: placeConstants.PLACES_REQUEST } }
  function success(places) { return { type: placeConstants.PLACES_SUCCESS, places } }
  function failure(message) { return { type: placeConstants.PLACES_FAILURE, message } }
}

function selectPlace(selectedPlace) {
    return dispatch => {
        dispatch(success(selectedPlace));
    };
    function success(selectedPlace) {
      return { type: placeConstants.SELECTPLACE_SUCCESS, selectedPlace }
    }
}

function resetPlace() {
    return dispatch => {
        dispatch(place());
        dispatch(group());
    };
    function place() {
      return { type: placeConstants.RESETPLACE_SUCCESS }
    }
    function group() {
      return { type: reservationConstants.RESETGROUP_SUCCESS }
    }
}

function getRooms(placeId) {
  return dispatch => {
      dispatch(request());

      placeService.getRooms(placeId)
          .then(
              rooms => dispatch(success(rooms)),
              error => {
                  var message = http.handleServiceError(error);
                  dispatch(failure(message));
                  dispatch(alertActions.error(message));
              }
          );
  };

  function request() { return { type: placeConstants.ROOMS_REQUEST } }
  function success(rooms) { return { type: placeConstants.ROOMS_SUCCESS, rooms } }
  function failure(message) { return { type: placeConstants.ROOMS_FAILURE, message } }
}

function selectRoom(selectedRoom) {
    return dispatch => {
        dispatch(success(selectedRoom));
    };
    function success(selectedRoom) {
      return { type: placeConstants.SELECTROOM_SUCCESS, selectedRoom }
    }
}

function resetRoom() {
    return dispatch => {
        dispatch(success());
    };
    function success() {
      return { type: placeConstants.RESETROOM_SUCCESS }
    }
}
