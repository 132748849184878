import React from 'react';
import { connect } from 'react-redux';

import { treatmentGroupActions, alertActions, placeActions } from '../_actions';
import { SubmitButton } from '../_components';

class PriceCreatePage extends React.Component {

    constructor(props) {
        super(props);

        this.handleBackClick = this.handleBackClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDurationChange = this.handleDurationChange.bind(this);
        this.handleAmountChange = this.handleAmountChange.bind(this);
        this.placeChanged = this.placeChanged.bind(this);
        this.onPlaceChanged = this.onPlaceChanged.bind(this);
        this.populateData = this.populateData.bind(this);

        if (!props.selectedTreatment) {
            this.state = {};
            this.props.history.push("/treatments");
        } else {
            this.state = {
                newPrice: {
                    treatmentId: props.selectedTreatment.id
                },
                submitted: false
            };
        }
    }

    componentDidMount() {
        if (!this.props.loggedAs) {
            this.props.history.push("/login");
        }
        this.populateData();
    }

    componentDidUpdate() {
        if (!this.props.loggedAs) {
            this.props.history.push("/login");
        }
        this.populateData();
    }

    populateData() {
        const { dispatch, place } = this.props;
        if (!place.places && !place.requestingPlaces) {
            dispatch(placeActions.getPlaces());
        } else if (place.places && (!this.state.newPrice.placeId || this.state.newPrice.placeId === -1)) {
            // Let's preselect a first non customer place
            const places = place.places;
            for (var i = 0; i < places.length; i++) {
                if (!places[i].customerAddress) {
                    this.placeChanged(places[i].id);
                    break;
                }
            }
        }
    }

    handleBackClick() {
        this.props.history.push("/prices");
    }

    handleSubmit(e) {
        e.preventDefault();
        var form = document.getElementById('priceForm');
        const { newPrice } = this.state;
        if (form.checkValidity() === false
            || newPrice.price > 999.99 || isNaN(newPrice.price)
            || newPrice.duration > 240) {
            form.classList.add('was-validated');
            e.stopPropagation();
            return;
        }

        const { dispatch } = this.props;
        // Alter the duration to milliseconds
        dispatch(alertActions.clear());
        dispatch(treatmentGroupActions.createPrice(this.state.newPrice));
    }

    handleDurationChange(e) {
        const { name, value } = e.target;
        if (value > 240 || isNaN(value)) {
            e.target.classList.add('is-invalid');
        } else {
             e.target.classList.remove('is-invalid');
        }
        const { newPrice } = this.state;
        this.setState({
            newPrice: {
                ...newPrice,
                [name]: value
            }
        });
    }

    handleAmountChange(e) {
            const { name, value } = e.target;
            if (value > 999.99 || isNaN(value)) {
                e.target.classList.add('is-invalid');
            } else {
                 e.target.classList.remove('is-invalid');
            }
            const { newPrice } = this.state;
            this.setState({
                newPrice: {
                    ...newPrice,
                    [name]: value
                }
            });
        }

    placeChanged(placeId) {
        var place = this.props.place.places.find(function(place) {
            return place.id === placeId;
        }, placeId);
        const { newPrice } = this.state;
        this.setState({
            newPrice: {
                ...newPrice,
                placeId: place.id
            }
        });
    }

    onPlaceChanged(event) {
        if (event.currentTarget.value) {
            this.placeChanged(event.currentTarget.value);
        }
    }

    render() {
        const { savingPrice, place } = this.props;
        const { newPrice } = this.state;
            return (
                <div className="col-lg-8">
                    <h2 className="text-center">New price</h2>
                    <div className="card card-primary card-transparent">
                        <div className="card-body">
                            <form id="priceForm" noValidate className="needs-validation my-4" onSubmit={this.handleSubmit}>
                                <div className="form-group row">
                                    <label className="col-sm-4 col-md-3 col-xl-2 col-form-label" htmlFor="duration">Duration (min)</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" name="duration" value={newPrice.duration} required onChange={this.handleDurationChange}/>
                                        <div className="invalid-feedback">
                                            Please enter the duration in minutes. Maximum duration is 4 hours (240 minutes).
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-4 col-md-3 col-xl-2 col-form-label" htmlFor="price">Amount</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" name="price" value={newPrice.price} required onChange={this.handleAmountChange}/>
                                        <div className="invalid-feedback">
                                            Please enter amount. Maximum amount is $999.99 .
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-4 col-md-3 col-xl-2 col-form-label" htmlFor="place">Place</label>
                                    <div className="col-sm-8">
                                        {(!place || place.requestingPlaces) && <span>Loading places ....</span>}
                                        {place && place.places && !place.requestingPlaces && <select class="form-control" name="place" id="place" value={newPrice.placeId ? newPrice.placeId : -1} onChange={this.onPlaceChanged}>
                                                {place.places.map((item, index) =>
                                                    <option key={`place_${index}`} value={item.id}>{item.name}</option>
                                                )}
                                            </select>
                                        }
                                        <div className="invalid-feedback">
                                            Please pick a place
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row align-items-center ml-1">
                                    <button type="button" className="btn btn-secondary col-md-2 col-xl-1 mr-2 mb-2" onClick={this.handleBackClick}>Back</button>
                                    <SubmitButton updating={savingPrice} label="Create price" labelLoading="Saving ..." className="col-md-3 col-xl-2 mr-2 mb-2"/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            );
    }
}

function mapStateToProps(state) {
    const { loggedAs } = state.user;
    const { savingPrice, selectedTreatment } = state.treatmentGroup;
    const { place } = state;

    return {
        loggedAs,
        savingPrice,
        selectedTreatment,
        place
    };
}

const connectedPriceCreatePage = connect(mapStateToProps)(PriceCreatePage);
export { connectedPriceCreatePage as PriceCreatePage };
