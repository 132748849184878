import React from 'react';
import { connect } from 'react-redux';

import { userActions } from '../_actions';

class Therapist extends React.Component {

    constructor(props) {
        super(props);

        this.handleEditTherapist = this.handleEditTherapist.bind(this);
    }

    handleEditTherapist(){
        this.props.dispatch(userActions.selectTherapist(this.props.therapist));
    }

    render() {
        const { therapist } = this.props;
        if (therapist) {
            return(
                <div className="card bg-dark">
                    <div className="card-header">
                        {therapist.surname + ", " + therapist.firstName}
                    </div>
                    <div className="card-body">
                        <div className="card-text">
                            <div>Id - <strong>{therapist.id}</strong></div>
                            <div>Email - <a href={'mailto:' + therapist.email}>{therapist.email}</a></div>
                            <div>Phone - <strong>{therapist.mobilePhoneNumber}{(therapist.mobilePhoneNumber && therapist.homePhone) ? ', ': ''}{therapist.homePhone ? therapist.homePhone: ''}</strong></div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <button type="button" className="btn btn-primary btn-sm mr-1" onClick={this.handleEditTherapist}>Details</button>
                    </div>
                </div>
            );
        } else {
            return "Nothing to see";
        }
    }
}

function mapStateToProps(state) {
    return {};
}

const connectedTherapist = connect(mapStateToProps)(Therapist);
export { connectedTherapist as Therapist };
