import React from 'react';
import Slider from 'react-rangeslider'

import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import 'react-rangeslider/lib/index.css'

import { SingleDatePicker } from 'react-dates';

export class DateTimeField extends React.Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.handleOnFocusChanged = this.handleOnFocusChanged.bind(this);
        this.handleOnTimeChange = this.handleOnTimeChange.bind(this)

        this.state = {
            focusedInput: false,
            hour: this.props.dateTime.hour()
        };
    }

    onChange( dateTime ) {
        if (dateTime) {
            dateTime.hour(this.state.hour);
            if (this.state.hour % 1 !== 0) {
                dateTime.minute(30);
            } else {
                dateTime.minute(0);
            }
        }
        this.props.onChange( dateTime );
    }

    handleOnFocusChanged(value) {
        this.setState({ focusedInput: value.focused })
    }

    handleOnTimeChange(value) {
        this.setState({
            hour : value
        });
        this.props.dateTime.hour(value);
        if (value % 1 !== 0) {
            this.props.dateTime.minute(30);
        } else {
            this.props.dateTime.minute(0);
        }
        this.onChange(this.props.dateTime);
    }

    render() {
        var { id, dateTime, className } = this.props;
        var { hour } = this.state;
        return (
            <div className="form-row">
                <div className={className ? className : 'col-md-12 col-lg-4 col-xl-3'}>
                    <SingleDatePicker
                        date={dateTime} // momentPropTypes.momentObj or null
                        onDateChange={this.onChange} // PropTypes.func.isRequired
                        focused={this.state.focusedInput} // PropTypes.bool
                        onFocusChange={this.handleOnFocusChanged} // PropTypes.func.isRequired
                        id={`sdp_${id}`} // PropTypes.string.isRequired,
                        isOutsideRange={function() { return false;}}
                        displayFormat="D/M/YYYY"
                        placeholder="D/M/YYYY"
                        numberOfMonths={1}
                    />
                </div>
                <div className="col-md-2 col-lg-2 col-xl-2">
                    <input type="text" className="form-control-plaintext font-weight-bold" name={`time_${id}`} readonly value={dateTime.format("HH:mm")}/>
                </div>
                <div className="col-md-8 col-lg-6 col-xl-5">
                    <Slider
                        value={hour}
                        min={0}
                        max={23.5}
                        step={0.5}
                        orientation="horizontal"
                        tooltip={false}
                        onChange={this.handleOnTimeChange}
                        />
                </div>
            </div>
        )
    }
}
