export const placeConstants = {
    PLACES_REQUEST: 'PLACE_PLACES_REQUEST',
    PLACES_SUCCESS: 'PLACE_PLACES_SUCCESS',
    PLACES_FAILURE: 'PLACE_PLACES_FAILURE',

    SELECTPLACE_SUCCESS: 'PLACE_SELECTPLACE_SUCCESS',

    RESETPLACE_SUCCESS: 'PLACE_RESETPLACE_SUCCESS',

    ROOMS_REQUEST: 'PLACE_ROOMS_REQUEST',
    ROOMS_SUCCESS: 'PLACE_ROOMS_SUCCESS',
    ROOMS_FAILURE: 'PLACE_ROOMS_FAILURE',

    SELECTROOM_SUCCESS: 'PLACE_SELECTROOM_SUCCESS',

    RESETROOM_SUCCESS: 'PLACE_RESETROOM_SUCCESS'
}
