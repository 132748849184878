import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { treatmentGroupActions, slotGroupActions, reservationActions } from '../_actions';
import { SelectedItem, Items } from '.';

class BookingPriceComponent extends React.Component {

    constructor(props) {
        super(props);

        this.handleClickSelectPrice = this.handleClickSelectPrice.bind(this);
        this.handleClickResetPrice = this.handleClickResetPrice.bind(this);
    }

    componentDidMount() {
        const { treatmentGroup, reservation } = this.props;
        if (treatmentGroup && !treatmentGroup.loading && !treatmentGroup.prices && reservation.selectedGroup && reservation.selectedTreatment && !reservation.selectedPrice) {
            this.props.dispatch(treatmentGroupActions.getPrices(reservation.selectedTreatment.id));
        }
    }

    handleClickSelectPrice(id) {
        var selectedPrice;
        var selectedPriceId = parseInt(id);
        var prices = this.props.treatmentGroup.prices;
        if (selectedPriceId && prices) {
            for (var i = 0; i < prices.length; i++) {
                if (selectedPriceId === prices[i].id) {
                    selectedPrice = prices[i];
                    this.props.dispatch(reservationActions.selectPrice(selectedPrice));
                    this.requestSlotGroups(selectedPrice);
                    break;
                }
            }
        }

    }

    handleClickResetPrice() {
        this.props.dispatch(reservationActions.resetPrice());
    }

    requestSlotGroups(selectedPrice) {
        var selectedTherapist = this.props.reservation.selectedTherapist;
        if (selectedTherapist && selectedPrice) {
            const start = moment().startOf('isoweek').format('YYYY-MM-DD');
            const end = moment().endOf('isoweek').format('YYYY-MM-DD');
            this.props.dispatch(slotGroupActions.getAvailable(selectedTherapist.id, selectedPrice.id, start, end));
        }
    }

    render() {
        const { treatmentGroup, reservation } = this.props;
        var selectedGroup = reservation.selectedGroup;
        var selectedTreatment = reservation.selectedTreatment;
        var selectedPrice = reservation.selectedPrice;
        if (selectedGroup && selectedTreatment && selectedPrice) {
            return (
                <SelectedItem
                    label="3. Price:"
                    value={selectedPrice.durationFormatted + ' - ' + selectedPrice.priceFormatted}
                    onClickHandle = {this.handleClickResetPrice}/>

            );
        } else if (selectedGroup && selectedTreatment && !selectedPrice) {
            if (treatmentGroup.prices) {
                var sortedValues = treatmentGroup.prices.sort((a, b) => a.duration -b.duration);
                var values = sortedValues.map((price, index) => {
                        return {id: price.id, label: price.durationFormatted + ' - ' + price.priceFormatted};
                    }
                );
                return (
                    <Items label="3. Pick a price:"
                        values={!treatmentGroup.requestingPrices && values}
                        loading={treatmentGroup.requestingPrices}
                        error={false}
                        onClickHandle = {this.handleClickSelectPrice}
                        keyPrefix='price_'/>
                );
            } else {
                return "";
            }
        } else {
            return (
                <fieldset className="form-group">
                    <div className="row mb-1">
                        <legend className="col-form-label col-sm-4 col-md-4 col-lg-3 pt-0 font-weight-bold text-muted">3. Pick price:</legend>
                        <div className="col-sm-4 col-md-5 col-lg-7">
                        </div>
                    </div>
                </fieldset>
            );
        }
    }
}

function mapStateToProps(state) {
    const { treatmentGroup, reservation } = state;

    return {
        treatmentGroup,
        reservation
    };
}

const connectedBookingPriceComponent = connect(mapStateToProps)(BookingPriceComponent);
export { connectedBookingPriceComponent as BookingPriceComponent };
