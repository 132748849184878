import React from 'react';

import { Holiday } from '.';

export class Holidays extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.itemRefs = {};
    }

    render() {
        const { holidays } = this.props;

        if (holidays && holidays.length > 0) {
            return (
                <div className="table-responsive-md">
                    <table id="holidays" className="table table-sm">
                        <thead>
                            <tr>
                              <th scope="col">Id</th>
                              <th scope="col">Start</th>
                              <th scope="col">End</th>
                              <th scope="col">Description</th>
                              <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            holidays.map((holiday) =>
                                <Holiday
                                    key={"Holiday_" + holiday.id}
                                    holiday={holiday}
                                    ref={el => (this.itemRefs[holiday.id] = el)}
                                    />
                            )
                        }
                        </tbody>
                    </table>
                </div>
            );
        } else {
            return (
                <div className="form-row">
                    <div className="col form-control-plaintext">
                        <strong>No holidays found</strong>
                    </div>
                </div>
            );
        }
    }
}
