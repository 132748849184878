import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { Treatments } from '.';

class TreatmentsPage extends React.Component {

    componentDidMount() {
        if (!this.props.loggedAs) {
            this.props.history.push("/login");
        }
        if (!this.props.selectedTreatmentGroup) {
            this.props.history.push("/treatmentGroups");
        }
    }

    componentDidUpdate() {
        if (!this.props.loggedAs) {
            this.props.history.push("/login");
        }
        if (!this.props.selectedTreatmentGroup) {
            this.props.history.push("/treatmentGroups");
        }
    }

    render() {
        const { selectedTreatmentGroup } = this.props;
        if (selectedTreatmentGroup) {
            return (
                <div className="col-lg-10">
                    <h2 className="text-center">Treatments for - <strong>{selectedTreatmentGroup.label}</strong></h2>
                    <div className="card card-primary card-transparent">
                        <div className="card-body">
                            <form id="TreatmentsForm" noValidate className="needs-validation my-4" onSubmit={this.handleSubmit}>
                                <div className="form-group row align-items-center ml-1">
                                    <Link to="/treatmentGroups" className="btn btn-secondary col-md-2 col-xl-1 mr-2 mb-2">
                                        Back
                                    </Link>
                                    <Link to="/treatmentCreate" className="btn btn-primary col-md-3 mr-2 mb-2">
                                        Create new treatment
                                    </Link>
                                </div>
                            </form>
                            <Treatments treatments={selectedTreatmentGroup.treatmentViewList}/>
                        </div>
                    </div>
                </div>
            );
        } else {
            return "";
        }
    }
}

function mapStateToProps(state) {
    const { loggedAs } = state.user;
    const { selectedTreatmentGroup } = state.treatmentGroup;

    return {
        loggedAs,
        selectedTreatmentGroup
    };
}

const connectedTreatmentsPage = connect(mapStateToProps)(TreatmentsPage);
export { connectedTreatmentsPage as TreatmentsPage };
