import React from 'react';
import { connect } from 'react-redux';

import { treatmentGroupActions, reservationActions } from '../_actions';
import { SelectedItem, Items } from '.';

class BookingTreatmentComponent extends React.Component {

    constructor(props) {
        super(props);

        this.handleClickSelectTreatment = this.handleClickSelectTreatment.bind(this);
        this.handleClickResetTreatment = this.handleClickResetTreatment.bind(this);
    }

    handleClickSelectTreatment(id) {
        var selectedTreatment;
        var selectedTreatmentId = parseInt(id);
        var selectedtGroup = this.props.reservation.selectedGroup;
        if (selectedTreatmentId && selectedtGroup.treatmentViewList) {
            for (var i = 0; i < selectedtGroup.treatmentViewList.length; i++) {
                if (selectedTreatmentId === selectedtGroup.treatmentViewList[i].id) {
                    selectedTreatment = selectedtGroup.treatmentViewList[i];
                    this.props.dispatch(reservationActions.selectTreatment(selectedTreatment));
                    this.props.dispatch(treatmentGroupActions.getPrices(selectedTreatment.id));
                    this.props.dispatch(treatmentGroupActions.getTherapists(selectedTreatment.id));
                    break;
                }
            }
        }
    }

    handleClickResetTreatment() {
        this.props.dispatch(reservationActions.resetTreatment());
    }

    render() {
        const { reservation } = this.props;
        var selectedGroup = reservation.selectedGroup;
        var selectedTreatment = reservation.selectedTreatment;
        if (selectedGroup && selectedTreatment) {
            return (
                <SelectedItem
                    label="2. Treatment:"
                    value={selectedTreatment.label}
                    onClickHandle = {this.handleClickResetTreatment}/>
            );
        } else if (selectedGroup && !selectedTreatment) {
            return (
                <Items label="2. Pick treatment:"
                    values={selectedGroup.treatmentViewList}
                    loading={false}
                    error={false}
                    onClickHandle = {this.handleClickSelectTreatment}
                    keyPrefix='treatment_'/>
            );
        } else {
            return (
                <fieldset className="form-group">
                    <div className="row mb-1">
                        <legend className="col-form-label col-sm-4 col-lg-3 pt-0 font-weight-bold text-muted">2. Pick treatment:</legend>
                        <div className="col-sm-8 col-lg-9">
                        </div>
                    </div>
                </fieldset>
            );
        }
    }
}

function mapStateToProps(state) {
    const { treatmentGroup, reservation } = state;

    return {
        treatmentGroup,
        reservation
    };
}

const connectedBookingTreatmentComponent = connect(mapStateToProps)(BookingTreatmentComponent);
export { connectedBookingTreatmentComponent as BookingTreatmentComponent };
