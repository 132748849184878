export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    CREATE_REQUEST: 'USERS_CREATE_REQUEST',
    CREATE_SUCCESS: 'USERS_CREATE_SUCCESS',
    CREATE_FAILURE: 'USERS_CREATE_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',

    UPDATE_REQUEST: 'USERS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'USERS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'USERS_UPDATE_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    UPDATE_PASSWORD_REQUEST: 'USERS_UPDATE_PASSWORD_REQUEST',
    UPDATE_PASSWORD_SUCCESS: 'USERS_UPDATE_PASSWORD_SUCCESS',
    UPDATE_PASSWORD_FAILURE: 'USERS_UPDATE_PASSWORD_FAILURE',

    SECURITY_QUESTION_REQUEST: 'USERS_SECURITY_QUESTION_REQUEST',
    SECURITY_QUESTION_SUCCESS: 'USERS_SECURITY_QUESTION_SUCCESS',
    SECURITY_QUESTION_FAILURE: 'USERS_SECURITY_QUESTION_FAILURE',

    UPDATE_SECURITY_QUESTION_REQUEST: 'USERS_UPDATE_SECURITY_QUESTION_REQUEST',
    UPDATE_SECURITY_QUESTION_SUCCESS: 'USERS_UPDATE_SECURITY_QUESTION_SUCCESS',
    UPDATE_SECURITY_QUESTION_FAILURE: 'USERS_UPDATE_SECURITY_QUESTION_FAILURE',

    RESET_PASSWORD_REQUEST: 'USERS_RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'USERS_RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'USERS_RESET_PASSWORD_FAILURE',

    RESET_PASSWORD_BY_ADMIN_REQUEST: 'USERS_RESET_PASSWORD_BY_ADMIN_REQUEST',
    RESET_PASSWORD_BY_ADMIN_SUCCESS: 'USERS_RESET_PASSWORD_BY_ADMIN_SUCCESS',
    RESET_PASSWORD_BY_ADMIN_FAILURE: 'USERS_RESET_PASSWORD_BY_ADMIN_FAILURE',

    GETCUSTOMERS_REQUEST: 'USERS_GETCUSTOMERS_REQUEST',
    GETCUSTOMERS_SUCCESS: 'USERS_GETCUSTOMERS_SUCCESS',
    GETCUSTOMERS_FAILURE: 'USERS_GETCUSTOMERS_FAILURE',
    GETCUSTOMERS_SEARCHSTRING: 'USERS_GETCUSTOMERS_SEARCHSTRING',
    GETCUSTOMERS_SEARCHPAGE: 'USERS_GETCUSTOMERS_SEARCHPAGE',

    RESETCUSTOMERS_SUCCESS: 'USERS_RESETCUSTOMERS_SUCCESS',

    GETTHERAPISTS_REQUEST: 'USERS_GETTHERAPISTS_REQUEST',
    GETTHERAPISTS_SUCCESS: 'USERS_GETTHERAPISTS_SUCCESS',
    GETTHERAPISTS_FAILURE: 'USERS_GETTHERAPISTS_FAILURE',

    LEAVE_REQUEST: 'USERS_LEAVE_REQUEST',
    LEAVE_SUCCESS: 'USERS_LEAVE_SUCCESS',
    LEAVE_FAILURE: 'USERS_LEAVE_FAILURE',

    FINDLEAVE_REQUEST: 'USERS_FINDLEAVE_REQUEST',
    FINDLEAVE_SUCCESS: 'USERS_FINDLEAVE_SUCCESS',
    FINDLEAVE_FAILURE: 'USERS_FINDLEAVE_FAILURE',

    REMOVELEAVE_DIALOG: 'USERS_REMOVELEAVE_DIALOG',
    REMOVELEAVE_REQUEST: 'USERS_REMOVELEAVE_REQUEST',
    REMOVELEAVE_SUCCESS: 'USERS_REMOVELEAVE_SUCCESS',
    REMOVELEAVE_FAILURE: 'USERS_REMOVELEAVE_FAILURE',

    RESETLEAVE_SUCCESS: 'USERS_RESETLEAVE_SUCCESS',

    SELECTEDCUSTOMER_SUCCESS: 'USERS_SELECTEDCUSTOMER_SUCCESS',
    RESETCUSTOMER_SUCCESS: 'USERS_RESETCUSTOMER_SUCCESS',

    SELECTEDTHERAPIST_SUCCESS: 'USERS_SELECTEDTHERAPIST_SUCCESS',
    RESETTHERAPIST_SUCCESS: 'USERS_RESETTHERAPIST_SUCCESS',
    RESETTHERAPISTS_SUCCESS: 'USERS_RESETTHERAPISTS_SUCCESS',
}
