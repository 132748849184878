import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/en-nz';

import { Modal } from '../_components';

import { userActions } from '../_actions';

class Holiday extends React.Component {

    constructor(props) {
        super(props);

        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);
        this.hideModal = this.hideModal.bind(this);
        if (props.highlight) {
            this.removeHighlight = this.removeHighlight.bind(this);
            this.transitionHighlight = this.transitionHighlight.bind(this);
            this.startTimerRemoval = this.startTimerRemoval.bind(this);
            this.startTimerTransition = this.startTimerTransition.bind(this);
        }

        this.state = {
			modal: false,
            highlight: props.highlight,
		};
    }

    startTimerRemoval() {
        this.timerID1 = setInterval(
            () => this.removeHighlight(),
            4000
        );
    }
    startTimerTransition() {
        this.timerID2 = setInterval(
            () => this.transitionHighlight(),
            2000
        );
    }

    componentWillUnmount() {
        clearInterval(this.timerID1);
        clearInterval(this.timerID2);
    }

    removeHighlight() {
        this.setState({highlight: false});
        clearInterval(this.timerID1);
    }

    transitionHighlight() {
        let row = document.getElementsByClassName('edited-holiday');
        if (row && row[0]) {
            row[0].style.backgroundColor = 'transparent';
            clearInterval(this.timerID2);
        }
    }

    handleEditClick() {
        var holiday = this.props.holiday;
        if (holiday) {
            //this.props.dispatch(userActions.selectFinancialTransaction(holiday));
        }
    }

    handleDeleteClick() {
        //this.setState({ modal: true })
        var holiday = this.props.holiday;
        this.props.dispatch(userActions.removeLeaveDialogVisibility(holiday.id));
    }

    handleDeleteConfirm() {
        var holiday = this.props.holiday;
        if (holiday) {
            this.props.dispatch(userActions.removeLeave(holiday));
        }
    }

    hideModal() {
        // this.setState({ modal: false })
        this.props.dispatch(userActions.removeLeaveDialogVisibility(null));
    }

    render() {
        const { holiday, removingLeave, removeLeaveDialogVisible } = this.props;
        holiday.ref = React.createRef();

        if (holiday) {
            let message = `Are you sure you want to delete the holiday. (${holiday.id})`;
            return (
                <tr ref={holiday.ref}>
                    <td>{holiday.id}</td>
                    <td>{moment(holiday.start).format('DD/MM/YYYY HH:mm:ss')}</td>
                    <td>{moment(holiday.end).format('DD/MM/YYYY HH:mm:ss')}</td>
                    <td>{holiday.title}</td>
                    <td>
                        <button type="button" className="btn btn-danger btn-sm py-0 mr-1 mb-1" style={{"fontSize": "0.8em"}} onClick={this.handleDeleteClick} disabled={removingLeave ? 'disabled' : ''}>
                            {removingLeave ? 'Deleting ...' : 'Delete'}
                        </button>
                        {removeLeaveDialogVisible === holiday.id &&
                            <Modal id="deleteConfirmation"
                                title="Delete confirmation"
                                body= {message}
                                primaryButtonLabel="Yes"
                                primaryButtonLabelWait="Deleting..."
                                primaryFunction={this.handleDeleteConfirm}
                                primaryFunctionWait={removingLeave}
                                primaryButtonClass="btn-danger"
                                cancelFunction={this.hideModal}/>
                        }
                    </td>
                </tr>
            );
        } else {
            return "";
        }
    }
}

function mapStateToProps(state) {
    const { removingLeave, removeLeaveDialogVisible } = state.user;
    return {
        removingLeave, removeLeaveDialogVisible
    };
}

const connectedHoliday = connect(mapStateToProps)(Holiday);
export { connectedHoliday as Holiday };
