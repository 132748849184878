export const financeConstants = {
  GETFINANCIALTRANSACTIONS_REQUEST: 'FINANCES_GETFINANCIALTRANSACTIONS_REQUEST',
  GETFINANCIALTRANSACTIONS_SUCCESS: 'FINANCES_GETFINANCIALTRANSACTIONS_SUCCESS',
  GETFINANCIALTRANSACTIONS_FAILURE: 'FINANCES_GETFINANCIALTRANSACTIONS_FAILURE',

  RESETFINANCIALTRANSACTIONS_SUCCESS: 'FINANCES_RESETFINANCIALTRANSACTIONS_SUCCESS',

  GETFINANCIALTRANSACTIONCATEGORIES_REQUEST: 'FINANCES_GETFINANCIALTRANSACTIONCATEGORIES_REQUEST',
  GETFINANCIALTRANSACTIONCATEGORIES_SUCCESS: 'FINANCES_GETFINANCIALTRANSACTIONCATEGORIES_SUCCESS',
  GETFINANCIALTRANSACTIONCATEGORIES_FAILURE: 'FINANCES_GETFINANCIALTRANSACTIONCATEGORIES_FAILURE',

  GETFINANCIALTRANSACTIONTYPES_REQUEST: 'FINANCES_GETFINANCIALTRANSACTIONTYPES_REQUEST',
  GETFINANCIALTRANSACTIONTYPES_SUCCESS: 'FINANCES_GETFINANCIALTRANSACTIONTYPES_SUCCESS',
  GETFINANCIALTRANSACTIONTYPES_FAILURE: 'FINANCES_GETFINANCIALTRANSACTIONTYPES_FAILURE',

  GETSLOTGROUP_REQUEST: 'FINANCES_GETSLOTGROUP_REQUEST',
  GETSLOTGROUP_SUCCESS: 'FINANCES_GETSLOTGROUP_SUCCESS',
  GETSLOTGROUP_FAILURE: 'FINANCES_GETSLOTGROUP_FAILURE',

  CREATEFINANCIALTRANSACTION_REMEMBERDATE: 'FINANCES_CREATEFINANCIALTRANSACTION_REMEMBERDATE',
  CREATEFINANCIALTRANSACTION_REQUEST: 'FINANCES_CREATEFINANCIALTRANSACTION_REQUEST',
  CREATEFINANCIALTRANSACTION_SUCCESS: 'FINANCES_CREATEFINANCIALTRANSACTION_SUCCESS',
  CREATEFINANCIALTRANSACTION_FAILURE: 'FINANCES_CREATEFINANCIALTRANSACTION_FAILURE',

  UPDATEFINANCIALTRANSACTION_REQUEST: 'FINANCES_UPDATEFINANCIALTRANSACTION_REQUEST',
  UPDATEFINANCIALTRANSACTION_SUCCESS: 'FINANCES_UPDATEFINANCIALTRANSACTION_SUCCESS',
  UPDATEFINANCIALTRANSACTION_FAILURE: 'FINANCES_UPDATEFINANCIALTRANSACTION_FAILURE',

  DELETEFINANCIALTRANSACTION_REQUEST: 'FINANCES_DELETEFINANCIALTRANSACTION_REQUEST',
  DELETEFINANCIALTRANSACTION_SUCCESS: 'FINANCES_DELETEFINANCIALTRANSACTION_SUCCESS',
  DELETEFINANCIALTRANSACTION_FAILURE: 'FINANCES_DELETEFINANCIALTRANSACTION_FAILURE',

  SELECTEDFINANCIALTRANSACTION_SUCCESS: 'FINANCES_SELECTEDFINANCIALTRANSACTION_SUCCESS',
  RESETFINANCIALTRANSACTION_SUCCESS: 'FINANCES_RESETFINANCIALTRANSACTION_SUCCESS',
}
