import { reservationConstants } from '../_constants';

const initialState = {};

export function reservation(state = initialState, action) {
    switch (action.type) {
        case reservationConstants.SELECTEDGROUP_SUCCESS:
            return Object.assign({}, state, {
                selectedGroup: action.selectedGroup
            });
        case reservationConstants.RESETGROUP_SUCCESS:
            return Object.assign({}, state, {
                selectedGroup: null,
                selectedTreatment: null,
                selectedTherapist: null,
                selectedPrice: null,
                selectedEvent: null
            });
        case reservationConstants.RESETGROUP_THERAPIST_SUCCESS:
            return Object.assign({}, state, {
                selectedGroup: null,
                selectedTreatment: null,
                selectedTherapist: null,
                selectedPrice: null
            });
        case reservationConstants.SELECTEDTREATMENT_SUCCESS:
            return Object.assign({}, state, {
                selectedTreatment: action.selectedTreatment
            });
        case reservationConstants.RESETTREATMENT_SUCCESS:
            return Object.assign({}, state, {
                selectedTreatment: null,
                selectedTherapist: null,
                selectedPrice: null,
                selectedEvent: null
            });
        case reservationConstants.RESETTREATMENT_THERAPIST_SUCCESS:
            return Object.assign({}, state, {
                selectedTreatment: null,
                selectedTherapist: null,
                selectedPrice: null
            });
        case reservationConstants.SELECTEDTHERAPIST_SUCCESS:
            return Object.assign({}, state, {
                selectedTherapist: action.selectedTherapist
            });
        case reservationConstants.RESETTHERAPIST_SUCCESS:
            return Object.assign({}, state, {
                selectedTherapist: null,
                selectedEvent: null
            });
        case reservationConstants.RESETTHERAPIST_THERAPIST_SUCCESS:
            return Object.assign({}, state, {
                selectedTherapist: null
            });
        case reservationConstants.SELECTEDPRICE_SUCCESS:
            return Object.assign({}, state, {
                selectedPrice: action.selectedPrice
            });
        case reservationConstants.RESETPRICE_SUCCESS:
            return Object.assign({}, state, {
                selectedPrice: null,
                selectedEvent: null
            });
        case reservationConstants.RESETPRICE_THERAPIST_SUCCESS:
            return Object.assign({}, state, {
                selectedPrice: null
            });
        case reservationConstants.SELECTEDEVENT_SUCCESS:
            return Object.assign({}, state, {
                selectedEvent: action.selectedEvent
            });
        case reservationConstants.RESETEVENT_SUCCESS:
            return Object.assign({}, state, {
                selectedEvent: null
            });
        case reservationConstants.SELECTEDEVENTBACKDESTINATION_SUCCESS:
            return Object.assign({}, state, {
                selectedEventBackDestination: action.backDestination
            });
        case reservationConstants.RESETEVENTBACKDESTINATION_SUCCESS:
            return Object.assign({}, state, {
                selectedEventBackDestination: null
            });
        case reservationConstants.SELECTEDDATE_SUCCESS:
            return Object.assign({}, state, {
                selectedDate: action.selectedDate
            });
        case reservationConstants.MODIFYBASKET_SUCCESS: {
                var selectedBasketTherapist = action.selectedBasketTherapist ? action.selectedBasketTherapist : state.selectedBasketTherapist;
                return Object.assign({}, state, {
                    selectedPrices: action.selectedPrices,
                    selectedBasketTherapist: selectedBasketTherapist
                });
            }
        case reservationConstants.RESETBASKET_SUCCESS:
            return Object.assign({}, state, {
                selectedPrices: null,
                selectedBasketTherapist: null
            });
        default:
            return state;
    }
}
