import { slotGroupConstants, reservationConstants, reservationTherapistConstants } from '../_constants';
import { slotGroupService } from '../_services';
import { alertActions } from './';
import { http, history } from '../_helpers';

export const slotGroupActions = {
    getAvailable,
    getBookings,
    selectEvent,
    selectEventTherapist,
    selectSlotGroup,
    createSlotGroup,
    onBreak,
    pickSlotGroupType,
    selectDate,
    resetEvent,
    resetEventBackDestination,
    reserve,
    confirm,
    remove,
    reject,
    pay,
    cancel,
    updatePrices,
    getLast,
    getUpcoming
};

function getAvailable(therapistId, priceIds, start, end) {
    return dispatch => {
        dispatch(request());

        slotGroupService.getAvailable(therapistId, priceIds, start, end)
            .then(
                slotGroups => dispatch(success(slotGroups)),
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request() { return { type: slotGroupConstants.GETAVAILABLE_REQUEST } }
    function success(slotGroups) { return { type: slotGroupConstants.GETAVAILABLE_SUCCESS, slotGroups } }
    function failure(message) { return { type: slotGroupConstants.GETAVAILABLE_FAILURE, message } }
}

function getBookings(start, end) {
    return dispatch => {
        dispatch(request());

        // 5 is id of Euromassage - TODO fetch the real Id eventually
        slotGroupService.getBookings(start, end, 5)
            .then(
                slotGroups => dispatch(success(slotGroups)),
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request() { return { type: slotGroupConstants.GETBOOKINGS_REQUEST } }
    function success(slotGroups) { return { type: slotGroupConstants.GETBOOKINGS_SUCCESS, slotGroups } }
    function failure(message) { return { type: slotGroupConstants.GETBOOKINGS_FAILURE, message } }
}

function selectEvent(selectedEvent) {
    return dispatch => {
        dispatch(success(selectedEvent));
    };
    function success(selectedEvent) {
      return { type: reservationConstants.SELECTEDEVENT_SUCCESS, selectedEvent }
    }
}

function selectEventTherapist(selectedEvent) {
    return dispatch => {
        dispatch(success(selectedEvent));
    };
    function success(selectedEvent) {
      return { type: reservationTherapistConstants.SELECTEDEVENT_SUCCESS, selectedEvent }
    }
}

function selectSlotGroup(selectedSlotGroup, loggedAs) {
    return dispatch => {
        dispatch(success(selectedSlotGroup));
        if (selectedSlotGroup.eventType === 'leave') {
            history.push('/leaveDetails');
        } else {
            history.push('/slotGroupDetails');
        }
    };
    function success(selectedEvent) {
      return { type: reservationConstants.SELECTEDEVENT_SUCCESS, selectedEvent }
    }
}

function createSlotGroup(newSlotGroup) {
    return dispatch => {
        dispatch(success(newSlotGroup));
        history.push('/slotGroupCreate');
    };
    function success(selectedEvent) {
      return { type: reservationTherapistConstants.SELECTEDEVENT_SUCCESS, selectedEvent }
    }
}

function pickSlotGroupType(newSlotGroup) {
    return dispatch => {
        dispatch(success(newSlotGroup));
        history.push('/slotGroupType');
    };
    function success(selectedEvent) {
      return { type: reservationTherapistConstants.SELECTEDEVENT_SUCCESS, selectedEvent }
    }
}

function selectDate(selectedDate) {
    return dispatch => {
        dispatch(success(selectedDate));
    };
    function success(selectedDate) {
      return { type: reservationConstants.SELECTEDDATE_SUCCESS, selectedDate }
    }
}

function resetEvent() {
    return dispatch => {
        dispatch(success());
    };
    function success() {
      return { type: reservationConstants.RESETEVENT_SUCCESS }
    }
}

function resetEventBackDestination() {
    return dispatch => {
        dispatch(success());
    };
    function success() {
      return { type: reservationConstants.RESETEVENTBACKDESTINATION_SUCCESS }
    }
}


function reserve(event, loggedAs) {
    return dispatch => {
        dispatch(request());

        slotGroupService.create(event)
            .then(
                event => {
                    dispatch(success(event));
                    var canReserveTherapist = loggedAs && loggedAs.permissions && loggedAs.permissions.includes('canReserveTherapist');
                    if (canReserveTherapist) {
                        dispatch(resetSlotGroups());
                        dispatch(resetReservationTherapist());
                        dispatch(navigateToDate(event.start));
                        history.push('/bookingCalendar');
                        dispatch(alertActions.success('The treatment has been reserved.'));
                    } else {
                        dispatch(resetReservation());
                        dispatch(resetBasket());
                        dispatch(resetUpcoming());
                        history.push('/');
                        dispatch(alertActions.success('Your treatment has been reserved.'));
                    }
                },
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request() { return { type: slotGroupConstants.RESERVE_REQUEST } }
    function success(event) { return { type: slotGroupConstants.RESERVE_SUCCESS, event } }
    function failure(message) { return { type: slotGroupConstants.RESERVE_FAILURE, message } }
    function resetReservation() { return { type: reservationConstants.RESETGROUP_SUCCESS } }
    function resetBasket() { return { type: reservationConstants.RESETBASKET_SUCCESS } }
    function resetReservationTherapist() { return { type: reservationTherapistConstants.RESETGROUP_SUCCESS } }
    function navigateToDate(selectedDate) { return { type: reservationConstants.SELECTEDDATE_SUCCESS, selectedDate } }
    function resetUpcoming() { return { type: slotGroupConstants.RESETUPCOMING_SUCCESS } }
    function resetSlotGroups() { return { type: slotGroupConstants.RESETGROUPS_SUCCESS } }
}

function onBreak(event) {
    return dispatch => {
        dispatch(request());

        slotGroupService.create(event)
            .then(
                event => {
                    dispatch(success(event));
                    dispatch(resetSlotGroups());
                    dispatch(resetReservationTherapist());
                    history.push('/bookingCalendar');
                    dispatch(alertActions.success('The break has been inserted.'));
                },
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request() { return { type: slotGroupConstants.RESERVE_REQUEST } }
    function success(event) { return { type: slotGroupConstants.RESERVE_SUCCESS, event } }
    function failure(message) { return { type: slotGroupConstants.RESERVE_FAILURE, message } }
    function resetReservationTherapist() { return { type: reservationTherapistConstants.RESETGROUP_SUCCESS, event } }
    function resetSlotGroups() { return { type: slotGroupConstants.RESETGROUPS_SUCCESS, event } }
}

function confirm(event) {
    return dispatch => {
        dispatch(request());

        slotGroupService.update(event)
            .then(
                event => {
                    dispatch(success(event));
                    dispatch(resetReservation());
                    dispatch(resetSlotGroups());
                    history.push('/bookingCalendar');
                    dispatch(alertActions.success('The session has been confirmed.'));
                },
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request() { return { type: slotGroupConstants.CONFIRM_REQUEST } }
    function success(event) { return { type: slotGroupConstants.CONFIRM_SUCCESS, event } }
    function failure(message) { return { type: slotGroupConstants.CONFIRM_FAILURE, message } }
    function resetReservation() { return { type: reservationConstants.RESETGROUP_SUCCESS, event } }
    function resetSlotGroups() { return { type: slotGroupConstants.RESETGROUPS_SUCCESS, event } }
}

function reject(event) {
    return dispatch => {
        dispatch(request());

        slotGroupService.update(event)
            .then(
                event => {
                    dispatch(success(event));
                    dispatch(resetReservation());
                    dispatch(resetSlotGroups());
                    history.push('/bookingCalendar');
                    dispatch(alertActions.success('The session has been rejected.'));
                },
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request() { return { type: slotGroupConstants.REJECT_REQUEST } }
    function success(event) { return { type: slotGroupConstants.REJECT_SUCCESS, event } }
    function failure(message) { return { type: slotGroupConstants.REJECT_FAILURE, message } }
    function resetReservation() { return { type: reservationConstants.RESETGROUP_SUCCESS, event } }
    function resetSlotGroups() { return { type: slotGroupConstants.RESETGROUPS_SUCCESS, event } }
}

function pay(event) {
    return dispatch => {
        dispatch(request());

        slotGroupService.update(event)
            .then(
                event => {
                    dispatch(success(event));
                    dispatch(resetReservation());
                    dispatch(resetSlotGroups());
                    history.push('/bookingCalendar');
                    dispatch(alertActions.success('The session has been payed.'));
                },
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request() { return { type: slotGroupConstants.PAY_REQUEST } }
    function success(event) { return { type: slotGroupConstants.PAY_SUCCESS, event } }
    function failure(message) { return { type: slotGroupConstants.PAY_FAILURE, message } }
    function resetReservation() { return { type: reservationConstants.RESETGROUP_SUCCESS, event } }
    function resetSlotGroups() { return { type: slotGroupConstants.RESETGROUPS_SUCCESS, event } }
}

function cancel(event) {
    return dispatch => {
        dispatch(request());

        slotGroupService.update(event)
            .then(
                event => {
                    dispatch(success(event));
                    dispatch(resetReservation());
                    dispatch(resetUpcoming());
                    dispatch(slotGroupActions.getLast());
                    dispatch(slotGroupActions.getUpcoming());
                    history.push('/');
                    dispatch(alertActions.success('Your treatment has been canceled.'));
                },
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request() { return { type: slotGroupConstants.CANCEL_REQUEST } }
    function success(event) { return { type: slotGroupConstants.CANCEL_SUCCESS, event } }
    function failure(message) { return { type: slotGroupConstants.CANCEL_FAILURE, message } }
    function resetReservation() { return { type: reservationConstants.RESETGROUP_SUCCESS, event } }
    function resetUpcoming() { return { type: slotGroupConstants.RESETUPCOMING_SUCCESS, event } }
}

function remove(event) {
    return dispatch => {
        dispatch(request());

        slotGroupService.remove(event)
            .then(
                event => {
                    dispatch(success(event));
                    dispatch(resetReservation());
                    dispatch(resetSlotGroups());
                    history.push('/bookingCalendar');
                    dispatch(alertActions.success('The slot has been removed.'));
                },
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request() { return { type: slotGroupConstants.DELETE_REQUEST } }
    function success(event) { return { type: slotGroupConstants.DELETE_SUCCESS, event } }
    function failure(message) { return { type: slotGroupConstants.DELETE_FAILURE, message } }
    function resetReservation() { return { type: reservationConstants.RESETGROUP_SUCCESS, event } }
    function resetSlotGroups() { return { type: slotGroupConstants.RESETGROUPS_SUCCESS, event } }
}

function updatePrices(sessions, event) {
    var sessionUpdatingMap = [];
    return dispatch => {
        dispatch(request());

        sessions.forEach((session, index) => {
            // So we might have multiple sessions within group. Some of the updates might be OK some might be with an error
            // When there is an error we can go to a success() so we need to gather all the responses and deal with the outcome
            // after that. Let's put the results into map, including the in flight status
            sessionUpdatingMap.push(
                {session: session, inFlight:true, success: false, error: ""}
            );
            slotGroupService.updateSession(session, event)
                .then(
                    session => {
                        var sessionUpdatingIndex = sessionUpdatingMap.findIndex( obj => obj.session.id === session.id); // Get the index
                        var sessionUpdating = sessionUpdatingMap[sessionUpdatingIndex] // Get the object
                        sessionUpdating.inFlight = false; // Update it
                        sessionUpdating.success = true;
                        sessionUpdating.session = session;
                        sessionUpdatingMap[sessionUpdatingIndex] = sessionUpdating; // Put it bak to map
                        dispatch(priceUpdated());
                    },
                    error => {
                        var sessionUpdatingIndex = sessionUpdatingMap.findIndex( obj => obj.session.id === session.id); // Get the index
                        var sessionUpdating = sessionUpdatingMap[sessionUpdatingIndex] // Get the object
                        sessionUpdating.inFlight = false; // Update it
                        sessionUpdating.success = false;
                        sessionUpdating.error = http.handleServiceError(error);
                        sessionUpdatingMap[sessionUpdatingIndex] = sessionUpdating; // Put it bak to map
                        dispatch(priceUpdated());
                    }
                );
        });
    };

    function priceUpdated() {
        return dispatch => {
            var anyErrors = false;
            var BreakException = {};
            try {
                // Check if we are still in flight
                sessionUpdatingMap.forEach(sessionUpdating => {
                    if (sessionUpdating.inFlight) {
                        // Whoops still something in flight so get out of here by breaking the loop
                        throw BreakException; // The only way to safely break the forEach loop
                    }
                    if (!sessionUpdating.success) {
                        anyErrors = true;
                    }
                });
            } catch (e) {
                // loop is broken so get out of the method
                return;
            }
            if (anyErrors) {
                // Show alerts for all errors (the alertAction only shows one message at the time so collate them into one variable)
                var errors = "";
                sessionUpdatingMap.forEach(sessionUpdating => {
                    if (!sessionUpdating.success) {
                        dispatch(failure(sessionUpdating.error));
                        errors = errors + sessionUpdating.error + "\n";
                        dispatch(alertActions.error);
                    }
                    dispatch(alertActions.error(errors));
                });
            } else {
                // But only one OK message for success
                event.sessions.forEach((sessionOld, index) => {
                    var sessionUpdatingIndex = sessionUpdatingMap.findIndex( obj => obj.session.id === sessionOld.id); // Get the index
                    var sessionNew = sessionUpdatingMap[sessionUpdatingIndex].session // Get the object
                    event.sessions[index] = sessionNew;
                });
                dispatch(success());
                dispatch(resetReservation());
                dispatch(resetSlotGroups());
                history.push('/bookingCalendar');
                alertActions.success('The prices have been updated.');
            }
        }
    }

    function request() { return { type: slotGroupConstants.PRICEUPDATE_REQUEST } }
    function success() { return { type: slotGroupConstants.PRICEUPDATE_SUCCESS } }
    function failure(message) { return { type: slotGroupConstants.PRICEUPDATE_FAILURE, message } }
    function resetReservation() { return { type: reservationConstants.RESETGROUP_SUCCESS, event } }
    function resetSlotGroups() { return { type: slotGroupConstants.RESETGROUPS_SUCCESS, event } }
}

function getLast() {
    return dispatch => {
        dispatch(request());

        slotGroupService.getLast()
            .then(
                slotGroup => dispatch(success(slotGroup)),
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request() { return { type: slotGroupConstants.GETLAST_REQUEST } }
    function success(slotGroup) { return { type: slotGroupConstants.GETLAST_SUCCESS, slotGroup } }
    function failure(message) { return { type: slotGroupConstants.GETLAST_FAILURE, message } }
}

function getUpcoming() {
    return dispatch => {
        dispatch(request());

        slotGroupService.getUpcoming()
            .then(
                slotGroups => dispatch(success(slotGroups)),
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request() { return { type: slotGroupConstants.GETUPCOMING_REQUEST } }
    function success(slotGroups) { return { type: slotGroupConstants.GETUPCOMING_SUCCESS, slotGroups } }
    function failure(message) { return { type: slotGroupConstants.GETUPCOMING_FAILURE, message } }
}
