import React from 'react';
import { PoseGroup } from 'react-pose';

export class SelectedItem extends React.Component {
    state = { isVisible: false };

    constructor(props) {
        super(props);

        this.sleep = this.sleep.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        this.setState({
            isVisible: true
        });
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async handleClick(e) {
        this.setState({
            isVisible: false
        });
        await this.sleep(100);
        this.props.onClickHandle();
    }

    render() {
        const { label, value } = this.props;
        const { isVisible } = this.state;
        return (
            <PoseGroup>
                {isVisible && <fieldset key="selectedValue" className="form-group">
                    <div className="row mb-1 align-items-center">
                        <legend className="col-form-label col-6 col-sm-4 col-xl-3 pt-0">{label}</legend>
                        <div className="col-6 col-sm-4 col-xl-6">
                            <div className="form-control-plaintext">
                                <span className="p-1 rounded font-weight-bold">
                                    {value}
                                </span>
                            </div>
                        </div>
                        <div className="col-sm-3 col-xl-2 ml-3 ">
                            <button type="button" className="btn btn-secondary btn-block btn-sm" onClick={this.handleClick}>Change this</button>
                        </div>
                    </div>
                </fieldset>
                }
            </PoseGroup>
        );
    }
}
