import { pingService } from '../_services';
import { userConstants } from '../_constants';
import { history, http } from '../_helpers';

import { userActions } from '.';

export const pingActions = {
    ping
};

function ping() {
  return dispatch => {
      pingService.ping()
                  .then(response => {},
                  error => {
                      dispatch(userActions.logout());
                  });
  };
}