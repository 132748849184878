import React from 'react';

import { Therapist } from '.';

export class Therapists extends React.Component {

    render() {
        const { therapists, searching } = this.props;
        if (therapists && therapists.length > 0) {
            return (
                <div className="card-columns">
                    {therapists.map((therapist, index) =>
                        <Therapist key={"Therapists_" + therapist.id} therapist={therapist}/>
                    )}
                </div>
            );
        } else if (searching) {
            return (<footer className="blockquote-footer col-sm-12">Searching ... </footer>);
        } else {
            return (
                <div className="form-group row">
                    {(!therapists || therapists.length === 0) && !searching && <footer className="blockquote-footer col-sm-12">Whoops ... no therapists found in the system</footer>}
                </div>
            );
        }
    }
}
