import React from 'react';

import { Voucher } from '.';

export class Vouchers extends React.Component {

    render() {
        const { vouchers } = this.props;

        if (vouchers && vouchers.length > 0) {
            return (
                <div className="card-columns">
                    {
                        vouchers.map((voucher, index) =>
                            <Voucher key={"Voucher_" + voucher.id} voucher={voucher}/>
                        )
                    }
                </div>
            );
        } else {
            return (
                <div className="form-group row">
                    <footer className="blockquote-footer col-sm-12">No vouchers found</footer>
                </div>
            );
        }
    }
}
