import React from 'react';

import { TreatmentTherapist } from '.';

export class TreatmentTherapists extends React.Component {

    constructor(props) {
        super(props);

        this.handleSelectTherapist = this.handleSelectTherapist.bind(this);
    }

    handleSelectTherapist(therapistId, ticked) {
        var roster = this.props.roster;
        var therapists = this.props.therapists;
        var treatment = this.props.treatment;
        treatment.therapistsRoster = []
        var updatedRoster;
        if (ticked) {
            // Find the therapist in roster
            var indexOfItem = roster.findIndex(item => item.therapist.id === therapistId);
            if (indexOfItem === -1) {
                // so it's not in, let's find therapist in the list
                var indexOfTherapist = therapists.findIndex(therapist => therapist.id === therapistId)
                if (indexOfTherapist !== -1) {
                    roster.push({
                        therapist: therapists[indexOfTherapist],
                        treatment: treatment
                    });
                }
            }
            updatedRoster = roster;
        } else {
            //remove it from roster
            updatedRoster = roster.filter(item => item.therapist.id !== therapistId)
        }
        this.props.onSelectTherapist(updatedRoster);
    }

    render() {
        const { therapists, loading, roster } = this.props;

        if (loading) {
            return (<em>Loading ...</em>);
        } else if (therapists && therapists.length > 0) {
            return (
                <ul class="list-group list-group-horizontal-xl mx-auto">
                    {
                        therapists.map((therapist, index) => {
                            var ticked = false;
                            if (roster) {
                                for (var i = 0; i < roster.length; i++) {
                                    if (roster[i].therapist.id === therapist.id) {
                                        ticked = true;
                                        break;
                                    }
                                }
                            }
                            return <TreatmentTherapist therapist={therapist} ticked={ticked} onSelectTherapist={this.handleSelectTherapist}/>;
                        })
                    }
                </ul>
            );
        } else {
            return (
                <div className="is-invalid">
                    <span>No therapists found!</span>
                </div>
            );
        }
    }
}
