import React from 'react';

import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';

import { DateRangePicker } from 'react-dates';

export class DateRangeField extends React.Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.handleOnFocusChanged = this.handleOnFocusChanged.bind(this);

        this.state = {
            focusedInput: ''
        };
    }

    onChange( range ) {
        this.props.onChange( range.startDate, range.endDate );        
    }

    handleOnFocusChanged(value) {
        this.setState({ focusedInput: value })
    }

    isOutsideRange(){
        return false;
    }

    render() {
        var { startDateId, startDateTime, endDateId, endDateTime, className, block, required } = this.props;
        return (
            <DateRangePicker
                className={className}
                startDateId={`sdp_${startDateId}`} // PropTypes.string.isRequired,
                startDate={startDateTime} // momentPropTypes.momentObj or null
                endDateId={`sdp_${endDateId}`} // PropTypes.string.isRequired,
                endDate={endDateTime} // momentPropTypes.momentObj or null
                onDatesChange={this.onChange} // PropTypes.func.isRequired
                focusedInput={this.state.focusedInput} // PropTypes.bool
                onFocusChange={this.handleOnFocusChanged} // PropTypes.func.isRequired

                required={required}
                isOutsideRange={this.isOutsideRange}
                displayFormat="D/M/YYYY"
                startDatePlaceholderText="D/M/YYYY"
                endDatePlaceholderText="D/M/YYYY"
                numberOfMonths={1}
                orientation={'horizontal'}
                noBorder={true}
                daySize={30}
                block={block}
                customArrowIcon={<i class="fa fa-arrow-right"></i>}
                />

        )
    }
}
