import moment from 'moment';
import 'moment/locale/en-nz';
import { financeConstants } from '../_constants';

const initialState = {};

export function finance(state = initialState, action) {
    switch (action.type) {
        case financeConstants.GETFINANCIALTRANSACTIONS_REQUEST:
            return Object.assign({}, state, {
                requestingFinancialTransactions: true,
                financialTransactionsFromDate: action.fromDate,
                financialTransactionsToDate: action.toDate,
            });
        case financeConstants.GETFINANCIALTRANSACTIONS_SUCCESS:
            return Object.assign({}, state, {
                requestingFinancialTransactions: false,
                financialTransactions: action.financialTransactions.financialTransactionViews,
                moneyComingIn: action.financialTransactions.moneyComingIn,
                expenses: action.financialTransactions.expenses,
                drawings: action.financialTransactions.drawings
            });
        case financeConstants.GETFINANCIALTRANSACTIONS_FAILURE:
            return Object.assign({}, state, {
                requestingFinancialTransactions: false,
                error: action.message,
                financialTransactions: 'failure',
                moneyComingIn: null,
                expenses: null,
                drawings: null
            });
        case financeConstants.RESETFINANCIALTRANSACTIONS_SUCCESS:
            return Object.assign({}, state, {
                financialTransactions: null,
                moneyComingIn: null,
                expenses: null,
                drawings: null,
                financialTransactionsFromDate: action.fromDate,
                financialTransactionsToDate: action.toDate,
                requestingFinancialTransactions: false,
            });
        case financeConstants.GETFINANCIALTRANSACTIONCATEGORIES_REQUEST:
            return Object.assign({}, state, {
                requestingFinancialTransactionCategories: true
            });
        case financeConstants.GETFINANCIALTRANSACTIONCATEGORIES_SUCCESS:
            return Object.assign({}, state, {
                requestingFinancialTransactionCategories: false,
                financialTransactionCategories: action.financialTransactionCategories.financialTransactionCategoryViews
            });
        case financeConstants.GETFINANCIALTRANSACTIONCATEGORIES_FAILURE:
            return Object.assign({}, state, {
                requestingFinancialTransactionCategories: false,
                error: action.message
            });
        case financeConstants.GETFINANCIALTRANSACTIONTYPES_REQUEST:
            return Object.assign({}, state, {
                requestingFinancialTransactionTypes: true
            });
        case financeConstants.GETFINANCIALTRANSACTIONTYPES_SUCCESS:
            return Object.assign({}, state, {
                requestingFinancialTransactionTypes: false,
                financialTransactionTypes: action.financialTransactionTypes.financialTransactionTypeViews
            });
        case financeConstants.GETFINANCIALTRANSACTIONTYPES_FAILURE:
            return Object.assign({}, state, {
                requestingFinancialTransactionTypes: false,
                error: action.message
            });
        case financeConstants.GETSLOTGROUP_REQUEST:
            return Object.assign({}, state, {
                requestingSlotGroup: true
            });
        case financeConstants.GETSLOTGROUP_SUCCESS:
            var updatedSelectedFinancialTransaction = {...state.selectedFinancialTransaction};
            updatedSelectedFinancialTransaction.slotGroup = action.slotGroup

            return Object.assign({}, state, {
                requestingSlotGroup: false,
                selectedFinancialTransaction: updatedSelectedFinancialTransaction,
                financialTransactions: state.financialTransactions.map(
                    (transaction, i) => transaction.id === state.selectedFinancialTransaction.id ? updatedSelectedFinancialTransaction : transaction
                )
            });
        case financeConstants.GETSLOTGROUP_FAILURE:
            return Object.assign({}, state, {
                requestingSlotGroup: false,
                error: action.message
            });
        case financeConstants.CREATEFINANCIALTRANSACTION_REMEMBERDATE:
            return Object.assign({}, state, { lastUsedDate: action.financialTransactionDate });
        case financeConstants.CREATEFINANCIALTRANSACTION_REQUEST:
            return Object.assign({}, state, { creatingFinancialTransaction: true });
        case financeConstants.CREATEFINANCIALTRANSACTION_SUCCESS:
            if (state.financialTransactions) {
                let updatedFinancialTransactionsForCreate = state.financialTransactions.concat(action.financialTransaction)
                updatedFinancialTransactionsForCreate.sort(function(a,b){
                    return moment(a.date).diff(moment(b.date));
                });
                var moneyComingIn = 0;
                var drawings = 0;
                var expenses = 0;
                updatedFinancialTransactionsForCreate.forEach((transaction) => {
                    if (transaction.transactionCategoryLabel.toUpperCase() === 'SALES') {
                        moneyComingIn += Math.abs(transaction.amount);
                    } else if (transaction.transactionCategoryLabel.toUpperCase() === 'DRAWINGS') {
                        drawings += Math.abs(transaction.amount);
                    } else {
                        expenses += Math.abs(transaction.amount);
                    }
                })
                return Object.assign({}, state, {
                    creatingFinancialTransaction: false,
                    lastTransactionId: action.financialTransaction.id,
                    financialTransactions: updatedFinancialTransactionsForCreate,
                    moneyComingIn: moneyComingIn,
                    expenses: drawings,
                    drawings: expenses
                });
            } else {
                return Object.assign({}, state, {
                    creatingFinancialTransaction: false,
                    lastTransactionId: action.financialTransaction.id
                });
            }
        case financeConstants.CREATEFINANCIALTRANSACTION_FAILURE:
            return Object.assign({}, state, {
                creatingFinancialTransaction: false,
                error: action.message
            });
        case financeConstants.UPDATEFINANCIALTRANSACTION_REQUEST:
            return Object.assign({}, state, { updatingFinancialTransaction: true });
        case financeConstants.UPDATEFINANCIALTRANSACTION_SUCCESS:
            let updatedFinancialTransactionsForUpdate = state.financialTransactions.map(
                (transaction, i) => transaction.id === action.financialTransaction.id ? action.financialTransaction : transaction
            )
            updatedFinancialTransactionsForUpdate.sort(function(a,b){
                return moment(a.date).diff(moment(b.date));
            });
            var moneyComingIn = 0;
            var drawings = 0;
            var expenses = 0;
            updatedFinancialTransactionsForUpdate.forEach((transaction) => {
                if (transaction.transactionCategoryLabel.toUpperCase() === 'SALES') {
                    moneyComingIn += Math.abs(transaction.amount);
                } else if (transaction.transactionCategoryLabel.toUpperCase() === 'DRAWINGS') {
                    drawings += Math.abs(transaction.amount);
                } else {
                    expenses += Math.abs(transaction.amount);
                }
            })
            return Object.assign({}, state, {
                updatingFinancialTransaction: false,
                lastTransactionId: action.financialTransaction.id,
                financialTransactions: updatedFinancialTransactionsForUpdate,
                moneyComingIn: moneyComingIn,
                expenses: expenses,
                drawings: drawings
            });
        case financeConstants.UPDATEFINANCIALTRANSACTION_FAILURE:
            return Object.assign({}, state, {
                updatingFinancialTransaction: false,
                error: action.message
            });
        case financeConstants.DELETEFINANCIALTRANSACTION_REQUEST:
            return Object.assign({}, state, { deletingFinancialTransaction: true });
        case financeConstants.DELETEFINANCIALTRANSACTION_SUCCESS:
            let indexTransaction = state.financialTransactions.findIndex(transaction => transaction.id === action.financialTransaction.id);
            // Don't use filter or splice as they are mutable ... have to use slice
            let slicedTransactions = [...state.financialTransactions.slice(0, indexTransaction), ...state.financialTransactions.slice(indexTransaction + 1)]
            var moneyComingIn = 0;
            var drawings = 0;
            var expenses = 0;
            slicedTransactions.forEach((transaction) => {
                if (transaction.transactionCategoryLabel.toUpperCase() === 'SALES') {
                    moneyComingIn += Math.abs(transaction.amount);
                } else if (transaction.transactionCategoryLabel.toUpperCase() === 'DRAWINGS') {
                    drawings += Math.abs(transaction.amount);
                } else {
                    expenses += Math.abs(transaction.amount);
                }
            })
            return Object.assign({}, state, {
                financialTransactions: slicedTransactions,
                deletingFinancialTransaction: false,
                moneyComingIn: moneyComingIn,
                expenses: expenses,
                drawings: drawings
            });
        case financeConstants.DELETEFINANCIALTRANSACTION_FAILURE:
            return Object.assign({}, state, {
                deletingFinancialTransaction: false,
                error: action.message
            });
        case financeConstants.SELECTEDFINANCIALTRANSACTION_SUCCESS:
            return Object.assign({}, state, {
                selectedFinancialTransaction: action.selectedFinancialTransaction,
                lastTransactionId: action.selectedFinancialTransaction.id,
                updating: false,
            });
        case financeConstants.RESETFINANCIALTRANSACTION_SUCCESS:
            return Object.assign({}, state, {
                selectedFinancialTransaction: null
            });
        default:
            return state;
    }
}