import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/en-nz';
import { formatting } from '../_helpers';

import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';

import { slotGroupActions, placeActions, userActions, reservationTherapistActions } from '../_actions';
import { SubmitButton, DateTimeField } from '../_components';

class BookingTherapistSlotGroupTypePage extends React.Component {

    constructor(props) {
        super(props);

        this.onPlaceChanged = this.onPlaceChanged.bind(this);
        this.placeChanged = this.placeChanged.bind(this);
        this.onRoomChanged = this.onRoomChanged.bind(this);
        this.onTherapistChanged = this.onTherapistChanged.bind(this);
        this.therapistChanged = this.therapistChanged.bind(this);
        this.handleBackClick = this.handleBackClick.bind(this);
        this.handleNewReservationClick = this.handleNewReservationClick.bind(this);
        this.handleOnBreakClick = this.handleOnBreakClick.bind(this);
        this.handleOnBreakBackClick = this.handleOnBreakBackClick.bind(this);
        this.handleOnBreakSubmit = this.handleOnBreakSubmit.bind(this);
        this.handleOnLeaveClick = this.handleOnLeaveClick.bind(this);
        this.handleOnLeaveBackClick = this.handleOnLeaveBackClick.bind(this);
        this.handleOnStartDateChanged = this.handleOnStartDateChanged.bind(this);
        this.handleOnStartFocusChanged = this.handleOnStartFocusChanged.bind(this);
        this.handleOnEndDateChanged = this.handleOnEndDateChanged.bind(this);
        this.handleOnEndFocusChanged = this.handleOnEndFocusChanged.bind(this);
        this.handleOnLeaveSubmit = this.handleOnLeaveSubmit.bind(this);
        this.state = {
            showOnBreak: false,
            showOnLeave: false,
            startDate: null,
            endDate: null,
            focusedInputStart: false,
            focusedInputEnd: false,
            newLeave: {
                start: null,
                end: null,
            }
        };
    }


    componentDidMount() {
        if (!this.props.loggedAs) {
            this.props.history.push("/login");
        }
        if (!this.props.reservationTherapist.selectedEvent) {
            this.props.history.push("/bookingCalendar");
        }

        this.populateData();
    }

    componentDidUpdate() {
        this.populateData();
    }

    populateData() {
        const { dispatch, place, reservationTherapist, user } = this.props;

        if (!place.places && !place.requestingPlaces) {
            dispatch(placeActions.getPlaces());
        } else if (place.places && !place.selectedPlace) {
            // Let's preselect a first non customer place
            const places = place.places;
            for (var i = 0; i < places.length; i++) {
                if (!places[i].customerAddress) {
                    this.placeChanged(places[i].id);
                    break;
                }
            }
        }
        if (place.rooms && !place.requestingRooms && !place.selectedRoom) {
            this.roomChanged(place.rooms[0].id);
        }
        if (!user.therapists && !user.requestingTherapists) {
            dispatch(userActions.getTherapists());
        } else if (user.therapists && !reservationTherapist.selectedTherapist) {
            this.therapistChanged(user.therapists[0].id);
        }
        if (!this.state.newLeave.start && reservationTherapist.selectedEvent) {
            this.setState({
                newLeave: {
                    ...this.state.newLeave,
                    start: reservationTherapist.selectedEvent.start,
                    end: reservationTherapist.selectedEvent.end
                }
            })
        }
    }

    onPlaceChanged(event) {
        if (event.currentTarget.value) {
            this.placeChanged(event.currentTarget.value);
        } else {
            this.props.dispatch(placeActions.resetPlace());
        }
    }

    placeChanged(placeId) {
        var place = this.props.place.places.find(function(place) {
            return place.id === placeId;
        }, placeId);
        this.props.dispatch(placeActions.selectPlace(place));
        this.props.dispatch(placeActions.getRooms(place.id));
    }

    onRoomChanged(event) {
        if (event.currentTarget.value) {
            this.props.dispatch(placeActions.selectRoom(event.currentTarget.value));
        } else {
            this.props.dispatch(placeActions.resetRoom());
        }
    }

    roomChanged(roomId) {
        var room = this.props.place.rooms.find(function(room) {
            return room.id === roomId;
        }, roomId);
        this.props.dispatch(placeActions.selectRoom(room));
    }

    onTherapistChanged(event) {
        if (event.currentTarget.value) {
            this.therapistChanged(event.currentTarget.value);
        } else {
            this.props.dispatch(reservationTherapistActions.resetTherapist());
        }
    }

    therapistChanged(therapistId) {
        var therapist = this.props.user.therapists.find(function(therapist) {
            return therapist.id === therapistId;
        }, therapistId);
        if (therapist) {
            this.props.dispatch(reservationTherapistActions.selectTherapist(therapist));
        } else {
            this.props.dispatch(reservationTherapistActions.resetTherapist());
        }
    }

    handleBackClick() {
        if (this.props.reservationTherapist.selectedEvent) {
            this.props.dispatch(slotGroupActions.resetEvent());
        }
        this.props.history.push("/bookingCalendar");
    }

    handleNewReservationClick() {
        this.props.dispatch(slotGroupActions.createSlotGroup(this.props.reservationTherapist.selectedEvent));
    }

    handleOnBreakClick() {
        this.setState({ showOnBreak: true });
    }

    handleOnBreakBackClick() {
        this.setState({ showOnBreak: false });
    }

    handleOnBreakSubmit(e) {
        const {reservationTherapist, place} = this.props;
        e.preventDefault();
        var form = document.getElementById('onBreakForm');
        if (form.checkValidity() === false) {
            form.classList.add('was-validated')
            e.stopPropagation();
            return;
        }
        if (reservationTherapist) {
            var event = {
                id: -1,
                start: moment(reservationTherapist.selectedEvent.start).format(formatting.DATE_TIME_FORMATTING),
                stateCode: 'notWorking',
                therapistId: reservationTherapist.selectedTherapist.id,
                roomId: place.selectedRoom.id
            };

            this.props.dispatch(slotGroupActions.onBreak(event));
        }
    }

    handleOnLeaveClick() {
        this.setState({ showOnLeave: true });
    }

    handleOnLeaveBackClick() {
        this.setState({ showOnLeave: false });
    }

    handleOnStartDateChanged(dateMoment) {
        let localState = {...this.state};
        localState.newLeave.start = dateMoment;
        this.setState(localState);
    }

    handleOnStartFocusChanged(value) {
        this.setState({ focusedInputStart: value.focused })
    }

    handleOnEndDateChanged(dateMoment) {
        let localState = {...this.state};
        localState.newLeave.end = dateMoment;
        this.setState(localState);
    }

    handleOnEndFocusChanged(value) {
        this.setState({ focusedInputEnd: value.focused })
    }

    handleOnLeaveSubmit(e) {
        const {reservationTherapist} = this.props;
        e.preventDefault();
        var form = document.getElementById('onLeaveForm');
        if (form.checkValidity() === false) {
            form.classList.add('was-validated')
            e.stopPropagation();
            return;
        }
        if (reservationTherapist) {
            var leave = {
                start : moment(this.state.newLeave.start).format(formatting.DATE_TIME_FORMATTING),
                end : moment(this.state.newLeave.end).format(formatting.DATE_TIME_FORMATTING),
                therapistId : reservationTherapist.selectedTherapist.id,
            }

            this.props.dispatch(userActions.createLeave(leave));
        }
    }

    render() {
        const { reservationTherapist, place, user, slotGroup } = this.props;
        var selectedEvent = reservationTherapist.selectedEvent;
        const { showOnBreak, showOnLeave } = this.state;
        if (selectedEvent) {
            return (
                <div className="col-12 col-lg-8">
                    {!showOnLeave && <h2 className="text-center">Pick activity</h2>}
                    {showOnLeave && <h2 className="text-center">Add leave/holiday</h2>}
                    <div className="card card-primary card-transparent">
                        <div className="card-body">
                            {!showOnLeave && <div className="form-group row">
                                <label className="col-sm-3 col-form-label" htmlFor="dateTime">Date and time</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control-plaintext font-weight-bold" name="dateTime" value={moment(selectedEvent.start).format('dddd, MMMM Do YYYY, h:mm a')} readonly/>
                                </div>
                            </div> }

                            {showOnBreak && <form noValidate id="onBreakForm" onSubmit={this.handleOnBreakSubmit}>
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" htmlFor="place">Place</label>
                                    <div className="col-sm-8">
                                        {(!place || place.requestingPlaces) && <span>Loading places ....</span>}
                                        {place && place.places && !place.requestingPlaces && <select class="form-control" name="place" id="place" value={place.selectedPlace ? place.selectedPlace.id : -1} onChange={this.onPlaceChanged}>
                                                {place.places.map((item, index) =>
                                                    <option value={item.id}>{item.name}</option>
                                                )}
                                            </select>
                                        }
                                    </div>
                                </div>

                                {place && place.selectedPlace && <div className="form-group row">
                                        <label className="col-sm-3 col-form-label" htmlFor="room">Room</label>
                                        <div className="col-sm-8">
                                            {(!place || place.requestingRooms) && <span>Loading rooms ....</span>}
                                            {place && place.rooms && !place.requestingRooms && <select class="form-control" name="room" id="room" value={place.selectedRoom ? place.selectedRoom.id : -1} onChange={this.onRoomChanged}>
                                                    {place.rooms.map((item, index) =>
                                                        <option value={item.id}>{item.name}</option>
                                                    )}
                                                </select>
                                            }
                                        </div>
                                    </div>
                                }

                                {user && user.therapists && <div className="form-group row">
                                        <label className="col-sm-3 col-form-label" htmlFor="therapist">Therapist</label>
                                        <div className="col-sm-8">
                                            {(!user.therapists || user.requestingTherapists) && <span>Loading therapists ...</span>}
                                            {user.therapists && !user.requestingTherapists && user.therapists && <select class="form-control" name="therapist" id="therapist" onChange={this.onTherapistChanged}>
                                                    {user.therapists.map((item, index) =>
                                                        <option value={item.id}>{item.firstName}</option>
                                                    )}
                                                </select>
                                            }
                                        </div>
                                    </div>
                                }
                                <div className="form-group row align-items-center ml-1 ">
                                    <button className="btn btn-secondary col-md-2 col-xl-1 mr-2 mb-2" onClick={this.handleOnBreakBackClick}>Back</button>
                                    <SubmitButton updating={slotGroup.booking} label="Create break" className="btn-primary col-md-4 col-xl-3 mr-2 mb-2" labelLoading="Inserting break ..."/>
                                </div>
                            </form> }

                            {showOnLeave && <form noValidate id="onLeaveForm" onSubmit={this.handleOnLeaveSubmit}>
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" htmlFor="start">Start</label>
                                    <div className="col-sm-9">
                                        <DateTimeField
                                            id="start"
                                            dateTime={moment(this.state.newLeave.start)}
                                            onChange={this.handleOnStartDateChanged}
                                            />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" htmlFor="end">End</label>
                                    <div className="col-sm-9">
                                        <DateTimeField
                                            id="end"
                                            dateTime={moment(this.state.newLeave.end)}
                                            onChange={this.handleOnEndDateChanged}
                                            />
                                    </div>
                                </div>
                                {user && user.therapists && <div className="form-group row">
                                        <label className="col-sm-3 col-form-label" htmlFor="therapist">Therapist</label>
                                        <div className="col-sm-8">
                                            {(!user.therapists || user.requestingTherapists) && <span>Loading therapists ...</span>}
                                            {user.therapists && !user.requestingTherapists && user.therapists && <select className="form-control" name="therapist" id="therapist" onChange={this.onTherapistChanged}>
                                                    {user.therapists.map((item, index) =>
                                                        <option key={item.id} value={item.id}>{item.firstName}</option>
                                                    )}
                                                </select>
                                            }
                                        </div>
                                    </div>
                                }
                                <div className="form-group row align-items-center ml-1 ">
                                    <button className="btn btn-secondary col-md-2 col-xl-1 mr-2 mb-2" onClick={this.handleOnLeaveBackClick}>Back</button>
                                    <SubmitButton updating={user.requestingLeave} label="Create leave" className="btn-primary col-md-4 col-xl-3 mr-2 mb-2" labelLoading="Inserting leave ..."/>
                                </div>
                            </form> }

                            {!showOnBreak && !showOnLeave && <div className="form-group row align-items-center ml-1 ">
                                <button className="btn btn-secondary col-md-2 col-xl-1 mr-2 mb-2" onClick={this.handleBackClick}>Back</button>
                                <button className="btn btn-primary col-md-4 col-xl-3 mr-2 mb-2" onClick={this.handleNewReservationClick}>New reservation</button>
                                <button className="btn btn-info col-md-2 col-xl-2 mr-2 mb-2" onClick={this.handleOnBreakClick}>On break</button>
                                <button className="btn btn-success col-md-2 col-xl-2 mr-2 mb-2" onClick={this.handleOnLeaveClick}>Holiday</button>
                            </div>}
                        </div>
                    </div>
                </div>

            );
        } else {
            return ("");
        }
    }
}

function mapStateToProps(state) {
    const { loggedAs } = state.user;
    const { reservationTherapist, slotGroup, place, user } = state;

    return {
        loggedAs,
        reservationTherapist,
        slotGroup,
        place,
        user
    };
}

const connectedBookingTherapistSlotGroupTypePage = connect(mapStateToProps)(BookingTherapistSlotGroupTypePage);
export { connectedBookingTherapistSlotGroupTypePage as BookingTherapistSlotGroupTypePage };
