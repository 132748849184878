import axios from 'axios';
import moment from 'moment';

import { http } from '../_helpers';

const URL = http.BASE_URL + '/api/user';

export const userService = {
    getUser,
    update,
    save,
    deleteUser,
    updatePassword,
    securityQuestion,
    resetPassword,
    resetPasswordByAdmin,
    updateSecurityQuestion,
    getCustomers,
    getTherapists,
    createLeave,
    findLeave,
    removeLeave,
    updateTherapist
};

function getUser() {
    const url = `${URL}`
    return axios.get(url, {
        headers: http.HEADERS,
        withCredentials: true
    })
    .then(response => {
        if (response && response.data) {
            // Save it to local storage so the PrivateRoute can access it and deal with it
            localStorage.setItem('loggedAs', JSON.stringify(response.data));
            return response.data;
        }
    })
    .catch(result=> {
        return http.handleHttpError(result);
    });
}

function update(user) {
    const url = `${URL}`
    return axios.put(url,
        user,
        {
            headers: http.HEADERS,
            withCredentials: true
        })
        .then(response => {
            if (response && response.data) {
                // Save it to local storage so the PrivateRoute can access it and deal with it
                localStorage.setItem('user', JSON.stringify(response.data));
                return response.data;
            }
        })
        .catch(result=> {
            return http.handleHttpError(result);
        }
    );
}

function save(user, authenticated) {
    const url = `${URL}`
    return axios.post(url,
        user,
        {
            headers: http.HEADERS,
            withCredentials: authenticated
        })
        .then(response => {
            if (response && response.data) {
                return response.data;
            }
        })
        .catch(result=> {
            return http.handleHttpError(result);
        }
    );
}

function deleteUser(user) {
    const url = `${URL}/` + user.id;
    return axios.delete(url,
        {
            headers: http.HEADERS,
            withCredentials: true
        })
        .then(response => {
            if (response && response.data) {
                return response.data;
            }
        })
        .catch(result=> {
            return http.handleHttpError(result);
        }
    );
}

function updatePassword(oldPassword, newPassword) {
    const url = `${URL}/password`;
    return axios.put(url,
        {
            oldPassword: oldPassword,
            newPassword: newPassword
        },
        {
            headers: http.HEADERS,
            withCredentials: true
        })
        .then(response => {
            if (response.status === 200) {
                return;
            } else {
                return Promise.reject(response.statusText);
            }
        })
        .catch(result=> {
            return http.handleHttpError(result);
        }
    );
}

function securityQuestion(username, gRecaptchaResponse) {
    const url = `${URL}/securityquestion`;
    return axios.get(url,
        {
            params: {
                email: username,
                gRecaptchaResponse: gRecaptchaResponse
            },
            headers: http.HEADERS,
            withCredentials: true
        })
        .then(response => {
            if (response && response.data) {
                return response.data.securityQuestion;
            }
        })
        .catch(result=> {
            return http.handleHttpError(result);
        }
    );
}

function updateSecurityQuestion(securityQuestion) {
    const url = `${URL}/securityquestion`;
    return axios.put(url,
        {
            password: securityQuestion.password,
            newSecurityQuestion: securityQuestion.new,
            newSecurityAnswer: securityQuestion.answer
        },
        {
            headers: http.HEADERS,
            withCredentials: true
        })
        .then(response => {
            if (response.status === 200) {
                return;
            } else {
                return Promise.reject(response.statusText);
            }
        })
        .catch(result=> {
            return http.handleHttpError(result);
        }
    );
}

function resetPassword(email, securityAnswer) {
    const url = `${URL}/resetpassword`;
    return axios.put(url,
        {
            email: email,
            securityAnswer: securityAnswer
        },
        {
            headers: http.HEADERS,
            withCredentials: true
        })
        .then(response => {
            if (response.status === 200) {
                return;
            } else {
                return Promise.reject(response.statusText);
            }
        })
        .catch(result=> {
            return http.handleHttpError(result);
        }
    );
}

function resetPasswordByAdmin(userId) {
    const url = `${URL}/` + userId + '/resetpassword' ;
    return axios.put(url,
        null,
        {
            headers: http.HEADERS,
            withCredentials: true
        })
        .then(response => {
            if (response.status === 200) {
                return;
            } else {
                return Promise.reject(response.statusText);
            }
        })
        .catch(result=> {
            return http.handleHttpError(result);
        }
    );
}

var CancelToken = axios.CancelToken;
var cancel;

function getCustomers(startsWith, enhanced, records, page) {
    if (cancel !== undefined) {
        cancel();
    }
    var url = `${URL}/findcustomer?startsWith=` + startsWith + `&enhanced=` + enhanced;
    if (records) {
        url = url + `&records=` + records;
    }
    if (page) {
        url = url + `&page=` + page;
    }
    return axios.get(url, {
        cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            cancel = c;
        }),
        headers: http.HEADERS,
        withCredentials: true
    })
    .then(response => {
        if (response && response.data) {
            return response.data;
        }
    })
    .catch(result=> {
        if (result.__CANCEL__ === undefined) {
            return http.handleHttpError(result);
        }
    });
}

function getTherapists() {
    const url = `${URL}/findtherapist`;
    return axios.get(url, {
        headers: http.HEADERS,
        withCredentials: true
    })
    .then(response => {
        if (response && response.data) {
            return response.data;
        }
    })
    .catch(result=> {
        return http.handleHttpError(result);
    });
}

function createLeave(leave) {
    const url = `${URL}/leave`
    return axios.post(url,
        leave,
        {
            headers: http.HEADERS,
            withCredentials: true
        })
        .then(response => {
            if (response && response.data) {
                return response.data;
            }
        })
        .catch(result=> {
            return http.handleHttpError(result);
        }
    );
}

function findLeave(start, end) {
    const url = `${URL}/leave?start=` + start + `&end=` + end;
    return axios.get(url, {
        headers: http.HEADERS,
        withCredentials: true
    })
    .then(response => {
        if (response && response.data) {
            for (var i = 0, size = response.data.leaveViews.length; i < size ; i++) {
                var item = response.data.leaveViews[i];
                item.start = moment(item.start).toDate();
                item.end = moment(item.end).toDate();
            }
            return response.data;
        }
    })
    .catch(result=> {
        return http.handleHttpError(result);
    });
}

function removeLeave(event) {
    const url = `${URL}/leave/` + event.id;
    return axios.delete(url,
        {
            headers: http.HEADERS,
            withCredentials: true
        })
        .then(response => {
            if (response && response.data) {
                return response.data;
            }
        })
        .catch(result=> {
            return http.handleHttpError(result);
        }
    );
}

function updateTherapist(therapist) {
    const url = `${URL}/therapist`
    return axios.put(url,
        therapist,
        {
            headers: http.HEADERS,
            withCredentials: true
        })
        .then(response => {
            if (response && response.data) {
                // Save it to local storage so the PrivateRoute can access it and deal with it
                localStorage.setItem('therapist', JSON.stringify(response.data));
                return response.data;
            }
        })
        .catch(result=> {
            return http.handleHttpError(result);
        }
    );
}
