import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/en-nz';
import { formatting } from '../_helpers';

import { treatmentGroupActions, slotGroupActions, alertActions, placeActions, reservationTherapistActions, userActions } from '../_actions';
import { SubmitButton, Autocomplete, DateTimeField } from '../_components';

import { Basket } from '.';

class BookingTherapistSlotGroupCreatePage extends React.Component {

    constructor(props) {
        super(props);

        this.handleBackClick = this.handleBackClick.bind(this);
        this.handleAddToBasket = this.handleAddToBasket.bind(this);
        this.handleAddToBasketAndReserve = this.handleAddToBasketAndReserve.bind(this)
        this.handleResetBasket = this.handleResetBasket.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleOnStartDateChanged = this.handleOnStartDateChanged.bind(this);
        this.onPlaceChanged = this.onPlaceChanged.bind(this);
        this.placeChanged = this.placeChanged.bind(this);
        this.onRoomChanged = this.onRoomChanged.bind(this);
        this.roomChanged = this.roomChanged.bind(this);
        this.treatmentGroupChanged = this.treatmentGroupChanged.bind(this);
        this.onTreatmentGroupChanged = this.onTreatmentGroupChanged.bind(this);
        this.treatmentChanged = this.treatmentChanged.bind(this);
        this.onPriceChanged = this.onPriceChanged.bind(this);
        this.priceChanged = this.priceChanged.bind(this);
        this.onTherapistChanged = this.onTherapistChanged.bind(this);
        this.therapistChanged = this.therapistChanged.bind(this);
        this.onTreatmentChanged = this.onTreatmentChanged.bind(this);
        this.onSearchCustomers = this.onSearchCustomers.bind(this);
        this.onSelectCustomer = this.onSelectCustomer.bind(this);
        this.onBlurCustomer = this.onBlurCustomer.bind(this);
        this.onCustomerContactChanged = this.onCustomerContactChanged.bind(this);
        this.populateData = this.populateData.bind(this);
        this.onResetCustomer = this.onResetCustomer.bind(this);
        this.handleAdvanced = this.handleAdvanced.bind(this);
        this.handleSendEmail = this.handleSendEmail.bind(this);

        this.state = {
			previousCustomerSearch: "",
			advanced: false,
		};
    }

    componentDidMount() {
        if (!this.props.loggedAs) {
            this.props.history.push("/login");
        }
        if (!this.props.reservationTherapist.selectedEvent) {
            this.props.history.push("/bookingCalendar");
        }
        this.props.dispatch(userActions.resetCustomers());
        this.populateData();
    }

    componentDidUpdate() {
        if (!this.props.loggedAs) {
            this.props.history.push("/login");
        }
        if (!this.props.reservationTherapist.selectedEvent) {
            this.props.history.push("/bookingCalendar");
        }
        this.populateData();
    }

    populateData() {
        const { dispatch, place, reservationTherapist, treatmentGroup } = this.props;
        if (!place.places && !place.requestingPlaces) {
            dispatch(placeActions.getPlaces());
        } else if (!treatmentGroup.treatmentGroups && !treatmentGroup.requestingTreatmentGroups) {
            dispatch(treatmentGroupActions.getGroups());
            dispatch(reservationTherapistActions.resetGroup());
        } else if (place.places && !place.selectedPlace) {
            // Let's preselect a first non customer place
            const places = place.places;
            for (var i = 0; i < places.length; i++) {
                if (!places[i].customerAddress) {
                    this.placeChanged(places[i].id);
                    break;
                }
            }
        } else if (place.places
            && place.selectedPlace
            && place.rooms
            && !place.requestingRooms
            && !place.selectedRoom) {
            this.roomChanged(place.rooms[0].id);
        } else if (place.places
            && place.selectedPlace
            && !reservationTherapist.selectedGroup
            && treatmentGroup.treatmentGroups) {
            // Let's preselect a first treatment group
            this.treatmentGroupChanged(treatmentGroup.treatmentGroups[0].id);
        } else if (place.places
            && place.selectedPlace
            && reservationTherapist.selectedGroup
            && treatmentGroup.treatmentGroups
            && !reservationTherapist.selectedTreatment) {
            // Let's preselect a first treatment from the group
            this.treatmentChanged(reservationTherapist.selectedGroup.treatmentViewList[0].id);
        } else if (place.places
            && place.selectedPlace
            && reservationTherapist.selectedGroup
            && treatmentGroup.treatmentGroups
            && reservationTherapist.selectedTreatment
            && treatmentGroup.prices
            && !reservationTherapist.selectedPrice) {
                if (treatmentGroup.prices.length === 1) {
                    this.props.dispatch(reservationTherapistActions.selectPrice(treatmentGroup.prices[0]));
                } else {
                    var hasBeenSet = false;
                    for (let i = 0; i < treatmentGroup.prices.length; i++) {
                        if (treatmentGroup.prices[i].duration === 3600000) {
                            // Preselect the 60 minut as default
                            this.props.dispatch(reservationTherapistActions.selectPrice(treatmentGroup.prices[i]));
                            hasBeenSet = true;
                            break;
                        }
                    }
                    if (!hasBeenSet) {
                        this.props.dispatch(reservationTherapistActions.selectPrice(treatmentGroup.prices[0]));
                    }
                }
        } else if (place.places
            && place.selectedPlace
            && reservationTherapist.selectedGroup
            && treatmentGroup.treatmentGroups
            && reservationTherapist.selectedTreatment
            && treatmentGroup.therapists
            && !reservationTherapist.selectedTherapist) {
            // Let's preselect a first therapist
            this.therapistChanged(treatmentGroup.therapists[0].id);
        } else if (place.places
            && place.selectedPlace
            && reservationTherapist.selectedGroup
            && treatmentGroup.treatmentGroups
            && reservationTherapist.selectedTreatment
            && !treatmentGroup.therapists
            && !treatmentGroup.requestingTherapists
            && reservationTherapist.selectedTherapist) {
            // Well this is a scenario when we don't have a therapist assigned to a treatment
            this.props.dispatch(reservationTherapistActions.resetTherapist());
        }
    }

    handleBackClick() {
        if (this.props.reservationTherapist.selectedEvent) {
            this.props.dispatch(slotGroupActions.resetEvent());
        }
        this.props.history.push("/bookingCalendar");
    }

    handleAddToBasket() {
        this.props.dispatch(reservationTherapistActions.addToBasket(this.props.reservationTherapist.selectedPrice, this.props.reservationTherapist.selectedPrices));
    }

    async handleAddToBasketAndReserve() {
        await this.props
            .dispatch(reservationTherapistActions.addToBasket(this.props.reservationTherapist.selectedPrice, this.props.reservationTherapist.selectedPrices))

        document.getElementById("bookingForm").requestSubmit();
    }

    handleResetBasket() {
        this.props.dispatch(reservationTherapistActions.resetBasket());
    }

    handleOnStartDateChanged(date) {
        this.props.reservationTherapist.selectedEvent.start = date == null ? null : date; //2019-06-20T18:30:00.000Z
        this.props.reservationTherapist.selectedEvent.end = date == null ? null : date.add(30, 'minutes');
        this.props.dispatch(slotGroupActions.selectEventTherapist(this.props.reservationTherapist.selectedEvent));
    }

    onPlaceChanged(event) {
        if (event.currentTarget.value) {
            this.placeChanged(event.currentTarget.value);
        } else {
            this.props.dispatch(placeActions.resetPlace());
        }
    }

    placeChanged(placeId) {
        var place = this.props.place.places.find(function(place) {
            return place.id === Number(placeId);
        }, placeId);
        this.props.dispatch(placeActions.selectPlace(place));
        this.props.dispatch(placeActions.getRooms(place.id));
    }

    onRoomChanged(event) {
        if (event.currentTarget.value) {
            this.roomChanged(event.currentTarget.value);
        } else {
            this.props.dispatch(placeActions.resetRoom());
        }
    }

    roomChanged(roomId) {
        this.props.dispatch(placeActions.selectRoom(roomId));
    }

    onTreatmentGroupChanged(event) {
        if (event.currentTarget.value) {
            this.treatmentGroupChanged(event.currentTarget.value);
        } else {
            this.props.dispatch(reservationTherapistActions.resetGroup());
        }
    }

    treatmentGroupChanged(treatmentGroupId) {
        this.props.dispatch(reservationTherapistActions.resetTreatment());
        var group = this.props.treatmentGroup.treatmentGroups.find(function(group) {
            return group.id === Number(treatmentGroupId);
        }, treatmentGroupId);
        this.props.dispatch(reservationTherapistActions.selectGroup(group));
    }

    onTreatmentChanged(event) {
        if (event.currentTarget.value) {
            this.treatmentChanged(event.currentTarget.value);
        } else {
            this.props.dispatch(reservationTherapistActions.resetTreatment());
        }
    }

    treatmentChanged(treatmentId) {
        var treatment = this.props.reservationTherapist.selectedGroup.treatmentViewList.find(function(treatment) {
            return treatment.id === Number(treatmentId);
        }, treatmentId);
        this.props.dispatch(treatmentGroupActions.resetPrices());
        this.props.dispatch(treatmentGroupActions.resetTherapists());
        this.props.dispatch(reservationTherapistActions.resetPrice());
        this.props.dispatch(reservationTherapistActions.resetTherapist());
        this.props.dispatch(reservationTherapistActions.selectTreatment(treatment));
        this.props.dispatch(treatmentGroupActions.getPrices(treatment.id));
        this.props.dispatch(treatmentGroupActions.getTherapists(treatment.id));
    }

    onPriceChanged(event) {
        if (event.currentTarget.value) {
            this.priceChanged(event.currentTarget.value);
        } else {
            this.props.dispatch(reservationTherapistActions.resetPrice());
        }
    }

    priceChanged(priceId) {
        var price = this.props.treatmentGroup.prices.find(function(price) {
            return price.id === Number(priceId);
        }, priceId);
        if (price) {
            this.props.dispatch(reservationTherapistActions.selectPrice(price));
        } else {
            this.props.dispatch(reservationTherapistActions.resetPrice());
        }
    }

    onTherapistChanged(event) {
        if (event.currentTarget.value) {
            this.therapistChanged(event.currentTarget.value);
        } else {
            this.props.dispatch(reservationTherapistActions.resetTherapist());
        }
    }

    therapistChanged(therapistId) {
        var therapist = this.props.treatmentGroup.therapists.find(function(therapist) {
            return therapist.id === Number(therapistId);
        }, therapistId);
        if (therapist) {
            this.props.dispatch(reservationTherapistActions.selectTherapist(therapist));
        } else {
            this.props.dispatch(reservationTherapistActions.resetTherapist());
        }
    }

    onSearchCustomers(value) {
		this.props.dispatch(userActions.resetCustomers());

		value = value.trim();
		if (value === "") {
            this.props.dispatch(reservationTherapistActions.setCustomerName(null));
			return;
		}
        this.props.dispatch(reservationTherapistActions.setCustomerName(value));
		this.props.dispatch(userActions.getCustomers(value));
	}

    onCustomerContactChanged(event) {
        this.props.dispatch(reservationTherapistActions.setCustomerContact(event.currentTarget.value));
    }

	onSelectCustomer( result, index ) {
		console.log( "selected", result, index );

        this.props.dispatch(reservationTherapistActions.selectCustomer(result));
		this.props.dispatch(userActions.resetCustomers());
	}

    onBlurCustomer() {
        this.props.dispatch(userActions.resetCustomers());
    }

    onResetCustomer() {
        this.props.dispatch(reservationTherapistActions.resetCustomer());
    }

    handleSubmit(e) {
        e.preventDefault();
        var form = document.getElementById('bookingForm');
        if (form.checkValidity() === false) {
            form.classList.add('was-validated');
            e.stopPropagation();
            return;
        }

        const { dispatch, reservationTherapist, place } = this.props;
        dispatch(alertActions.clear());
        if (reservationTherapist.selectedEvent) {
            var prices = [reservationTherapist.selectedPrice.id];
            if (reservationTherapist.selectedPrices && reservationTherapist.selectedPrices.length > 0) {
                prices = [];
                reservationTherapist.selectedPrices.forEach(price => {
                    prices.push(price.id);
                });
            }
            var event = {
                id: -1,
                start: moment(reservationTherapist.selectedEvent.start).format(formatting.DATE_TIME_FORMATTING),
                stateCode: 'reserved',
                therapistId: reservationTherapist.selectedTherapist.id,
                priceIds: prices,
                roomId: place.selectedRoom.id,
                sendEmail: reservationTherapist.sendEmail
            };
            if (reservationTherapist.selectedCustomer) {
                event.customerId = reservationTherapist.selectedCustomer.id;
            } else {
                event.customerName = reservationTherapist.customerName;
                event.customerContact = reservationTherapist.customerContact;
            }
            dispatch(slotGroupActions.reserve(event, this.props.loggedAs));
            dispatch(reservationTherapistActions.resetCustomer());
            dispatch(reservationTherapistActions.resetEvent());
            dispatch(reservationTherapistActions.resetGroup());
            dispatch(reservationTherapistActions.setSendEmail(true));
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleAdvanced() {
        var newAdvanced = !this.state.advanced
        this.setState({ advanced: newAdvanced });
    }

    handleSendEmail(event) {
        var newSendEmail = event.target.checked
        this.props.dispatch(reservationTherapistActions.setSendEmail(newSendEmail));
    }

    render() {
        const { reservationTherapist, place, treatmentGroup, user, slotGroup } = this.props;
        var selectedEvent = reservationTherapist.selectedEvent;
        var hideAdvanceClass = !this.state.advanced ? 'd-none' : '';
        if (selectedEvent) {
            return (
                <div className="col-12 col-lg-10">
                    <h2 className="text-center">New booking</h2>
                    <div className="card card-primary card-transparent">
                        <div className="card-body">
                            <form id="bookingForm" noValidate className="needs-validation my-4" onSubmit={this.handleSubmit}>
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" htmlFor="dateTime">Date & Time</label>
                                    <div className="col-sm-8">
                                        <DateTimeField
                                            id="dateTime"
                                            dateTime={moment(selectedEvent.start)}
                                            onChange={this.handleOnStartDateChanged}
                                            />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" htmlFor="customer">Customer</label>
                                    { !reservationTherapist.selectedCustomer && !(reservationTherapist && reservationTherapist.selectedPrices && reservationTherapist.selectedPrices.length > 0) &&
                                        <div className="col-sm-8">

                                            <Autocomplete
                                                className=""
                                                placeholderText="Search a customer ..."
                                                onSearch={this.onSearchCustomers}
                                                onSelect={this.onSelectCustomer}
                                                onBlur={this.onBlurCustomer}
                                                results={user.customers}
                                                loading={user.requestingCustomers}>
                                                    {customer => {
                                                        return <div key={"Customer_" + customer.id}>{customer.surname + ", " + customer.firstName + " - " + customer.email + " - " + customer.cellPhone}</div>;
                                                    }}
                                            </Autocomplete>
                                        </div>
                                    }

                                    { reservationTherapist.selectedCustomer &&
                                        <div className="col-sm-8">
                                            <div className="row">
                                                <div className="col-sm-8 form-control-plaintext font-weight-bold">
                                                    {reservationTherapist.selectedCustomer.surname + ", " + reservationTherapist.selectedCustomer.firstName}
                                                </div>
                                                {(!reservationTherapist.selectedPrices || reservationTherapist.selectedPrices.length === 0) &&
                                                    <button type="button" className=" col-sm-12 col-md-4 col-lg-4 pt-1 btn btn-secondary btn-block btn-sm float-right" onClick={this.onResetCustomer}>Search again</button>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>

                                { (reservationTherapist.selectedCustomer || reservationTherapist.customerName) &&
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label" htmlFor="customerContact">Contact phone</label>
                                        <div className="col-sm-8">
                                            { !reservationTherapist.selectedCustomer && <input type="text" className="form-control font-weight-bold" name="customerContact" value={reservationTherapist.customerContact} onChange={this.onCustomerContactChanged}/>}
                                            { reservationTherapist.selectedCustomer && <span className="form-control-plaintext font-weight-bold" name="customerContact">{reservationTherapist.selectedCustomer.phoneNumbers + "; " + reservationTherapist.selectedCustomer.email} </span>}
                                        </div>
                                    </div>
                                }

                                <div className={`form-group row ${hideAdvanceClass}`}>
                                    <label className="col-sm-3 col-form-label" htmlFor="place">Place</label>
                                    <div className="col-sm-8">
                                        {(!place || place.requestingPlaces) && <span>Loading places ....</span>}
                                        {place && place.places && !place.requestingPlaces && <select className="form-control" name="place" id="place" value={place.selectedPlace ? place.selectedPlace.id : -1} onChange={this.onPlaceChanged}>
                                                {place.places.map((item, index) =>
                                                    <option value={item.id}>{item.name}</option>
                                                )}
                                            </select>
                                        }
                                    </div>
                                </div>

                                { place && place.selectedPlace &&
                                    <div className={`form-group row ${hideAdvanceClass}`}>
                                        <label className="col-sm-3 col-form-label" htmlFor="room">Room</label>
                                        <div className="col-sm-8">
                                            {(!place || place.requestingRooms) && <span>Loading rooms ....</span>}
                                            {place && place.rooms && !place.requestingRooms && <select className="form-control" name="room" id="room" value={place.selectedRoom ? place.selectedRoom.id : -1} onChange={this.onRoomChanged}>
                                                    {place.rooms.map((item, index) =>
                                                        <option value={item.id}>{item.name}</option>
                                                    )}
                                                </select>
                                            }
                                        </div>
                                    </div>
                                }

                                {place && place.selectedPlace &&
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label" htmlFor="treatmentGroup">Treatment group</label>
                                        <div className="col-sm-8">
                                            {(!treatmentGroup || treatmentGroup.requestingTreatmentGroups) && <span>Loading groups ....</span>}
                                            {treatmentGroup
                                                && treatmentGroup.treatmentGroups
                                                && !treatmentGroup.requestingTreatmentGroups
                                                && <select className="form-control"
                                                        name="treatmentGroup"
                                                        id="treatmentGroup"
                                                        onChange={this.onTreatmentGroupChanged}>
                                                    {treatmentGroup.treatmentGroups
                                                        .filter(item => item.current || (!item.current && this.state.advanced))
                                                        .map((item, index) =>
                                                            <option value={item.id}>{item.label}</option>
                                                    )}
                                                </select>
                                            }
                                        </div>
                                    </div>
                                }

                                {reservationTherapist && reservationTherapist.selectedGroup &&
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label" htmlFor="treatmentGroup">Treatment</label>
                                        <div className="col-sm-8">
                                            {(!treatmentGroup || treatmentGroup.requestingTreatmentGroups) && <span>Loading treatments ....</span>}
                                            {treatmentGroup
                                                && treatmentGroup.treatmentGroups
                                                && !treatmentGroup.requestingTreatmentGroups
                                                && reservationTherapist.selectedTreatment
                                                && <select className="form-control"
                                                    name="treatmentGroup"
                                                    id="treatmentGroup"
                                                    value={reservationTherapist.selectedTreatment.id}
                                                    onChange={this.onTreatmentChanged}>
                                                    {reservationTherapist.selectedGroup.treatmentViewList
                                                        .filter(item => item.current || (!item.current && this.state.advanced))
                                                        .map((item, index) =>
                                                            <option value={item.id}>{item.label}</option>
                                                    )}
                                                </select>
                                            }
                                        </div>
                                    </div>
                                }

                                {reservationTherapist && reservationTherapist.selectedTreatment && <div className="form-group row">
                                        <label className="col-sm-3 col-form-label" htmlFor="price">Price/Duration</label>
                                        <div className="col-sm-8">
                                            {(!treatmentGroup || treatmentGroup.requestingPrices) && <span>Loading prices ...</span>}
                                            {treatmentGroup && treatmentGroup.treatmentGroups && !treatmentGroup.requestingPrices && reservationTherapist.selectedPrice &&
                                                <select className="form-control"
                                                    name="price"
                                                    id="price"
                                                    value={reservationTherapist.selectedPrice.id}
                                                    onChange={this.onPriceChanged}>
                                                    {treatmentGroup.prices.map((item, index) =>
                                                        <option value={item.id}>{item.durationFormatted + ' - ' + item.priceFormatted}</option>
                                                    )}
                                                </select>
                                            }
                                        </div>
                                    </div>
                                }

                                {reservationTherapist && reservationTherapist.selectedTreatment &&
                                    <div className={`form-group row ${hideAdvanceClass}`}>
                                        <label className="col-sm-3 col-form-label" htmlFor="therapist">Therapist</label>
                                        <div className="col-sm-8">
                                            {(!treatmentGroup || treatmentGroup.requestingTherapists) && <span>Loading therapists ...</span>}
                                            {treatmentGroup && treatmentGroup.treatmentGroups && !treatmentGroup.requestingTherapists && treatmentGroup.therapists && reservationTherapist.selectedTherapist &&
                                                <select className="form-control"
                                                    name="therapist"
                                                    id="therapist"
                                                    value={reservationTherapist.selectedTherapist.id}
                                                    onChange={this.onTherapistChanged}>
                                                    {treatmentGroup.therapists.map((item, index) =>
                                                        <option value={item.id}>{item.firstName}</option>
                                                    )}
                                                </select>
                                            }
                                            {treatmentGroup && treatmentGroup.treatmentGroups && !treatmentGroup.requestingTherapists && !treatmentGroup.therapists && <span>No therapist found</span>}
                                        </div>
                                    </div>
                                }

                                <div className={`form-check mr-10 mb-2 col-md-4 col-xl-3 ${hideAdvanceClass}`}>
                                    <input className="form-check-input" type="checkbox" id="sendEmail" onChange={this.handleSendEmail} checked={reservationTherapist.sendEmail}/>
                                    <label className="form-check-label" for="sendEmail">Send email</label>
                                </div>

                                <div className="form-group row align-items-center ml-1 ">
                                    {reservationTherapist.selectedPrices == null &&
                                        <button className="btn btn-secondary col-md-2 col-xl-1 mr-2 mb-2" onClick={this.handleBackClick}>Back</button>
                                    }
                                    {reservationTherapist.selectedPrice && reservationTherapist.selectedTherapist && (reservationTherapist.selectedCustomer || reservationTherapist.customerName) &&
                                        <button type="button" className="btn btn-warning col-md-3 col-xl-2 mr-2 mb-2" onClick={this.handleAddToBasket}>Add another treatment</button>
                                    }
                                    {reservationTherapist.selectedPrice && reservationTherapist.selectedTherapist && (reservationTherapist.selectedCustomer || reservationTherapist.customerName) && (reservationTherapist.selectedPrices == null || reservationTherapist.selectedPrices.length == 0) &&
                                        <SubmitButton updating={slotGroup.booking} label="Reserve" labelLoading="Reserving ..." className="col-md-3 col-xl-2 mr-2 mb-2"/>
                                    }

                                    <div className="form-check mr-10 mb-2 col-md-4 col-xl-3">
                                        <input className="form-check-input" type="checkbox" id="advanced" onChange={this.handleAdvanced} value={this.state.advanced}/>
                                        <label className="form-check-label" for="advanced">Show more fields</label>
                                    </div>
                                </div>
                                <hr className="bg-secondary"/>
                                {reservationTherapist && reservationTherapist.selectedPrices && reservationTherapist.selectedPrices.length > 0 && <div className="form-group row">
                                        <h5 className="col-sm-3">Current booking</h5>
                                        <Basket items={reservationTherapist.selectedPrices}/>
                                    </div>
                                }

                                {reservationTherapist && reservationTherapist.selectedPrices && reservationTherapist.selectedPrices.length > 0 && <div className="form-group row align-items-center ml-1 ">
                                        <button className="btn btn-secondary col-md-2 col-xl-1 mr-2 mb-2" onClick={this.handleBackClick}>Back</button>
                                        {reservationTherapist.selectedPrice && reservationTherapist.selectedTherapist && (reservationTherapist.selectedCustomer || reservationTherapist.customerName) &&
                                            <SubmitButton updating={slotGroup.booking} label={`Reserve${reservationTherapist.selectedPrices && reservationTherapist.selectedPrices.length > 0 ? ` this booking` : ``}`} labelLoading="Reserving ..." className="col-md-3 col-xl-2 mr-2 mb-2"/>
                                        }
                                        {reservationTherapist.selectedPrice && reservationTherapist.selectedTherapist && (reservationTherapist.selectedCustomer || reservationTherapist.customerName) && reservationTherapist.selectedPrices && reservationTherapist.selectedPrices.length > 0 &&
                                            <button type="button" className="btn btn-danger col-md-3 col-xl-2 mr-2 mb-2" onClick={this.handleResetBasket}>Reset booking</button>
                                        }
                                    </div>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            );
        } else {
            return ("");
        }
    }
}

function mapStateToProps(state) {
    const { loggedAs } = state.user;
    const { reservationTherapist, place, treatmentGroup, user, slotGroup } = state;

    return {
        loggedAs,
        user,
        reservationTherapist,
        place,
        treatmentGroup,
        slotGroup
    };
}

const connectedBookingTherapistSlotGroupCreatePage = connect(mapStateToProps)(BookingTherapistSlotGroupCreatePage);
export { connectedBookingTherapistSlotGroupCreatePage as BookingTherapistSlotGroupCreatePage };
