import React from 'react';

import { Transaction } from '.';

export class Transactions extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.itemRefs = {};
    }

    scrollTo(id) {
        if (this.itemRefs[id]) {
            this.itemRefs[id].props.transaction.ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }

    render() {
        const { financialTransactions, lastTransactionId } = this.props;

        if (financialTransactions && financialTransactions.length > 0) {
            return (
                <div className="table-responsive-md">
                    <table id="transactions" className="table table-sm">
                        <thead>
                            <tr>
                              <th scope="col">Date</th>
                              <th scope="col">Amount</th>
                              <th scope="col">Note; Category</th>
                              <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            financialTransactions.map((transaction) =>
                                <Transaction
                                    key={"Transaction_" + transaction.id}
                                    transaction={transaction}
                                    highlight={lastTransactionId === transaction.id}
                                    ref={el => (this.itemRefs[transaction.id] = el)}
                                    />
                            )
                        }
                        </tbody>
                    </table>
                </div>
            );
        } else {
            return (
                <div className="form-row">
                    <div className="col text-center">
                        <strong>No transactions found</strong>
                    </div>
                </div>
            );
        }
    }
}
