import { userConstants } from '../_constants';

let loggedAs = JSON.parse(localStorage.getItem('loggedAs'));
const initialState = loggedAs ? { loggedIn: true, loggedAs } : {};

export function user(state = initialState, action) {
    switch (action.type) {
        case userConstants.LOGOUT:
            return Object.assign({}, state, {
                loggingIn: false,
                loggedIn: false,
                loggedAs: {}
            });
        case userConstants.LOGIN_REQUEST:
            return Object.assign({}, state, {
                loggingIn: true,
                loggedAs: action.user
            });
        case userConstants.LOGIN_SUCCESS:
            return Object.assign({}, state, {
                loggedIn: true,
                loggingIn: false,
                loggedAs: action.user
            });
        case userConstants.LOGIN_FAILURE:
            return Object.assign({}, state, {
                loggedIn: false,
                loggingIn: false,
                loggedAs: {}
            });
        case userConstants.UPDATE_REQUEST:
            return Object.assign({}, state, { updating: true });
        case userConstants.UPDATE_SUCCESS:
            return Object.assign({}, state, {
                loggedIn: true,
                updating: false,
                loggedAs: action.user
            });
        case userConstants.UPDATE_FAILURE:
            return Object.assign({}, state, {
                loggedIn: true,
                updating: false
            });
        case userConstants.REGISTER_REQUEST:
            return Object.assign({}, state, { registering: true });
        case userConstants.REGISTER_SUCCESS:
            return Object.assign({}, state, { registering: false});
        case userConstants.REGISTER_FAILURE:
            return Object.assign({}, state, { registering: false});
        case userConstants.CREATE_REQUEST:
            return Object.assign({}, state, { creating: true });
        case userConstants.CREATE_SUCCESS:
            return Object.assign({}, state, { creating: false});
        case userConstants.CREATE_FAILURE:
            return Object.assign({}, state, { creating: false});
        case userConstants.DELETE_REQUEST:
            return Object.assign({}, state, { deletingCustomer: true });
        case userConstants.DELETE_SUCCESS:
            return Object.assign({}, state, { deletingCustomer: false});
        case userConstants.DELETE_FAILURE:
            return Object.assign({}, state, { deletingCustomer: false});
        case userConstants.SECURITY_QUESTION_REQUEST:
            return Object.assign({}, state, {
                loggedIn: false,
                requestingQuestion: true,
                securityQuestion: '',
                requestingQuestionError: false
            });
        case userConstants.SECURITY_QUESTION_SUCCESS:
            return Object.assign({}, state, {
                loggedIn: false,
                requestingQuestion: false,
                securityQuestion: action.question,
                requestingQuestionError: false
            });
        case userConstants.SECURITY_QUESTION_FAILURE:
            return Object.assign({}, state, {
                loggedIn: false,
                requestingQuestion: false,
                securityQuestion: '',
                requestingQuestionError: true
            });
        case userConstants.RESET_PASSWORD_REQUEST:
            return Object.assign({}, state, {
                resettingPassword: true
            });
        case userConstants.RESET_PASSWORD_SUCCESS:
            return Object.assign({}, state, {
                resettingPassword: false
            });
        case userConstants.RESET_PASSWORD_FAILURE:
            return Object.assign({}, state, {
                resettingPassword: false,
                resettingPasswordError: true
            });
        case userConstants.RESET_PASSWORD_BY_ADMIN_REQUEST:
            return Object.assign({}, state, {
                resettingPasswordByAdmin: true
            });
        case userConstants.RESET_PASSWORD_BY_ADMIN_SUCCESS:
            return Object.assign({}, state, {
                resettingPasswordByAdmin: false
            });
        case userConstants.RESET_PASSWORD_BY_ADMIN_FAILURE:
            return Object.assign({}, state, {
                resettingPasswordByAdmin: false
            });
        case userConstants.GETCUSTOMERS_REQUEST:
            return Object.assign({}, state, { requestingCustomers: true });
        case userConstants.GETCUSTOMERS_SUCCESS:
            return Object.assign({}, state, {
                requestingCustomers: false,
                customers: action.customers.userViews,
                totalNumberOfCustomers: action.customers.totalNumberOfCustomers,
                totalNumberOfFilteredCustomers: action.customers.totalNumberOfFilteredCustomers
            });
        case userConstants.GETCUSTOMERS_FAILURE:
            return Object.assign({}, state, { requestingCustomers: false});
        case userConstants.GETCUSTOMERS_SEARCHSTRING:
            return Object.assign({}, state, {
                searchString: action.searchString
            });
        case userConstants.GETCUSTOMERS_SEARCHPAGE:
            return Object.assign({}, state, {
                searchPage: action.searchPage
            });
        case userConstants.RESETCUSTOMERS_SUCCESS:
            return Object.assign({}, state, {
                requestingCustomers: false,
                customers: null,
                totalNumberOfCustomers: null,
                totalNumberOfFilteredCustomers: null
            });
        case userConstants.GETTHERAPISTS_REQUEST:
            return Object.assign({}, state, { requestingTherapists: true });
        case userConstants.GETTHERAPISTS_SUCCESS:
            return Object.assign({}, state, {
                requestingTherapists: false,
                therapists: action.therapists.therapistViews
            });
        case userConstants.GETTHERAPISTS_FAILURE:
            return Object.assign({}, state, { requestingTherapists: false});
        case userConstants.RESETTHERAPISTS_SUCCESS:
            return Object.assign({}, state, {
                requestingTherapists: false,
                therapists: null
            });
        case userConstants.LEAVE_REQUEST:
            return Object.assign({}, state, { requestingLeave: true });
        case userConstants.LEAVE_SUCCESS:
            return Object.assign({}, state, {
                requestingLeave: false
            });
        case userConstants.LEAVE_FAILURE:
            return Object.assign({}, state, { requestingLeave: false});
        case userConstants.FINDLEAVE_REQUEST:
            return Object.assign({}, state, {
                loadingLeave: true
            });
        case userConstants.FINDLEAVE_SUCCESS:
            return Object.assign({}, state, {
                loadingLeave: false,
                leave: action.slotGroups.leaveViews
            });
        case userConstants.FINDLEAVE_FAILURE:
            return Object.assign({}, state, {
                loadingLeave: false,
                error: action.error
            });
        case userConstants.REMOVELEAVE_DIALOG:
            return Object.assign({}, state, {
                removeLeaveDialogVisible: action.holidayId
            });
        case userConstants.REMOVELEAVE_REQUEST:
            return Object.assign({}, state, {
                removingLeave: true
            });
        case userConstants.REMOVELEAVE_SUCCESS:
            if (state.selectedTherapist) {
                let updatedTherapist = state.selectedTherapist;
                let indexHoliday = state.selectedTherapist.leavesView.leaveViews.findIndex(holiday => holiday.id === action.event.id);
                // Don't use filter or splice as they are mutable ... have to use slice
                let slicedHolidays = [...state.selectedTherapist.leavesView.leaveViews.slice(0, indexHoliday), ...state.selectedTherapist.leavesView.leaveViews.slice(indexHoliday + 1)];
                updatedTherapist.leavesView.leaveViews = slicedHolidays;
                return Object.assign({}, state, {
                    removingLeave: false,
                    selectedTherapist: updatedTherapist,
                    leave: null
                });
            } else {
                return Object.assign({}, state, {
                    removingLeave: false,
                    leave: null
                });
            }
        case userConstants.REMOVELEAVE_FAILURE:
            return Object.assign({}, state, {
                removingLeave: false,
                error: action.error
            });
        case userConstants.RESETLEAVE_SUCCESS:
            return Object.assign({}, state, {
                loadingLeave: false,
                removingLeave: false,
                leave: null
            });
        case userConstants.SELECTEDCUSTOMER_SUCCESS:
            return Object.assign({}, state, {
                selectedCustomer: action.selectedCustomer,
                updating: false,
            });
        case userConstants.RESETCUSTOMER_SUCCESS:
            return Object.assign({}, state, {
                selectedCustomer: null
            });
        case userConstants.SELECTEDTHERAPIST_SUCCESS:
            return Object.assign({}, state, {
                selectedTherapist: action.selectedTherapist,
                updating: false,
            });
        case userConstants.RESETTHERAPIST_SUCCESS:
            return Object.assign({}, state, {
                selectedTherapist: null
            });
        default:
            return state;
    }
}
