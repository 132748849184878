import React from 'react';

import { TreatmentGroup } from '.';

export class TreatmentGroups extends React.Component {

    render() {
        const { treatmentGroups } = this.props;

        if (treatmentGroups && treatmentGroups.length > 0) {
            return (
                <div className="card-columns">
                    {
                        treatmentGroups.map((treatmentGroup, index) =>
                            <TreatmentGroup key={"TreatmentGroup_" + treatmentGroup.id} treatmentGroup={treatmentGroup}/>
                        )
                    }
                </div>
            );
        } else {
            return (
                <div className="form-group row">
                    <footer className="blockquote-footer col-sm-12">No treatment groups found</footer>
                </div>
            );
        }
    }
}
