import React from 'react';

export class Modal extends React.Component {

    render() {
        var { id, title, body, primaryButtonLabel, primaryButtonLabelWait, primaryFunction, primaryFunctionWait, primaryButtonClass, cancelFunction } = this.props;
        if (!primaryButtonClass) {
            primaryButtonClass = "btn-primary";
        }
        return (
            <div className="modal d-block" id={id} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content text-light">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">{title}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={cancelFunction}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {body}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={cancelFunction}>Close</button>
                            {!primaryFunctionWait && <button type="button" className={`btn ${primaryButtonClass}`} onClick={primaryFunction}>{primaryButtonLabel}</button>}
                            {primaryFunctionWait && <button type="button" className={`btn ${primaryButtonClass}`} disabled>{primaryButtonLabelWait} <i className="fa fa-circle-o-notch fa-spin"></i></button>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
