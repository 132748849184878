import React from 'react';
import { connect } from 'react-redux';

import { Modal } from '../_components';

import { treatmentGroupActions } from '../_actions';

class Price extends React.Component {

    constructor(props) {
        super(props);

        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.handleShowClick = this.handleShowClick.bind(this);

        this.state = {
			modal: false,
            show: false
		};
    }

    componentDidUpdate() {
        console.log("componentDidUpdate in Price.jsx");
    }

    handleDeleteClick() {
        this.setState({ modal: true })
    }

    handleEditClick() {
        var price = this.props.price;
        if (price) {
            this.props.dispatch(treatmentGroupActions.selectPrice(price));
        }
    }

    handleDeleteConfirm() {
        var price = this.props.price;
        if (price) {
            this.props.dispatch(treatmentGroupActions.deletePrice(price));
        }
        this.setState({ modal: false })
    }

    hideModal() {
        this.setState({ modal: false })
    }

    handleShowClick() {
        this.setState({ show: !this.state.show })
    }


    render() {
        const { price, deletingPrice } = this.props;
        const { modal, show } = this.state;
        if (price) {
            var styleClassExpired = '';
            if (price.current) {
                styleClassExpired = 'bg-dark';
            } else {
                styleClassExpired = 'text-dark bg-light';
            }
            var styleClassShowBody = '';
            var styleClassShowIcon = '';
            if (show) {
                styleClassShowBody = 'show';
                styleClassShowIcon = 'fa-angle-up';
            } else {
                styleClassShowIcon = 'fa-angle-down';
            }
            return (
                <div className={`card ${styleClassExpired}`}>
                    <div className="card-header">
                        <strong>{price.durationFormatted} - {price.priceFormatted}</strong>
                        <button type="button" className="btn btn-sm btn-outline-secondary float-right " onClick={this.handleShowClick}><span className={`fa ${styleClassShowIcon}`}></span></button>
                    </div>
                    <div className={`card-body collapse ${styleClassShowBody}`}>
                        <div className="card-text">
                            <div className="row">
                                <div className="col-4">Id</div>
                                <div className="col-8"><strong>{price.id}</strong></div>
                            </div>
                            <div className="row">
                                <div className="col-xl-4">Duration</div>
                                <div className="col-xl-8"><strong>{price.durationFormatted}</strong></div>
                            </div>
                            <div className="row">
                                <div className="col-xl-4">Price</div>
                                <div className="col-xl-8"><strong>{price.priceFormatted}</strong></div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <button type="button" className="btn btn-primary btn-sm mr-1 mb-1" onClick={this.handleEditClick}>Edit</button>
                        <button type="button" className="btn btn-danger btn-sm mr-1 mb-1" onClick={this.handleDeleteClick}>Delete</button>
                    </div>
                    {modal &&
                        <Modal id="deleteConfirmation"
                            title="Delete confirmation"
                            body= {`Are you sure you want to delete the price?`}
                            primaryButtonLabel="Yes"
                            primaryButtonLabelWait="Deleting..."
                            primaryFunction={this.handleDeleteConfirm}
                            primaryFunctionWait={deletingPrice}
                            primaryButtonClass="btn-danger"
                            cancelFunction={this.hideModal}/>
                    }
                </div>

            );
        } else {
            return "";
        }
    }
}

function mapStateToProps(state) {
    const { deletingPrice } = state.treatmentGroup;
    return {
        deletingPrice
    };
}

const connectedPrice = connect(mapStateToProps)(Price);
export { connectedPrice as Price };
