export const slotGroupConstants = {
  GETAVAILABLE_REQUEST: 'SLOT_GROUPS_GETAVAILABLE_REQUEST',
  GETAVAILABLE_SUCCESS: 'SLOT_GROUPS_GETAVAILABLE_SUCCESS',
  GETAVAILABLE_FAILURE: 'SLOT_GROUPS_GETAVAILABLE_FAILURE',

  GETBOOKINGS_REQUEST: 'SLOT_GROUPS_GETBOOKINGS_REQUEST',
  GETBOOKINGS_SUCCESS: 'SLOT_GROUPS_GETBOOKINGS_SUCCESS',
  GETBOOKINGS_FAILURE: 'SLOT_GROUPS_GETBOOKINGS_FAILURE',

  RESERVE_REQUEST: 'SLOT_GROUPS_RESERVE_REQUEST',
  RESERVE_SUCCESS: 'SLOT_GROUPS_RESERVE_SUCCESS',
  RESERVE_FAILURE: 'SLOT_GROUPS_RESERVE_FAILURE',

  CONFIRM_REQUEST: 'SLOT_GROUPS_CONFIRM_REQUEST',
  CONFIRM_SUCCESS: 'SLOT_GROUPS_CONFIRM_SUCCESS',
  CONFIRM_FAILURE: 'SLOT_GROUPS_CONFIRM_FAILURE',

  REJECT_REQUEST: 'SLOT_GROUPS_REJECT_REQUEST',
  REJECT_SUCCESS: 'SLOT_GROUPS_REJECT_SUCCESS',
  REJECT_FAILURE: 'SLOT_GROUPS_REJECT_FAILURE',

  PAY_REQUEST: 'SLOT_GROUPS_PAY_REQUEST',
  PAY_SUCCESS: 'SLOT_GROUPS_PAY_SUCCESS',
  PAY_FAILURE: 'SLOT_GROUPS_PAY_FAILURE',

  DELETE_REQUEST: 'SLOT_GROUPS_DELETE_REQUEST',
  DELETE_SUCCESS: 'SLOT_GROUPS_DELETE_SUCCESS',
  DELETE_FAILURE: 'SLOT_GROUPS_DELETE_FAILURE',

  CANCEL_REQUEST: 'SLOT_GROUPS_CANCEL_REQUEST',
  CANCEL_SUCCESS: 'SLOT_GROUPS_CANCEL_SUCCESS',
  CANCEL_FAILURE: 'SLOT_GROUPS_CANCEL_FAILURE',

  PRICEUPDATE_REQUEST: 'SLOT_GROUPS_PRICEUPDATE_REQUEST',
  PRICEUPDATE_SUCCESS: 'SLOT_GROUPS_PRICEUPDATE_SUCCESS',
  PRICEUPDATE_FAILURE: 'SLOT_GROUPS_PRICEUPDATE_FAILURE',

  GETLAST_REQUEST: 'SLOT_GROUPS_GETLAST_REQUEST',
  GETLAST_SUCCESS: 'SLOT_GROUPS_GETLAST_SUCCESS',
  GETLAST_FAILURE: 'SLOT_GROUPS_GETLAST_FAILURE',

  GETUPCOMING_REQUEST: 'SLOT_GROUPS_GETUPCOMING_REQUEST',
  GETUPCOMING_SUCCESS: 'SLOT_GROUPS_GETUPCOMING_SUCCESS',
  GETUPCOMING_FAILURE: 'SLOT_GROUPS_GETUPCOMING_FAILURE',

  RESETUPCOMING_SUCCESS: 'SLOT_GROUPS_RESETUPCOMING_SUCCESS',
  RESETGROUPS_SUCCESS: 'SLOT_GROUPS_RESETGROUPS_SUCCESS',
}
