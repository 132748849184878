import React from 'react';

import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';

import { SingleDatePicker } from 'react-dates';

export class DateField extends React.Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.handleOnFocusChanged = this.handleOnFocusChanged.bind(this);

        this.state = {
            focusedInput: false
        };
    }

    onChange( dateTime ) {
        if (dateTime && dateTime.isValid()) {
            this.props.onChange( dateTime );
        } else if (dateTime == null) {
            this.props.onChange( null );
        }
    }

    handleOnFocusChanged(value) {
        this.setState({ focusedInput: value.focused })
    }

    isOutsideRange(){
        return false;
    }

    render() {
        var { id, dateTime, className, required } = this.props;
        return (
            <div className="form-row">
                <div className={className ? className : 'col-lg-3 col-md-12'}>
                    <SingleDatePicker
                        date={dateTime} // momentPropTypes.momentObj or null
                        onDateChange={this.onChange} // PropTypes.func.isRequired
                        focused={this.state.focusedInput} // PropTypes.bool
                        onFocusChange={this.handleOnFocusChanged} // PropTypes.func.isRequired
                        id={`sdp_${id}`} // PropTypes.string.isRequired,
                        required={required}
                        isOutsideRange={this.isOutsideRange}
                        displayFormat="D/M/YYYY"
                        placeholder="D/M/YYYY"
                        numberOfMonths={1}
                        />
                    <div className="invalid-feedback">
                        Please enter date
                    </div>
                </div>
            </div>
        )
    }
}
