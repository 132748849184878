import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { treatmentGroupActions } from '../_actions';

import { TreatmentGroups } from '.';

class TreatmentGroupsPage extends React.Component {

    constructor(props) {
        super(props);

        if (!this.props.treatmentGroup.treatmentGroups && !this.props.treatmentGroup.requestingTreatmentGroups) {
            this.props.dispatch(treatmentGroupActions.getGroups());
        }
    }

    componentDidMount() {
        if (!this.props.loggedAs) {
            this.props.history.push("/login");
        }
        if (!this.props.treatmentGroup.treatmentGroups && !this.props.treatmentGroup.requestingTreatmentGroups) {
            this.props.dispatch(treatmentGroupActions.getGroups());
        }
    }

    componentDidUpdate() {
        if (!this.props.loggedAs) {
            this.props.history.push("/login");
        }
        if (!this.props.treatmentGroup.treatmentGroups && !this.props.treatmentGroup.requestingTreatmentGroups) {
            this.props.dispatch(treatmentGroupActions.getGroups());
        }
    }

    render() {
        const { treatmentGroup } = this.props;
        return (
            <div className="col-lg-10">
                <h2 className="text-center">Treatment groups</h2>
                <div className="card card-primary card-transparent">
                    <div className="card-body">
                        <form id="VouchersForm" noValidate className="needs-validation my-4" onSubmit={this.handleSubmit}>
                            <div className="form-group row align-items-center ml-1">
                                <Link to="/treatmentGroupCreate" className="btn btn-primary col-md-4 col-xl-3 mr-2 mb-2">
                                    Create new treatment group
                                </Link>
                                <Link to="/treatmentGroupIncreasePrices" className="btn btn-warning col-md-3 mr-2 mb-2">
                                    Increase prices
                                </Link>
                            </div>
                        </form>
                        {treatmentGroup.requestingTreatmentGroups &&
                            <div className="form-row">
                                <div class="col text-center">
                                    <strong>Loading treatment groups ... <i className="fa fa-circle-o-notch fa-spin"></i></strong>
                                </div>
                            </div>
                        }
                        {!treatmentGroup.requestingTreatmentGroups &&
                            <TreatmentGroups treatmentGroups={treatmentGroup.treatmentGroups}/>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { loggedAs } = state.user;
    const { treatmentGroup } = state;

    return {
        loggedAs,
        treatmentGroup
    };
}

const connectedTreatmentGroupsPage = connect(mapStateToProps)(TreatmentGroupsPage);
export { connectedTreatmentGroupsPage as TreatmentGroupsPage };
