import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { Prices } from '.';

class PricesPage extends React.Component {

    componentDidMount() {
        if (!this.props.loggedAs) {
            this.props.history.push("/login");
        }
        if (!this.props.selectedTreatment) {
            this.props.history.push("/treatmentGroups");
        }
    }

    componentDidUpdate() {
        console.log("componentDidUpdate");
        if (!this.props.loggedAs) {
            this.props.history.push("/login");
        }
        if (!this.props.selectedTreatment) {
            this.props.history.push("/treatmentGroups");
        }
    }

    render() {
        const { selectedTreatment } = this.props;
        if (selectedTreatment) {
            return (
                <div className="col-lg-10">
                    <h2 className="text-center">Prices for - <strong>{selectedTreatment.label}</strong></h2>
                    <div className="card card-primary card-transparent">
                        <div className="card-body">
                            <form id="PricesForm" noValidate className="needs-validation my-4" onSubmit={this.handleSubmit}>
                                <div className="form-group row align-items-center ml-1">
                                    <Link to="/treatments" className="btn btn-secondary col-md-2 col-xl-1 mr-2 mb-2">
                                        Back
                                    </Link>
                                    <Link to="/priceCreate" className="btn btn-primary col-md-3 col-xl-2 mr-2 mb-2">
                                        Create new price
                                    </Link>
                                </div>
                            </form>
                            <Prices prices={selectedTreatment.prices}/>
                        </div>
                    </div>
                </div>
            );
        } else {
            return "";
        }
    }
}

function mapStateToProps(state) {
    const { loggedAs } = state.user;
    const { selectedTreatment } = state.treatmentGroup;

    return {
        loggedAs,
        selectedTreatment
    };
}

const connectedPricesPage = connect(mapStateToProps)(PricesPage);
export { connectedPricesPage as PricesPage };
