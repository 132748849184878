import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { http, formatting } from '../_helpers';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';
import moment from 'moment';
import 'moment/locale/en-nz';

import { DateRangeField } from '../_components';

import { financeActions, alertActions, statisticsActions } from '../_actions';

import { Transactions } from '.';

class FinancesPage extends React.Component {

    constructor(props) {
        super(props);

        this.setDates = this.setDates.bind(this);
        this.alterInterval = this.alterInterval.bind(this);
        this.fetchFinances = this.fetchFinances.bind(this);
        this.fetchStatistics = this.fetchStatistics.bind(this);
        this.scrollToTransaction = this.scrollToTransaction.bind(this);
        this.handleOnDateChanged = this.handleOnDateChanged.bind(this);

        moment().zone(0);

        this.state = {
            from: moment().subtract(2, 'month').startOf('month').startOf('day'),
            to: moment().endOf('month').endOf('day'),
            invalidDates: false
        }
    }

    componentDidMount() {
        if (!this.props.loggedAs) {
            this.props.history.push("/login");
        }
        this.fetchFinances();
        this.fetchStatistics();
    }

    componentDidUpdate() {
        if (!this.props.loggedAs) {
            this.props.history.push("/login");
        }
        this.fetchFinances();
        this.fetchStatistics();
    }

    handleOnDateChanged(startDateTime, endDateTime) {
        if (startDateTime) {
            startDateTime.startOf('day');
        }
        if (endDateTime) {
            endDateTime.endOf('day');
        }
        this.setDates(startDateTime, endDateTime);
        this.props.dispatch(financeActions.resetFinancialTransactions(startDateTime, endDateTime));
        this.props.dispatch(statisticsActions.resetSpecific());
    }

    setDates(startDateTime, endDateTime) {
        if (this.state.from !== startDateTime) {
            this.setState({
                from: startDateTime
            });
        }
        if (this.state.to !== endDateTime) {
            this.setState({
                to: endDateTime
            });
        }
        if (startDateTime && endDateTime && startDateTime.isAfter(endDateTime)) {
            this.props.dispatch(alertActions.error("`From` date can't be before `To` date!"));
            if (!this.state.invalidDates) {
                this.setState({
                    invalidDates: true
                });
            }
            return;
        }
        if (startDateTime && endDateTime  && endDateTime.diff(startDateTime, 'days') >= 1095) {
            this.props.dispatch(alertActions.error("Interval is too long!"));
            if (!this.state.invalidDates) {
                this.setState({
                    invalidDates: true
                });
            }
            return;
        }
        if (this.state.invalidDates) {
            this.setState({
                invalidDates: false
            });
        }
    }

    fetchFinances() {
        // Check if we already have the from and to date set in finence so we can reuse it as we will be rendering already fetch transactions anyway
        if (this.props.finance.financialTransactions
            && !this.props.finance.requestingFinancialTransactions
            && (this.state.from !== this.props.finance.financialTransactionsFromDate || this.state.to !== this.props.finance.financialTransactionsToDate)) {
                    this.setState({
                        from: this.props.finance.financialTransactionsFromDate,
                        to: this.props.finance.financialTransactionsToDate
                    })
                    return;
        }
        if (!this.props.finance.financialTransactions
            && !this.props.finance.requestingFinancialTransactions
            && this.state.from
            && this.state.to
            && !this.state.invalidDates) {
                const fromDate = this.state.from;
                const toDate = this.state.to;
                this.props.dispatch(financeActions.getFinancialTransactions(fromDate, toDate));
        }

        if (this.props.finance.lastTransactionId) {
            this.scrollToTransaction(this.props.finance.lastTransactionId);
        }
    }

    fetchStatistics() {
        if (!this.props.statistics.specificStatistics
            && !this.props.statistics.specificStatistics !== 'failure'
            && !this.props.statistics.requestingSpecificStatistics
            && this.state.from
            && this.state.to
            && !this.state.invalidDates) {
                const fromDate = moment(this.state.from);
                const toDate = moment(this.state.to);
                this.props.dispatch(statisticsActions.getSpecific(fromDate, toDate));
        }
    }

    alterInterval(value, key) {
        this.handleOnDateChanged(this.state.from.add(value, key), this.state.to.add(value, key));
    }

    scrollToTransaction(lastTransactionId) {
        if (this.listComponent) {
            this.listComponent.scrollTo(lastTransactionId);
        }
    }

    render() {
        const { finance, statistics } = this.props;
        const stats = statistics.specificStatistics ? statistics.specificStatistics[1].values : null;
        const requestingSpecificStatistics = statistics.requestingSpecificStatistics;
        const specificStatisticsFailure = statistics.specificStatisticsFailure
        return (
            <div className="col-12 col-lg-10">
                <h2 className="text-center">Finances</h2>
                <div className="card card-primary card-transparent">
                    <div className="card-body">
                        <form id="FinancesForm" noValidate className="needs-validation my-4" onSubmit={this.handleSubmit}>
                            <div className="row">
                                <div className="col-lg-6 mt-1">
                                    <div className="form-group row">
                                        <label className="col-2 col-form-label pr-0">Period</label>
                                        <div className="col-10">
                                            <DateRangeField
                                                startDateId="from"
                                                startDateTime={this.state.from ? moment(this.state.from) : null}
                                                endDateId="to"
                                                endDateTime={this.state.to ? moment(this.state.to) : null}
                                                className="form-control"
                                                onChange={this.handleOnDateChanged}
                                                required={false}
                                                block={true}
                                                />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-2 col-form-label pr-0"></label>
                                        <div className="col-10">
                                            <div className="btn-group mr-3" role="group" aria-label="Day">
                                                <button type="button" className="btn btn-secondary btn-sm" onClick={() => this.alterInterval(-1, 'days')}><span className="fa fa-minus"/></button>
                                                <button type="button" className="btn btn-secondary btn-sm">D</button>
                                                <button type="button" className="btn btn-secondary btn-sm" onClick={() => this.alterInterval(1, 'days')}><span className="fa fa-plus"/></button>
                                            </div>
                                            <div className="btn-group mr-3" role="group" aria-label="Month">
                                                <button type="button" className="btn btn-secondary btn-sm" onClick={() => this.alterInterval(-1, 'months')}><span className="fa fa-minus"/></button>
                                                <button type="button" className="btn btn-secondary btn-sm">M</button>
                                                <button type="button" className="btn btn-secondary btn-sm" onClick={() => this.alterInterval(1, 'months')}><span className="fa fa-plus"/></button>
                                            </div>
                                            <div className="btn-group" role="group" aria-label="Year">
                                                <button type="button" className="btn btn-secondary btn-sm" onClick={() => this.alterInterval(-1, 'years')}><span className="fa fa-minus"/></button>
                                                <button type="button" className="btn btn-secondary btn-sm">Y</button>
                                                <button type="button" className="btn btn-secondary btn-sm" onClick={() => this.alterInterval(1, 'years')}><span className="fa fa-plus"/></button>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.from && this.state.to && finance.financialTransactions && finance.financialTransactions.length > 0 &&
                                        <div className="row mb-2">
                                            <div className="col">
                                                <a className="btn btn-info d-block mb-1"
                                                    href={`${http.BASE_URL}/api/financialtransaction/pdf?start=${moment(this.state.from).format(formatting.DATE_TIME_FORMATTING)}&end=${moment(this.state.to).format(formatting.DATE_TIME_FORMATTING)}`}
                                                    download>
                                                    Generate tax report
                                                </a>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="col-lg-6 mt-1" style={{"height": 150}}>
                                    {requestingSpecificStatistics && !this.state.invalidDates && <span>Loading chart ... <i className="fa fa-circle-o-notch fa-spin"></i></span>}
                                    {!requestingSpecificStatistics && this.state.invalidDates && <span>Invalid dates for chart</span>}
                                    {!stats && !requestingSpecificStatistics && <div className="small alert alert-warning fade show"><span>Chart not rendered ... {specificStatisticsFailure}</span></div>}
                                    {!requestingSpecificStatistics && !this.state.invalidDates && stats &&
                                        <ResponsiveContainer>
                                            <BarChart data={stats} stackOffset="sign">
                                                <CartesianGrid strokeDasharray="3 3" barCategoryGap="0%" barGap="0"/>
                                                <XAxis
                                                    dataKey = 'name'
                                                    name = 'Date'
                                                    stroke = 'white'
                                                    tick={{fontSize: 10}}
                                                    />
                                                <YAxis
                                                    stroke = 'white'
                                                    tick={{fontSize: 10}}
                                                    />
                                                <ReferenceLine y={0} stroke="#000" />
                                                <Bar
                                                    dataKey="values.moneyComingIn"
                                                    fill="#7AA82D"
                                                    name='Money coming in'
                                                    stackId="stack"
                                                />
                                                <Bar
                                                    dataKey="values.expenses"
                                                    fill="#fc296c"
                                                    name='Expenses'
                                                    stackId="stack"
                                                />
                                                <Bar
                                                    dataKey="values.drawings"
                                                    fill="#2575fc"
                                                    name='Drawings'
                                                    stackId="stack"
                                                />
                                                <Tooltip
                                                    wrapperStyle={
                                                        {opacity: 0.7}
                                                    }
                                                    labelStyle={
                                                        {
                                                            color:'black',
                                                            fontSize:'9px'
                                                        }
                                                    }
                                                    itemStyle={
                                                        {fontSize:'9px'}
                                                    }
                                                    cursor={{opacity:0.1}}
                                                />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    }
                                </div>
                            </div>
                            {finance.requestingFinancialTransactions && !this.state.invalidDates &&
                                <div className="form-row">
                                    <div className="col text-center">
                                        <strong>A long time ago in a galaxy far, far away …<i className="fa fa-circle-o-notch fa-spin"></i></strong>
                                    </div>
                                </div>
                            }
                            {finance.financialTransactions === 'failure' && <span>Error loading transactions</span>}
                            {!finance.requestingFinancialTransactions && !this.state.invalidDates && finance.financialTransactions !== 'failure' && finance.financialTransactions &&
                                <div className="form-row">
                                    <div className="col-12 col-lg-4 mb-2">
                                        <div className="card gradient-ohhappiness card-shade" >
                                            <div className="card-body">
                                                <p className="mb-1">Money coming in : <strong className="float-right">{finance.moneyComingIn.toLocaleString('en-NZ', {style: 'currency', currency: 'NZD'})}</strong></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 mb-2">
                                        <div className="card gradient-ibiza card-shade" >
                                            <div className="card-body">
                                                <p className="mb-1">Expenses : <strong className="float-right">{finance.expenses.toLocaleString('en-NZ', {style: 'currency', currency: 'NZD'})}</strong></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 mb-2">
                                        <div className="card gradient-deepblue card-shade" >
                                            <div className="card-body">
                                                <p className="mb-1">Drawings : <strong className="float-right">{finance.drawings.toLocaleString('en-NZ', {style: 'currency', currency: 'NZD'})}</strong></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="row my-2">
                                <Link to="/finacialTransationCreate" className="col">
                                    <button type="button" className="btn btn-primary btn-block mr-2 mb-2">Create new transaction</button>
                                </Link>
                            </div>
                            {!finance.requestingFinancialTransactions && !this.state.invalidDates && finance.financialTransactions &&
                                <Transactions
                                    ref={e => (this.listComponent = e)}
                                    financialTransactions={finance.financialTransactions}
                                    lastTransactionId={finance.lastTransactionId}/>
                            }
                            {!finance.requestingFinancialTransactions && this.state.invalidDates &&
                                <div className="form-row">
                                    <div className="col text-center">
                                        <strong>Invalid dates for transactions</strong>
                                    </div>
                                </div>
                            }
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { loggedAs } = state.user;
    const { finance, statistics } = state;

    return {
        loggedAs,
        finance,
        statistics
    };
}

const connectedFinancesPage = connect(mapStateToProps)(FinancesPage);
export { connectedFinancesPage as FinancesPage };
