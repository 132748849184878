import React from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/en-nz';

import { formatting } from '../_helpers';

export class MyCalendar extends React.Component {
    constructor() {
        super();
        this.state = {
            view:  'week'
        }
    }

    eventStyleGetter(event, start, end, isSelected) {
        var style = {
            color: event.textColor,
            backgroundColor: event.backgroundColor,
            opacity: 0.8,
            fontSize: '9px',
            fontWeight: 'bold',
            display: 'block',
            padding: '2px'
        };
        return {
            style: style
        };
    }

    eventTitleAccessor(event) {
        return event.title;
    }

    tooltipAccessor(event) {
        var toolTip = "";
        if (event.stateCode === 'notWorking') {
            toolTip = "\n" + event.therapist + "\n" + event.place + "\n";
        } else if (event.eventType === 'leave') {
            toolTip = "\n" + event.therapist + "\n" + event.start + "\n" + event.end;
        } else if (event.eventType === 'slotGroup') {
            toolTip = "\nTherapist: " + event.therapist;
            if (event.place) {
                toolTip = toolTip + "\nPlace: " + event.place;
            }
            if (event.customerName) {
                toolTip = toolTip + "\nCustomer: " + event.customerName + " - " + event.customerContact;
            }
            for (var i = 0; i < event.sessions.length; i++) {
                toolTip = toolTip + "\n" + event.sessions[i].treatmentLabel + " - " + event.sessions[i].priceFormatted + " - " + event.sessions[i].durationFormatted;
            }
            toolTip = toolTip + "\nTotal: " + event.totalPrice + " - " + event.totalDuration;
        } else {
            toolTip = "\nPick any of the available slots";
        }
        return toolTip;
    }

    render() {
        moment.tz.setDefault('Pacific/Auckland');
        const localizer = momentLocalizer(moment);
        let formats = {
            dayFormat: (date, culture, localizer) => localizer.format(date, 'ddd D', culture),
            dayRangeHeaderFormat: ({ start, end }, culture, localizer) => localizer.format(start, 'D/MMM', culture) + ' - ' + localizer.format(end,'D/MMM', culture),
            timeGutterFormat: (date, culture, localizer) => localizer.format(date, 'ha', culture)
        }

        return(
            <Calendar
                localizer={localizer}
                events={this.props.events}
                defaultView={this.state.view}
                views={[`${this.state.view}`]}
                view={this.state.view}
                showMultiDayTimes= {true}
                toolbar={true}
                selectable={true}
                longPressThreshold={250}
                culture={formatting.CULTURE}
                min={this.props.min}
                max={this.props.max}
                startAccessor='start'
                endAccessor='end'
                date={this.props.date}
                onNavigate={this.props.onNavigate}
                eventPropGetter={this.eventStyleGetter}
                titleAccessor={this.eventTitleAccessor}
                tooltipAccessor={this.tooltipAccessor}
                onSelectEvent={this.props.onSelectEvent}
                onSelectSlot={this.props.onSelectSlot}
                formats={formats}
                />
        );
    }
}
