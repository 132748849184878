import React from 'react';

export class TreatmentTherapist extends React.Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.props.onSelectTherapist(this.props.therapist.id, value)
    }

    render() {
        const { therapist, ticked } = this.props;

        if (therapist) {
            return (
                <li class="list-group-item">
                    <div class="custom-control custom-checkbox">
                        <input class="custom-control-input" id={"therapist_" + therapist.id} type="checkbox" checked={ticked} onChange={this.handleChange}/>
                        <label class="cursor-pointer font-italic d-block custom-control-label" for={"therapist_" + therapist.id}>{therapist.firstName}</label>
                    </div>
                </li>
            );
        } else {
            return (
                <li class="list-group-item">Whoops</li>
            );
        }
    }
}
