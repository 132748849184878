import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/en-nz';

import { TreatmentTherapists } from '.';
import { treatmentGroupActions, alertActions, userActions } from '../_actions';
import { SubmitButton, DateField } from '../_components';

class TreatmentEditPage extends React.Component {

    constructor(props) {
        super(props);

        this.handleBackClick = this.handleBackClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleOnStartDateChanged = this.handleOnStartDateChanged.bind(this);
        this.handleOnEndDateChanged = this.handleOnEndDateChanged.bind(this);
        this.handleOnVisibleFromDateChanged = this.handleOnVisibleFromDateChanged.bind(this);
        this.handleSelectTherapist = this.handleSelectTherapist.bind(this);

        if (!props.selectedTreatment) {
            this.state = {
                newTreatment: {
                    validFrom: moment(),
                    validTo: moment(),
                    visibleFrom: null
                },
                submitted: false,
                focusedInputStart: false,
                focusedInputEnd: false
            };
            this.props.history.push("/treatments");
        } else {
            this.state = {
                newTreatment: {
                    id: props.selectedTreatment.id,
                    heading: props.selectedTreatment.heading,
                    label: props.selectedTreatment.label,
                    keywords: props.selectedTreatment.keywords,
                    content: props.selectedTreatment.content,
                    contentAbbreviated: props.selectedTreatment.contentAbbreviated,
                    validFrom: props.selectedTreatment.validFrom,
                    validTo: props.selectedTreatment.validTo,
                    visibleFrom: props.selectedTreatment.visibleFrom,
                    special: props.selectedTreatment.special,
                    therapistsRoster: props.selectedTreatment.therapistsRoster
                },
                submitted: false,
                focusedInputStart: false,
                focusedInputEnd: false
            };
        }
    }

    componentDidMount() {
        if (!this.props.loggedAs) {
            this.props.history.push("/login");
        }
        if (!this.props.therapists && !this.props.requestingTherapists) {
            // Only ask for therapists in case we don't have them yet or if it's not in flight
            this.props.dispatch(userActions.getTherapists());
        }
    }

    componentDidUpdate() {
        if (!this.props.loggedAs) {
            this.props.history.push("/login");
        }
    }

    handleBackClick() {
        this.props.history.push("/treatments");
    }

    handleSubmit(e) {
        e.preventDefault();
        var form = document.getElementById('treatmentForm');
        if (form.checkValidity() === false) {
            form.classList.add('was-validated');
            e.stopPropagation();
            return;
        }

        const { dispatch } = this.props;
        dispatch(alertActions.clear());
        dispatch(treatmentGroupActions.updateTreatment(this.state.newTreatment));
    }

    handleChange(e) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const { newTreatment } = this.state;
        this.setState({
            newTreatment: {
                ...newTreatment,
                [name]: value
            }
        });
    }

    handleOnStartDateChanged(dateMoment) {
        const { newTreatment } = this.state;
        this.setState({
            newTreatment: {
                ...newTreatment,
                validFrom: dateMoment
            }
        });
    }

    handleOnVisibleFromDateChanged(dateMoment){
        const { newTreatment } = this.state;
        this.setState({
            newTreatment: {
                ...newTreatment,
                visibleFrom: dateMoment
            }
        });
    }

    handleOnEndDateChanged(dateMoment) {
        const { newTreatment } = this.state;
        this.setState({
            newTreatment: {
                ...newTreatment,
                validTo: dateMoment
            }
        });
    }

    handleSelectTherapist(roster) {
        const { newTreatment } = this.state;
        this.setState({
            newTreatment: {
                ...newTreatment,
                therapistsRoster: roster
            }
        });
    }

    render() {
        const { savingTreatment, therapists, requestingTherapists } = this.props;
        const { newTreatment } = this.state;
            return (
                <div className="col-lg-8">
                    <h2 className="text-center">Edit treatment</h2>
                    <div className="card card-primary card-transparent">
                        <div className="card-body">
                            <form id="treatmentForm" noValidate className="needs-validation my-4" onSubmit={this.handleSubmit}>
                                <div className="form-group row">
                                    <label className="col-4 col-md-3 col-xl-2 col-form-label" htmlFor="heading">Id</label>
                                    <div className="col-8">
                                        <input type="text" className="form-control-plaintext font-weight-bold" name="heading" value={newTreatment.id} readOnly/>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-4 col-md-3 col-xl-2 col-form-label" htmlFor="heading">Heading</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" name="heading" value={newTreatment.heading} required onChange={this.handleChange}/>
                                        <div className="invalid-feedback">
                                            Please enter heading
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-4 col-md-3 col-xl-2 col-form-label" htmlFor="label">Label</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" name="label" value={newTreatment.label} required onChange={this.handleChange}/>
                                        <div className="invalid-feedback">
                                            Please enter label
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-4 col-md-3 col-xl-2 col-form-label" htmlFor="keywords">Keywords</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" name="keywords" value={newTreatment.keywords} required onChange={this.handleChange}/>
                                        <div className="invalid-feedback">
                                            Please enter keywords
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-4 col-md-3 col-xl-2 col-form-label" htmlFor="content">Content</label>
                                    <div className="col-sm-8">
                                        <textarea className="form-control" name="content" rows="5" value={newTreatment.content} onChange={this.handleChange}/>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-4 col-md-3 col-xl-2 col-form-label" >Valid from</label>
                                    <div className="col-8">
                                        <DateField
                                            id="validFrom"
                                            dateTime={newTreatment.validFrom}
                                            onChange={this.handleOnStartDateChanged}
                                            required={true}
                                            />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-4 col-md-3 col-xl-2 col-form-label" >Visible from</label>
                                    <div className="col-8">
                                        <DateField
                                            id="visibleFrom"
                                            dateTime={newTreatment.visibleFrom}
                                            onChange={this.handleOnVisibleFromDateChanged}
                                            required={false}
                                            />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-4 col-md-3 col-xl-2 col-form-label" >Valid to</label>
                                    <div className="col-8">
                                        <DateField
                                            id="validTo"
                                            dateTime={newTreatment.validTo}
                                            onChange={this.handleOnEndDateChanged}
                                            required={false}
                                            />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-4 col-md-3 col-xl-2 col-form-label" >Extra stuff</label>
                                    <div className="col-8 pt-2 custom-control custom-checkbox pl-0">
                                        <input className="custom-control-input" type="checkbox" checked={!!newTreatment.special} name="special" id="special" onChange={this.handleChange}/>
                                        <label className="custom-control-label ml-5" htmlFor="special">Is special</label>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-4 col-md-3 col-xl-2 col-form-label" >Therapists</label>
                                    <div className="col-sm-8">
                                        <TreatmentTherapists therapists={therapists} treatment={newTreatment} loading={requestingTherapists} roster={newTreatment.therapistsRoster} onSelectTherapist={this.handleSelectTherapist}/>
                                    </div>
                                </div>

                                <div className="form-group row align-items-center ml-1">
                                    <button type="button" className="btn btn-secondary col-md-2 col-xl-1 mr-2 mb-2" onClick={this.handleBackClick}>Back</button>
                                    <SubmitButton updating={savingTreatment} label="Save treatment" labelLoading="Saving ..." className="col-md-3 col-xl-2 mr-2 mb-2"/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            );
    }
}

function mapStateToProps(state) {
    const { loggedAs, therapists, requestingTherapists } = state.user;
    const { savingTreatment, selectedTreatment } = state.treatmentGroup;

    return {
        loggedAs,
        savingTreatment,
        selectedTreatment,
        therapists,
        requestingTherapists
    };
}

const connectedTreatmentEditPage = connect(mapStateToProps)(TreatmentEditPage);
export { connectedTreatmentEditPage as TreatmentEditPage };
