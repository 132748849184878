import { treatmentGroupConstants, reservationConstants } from '../_constants';
import { treatmentGroupService } from '../_services';
import { alertActions } from './';
import moment from 'moment';
import 'moment/locale/en-nz';
import { history, http } from '../_helpers';

export const treatmentGroupActions = {
    getGroups,
    createTreatmentGroup,
    updateTreatmentGroup,
    deleteTreatmentGroup,
    selectTreatmentGroup,
    showTreatments,
    createTreatment,
    updateTreatment,
    deleteTreatment,
    selectTreatment,
    showPrices,
    showIncreasePrices,
    showTherapists,
    createPrice,
    updatePrice,
    deletePrice,
    selectPrice,
    getGroupsForPlaceAndDate,
    getTherapists,
    resetTherapists,
    getPrices,
    resetPrices,
    increasePrices,
    increasePricesForTreatment
};

function getGroups() {
    return dispatch => {
        dispatch(request());

        treatmentGroupService.getGroups()
            .then(
                treatmentGroups => {
                    treatmentGroups.treatmentGroupViews.map((treatmentGroup, i) => {
                        treatmentGroup.treatmentViewList.map((treatment, j) => {
                            treatment.validFrom = treatment.validFrom ? moment(treatment.validFrom, "YYYY-MM-DD") : null;
                            treatment.validTo = treatment.validTo ? moment(treatment.validTo, "YYYY-MM-DD") : null;
                            treatment.visibleFrom = treatment.visibleFrom ? moment(treatment.visibleFrom, "YYYY-MM-DD") : null;
                            return treatment;
                        })
                        return treatmentGroup;
                    });
                    dispatch(success(treatmentGroups));
                },
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request() { return { type: treatmentGroupConstants.GETGROUPS_REQUEST } }
    function success(treatmentGroups) { return { type: treatmentGroupConstants.GETGROUPS_SUCCESS, treatmentGroups } }
    function failure(message) { return { type: treatmentGroupConstants.GETGROUPS_FAILURE, message } }
}

function createTreatmentGroup(treatmentGroup) {
    return dispatch => {
        dispatch(request());
        treatmentGroupService.createTreatmentGroup(treatmentGroup)
            .then(
                event => {
                    dispatch(success());
                    dispatch(resetTreatmentGroups());
                    history.push('/treatmentGroups');
                    dispatch(alertActions.success('The treatment group has been created.'));
                },
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request() { return { type: treatmentGroupConstants.CREATEGROUP_REQUEST } }
    function resetTreatmentGroups() { return { type: treatmentGroupConstants.RESETGROUPS_SUCCESS } }
    function success() { return { type: treatmentGroupConstants.CREATEGROUP_SUCCESS } }
    function failure(message) { return { type: treatmentGroupConstants.CREATEGROUP_FAILURE, message } }
}

function updateTreatmentGroup(treatmentGroup) {
    return dispatch => {
        dispatch(request());
        treatmentGroupService.updateTreatmentGroup(treatmentGroup)
            .then(
                event => {
                    dispatch(success());
                    dispatch(resetTreatmentGroups());
                    history.push('/treatmentGroups');
                    dispatch(alertActions.success('The treatment group has been updated.'));
                },
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request() { return { type: treatmentGroupConstants.CREATEGROUP_REQUEST } }
    function resetTreatmentGroups() { return { type: treatmentGroupConstants.RESETGROUPS_SUCCESS } }
    function success() { return { type: treatmentGroupConstants.CREATEGROUP_SUCCESS } }
    function failure(message) { return { type: treatmentGroupConstants.CREATEGROUP_FAILURE, message } }
}

function deleteTreatmentGroup(treatmentGroup) {
    return dispatch => {
        dispatch(request());
        treatmentGroupService.deleteTreatmentGroup(treatmentGroup)
            .then(
                event => {
                    dispatch(success());
                    dispatch(resetTreatmentGroups());
                    dispatch(alertActions.success('The treatment group has been deleted.'));
                },
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request() { return { type: treatmentGroupConstants.DELETEGROUP_REQUEST } }
    function resetTreatmentGroups() { return { type: treatmentGroupConstants.RESETGROUPS_SUCCESS } }
    function success(vouchers) { return { type: treatmentGroupConstants.DELETEGROUP_SUCCESS, vouchers } }
    function failure(message) { return { type: treatmentGroupConstants.DELETEGROUP_FAILURE, message } }
}

function selectTreatmentGroup(selectedTreatmentGroup) {
    return dispatch => {
        dispatch(success(selectedTreatmentGroup));
        history.push('/treatmentGroupEdit');
    };
    function success(selectedTreatmentGroup) {
      return { type: treatmentGroupConstants.SELECTEDGROUP_SUCCESS, selectedTreatmentGroup }
    }
}

function createTreatment(treatment) {
    return dispatch => {
        dispatch(request());
        // Convert the date into string so it can be posted to the server
        var treatmentForServer = {
            ...treatment,
            validFrom: treatment.validFrom ? treatment.validFrom.format("YYYY-MM-DD") : null,
            validTo: treatment.validTo ? treatment.validTo.format("YYYY-MM-DD") : null,
            visibleFrom: treatment.visibleFrom ? treatment.visibleFrom.format("YYYY-MM-DD") : null
        };
        treatmentGroupService.createTreatment(treatmentForServer)
            .then(
                treatment => {
                    treatment.validFrom = treatment.validFrom ? moment(treatment.validFrom, "YYYY-MM-DD") : null;
                    treatment.validTo = treatment.validTo ? moment(treatment.validTo, "YYYY-MM-DD") : null;
                    treatment.visibleFrom = treatment.visibleFrom ? moment(treatment.visibleFrom, "YYYY-MM-DD") : null;
                    dispatch(success(treatment));
                    history.push('/treatments');
                    dispatch(alertActions.success('The treatment has been created.'));
                },
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request() { return { type: treatmentGroupConstants.CREATETREATMENT_REQUEST } }
    function success(treatment) { return { type: treatmentGroupConstants.CREATETREATMENT_SUCCESS, treatment } }
    function failure(message) { return { type: treatmentGroupConstants.CREATETREATMENT_FAILURE, message } }
}

function updateTreatment(treatment) {
    return dispatch => {
        dispatch(request());
        // Convert the date into string so it can be posted to the server
        var treatmentForServer = {
            ...treatment,
            validFrom: treatment.validFrom ? treatment.validFrom.format("YYYY-MM-DD") : null,
            validTo: treatment.validTo ? treatment.validTo.format("YYYY-MM-DD") : null,
            visibleFrom: treatment.visibleFrom ? treatment.visibleFrom.format("YYYY-MM-DD") : null
        };
        treatmentGroupService.updateTreatment(treatmentForServer)
            .then(
                treatment => {
                    treatment.validFrom = treatment.validFrom ? moment(treatment.validFrom, "YYYY-MM-DD") : null;
                    treatment.validTo = treatment.validTo ? moment(treatment.validTo, "YYYY-MM-DD") : null;
                    treatment.visibleFrom = treatment.visibleFrom ? moment(treatment.visibleFrom, "YYYY-MM-DD") : null;
                    dispatch(success(treatment));
                    history.push('/treatments');
                    dispatch(alertActions.success('The treatment has been updated.'));
                },
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request() { return { type: treatmentGroupConstants.UPDATETREATMENT_REQUEST } }
    function success(treatment) { return { type: treatmentGroupConstants.UPDATETREATMENT_SUCCESS, treatment} }
    function failure(message) { return { type: treatmentGroupConstants.UPDATETREATMENT_FAILURE, message } }
}

function deleteTreatment(treatment) {
    return dispatch => {
        dispatch(request());
        dispatch(alertActions.info('Deleting treatment ...'));
        treatmentGroupService.deleteTreatment(treatment)
            .then(
                event => {
                    dispatch(success(treatment));
                    dispatch(alertActions.success('The treatment ' + treatment.label + ' has been deleted.'));
                },
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request() { return { type: treatmentGroupConstants.DELETETREATMENT_REQUEST } }
    function success(treatment) { return { type: treatmentGroupConstants.DELETETREATMENT_SUCCESS, treatment } }
    function failure(message) { return { type: treatmentGroupConstants.DELETETREATMENT_FAILURE, message } }
}

function selectTreatment(selectedTreatment) {
    return dispatch => {
        dispatch(success(selectedTreatment));
        history.push('/treatmentEdit');
    };
    function success(selectedTreatment) {
      return { type: treatmentGroupConstants.SELECTEDTREATMENT_SUCCESS, selectedTreatment }
    }
}

function showTreatments(selectedTreatmentGroup) {
    return dispatch => {
        dispatch(success(selectedTreatmentGroup));
        history.push('/treatments');
    };
    function success(selectedTreatmentGroup) {
      return { type: treatmentGroupConstants.SELECTEDGROUP_SUCCESS, selectedTreatmentGroup }
    }
}

function createPrice(price) {
    // Alter the duration from minutes to milliseconds
    price.duration = price.duration * 60 * 1000;
    return dispatch => {
        dispatch(request());
        treatmentGroupService.createPrice(price)
            .then(
                price => {
                    dispatch(success(price));
                    history.push('/prices');
                    dispatch(alertActions.success('The price has been created.'));
                },
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request() { return { type: treatmentGroupConstants.CREATEPRICE_REQUEST } }
    function success(price) { return { type: treatmentGroupConstants.CREATEPRICE_SUCCESS, price } }
    function failure(message) { return { type: treatmentGroupConstants.CREATEPRICE_FAILURE, message } }
}

function updatePrice(price) {
    // Alter the duration to milliseconds
    price.duration = price.duration * 60 * 1000;
    return dispatch => {
        dispatch(request());
        treatmentGroupService.updatePrice(price)
            .then(
                price => {
                    dispatch(success(price));
                    history.push('/prices');
                    dispatch(alertActions.success('The price has been updated.'));
                },
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request() { return { type: treatmentGroupConstants.UPDATEPRICE_REQUEST } }
    function success(price) { return { type: treatmentGroupConstants.UPDATEPRICE_SUCCESS, price} }
    function failure(message) { return { type: treatmentGroupConstants.UPDATEPRICE_FAILURE, message } }
}

function deletePrice(price) {
    return dispatch => {
        dispatch(request());
        dispatch(alertActions.info('Deleting price ...'));
        treatmentGroupService.deletePrice(price)
            .then(
                event => {
                    dispatch(success(price));
                    dispatch(alertActions.success('The price ' + price.priceFormatted + ' has been deleted.'));
                },
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request() { return { type: treatmentGroupConstants.DELETEPRICE_REQUEST } }
    function success(price) { return { type: treatmentGroupConstants.DELETEPRICE_SUCCESS, price } }
    function failure(message) { return { type: treatmentGroupConstants.DELETEPRICE_FAILURE, message } }
}

function selectPrice(selectedPrice) {
    return dispatch => {
        dispatch(success(selectedPrice));
        history.push('/priceEdit');
    };
    function success(selectedPrice) {
      return { type: treatmentGroupConstants.SELECTEDPRICE_SUCCESS, selectedPrice }
    }
}

function showPrices(selectedTreatment) {
    return dispatch => {
        dispatch(success(selectedTreatment));
        history.push('/prices');
    };
    function success(selectedTreatment) {
      return { type: treatmentGroupConstants.SELECTEDTREATMENT_SUCCESS, selectedTreatment }
    }
}

function showIncreasePrices(selectedTreatment) {
    return dispatch => {
        dispatch(success(selectedTreatment));
        history.push('/treatmentIncreasePrices');
    };
    function success(selectedTreatment) {
      return { type: treatmentGroupConstants.SELECTEDTREATMENT_SUCCESS, selectedTreatment }
    }
}

function showTherapists(selectedTreatment) {
    return dispatch => {
        dispatch(success(selectedTreatment));
        history.push('/treatmentTherapists');
    };
    function success(selectedTreatment) {
      return { type: treatmentGroupConstants.SELECTEDTREATMENT_SUCCESS, selectedTreatment }
    }
}

function getGroupsForPlaceAndDate(placeId, date) {
    return dispatch => {
        dispatch(request());

        treatmentGroupService.getGroupsForPlaceAndDate(placeId, date)
            .then(
                treatmentGroups => dispatch(success(treatmentGroups)),
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request() { return { type: treatmentGroupConstants.GETGROUPSFORPLACEANDDATE_REQUEST } }
    function success(treatmentGroups) { return { type: treatmentGroupConstants.GETGROUPSFORPLACEANDDATE_SUCCESS, treatmentGroups } }
    function failure(message) { return { type: treatmentGroupConstants.GETGROUPSFORPLACEANDDATE_FAILURE, message } }
}

function getTherapists(treatmentId) {
    return dispatch => {
        dispatch(request());

        treatmentGroupService.getTherapists(treatmentId)
            .then(
                therapists => {
                    dispatch(success(therapists));
                    if (therapists.therapistViews.length === 1) {
                        dispatch(selectTherapist(therapists.therapistViews[0]));
                    }
                },
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request() { return { type: treatmentGroupConstants.GETTHERAPISTS_REQUEST } }
    function success(therapists) { return { type: treatmentGroupConstants.GETTHERAPISTS_SUCCESS, therapists } }
    function selectTherapist(selectedTherapist) { return { type: reservationConstants.SELECTEDTHERAPIST_SUCCESS, selectedTherapist } }
    function failure(message) { return { type: treatmentGroupConstants.GETTHERAPISTS_FAILURE, message } }
}

function resetTherapists() {
    return dispatch => {
        dispatch(successTherapist());
    };
    function successTherapist() {
      return { type: treatmentGroupConstants.RESETTHERAPISTS_SUCCESS }
    }
}

function getPrices(treatmentId) {
    return dispatch => {
        dispatch(request());

        treatmentGroupService.getPrices(treatmentId)
            .then(
                prices => {
                    dispatch(success(prices));
                    if (prices.priceViews.length === 1) {
                        dispatch(selectPrice(prices.priceViews[0]));
                    }
                },
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request() { return { type: treatmentGroupConstants.GETPRICES_REQUEST } }
    function success(prices) { return { type: treatmentGroupConstants.GETPRICES_SUCCESS, prices } }
    function selectPrice(selectedPrice) { return { type: reservationConstants.SELECTEDPRICE_SUCCESS, selectedPrice } }
    function failure(message) { return { type: treatmentGroupConstants.GETPRICES_FAILURE, message } }
}

function resetPrices() {
    return dispatch => {
        dispatch(successPrice());
    };
    function successPrice() {
      return { type: treatmentGroupConstants.RESETPRICES_SUCCESS }
    }
}

function increasePrices(byHowMuch, fromDate, oldTreatmentSuffix) {
    return dispatch => {
        dispatch(request());
        treatmentGroupService.increasePrices(byHowMuch, fromDate, oldTreatmentSuffix)
            .then(
                price => {
                    dispatch(success());
                    history.push('/treatmentGroups');
                    dispatch(alertActions.success('The prices have been updated.'));
                },
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request() { return { type: treatmentGroupConstants.UPDATEPRICES_REQUEST } }
    function success() { return { type: treatmentGroupConstants.UPDATEPRICES_SUCCESS} }
    function failure(message) { return { type: treatmentGroupConstants.UPDATEPRICES_FAILURE, message } }
}

function increasePricesForTreatment(treatmentId, byHowMuch, fromDate, oldTreatmentSuffix) {
    return dispatch => {
        dispatch(request());
        treatmentGroupService.increasePricesForTreatment(treatmentId, byHowMuch, fromDate, oldTreatmentSuffix)
            .then(
                price => {
                    dispatch(success());
                    dispatch(this.getGroups())
                    dispatch(alertActions.success('The prices have been updated and fetching all the groups again ...'));
                    history.push('/treatmentGroups');
                },
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request() { return { type: treatmentGroupConstants.UPDATEPRICES_REQUEST } }
    function success() { return { type: treatmentGroupConstants.UPDATEPRICES_SUCCESS} }
    function failure(message) { return { type: treatmentGroupConstants.UPDATEPRICES_FAILURE, message } }
}
