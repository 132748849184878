import axios from 'axios';

import { http } from '../_helpers';

const URL = http.BASE_URL + '/api/financialtransaction';

export const financeService = {
    getFinancialTransactions,
    getFinancialTransactionCategories,
    getFinancialTransactionTypes,
    createFinancialTransaction,
    updateFinancialTransaction,
    deleteFinancialTransaction
};

function getFinancialTransactions(startFormatted, endFormatted) {
    const url = `${URL}?start=` + startFormatted + `&end=` + endFormatted;
    return axios.get(url, {
        headers: http.HEADERS,
        withCredentials: true
    })
    .then(response => {
        if (response && response.data) {
            return response.data;
        }
    })
    .catch(result=> {
        return http.handleHttpError(result);
    });
}

function getFinancialTransactionCategories() {
    const url = `${URL}/category`;
    return axios.get(url, {
        headers: http.HEADERS,
        withCredentials: true
    })
    .then(response => {
        if (response && response.data) {
            return response.data;
        }
    })
    .catch(result=> {
        return http.handleHttpError(result);
    });
}

function getFinancialTransactionTypes() {
    const url = `${URL}/type`;
    return axios.get(url, {
        headers: http.HEADERS,
        withCredentials: true
    })
    .then(response => {
        if (response && response.data) {
            return response.data;
        }
    })
    .catch(result=> {
        return http.handleHttpError(result);
    });
}

function createFinancialTransaction(financialTransaction) {
    const url = `${URL}`
    return axios.post(url,
        financialTransaction,
        {
            headers: http.HEADERS,
            withCredentials: true
        })
        .then(response => {
            if (response && response.data) {
                return response.data;
            }
        })
        .catch(result=> {
            return http.handleHttpError(result);
        }
    );
}

function updateFinancialTransaction(financialTransaction) {
    const url = `${URL}`
    return axios.put(url,
        financialTransaction,
        {
            headers: http.HEADERS,
            withCredentials: true
        })
        .then(response => {
            if (response && response.data) {
                return response.data;
            }
        })
        .catch(result=> {
            return http.handleHttpError(result);
        }
    );
}

function deleteFinancialTransaction(financialTransaction) {
    const url = `${URL}/` + financialTransaction.id;
    return axios.delete(url,
        {
            headers: http.HEADERS,
            withCredentials: true
        })
        .then(response => {
            if (response && response.data) {
                return response.data;
            } else {
                return;
            }
        })
        .catch(result=> {
            return http.handleHttpError(result);
        }
    );
}
