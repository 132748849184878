import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { userActions } from '../_actions';

import { Customers } from '.';

class CustomersPage extends React.Component {

    constructor(props) {
        super(props);

        this.onSearchCustomers = this.onSearchCustomers.bind(this);
        this.handlePreviousClick = this.handlePreviousClick.bind(this);
        this.handleNextClick = this.handleNextClick.bind(this);

        this.props.dispatch(userActions.setCustomerSearchString(""));
        this.props.dispatch(userActions.setCustomerSearchPage(1));
        this.props.dispatch(userActions.resetCustomers());
        this.props.dispatch(userActions.getEnhancedCustomers("")); // initial search for first 50
    }

    componentDidMount() {
        if (!this.props.loggedAs) {
            this.props.history.push("/login");
        }
        if (this.props.user.searchString) {
            this.props.dispatch(userActions.getEnhancedCustomers(this.props.user.searchString));
        }
    }

    componentDidUpdate() {
        if (!this.props.loggedAs) {
            this.props.history.push("/login");
        }
        // Hmmm let's see if we need to reload data after customer list reset
        if (!this.props.user.requestingCustomers && this.props.user.searchPage > 0 && !this.props.user.customers) {
            this.props.dispatch(userActions.getEnhancedCustomers(this.props.user.searchString, this.props.user.searchPage))
        }
    }

    onSearchCustomers(event) {
        var { value } = event.target;
        this.props.dispatch(userActions.setCustomerSearchString(value));
        this.props.dispatch(userActions.setCustomerSearchPage(1));
        this.props.dispatch(userActions.resetCustomers());
        if (value.length > 0 && value.length < 3) {
            return;
        }
		this.props.dispatch(userActions.getEnhancedCustomers(value));
	}

	handlePreviousClick() {
	    var page = this.props.user.searchPage;
        if (page === 1) {
            return;
        }
        page--;
        this.props.dispatch(userActions.setCustomerSearchPage(page));
        this.props.dispatch(userActions.getEnhancedCustomers(this.props.user.searchString, page));
    }

    handleNextClick() {
        var page = this.props.user.searchPage;
        var totalNumberOfFilteredCustomers = this.props.user.totalNumberOfFilteredCustomers
        var numberOfPages = Math.ceil(totalNumberOfFilteredCustomers / 30);
        if (page === numberOfPages) {
            return;
        }
        page++;
        this.props.dispatch(userActions.setCustomerSearchPage(page));
        this.props.dispatch(userActions.getEnhancedCustomers(this.props.user.searchString, page));
    }

    render() {
        const { user } = this.props;
        var numberOfPages = Math.ceil(user.totalNumberOfFilteredCustomers / 30);
        return (
            <div className="col-12 col-lg-10">
                <h2 className="text-center">Customers</h2>
                <div className="card card-primary card-transparent">
                    <div className="card-body">
                        <form id="customersForm" noValidate className="needs-validation my-4" onSubmit={this.handleSubmit}>
                            <div className="form-group row">
                                <label className="col-md-2 col-xl-1 col-form-label mt-1 mb-2" htmlFor="search">Search</label>
                                <div className="col-md-10 col-xl-6 mt-1 mb-2">
                                    <div className="row mx-1">
                                        <input type="text" className="form-control col-10" placeholder="Start typing at least 3 letters ..." name="search" value={user.searchString} required onChange={this.onSearchCustomers} />
                                        { user.requestingCustomers &&
                                            <span className="col-1 pt-1 mt-1">
                                                <i className="fa fa-circle-o-notch fa-spin"></i>
                                            </span>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row align-items-center ml-1">
                                <button type="button" className="btn btn-info col-md-2 col-xl-1 mr-2 mb-2" onClick={this.handlePreviousClick}>Previous</button>
                                <button type="button" className="btn btn-info col-md-2 col-xl-1 mr-2 mb-2" onClick={this.handleNextClick}>Next</button>
                                {user.totalNumberOfCustomers &&<div className="col-md-6 col-xl-6 mr-2 mb-2 text-center">
                                    Page {user.searchPage} of {numberOfPages} for {user.totalNumberOfFilteredCustomers} filtered out of total {user.totalNumberOfCustomers}
                                </div>}
                                <Link to="/customerCreate" className="btn btn-primary col-md-4 col-xl-3 mr-2 mb-2">Create new customer</Link>
                            </div>
                            <Customers customers={user.customers} searchString={user.searchString} searching={user.requestingCustomers}/>
                            {user.customers && <div className="form-group row align-items-center ml-1">
                                <button type="button" className="btn btn-info col-md-2 col-xl-1 mr-2 mb-2" onClick={this.handlePreviousClick}>Previous</button>
                                <button type="button" className="btn btn-info col-md-2 col-xl-1 mr-2 mb-2" onClick={this.handleNextClick}>Next</button>
                            </div>}
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { loggedAs } = state.user;
    const { user } = state;

    return {
        loggedAs,
        user
    };
}

const connectedCustomersPage = connect(mapStateToProps)(CustomersPage);
export { connectedCustomersPage as CustomersPage };
