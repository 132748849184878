import React from 'react';

import { Treatment } from '.';

export class Treatments extends React.Component {

    render() {
        const { treatments } = this.props;

        if (treatments && treatments.length > 0) {
            return (
                <div className="card-columns">
                    {
                        treatments.map((treatment, index) =>
                            <Treatment key={"Treatment_" + treatment.id} treatment={treatment}/>
                        )
                    }
                </div>
            );
        } else {
            return (
                <div className="form-group row">
                    <footer className="blockquote-footer col-sm-12">No treatments found</footer>
                </div>
            );
        }
    }
}
