import { voucherConstants } from '../_constants';
import { voucherService } from '../_services';
import { alertActions } from './';
import { history, http } from '../_helpers';

export const voucherActions = {
    getVouchers,
    createVoucher,
    deleteVoucher,
    resetVouchers,
    selectVoucher,
    resetVoucher
};

function getVouchers(filter) {
    return dispatch => {
        dispatch(resetVouchers());
        dispatch(request());
        voucherService.getVouchers(filter)
            .then(
                vouchers => {
                    if (!!vouchers) {
                        dispatch(success(vouchers));
                    }
                },
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request() { return { type: voucherConstants.GETVOUCHERS_REQUEST } }
    function resetVouchers() { return { type: voucherConstants.RESETVOUCHERS_SUCCESS } }
    function success(vouchers) { return { type: voucherConstants.GETVOUCHERS_SUCCESS, vouchers } }
    function failure(message) { return { type: voucherConstants.GETVOUCHERS_FAILURE, message } }
}

function createVoucher(voucher) {
    return dispatch => {
        dispatch(request());
        voucherService.createVoucher(voucher)
            .then(
                event => {
                    dispatch(success());
                    dispatch(resetVouchers());
                    history.push('/vouchers');
                    dispatch(alertActions.success('The voucher has been created.'));
                },
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request() { return { type: voucherConstants.CREATE_REQUEST } }
    function resetVouchers() { return { type: voucherConstants.RESETVOUCHERS_SUCCESS } }
    function success(vouchers) { return { type: voucherConstants.CREATE_SUCCESS, vouchers } }
    function failure(message) { return { type: voucherConstants.CREATE_FAILURE, message } }
}

function deleteVoucher(voucher) {
    return dispatch => {
        dispatch(request());
        voucherService.deleteVoucher(voucher)
            .then(
                event => {
                    dispatch(success());
                    dispatch(resetVouchers());
                    dispatch(alertActions.success('The voucher has been deleted.'));
                },
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request() { return { type: voucherConstants.DELETE_REQUEST } }
    function resetVouchers() { return { type: voucherConstants.RESETVOUCHERS_SUCCESS } }
    function success(vouchers) { return { type: voucherConstants.DELETE_SUCCESS, vouchers } }
    function failure(message) { return { type: voucherConstants.DELETE_FAILURE, message } }
}

function resetVouchers() {
    return dispatch => {
        dispatch(success());
    };
    function success() {
      return { type: voucherConstants.RESETVOUCHERS_SUCCESS }
    }
}

function selectVoucher(selectedVoucher) {
    return dispatch => {
        dispatch(success(selectedVoucher));
        history.push('/voucherDetail');
    };
    function success(selectedVoucher) {
      return { type: voucherConstants.SELECTEDVOUCHER_SUCCESS, selectedVoucher }
    }
}

function resetVoucher() {
    return dispatch => {
        dispatch(success());
        history.push('/vouchers');
    };
    function success() {
      return { type: voucherConstants.RESETVOUCHER_SUCCESS }
    }
}
