import { placeConstants } from '../_constants';

const initialState = {};

export function place(state = initialState, action) {
    switch (action.type) {
        case placeConstants.PLACES_REQUEST:
            return Object.assign({}, state, {
                requestingPlaces: true,
                places: null
            });
        case placeConstants.PLACES_SUCCESS:
            return Object.assign({}, state, {
                requestingPlaces: false,
                places: action.places
            });
        case placeConstants.PLACES_FAILURE:
            return Object.assign({}, state, {
                requestingPlaces: false,
                error: action.error
            });
        case placeConstants.SELECTPLACE_SUCCESS:
            return Object.assign({}, state, {
                selectedPlace: action.selectedPlace,
                selectedRoom: null
            });
        case placeConstants.RESETPLACE_SUCCESS:
            return Object.assign({}, state, {
                selectedPlace: null,
                selectedRoom: null
            });
        case placeConstants.ROOMS_REQUEST:
            return Object.assign({}, state, {
                requestingRooms: true,
                rooms: null
            });
        case placeConstants.ROOMS_SUCCESS:
            return Object.assign({}, state, {
                requestingRooms: false,
                rooms: action.rooms
            });
        case placeConstants.ROOMS_FAILURE:
            return Object.assign({}, state, {
                requestingRooms: false,
                rooms: action.error
            });
        case placeConstants.SELECTROOM_SUCCESS:
            return Object.assign({}, state, {
                selectedRoom: action.selectedRoom
            });
        case placeConstants.RESETROOM_SUCCESS:
            return Object.assign({}, state, {
                selectedRoom: null
            });
        default:
            return state;
    }
}
