import axios from 'axios';
import moment from 'moment';

import { http, formatting } from '../_helpers';

const URL = http.BASE_URL + '/api/slotgroup';

Date.prototype.toJSON = function(){
    return moment(this).format(formatting.DATE_TIME_FORMATTING);
};

export const slotGroupService = {
    getSlotGroup,
    getAvailable,
    getBookings,
    create,
    update,
    updateSession,
    remove,
    getLast,
    getUpcoming
};

function getSlotGroup(slotGroupId) {
    const url = `${URL}/` + slotGroupId;
    return axios.get(url, {
        headers: http.HEADERS,
        withCredentials: true
    })
    .then(response => {
        if (response && response.data) {
            return response.data;
        }
    })
    .catch(result=> {
        return http.handleHttpError(result);
    });
}

function getAvailable(therapistId, priceIds, start, end) {
    const url = `${URL}/available?therapistId=` + therapistId + `&priceIds=` + priceIds + `&start=` + start + `&end=` + end;
    return axios.get(url, {
        headers: http.HEADERS,
        withCredentials: true
    })
    .then(response => {
        if (response && response.data) {
            for (var i = 0, size = response.data.slotGroupViews.length; i < size ; i++) {
                var item = response.data.slotGroupViews[i];
                item.start = moment(item.start).toDate();
                item.end = moment(item.end).toDate();
            }
            return response.data;
        }
    })
    .catch(result=> {
        return http.handleHttpError(result);
    });
}

function getBookings(start, end, placeId) {
    const url = `${URL}/forplace?start=` + start + `&end=` + end + `&placeId=` + placeId;
    return axios.get(url, {
        headers: http.HEADERS,
        withCredentials: true
    })
    .then(response => {
        if (response && response.data) {
            for (var i = 0, size = response.data.slotGroupViews.length; i < size ; i++) {
                var item = response.data.slotGroupViews[i];
                item.start = moment(item.start).toDate();
                item.end = moment(item.end).toDate();
            }
            return response.data;
        }
    })
    .catch(result=> {
        return http.handleHttpError(result);
    });
}

function create(slotGroup) {
    Date.prototype.toJSON = function(){
        return moment(this).format(formatting.DATE_TIME_FORMATTING);
    };
    const url = `${URL}`
    return axios.post(url,
        slotGroup,
        {
            headers: http.HEADERS,
            withCredentials: true
        })
        .then(response => {
            if (response && response.data) {
                return response.data;
            }
        })
        .catch(result=> {
            return http.handleHttpError(result);
        }
    );
}

function update(slotGroup) {
    const url = `${URL}`;
    return axios.put(url,
        slotGroup,
        {
            headers: http.HEADERS,
            withCredentials: true
        })
        .then(response => {
            if (response && response.data) {
                return response.data;
            }
        })
        .catch(result=> {
            return http.handleHttpError(result);
        }
    );
}

function updateSession(session, slotGroup) {
    const url = `${URL}/${slotGroup.id}/session/${session.id}`;
    return axios.put(url,
        session,
        {
            headers: http.HEADERS,
            withCredentials: true
        })
        .then(response => {
            if (response && response.data) {
                return response.data;
            }
        })
        .catch(result=> {
            return http.handleHttpError(result);
        }
    );
}

function remove(slotGroup) {
    const url = `${URL}/${slotGroup.id}`;
    return axios.delete(url,
        {
            headers: http.HEADERS,
            withCredentials: true
        })
        .then(response => {
            if (response && response.data) {
                return response.data;
            }
        })
        .catch(result=> {
            return http.handleHttpError(result);
        }
    );
}

function getLast() {
    const url = `${URL}/last`;
    return axios.get(url, {
        headers: http.HEADERS,
        withCredentials: true
    })
    .then(response => {
        if (response && response.data) {
            return response.data;
        }
    })
    .catch(result=> {
        return http.handleHttpError(result);
    });
}

function getUpcoming() {
    const url = `${URL}/upcoming`;
    return axios.get(url, {
        headers: http.HEADERS,
        withCredentials: true
    })
    .then(response => {
        if (response && response.data) {
            return response.data;
        }
    })
    .catch(result=> {
        return http.handleHttpError(result);
    });
}
