import { reservationTherapistConstants } from '../_constants';

const initialState = {
    sendEmail: true
};

export function reservationTherapist(state = initialState, action) {
    switch (action.type) {
        case reservationTherapistConstants.SELECTEDGROUP_SUCCESS:
            return Object.assign({}, state, {
                selectedGroup: action.selectedGroup
            });
        case reservationTherapistConstants.RESETGROUP_SUCCESS:
            return Object.assign({}, state, {
                selectedGroup: null,
                selectedTreatment: null,
                selectedTherapist: null,
                selectedPrice: null
            });
        case reservationTherapistConstants.SELECTEDTREATMENT_SUCCESS:
            return Object.assign({}, state, {
                selectedTreatment: action.selectedTreatment
            });
        case reservationTherapistConstants.RESETTREATMENT_SUCCESS:
            return Object.assign({}, state, {
                selectedTreatment: null,
                selectedTherapist: null,
                selectedPrice: null
            });
        case reservationTherapistConstants.SELECTEDTHERAPIST_SUCCESS:
            return Object.assign({}, state, {
                selectedTherapist: action.selectedTherapist
            });
        case reservationTherapistConstants.RESETTHERAPIST_SUCCESS:
            return Object.assign({}, state, {
                selectedTherapist: null
            });
        case reservationTherapistConstants.SELECTEDPRICE_SUCCESS:
            return Object.assign({}, state, {
                selectedPrice: action.selectedPrice
            });
        case reservationTherapistConstants.RESETPRICE_SUCCESS:
            return Object.assign({}, state, {
                selectedPrice: null
            });
        case reservationTherapistConstants.MODIFYBASKET_SUCCESS:
            return Object.assign({}, state, {
                selectedPrices: action.selectedPrices
            });
        case reservationTherapistConstants.RESETBASKET_SUCCESS:
            return Object.assign({}, state, {
                selectedPrices: null
            });
        case reservationTherapistConstants.SELECTEDCUSTOMER_SUCCESS:
            return Object.assign({}, state, {
                selectedCustomer: action.selectedCustomer
            });
        case reservationTherapistConstants.CUSTOMERNAME_SUCCESS:
            return Object.assign({}, state, {
                customerName: action.customerName
            });
        case reservationTherapistConstants.CUSTOMERCONTACT_SUCCESS:
            return Object.assign({}, state, {
                customerContact: action.customerContact
            });
        case reservationTherapistConstants.RESETCUSTOMER_SUCCESS:
            return Object.assign({}, state, {
                selectedCustomer: null,
                customerName: null,
                customerContact: null
            });
        case reservationTherapistConstants.SELECTEDEVENT_SUCCESS:
            return Object.assign({}, state, {
                selectedEvent: action.selectedEvent
            });
        case reservationTherapistConstants.RESETEVENT_SUCCESS:
            return Object.assign({}, state, {
                selectedEvent: null
            });
        case reservationTherapistConstants.SENDEMAIL_SUCCESS:
            return Object.assign({}, state, {
                sendEmail: action.sendEmail
            });
        default:
            return state;
    }
}
