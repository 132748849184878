import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { alertActions, userActions } from '../_actions';
import { SubmitButton } from '../_components';

class UpdateSecurityQuestionPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            securtiyQuestion: {
                password: '',
                new: '',
                answer: ''
            }
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const { name, value } = event.target;
        const { securtiyQuestion } = this.state;
        this.setState({
            securtiyQuestion: {
                ...securtiyQuestion,
                [name]: value
            }
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        var form = document.getElementById('updateSecurityQuestionForm');
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        form.classList.add('was-validated');

        this.setState({ submitted: true });
        const { securtiyQuestion } = this.state;
        const { dispatch } = this.props;
        dispatch(alertActions.clear());
        if (securtiyQuestion.password && securtiyQuestion.new && securtiyQuestion.answer) {
            dispatch(userActions.updateSecurityQuestion(securtiyQuestion));
        }
    }

    render() {
        const { updating } = this.props;
        const { securtiyQuestion } = this.state;
        return (
            <div className="col-lg-8">
                <h2 className="text-center">Update your security question</h2>
                <div className="card card-primary card-transparent">
                    <div className="card-body">
                        <form id="updateSecurityQuestionForm" noValidate className="needs-validation my-4" onSubmit={this.handleSubmit}>
                            <div className="form-group row">
                                <label className="col-xl-5 col-form-label" htmlFor="password">Please enter your password</label>
                                <div className="col-xl-7">
                                    <input type="password" className="form-control" name="password" value={securtiyQuestion.password} onChange={this.handleChange} />
                                    <div className="invalid-feedback">
                                        Please enter your password
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-xl-5 col-form-label" htmlFor="new">Enter your new security question</label>
                                <div className="col-xl-7">
                                    <input type="text" className="form-control" name="new" value={securtiyQuestion.new} onChange={this.handleChange} />
                                    <div className="invalid-feedback">
                                        Please enter your new security question
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-xl-5 col-form-label" htmlFor="answer">Enter the answer to your security question</label>
                                <div className="col-xl-7">
                                    <input type="password" className="form-control" name="answer" value={securtiyQuestion.answer} onChange={this.handleChange} />
                                    <div className="invalid-feedback">
                                        Please enter your answer to your security question
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row align-items-center ml-1">
                                <Link to="/profile" className="col-md-3 col-xl-2 m-0 pl-0 pr-2 nav-link">
                                    <button type="button" className="btn btn-secondary btn-block mb-2">Cancel</button>
                                </Link>
                                <SubmitButton updating={updating} label="Update" labelLoading="Updating ..." className="col-md-3 col-xl-2 mr-2 mb-2"/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { updating } = state.user;
    return {
        updating
    };
}

const connectedUpdateSecurityQuestionPage = connect(mapStateToProps)(UpdateSecurityQuestionPage);
export { connectedUpdateSecurityQuestionPage as UpdateSecurityQuestionPage };
