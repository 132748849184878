export const treatmentGroupConstants = {
  GETGROUPS_REQUEST: 'TREATMENT_GROUPS_GETGROUPS_REQUEST',
  GETGROUPS_SUCCESS: 'TREATMENT_GROUPS_GETGROUPS_SUCCESS',
  GETGROUPS_FAILURE: 'TREATMENT_GROUPS_GETGROUPS_FAILURE',

  CREATEGROUP_REQUEST: 'TREATMENT_GROUPS_CREATEGROUP_REQUEST',
  CREATEGROUP_SUCCESS: 'TREATMENT_GROUPS_CREATEGROUP_SUCCESS',
  CREATEGROUP_FAILURE: 'TREATMENT_GROUPS_CREATEGROUP_FAILURE',

  DELETEGROUP_REQUEST: 'TREATMENT_GROUPS_DELETEGROUP_REQUEST',
  DELETEGROUP_SUCCESS: 'TREATMENT_GROUPS_DELETEGROUP_SUCCESS',
  DELETEGROUP_FAILURE: 'TREATMENT_GROUPS_DELETEGROUP_FAILURE',

  SELECTEDGROUP_SUCCESS: 'TREATMENT_GROUPS_SELECTEDGROUP_SUCCESS',
  RESETGROUP_SUCCESS: 'TREATMENT_GROUPS_RESETGROUP_SUCCESS',

  CREATETREATMENT_REQUEST: 'TREATMENT_GROUPS_CREATETREATMENT_REQUEST',
  CREATETREATMENT_SUCCESS: 'TREATMENT_GROUPS_CREATETREATMENT_SUCCESS',
  CREATETREATMENT_FAILURE: 'TREATMENT_GROUPS_CREATETREATMENT_FAILURE',

  UPDATETREATMENT_REQUEST: 'TREATMENT_GROUPS_UPDATETREATMENT_REQUEST',
  UPDATETREATMENT_SUCCESS: 'TREATMENT_GROUPS_UPDATETREATMENT_SUCCESS',
  UPDATETREATMENT_FAILURE: 'TREATMENT_GROUPS_UPDATETREATMENT_FAILURE',

  DELETETREATMENT_REQUEST: 'TREATMENT_GROUPS_DELETETREATMENT_REQUEST',
  DELETETREATMENT_SUCCESS: 'TREATMENT_GROUPS_DELETETREATMENT_SUCCESS',
  DELETETREATMENT_FAILURE: 'TREATMENT_GROUPS_DELETETREATMENT_FAILURE',

  SELECTEDTREATMENT_SUCCESS: 'TREATMENT_GROUPS_SELECTEDTREATMENT_SUCCESS',
  RESETTREATMENT_SUCCESS: 'TREATMENT_GROUPS_RESETTREATMENT_SUCCESS',

  CREATEPRICE_REQUEST: 'TREATMENT_GROUPS_CREATEPRICE_REQUEST',
  CREATEPRICE_SUCCESS: 'TREATMENT_GROUPS_CREATEPRICE_SUCCESS',
  CREATEPRICE_FAILURE: 'TREATMENT_GROUPS_CREATEPRICE_FAILURE',

  UPDATEPRICE_REQUEST: 'TREATMENT_GROUPS_UPDATEPRICE_REQUEST',
  UPDATEPRICE_SUCCESS: 'TREATMENT_GROUPS_UPDATEPRICE_SUCCESS',
  UPDATEPRICE_FAILURE: 'TREATMENT_GROUPS_UPDATEPRICE_FAILURE',

  DELETEPRICE_REQUEST: 'TREATMENT_GROUPS_DELETEPRICE_REQUEST',
  DELETEPRICE_SUCCESS: 'TREATMENT_GROUPS_DELETEPRICE_SUCCESS',
  DELETEPRICE_FAILURE: 'TREATMENT_GROUPS_DELETEPRICE_FAILURE',

  SELECTEDPRICE_SUCCESS: 'TREATMENT_GROUPS_SELECTEDPRICE_SUCCESS',
  RESETPRICE_SUCCESS: 'TREATMENT_GROUPS_RESETPRICE_SUCCESS',

  RESETGROUPS_SUCCESS: 'TREATMENT_GROUPS_RESETGROUPS_SUCCESS',

  GETGROUPSFORPLACEANDDATE_REQUEST: 'TREATMENT_GROUPS_GETGROUPSFORPLACEANDDATE_REQUEST',
  GETGROUPSFORPLACEANDDATE_SUCCESS: 'TREATMENT_GROUPS_GETGROUPSFORPLACEANDDATE_SUCCESS',
  GETGROUPSFORPLACEANDDATE_FAILURE: 'TREATMENT_GROUPS_GETGROUPSFORPLACEANDDATE_FAILURE',

  GETTHERAPISTS_REQUEST: 'TREATMENT_GROUPS_GETTHERAPISTS_REQUEST',
  GETTHERAPISTS_SUCCESS: 'TREATMENT_GROUPS_GETTHERAPISTS_SUCCESS',
  GETTHERAPISTS_FAILURE: 'TREATMENT_GROUPS_GETTHERAPISTS_FAILURE',

  RESETTHERAPISTS_SUCCESS: 'TREATMENT_GROUPS_RESETTHERAPISTS_SUCCESS',

  GETPRICES_REQUEST: 'TREATMENT_GROUPS_GETPRICES_REQUEST',
  GETPRICES_SUCCESS: 'TREATMENT_GROUPS_GETPRICES_SUCCESS',
  GETPRICES_FAILURE: 'TREATMENT_GROUPS_GETPRICES_FAILURE',

  RESETPRICES_SUCCESS: 'TREATMENT_GROUPS_RESETPRICES_SUCCESS',

  UPDATEPRICES_REQUEST: 'TREATMENT_GROUPS_UPDATEPRICES_REQUEST',
  UPDATEPRICES_SUCCESS: 'TREATMENT_GROUPS_UPDATEPRICES_SUCCESS',
  UPDATEPRICES_FAILURE: 'TREATMENT_GROUPS_UPDATEPRICES_FAILURE',
}
