import React from 'react';
import { Link } from 'react-router-dom';

import { SlotGroupCard } from '.';

export class SlotGroupCards extends React.Component {

    render() {
        const { slotGroups, loading, canceling } = this.props;
        if (loading) {
            return (
                <div className="col-sm-12 col-md-6 my-1">
                    <div className="card card-shade">
                        <div className="card-body">
                            <p className="card-text">
                                Loading ...
                            </p>
                        </div>
                    </div>
                </div>
            );
        } else if (slotGroups && slotGroups.slotGroupViews && slotGroups.slotGroupViews.length > 0) {
            return(slotGroups.slotGroupViews.map(function(slotGroup, index){
                return <SlotGroupCard slotGroup = {slotGroup} loading={false} showCancel={true} canceling={canceling}/>
            }));
        } else if (!slotGroups || !slotGroups.slotGroupViews || slotGroups.slotGroupViews.length === 0) {
            return (
                <div className="col-sm-12 col-md-6 my-1">
                    <div className="card card-shade border-success ">
                        <div className="card-header">No treatment yet</div>
                        <div className="card-body">
                            <p className="card-text">
                                It looks like you don't have any upcoming treatments. Why not book one now?
                            </p>
                            <Link to="/booking" className="btn btn-primary">Book now</Link>
                        </div>
                    </div>
                </div>
            );
        }
    }
}
