export const voucherConstants = {
    GETVOUCHERS_REQUEST: 'VOUCHERS_GETVOUCHERS_REQUEST',
    GETVOUCHERS_SUCCESS: 'VOUCHERS_GETVOUCHERS_SUCCESS',
    GETVOUCHERS_FAILURE: 'VOUCHERS_GETVOUCHERS_FAILURE',

    CREATE_REQUEST: 'VOUCHERS_CREATE_REQUEST',
    CREATE_SUCCESS: 'VOUCHERS_CREATE_SUCCESS',
    CREATE_FAILURE: 'VOUCHERS_CREATE_FAILURE',

    DELETE_REQUEST: 'VOUCHERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'VOUCHERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'VOUCHERS_DELETE_FAILURE',

    RESETVOUCHERS_SUCCESS: 'VOUCHERS_RESETVOUCHERS_SUCCESS',

    SELECTEDVOUCHER_SUCCESS: 'VOUCHERS_SELECTEDVOUCHER_SUCCESS',
    RESETVOUCHER_SUCCESS: 'VOUCHERS_RESETVOUCHER_SUCCESS',

    PAYVOUCHER_REQUEST: 'VOUCHERS_PAYVOUCHER_REQUEST',
    PAYVOUCHER_SUCCESS: 'VOUCHERS_PAYVOUCHER_SUCCESS',
    PAYVOUCHER_FAILURE: 'VOUCHERS_PAYVOUCHER_FAILURE'
}
