import { reservationConstants, slotGroupConstants } from '../_constants';
import { history } from '../_helpers';
import moment from 'moment';
import 'moment/locale/en-nz';

import { slotGroupActions } from '../_actions';

export const reservationActions = {
    selectGroup,
    resetGroup,
    selectTreatment,
    resetTreatment,
    selectTherapist,
    resetTherapist,
    selectPrice,
    resetPrice,
    addToBasket,
    removeFromBasket,
    resetBasket,
    pickDateTime
};

function selectGroup(selectedGroup) {
    return dispatch => {
        dispatch(success(selectedGroup));
    };
    function success(selectedGroup) {
      return { type: reservationConstants.SELECTEDGROUP_SUCCESS, selectedGroup }
    }
}

function resetGroup() {
    return dispatch => {
        dispatch(successGroup());
    };
    function successGroup() {
      return { type: reservationConstants.RESETGROUP_SUCCESS }
    }
}

function selectTreatment(selectedTreatment) {
    return dispatch => {
        dispatch(success(selectedTreatment));
    };

    function success(selectedTreatment) {
      return { type: reservationConstants.SELECTEDTREATMENT_SUCCESS, selectedTreatment }
    }
}

function resetTreatment() {
    return dispatch => {
        dispatch(successTreatment());
    };
    function successTreatment() {
      return { type: reservationConstants.RESETTREATMENT_SUCCESS }
    }
}

function selectTherapist(selectedTherapist) {
    return dispatch => {
        dispatch(success(selectedTherapist));
    };
    function success(selectedTherapist) {
      return { type: reservationConstants.SELECTEDTHERAPIST_SUCCESS, selectedTherapist }
    }
}

function resetTherapist() {
    return dispatch => {
        dispatch(successTherapist());
    };
    function successTherapist() {
      return { type: reservationConstants.RESETTHERAPIST_SUCCESS }
    }
}

function selectPrice(selectedPrice) {
    return dispatch => {
        dispatch(success(selectedPrice));
    };
    function success(selectedPrice) {
      return { type: reservationConstants.SELECTEDPRICE_SUCCESS, selectedPrice }
    }
}

function resetPrice() {
    return dispatch => {
        dispatch(successPrice());
    };
    function successPrice() {
      return { type: reservationConstants.RESETPRICE_SUCCESS }
    }
}

function addToBasket(selectedPrice, selectedPrices, selectedBasketTherapist) {
    return dispatch => {
        if (!selectedPrices) {
            selectedPrices = new Set();
        } else {
            selectedPrices = new Set(selectedPrices);
        }
        selectedPrices.add(selectedPrice)
        dispatch(success(Array.from(selectedPrices), selectedBasketTherapist));
        dispatch(resetEvent());
        // Wrap the selectedPrices set in square brackets (spread operator) to convert it to array to use map() function
        var selectedBasketPriceIds = selectedPrices && [...selectedPrices].map(price => price.id);
        const start = moment().startOf('isoweek').format('YYYY-MM-DD');
        const end = moment().endOf('isoweek').format('YYYY-MM-DD');
        dispatch(slotGroupActions.getAvailable(selectedBasketTherapist.id, selectedBasketPriceIds, start, end));
    };
    function resetEvent() {
        return { type: reservationConstants.RESETEVENT_SUCCESS }
    }
    function success(selectedPrices, selectedBasketTherapist) {
      return { type: reservationConstants.MODIFYBASKET_SUCCESS, selectedPrices, selectedBasketTherapist }
    }
}

function removeFromBasket(selectedPrice, selectedPrices, selectedBasketTherapist) {
    return dispatch => {
        if (!selectedPrices) {
            dispatch(reset());
        } else {
            selectedPrices = new Set(selectedPrices);
        }
        selectedPrices.forEach(function(price){
            if (price.id === selectedPrice.id) {
                selectedPrices.delete(price);
            }
        });
        dispatch(success(Array.from(selectedPrices)));

        var selectedBasketPriceIds = selectedPrices && [...selectedPrices].map(price => price.id);
        if (selectedBasketPriceIds && selectedBasketPriceIds.length > 0) {
            dispatch(resetEvent());
            // Only fetch the available times if there are selected prices
            const start = moment().startOf('isoweek').format('YYYY-MM-DD');
            const end = moment().endOf('isoweek').format('YYYY-MM-DD');
            dispatch(slotGroupActions.getAvailable(selectedBasketTherapist.id, selectedBasketPriceIds, start, end));
        } else {
            dispatch(resetSlotGroups());
        }
    };
    function success(selectedPrices) {
      return { type: reservationConstants.MODIFYBASKET_SUCCESS, selectedPrices }
    }
    function reset() {
      return { type: reservationConstants.RESETBASKET_SUCCESS }
    }
    function resetEvent() {
        return { type: reservationConstants.RESETEVENT_SUCCESS }
    }
    function resetSlotGroups() { return { type: slotGroupConstants.RESETGROUPS_SUCCESS } }
}

function resetBasket() {
    return dispatch => {
        dispatch(successResetBasket());
    };
    function successResetBasket() {
      return { type: reservationConstants.RESETBASKET_SUCCESS }
    }
}

function pickDateTime(selectedCustomer) {
    return dispatch => {
        history.push('/bookingDateTime');
    };
}
