import React from 'react';
import { connect } from 'react-redux';

import { treatmentGroupActions, alertActions } from '../_actions';
import { SubmitButton } from '../_components';

class TreatmentGroupCreatePage extends React.Component {

    constructor(props) {
        super(props);

        this.handleBackClick = this.handleBackClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.state = {
            newTreatmentGroup: {},
            submitted: false
        };
    }

    componentDidMount() {
        if (!this.props.loggedAs) {
            this.props.history.push("/login");
        }
    }

    componentDidUpdate() {
        if (!this.props.loggedAs) {
            this.props.history.push("/login");
        }
    }

    handleBackClick() {
        this.props.history.push("/treatmentGroups");
    }

    handleSubmit(e) {
        e.preventDefault();
        var form = document.getElementById('treatmentGroupForm');
        if (form.checkValidity() === false) {
            form.classList.add('was-validated');
            e.stopPropagation();
            return;
        }

        const { dispatch } = this.props;
        dispatch(alertActions.clear());
        dispatch(treatmentGroupActions.createTreatmentGroup(this.state.newTreatmentGroup));
    }

    handleChange(e) {
        const { name, value } = e.target;
        const { newTreatmentGroup } = this.state;
        this.setState({
            newTreatmentGroup: {
                ...newTreatmentGroup,
                [name]: value
            }
        });
    }

    render() {
        const { treatmentGroup } = this.props;
        const { newTreatmentGroup } = this.state;
            return (
                <div className="col-lg-8">
                    <h2 className="text-center">New treatment group</h2>
                    <div className="card card-primary card-transparent">
                        <div className="card-body">
                            <form id="treatmentGroupForm" noValidate className="needs-validation my-4" onSubmit={this.handleSubmit}>
                                <div className="form-group row">
                                    <label className="col-sm-4 col-md-3 col-xl-2 col-form-label" htmlFor="heading">Heading</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" name="heading" value={newTreatmentGroup.heading} required onChange={this.handleChange}/>
                                        <div className="invalid-feedback">
                                            Please enter heading
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-4 col-md-3 col-xl-2 col-form-label" htmlFor="label">Label</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" name="label" value={newTreatmentGroup.label} required onChange={this.handleChange}/>
                                        <div className="invalid-feedback">
                                            Please enter label
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-4 col-md-3 col-xl-2 col-form-label" htmlFor="keywords">Keywords</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" name="keywords" value={newTreatmentGroup.keywords} required onChange={this.handleChange}/>
                                        <div className="invalid-feedback">
                                            Please enter keywords
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-4 col-md-3 col-xl-2 col-form-label" htmlFor="description">Description</label>
                                    <div className="col-sm-8">
                                        <textarea className="form-control" name="description" rows="5" value={newTreatmentGroup.description} onChange={this.handleChange}/>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-4 col-md-3 col-xl-2 col-form-label" htmlFor="content">Content</label>
                                    <div className="col-sm-8">
                                        <textarea className="form-control" name="content" rows="5" value={newTreatmentGroup.content} onChange={this.handleChange}/>
                                    </div>
                                </div>

                                <div className="form-group row align-items-center ml-1">
                                    <button type="button" className="btn btn-secondary col-md-2 col-xl-1 mr-2 mb-2" onClick={this.handleBackClick}>Back</button>
                                    <SubmitButton updating={treatmentGroup.savingTreatmentGroup} label="Create treatment group" labelLoading="Saving ..." className="col-md-4 col-xl-3 mr-2 mb-2"/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            );
    }
}

function mapStateToProps(state) {
    const { loggedAs } = state.user;
    const { treatmentGroup } = state;

    return {
        loggedAs,
        treatmentGroup
    };
}

const connectedTreatmentGroupCreatePage = connect(mapStateToProps)(TreatmentGroupCreatePage);
export { connectedTreatmentGroupCreatePage as TreatmentGroupCreatePage };
