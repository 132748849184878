import axios from 'axios';

import { http } from '../_helpers';

const URL = http.BASE_URL + '/api/statistics';

export const statisticsService = {
    getStatistics
};

// start and end has to be in the YYYY-MM-DD format
// scale is the java Calendar.WEEK_OF_YEAR = 3 or Calendar.DAY_OF_YEAR = 6 or Calendar.MONTH = 2
function getStatistics(start, end, scale) {
    var isFirst = true;
    var hasParameter = false;
    var url = URL;
    if (start) {
        if (isFirst) {
            url += '?';
            isFirst = false;
        }
        url += 'start=' + start;
        hasParameter = true;
    }
    if (end) {
        if (isFirst) {
            url += '?';
            isFirst = false;
        } else if (hasParameter) {
            url += '&';
        }
        url += 'end=' + end;
        hasParameter = true;
    }
    if (scale) {
        if (isFirst) {
            url += '?';
            isFirst = false;
        } else if (hasParameter) {
            url += '&';
        }
        url += 'scale=' + scale;
        hasParameter = true;
    }
    return axios.get(url, {
        headers: http.HEADERS,
        withCredentials: true
    })
    .then(response => {
        if (response && response.data) {
            return response.data;
        }
    })
    .catch(result=> {
        return http.handleHttpError(result);
    });
}
