import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { pingActions } from '../_actions';

import { http } from '../_helpers';

const CONTENT_URL = http.CONTENT_URL;

class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.state = {
            collapsed: true
        };
        this.intervalId = -1
    }

    componentDidMount() {
        window.addEventListener("focus", this.onFocus); // In case you want to check on loosing focus use "blur" instead
    }

    componentWillUnmount() {
       window.removeEventListener("focus", this.onFocus)
    }

    onFocus() {
        var pathname = window.location.pathname
        console.log("URL:" + pathname );
        if (pathname != "/register" && pathname != "/login" && pathname != "/login/resetPassword") {
            console.log("Fire ping!! ");
            this.props.dispatch(pingActions.ping());
        }
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    render() {
        const { loggedAs, loggedIn } = this.props;

        var canReserveClient = loggedAs && loggedAs.permissions && loggedAs.permissions.includes('canReserveClient');
        var canReserveTherapist = loggedAs && loggedAs.permissions && loggedAs.permissions.includes('canReserveTherapist');
        var canModifyUser = loggedAs && loggedAs.permissions && loggedAs.permissions.includes('canModifyUser');
        var canModifyVoucher = loggedAs && loggedAs.permissions && loggedAs.permissions.includes('canModifyVoucher');
        var canModifyTreatment = loggedAs && loggedAs.permissions && loggedAs.permissions.includes('canModifyTreatment');
        var canModifyFinance = loggedAs && loggedAs.permissions && loggedAs.permissions.includes('canModifyFinance');


        const collapsed = this.state.collapsed;
        const classOne = collapsed ? 'collapse navbar-collapse justify-content-stretch' : 'collapse navbar-collapse justify-content-stretch text-center show';
        const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

        return (
                <nav className="navbar navbar-expand-xl navbar-dark border-bottom">
                    {loggedIn && <Link className="navbar-brand" to="/">
                        <img src="../favicon.png" width="30" height="30" className="d-inline-block align-top" alt=""/>
                        Euro massage
                    </Link>}
                    {!loggedIn && <a className="navbar-brand" href={CONTENT_URL}>
                        <img src="../favicon.png" width="30" height="30" className="d-inline-block align-top" alt=""/>
                        Euro massage
                    </a>}
                    <div className="flex-column tiny mr-auto">
                        <div className="align-items-start navbar-nav">
                           <a className="text-center" href="tel:+64211680430">021 168 0430</a>
                        </div>
                        <div className="flex-row align-items-start navbar-text py-0">
                            <a className="text-center" href="mailto:info@euromassage.co.nz">info@euromassage.co.nz</a>
                        </div>
                    </div>
                    <button onClick={this.toggleNavbar} className={`${classTwo}`} type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={`${classOne}`} id="navbar">
                        <ul className="navbar-nav ml-auto mt-2 mt-lg-0 small">
                            {loggedIn && <li className="nav-item">
                                <a className="nav-link" href={CONTENT_URL}>Home</a>
                            </li>}
                            {loggedIn && <li className="nav-item">
                                <Link onClick={this.toggleNavbar} className="nav-link" to="/">{canReserveClient ? 'Booked treatments' : 'Dashboard'}</Link>
                            </li>}
                            {loggedIn && canReserveClient && <li className="nav-item">
                                <Link onClick={this.toggleNavbar} className="nav-link" to="/booking">Make a booking</Link>
                            </li>}
                            {loggedIn && canReserveTherapist && <li className="nav-item">
                                <Link onClick={this.toggleNavbar} className="nav-link" to="/bookingCalendar">Calendar</Link>
                            </li>}
                            {loggedIn && canModifyUser && <li className="nav-item">
                                <Link onClick={this.toggleNavbar} className="nav-link" to="/customers">Customers</Link>
                            </li>}
                            {loggedIn && canModifyUser && <li className="nav-item">
                                <Link onClick={this.toggleNavbar} className="nav-link" to="/therapists">Therapists</Link>
                            </li>}
                            {loggedIn && canModifyVoucher && <li className="nav-item">
                                <Link onClick={this.toggleNavbar} className="nav-link" to="/vouchers">Vouchers</Link>
                            </li>}
                            {loggedIn && canModifyTreatment && <li className="nav-item">
                                <Link onClick={this.toggleNavbar} className="nav-link" to="/treatmentGroups">Treatment groups</Link>
                            </li>}
                            {loggedIn && canModifyFinance && <li className="nav-item">
                                <Link onClick={this.toggleNavbar} className="nav-link" to="/finances">Finances</Link>
                            </li>}
                            {loggedIn && <li className="nav-item">
                                <Link onClick={this.toggleNavbar} className="nav-link" to="/profile">Profile</Link>
                            </li>}
                        </ul>
                        <ul className="navbar-nav my-2 my-lg-0">
                            {loggedIn && <li className="nav-item">
                                <Link onClick={this.toggleNavbar} className="btn btn-outline-warning btn-sm pt-2" to="/login"><span className="fa fa-sign-out"></span> Logout</Link>
                            </li>}
                        </ul>
                    </div>
                </nav>

        );
    }
}

function mapStateToProps(state) {
    const { loggedAs } = state.user;
    const { loggedIn } = state.user;
    return {
        loggedAs,
        loggedIn
    };
}

const connectedMenu = connect(mapStateToProps)(Menu);
export { connectedMenu as Menu };
