import React from 'react';
import { connect } from 'react-redux';

import moment from 'moment';
import 'moment/locale/en-nz';

import { userActions } from '../_actions';
import { SubmitButton, DateField, Modal } from '../_components';

import { SlotGroups } from '.';

class CustomerEditPage extends React.Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleOnDateOfBirthChanged = this.handleOnDateOfBirthChanged.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleBackClick = this.handleBackClick.bind(this);
        this.handleResetPasswordClick = this.handleResetPasswordClick.bind(this);
        this.handleDeleteCustomer = this.handleDeleteCustomer.bind(this);
        this.handleDeleteCustomerConfirmation = this.handleDeleteCustomerConfirmation.bind(this);
        this.hideModal = this.hideModal.bind(this);

        if (!props.selectedCustomer) {
            this.state = {
                user: {},
                submitted: false
            };
            this.props.history.push("/customers");
        } else {
            this.state = {
                user: {
                    id: props.selectedCustomer.id,
                    firstName: props.selectedCustomer.firstName,
                    surname: props.selectedCustomer.surname,
                    email: props.selectedCustomer.email,
                    homePhone: props.selectedCustomer.homePhone,
                    cellPhone: props.selectedCustomer.cellPhone,
                    street: props.selectedCustomer.street,
                    suburb: props.selectedCustomer.suburb,
                    city: props.selectedCustomer.city,
                    totalSpent: props.selectedCustomer.totalSpent,
                    lastPaidVisit: props.selectedCustomer.lastPaidVisit,
                    dateOfBirth: props.selectedCustomer.dateOfBirth,
                    note: props.selectedCustomer.note,
                    mailer: props.selectedCustomer.mailer,
                    slotGroupViews: props.selectedCustomer.slotGroupsView.slotGroupViews
                },
                submitted: false,
                modal: false
    		};
        }
    }

    componentDidMount() {
        if (!this.props.loggedAs) {
            this.props.history.push("/login");
        }
        if (!this.props.selectedCustomer) {
            this.props.history.push("/customers");
        }
    }

    componentDidUpdate() {
        if (!this.props.loggedAs) {
            this.props.history.push("/login");
        }
        if (!this.props.selectedCustomer) {
            this.props.history.push("/customers");
        }
    }

    handleChange(event) {
        const { name } = event.target;
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });
    }

    handleOnDateOfBirthChanged(date) {
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                dateOfBirth: date == null ? null : date.format("YYYY-MM-DD")
            }
        });
    }

    handleResetPasswordClick() {
        this.props.dispatch(userActions.resetPasswordByAdmin(this.state.user));
    }

    handleBackClick() {
        this.props.dispatch(userActions.resetCustomer());
    }

    handleDeleteCustomer() {
        this.setState({ modal: true })
    }

    handleDeleteCustomerConfirmation() {
        this.props.dispatch(userActions.deleteCustomer(this.state.user));
        this.props.dispatch(userActions.resetCustomer());
    }

    hideModal() {
        this.setState({ modal: false })
    }

    handleSubmit(event) {
        event.preventDefault();
        var form = document.getElementById('detailsForm');
        if (form.checkValidity() === false) {
            form.classList.add('was-validated')
            event.stopPropagation();
            return;
        }

        this.setState({ submitted: true });
        const { user } = this.state;
        const { dispatch } = this.props;
        if (user.firstName && user.surname) {
            dispatch(userActions.updateSelectedCustomer(user));
        }
    }

    render() {
        const { updating, deletingCustomer, resettingPasswordByAdmin } = this.props;
        const { user, modal } = this.state;
        return (
            <div className="col-lg-8">
                <h2 className="text-center">Update customer information</h2>
                <div className="card card-primary card-transparent">
                    <div className="card-body">
                        <form id="detailsForm" className="needs-validation my-4" noValidate onSubmit={this.handleSubmit}>
                            <div className="form-group row">
                                <label className="col-4 col-md-3 col-xl-2 col-form-label" htmlFor="id">Id</label>
                                <div className="col-8">
                                    <input type="text" className="form-control-plaintext font-weight-bold" name="id" value={user.id} readonly/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-md-3 col-xl-2 col-form-label" htmlFor="firstName">First Name</label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control " name="firstName" value={user.firstName} required onChange={this.handleChange}/>
                                    <div className="invalid-feedback">
                                        Please enter first name
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-md-3 col-xl-2 col-form-label" htmlFor="surname">Surname</label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control" name="surname" value={user.surname} required onChange={this.handleChange}/>
                                    <div className="invalid-feedback">
                                        Please enter surname
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-md-3 col-xl-2 col-form-label" htmlFor="email">Email</label>
                                <div className="col-sm-8">
                                    <input type="email" className="form-control" name="email" value={user.email} onChange={this.handleChange}/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-md-3 col-xl-2 col-form-label" htmlFor="cellPhone">Mobile</label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control" name="cellPhone" value={user.cellPhone} onChange={this.handleChange}/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-md-3 col-xl-2 col-form-label" htmlFor="street">Street</label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control" name="street" value={user.street} onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-md-3 col-xl-2 col-form-label" htmlFor="suburb">Suburb</label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control" name="suburb" value={user.suburb} onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-md-3 col-xl-2 col-form-label" htmlFor="city">City</label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control" name="city" value={user.city} onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-4 col-md-3 col-xl-2 col-form-label" htmlFor="dateOfBirth">Date of birth</label>
                                <div className="col-8">
                                    <DateField
                                        id="dateOfBirth"
                                        dateTime={user.dateOfBirth ? moment(user.dateOfBirth) : null}
                                        onChange={this.handleOnDateOfBirthChanged}
                                        required={false}
                                        />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-md-3 col-xl-2 col-form-label"  htmlFor="note">Note</label>
                                <div className="col-sm-8">
                                    <textarea className="form-control" name="note" value={user.note} onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-4 col-md-3 col-xl-2 col-form-label" >Extra stuff</label>
                                <div className="col-8 pt-2">
                                    <input className="form-check-input ml-2" type="checkbox" checked={user.mailer} name="mailer" onChange={this.handleChange}/>
                                    <label className="form-check-label ml-5" htmlFor="mailer">Send mailer</label>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-4 col-md-3 col-xl-2 col-form-label" htmlFor="totalSpent">Total spent</label>
                                <div className="col-8">
                                    <input type="text" className="form-control-plaintext font-weight-bold" name="totalSpent" value={"$" + user.totalSpent.toLocaleString(undefined)} readonly />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-4 col-md-3 col-xl-2 col-form-label" htmlFor="lastPaidVisit">Last paid visit</label>
                                <div className="col-8">
                                    <input type="text" className="form-control-plaintext font-weight-bold" name="lastPaidVisit" value={user.lastPaidVisit ? moment(user.lastPaidVisit, "YYYY-MM-DD").format('Do MMMM YYYY') : 'never'} readonly />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-4 col-md-3 col-xl-2 col-form-label" htmlFor="treatmentGroups">Treatments</label>
                                <SlotGroups slotGroups={user.slotGroupViews}/>
                            </div>

                            <div className="form-group row align-items-center ml-1">
                                <button type="button" className="btn btn-secondary col-md-2 col-xl-1 mr-2 mb-2" onClick={this.handleBackClick}>Back</button>
                                {resettingPasswordByAdmin && <button type="button" className="btn btn-warning col-md-4 col-xl-3 mr-2 mb-2 " disabled >Resetting password... <i className="fa fa-circle-o-notch fa-spin"></i></button>}
                                {!resettingPasswordByAdmin && <button type="button" className="btn btn-warning col-md-4 col-xl-3 mr-2 mb-2" onClick={this.handleResetPasswordClick}>Reset password</button>}
                                { (!user.slotGroupViews || user.slotGroupViews.length === 0) &&
                                    <button type="button" className="btn btn-danger col-md-3 col-xl-2 mr-2 mb-2" onClick={this.handleDeleteCustomer}>Delete</button>
                                }
                                <SubmitButton updating={updating} className="col-md-3 col-xl-2 mr-2 mb-2" label="Update" labelLoading="Updating ..."/>
                            </div>
                        </form>
                    </div>
                </div>
                {modal &&
                    <Modal id="deleteConfirmation"
                        title="Delete confirmation"
                        body= {`Are you sure you want to delete the customer?`}
                        primaryButtonLabel="Yes"
                        primaryButtonLabelWait="Deleting..."
                        primaryFunction={this.handleDeleteCustomerConfirmation}
                        primaryFunctionWait={deletingCustomer}
                        primaryButtonClass="btn-danger"
                        cancelFunction={this.hideModal}/>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { selectedCustomer, updating, deletingCustomer, loggedAs, resettingPasswordByAdmin } = state.user;
    return {
        updating,
        deletingCustomer,
        selectedCustomer,
        loggedAs,
        resettingPasswordByAdmin
    };
}

const connectedCustomerEditPage = connect(mapStateToProps)(CustomerEditPage);
export { connectedCustomerEditPage as CustomerEditPage };
