import { reservationTherapistConstants } from '../_constants';

export const reservationTherapistActions = {
    selectGroup,
    resetGroup,
    selectTreatment,
    resetTreatment,
    selectTherapist,
    resetTherapist,
    selectPrice,
    resetPrice,
    addToBasket,
    removeFromBasket,
    resetBasket,
    selectCustomer,
    resetCustomer,
    resetEvent,
    setCustomerName,
    setCustomerContact,
    setSendEmail
};

function selectGroup(selectedGroup) {
    return dispatch => {
        dispatch(success(selectedGroup));
    };
    function success(selectedGroup) {
      return { type: reservationTherapistConstants.SELECTEDGROUP_SUCCESS, selectedGroup }
    }
}

function resetGroup() {
    return dispatch => {
        dispatch(successGroup());
    };
    function successGroup() {
      return { type: reservationTherapistConstants.RESETGROUP_SUCCESS }
    }
}

function selectTreatment(selectedTreatment) {
    return dispatch => {
        dispatch(success(selectedTreatment));
    };

    function success(selectedTreatment) {
      return { type: reservationTherapistConstants.SELECTEDTREATMENT_SUCCESS, selectedTreatment }
    }
}

function resetTreatment() {
    return dispatch => {
        dispatch(successTreatment());
    };
    function successTreatment() {
      return { type: reservationTherapistConstants.RESETTREATMENT_SUCCESS }
    }
}

function selectTherapist(selectedTherapist) {
    return dispatch => {
        dispatch(success(selectedTherapist));
    };
    function success(selectedTherapist) {
      return { type: reservationTherapistConstants.SELECTEDTHERAPIST_SUCCESS, selectedTherapist }
    }
}

function resetTherapist() {
    return dispatch => {
        dispatch(successTherapist());
    };
    function successTherapist() {
      return { type: reservationTherapistConstants.RESETTHERAPIST_SUCCESS }
    }
}

function selectPrice(selectedPrice) {
    return dispatch => {
        dispatch(success(selectedPrice));
    };
    function success(selectedPrice) {
      return { type: reservationTherapistConstants.SELECTEDPRICE_SUCCESS, selectedPrice }
    }
}

function addToBasket(selectedPrice, selectedPrices) {
    return dispatch => {
        if (!selectedPrices) {
            selectedPrices = new Set();
        } else {
            selectedPrices = new Set(selectedPrices);
        }
        selectedPrices.add(selectedPrice)
        dispatch(success(Array.from(selectedPrices)));
    };
    function success(selectedPrices) {
      return { type: reservationTherapistConstants.MODIFYBASKET_SUCCESS, selectedPrices }
    }
}

function removeFromBasket(selectedPrice, selectedPrices) {
    return dispatch => {
        if (!selectedPrices) {
            dispatch(reset());
        } else {
            selectedPrices = new Set(selectedPrices);
        }
        selectedPrices.forEach(function(price){
            if (price.id === selectedPrice.id) {
                selectedPrices.delete(price);
            }
        });
        dispatch(success(Array.from(selectedPrices)));
    };
    function success(selectedPrices) {
      return { type: reservationTherapistConstants.MODIFYBASKET_SUCCESS, selectedPrices }
    }
    function reset() {
      return { type: reservationTherapistConstants.RESETBASKET_SUCCESS }
    }
}

function resetBasket() {
    return dispatch => {
        dispatch(successResetBasket());
    };
    function successResetBasket() {
      return { type: reservationTherapistConstants.RESETBASKET_SUCCESS }
    }
}

function resetPrice() {
    return dispatch => {
        dispatch(successPrice());
    };
    function successPrice() {
      return { type: reservationTherapistConstants.RESETPRICE_SUCCESS }
    }
}

function selectCustomer(selectedCustomer) {
    return dispatch => {
        dispatch(success(selectedCustomer));
    };
    function success(selectedCustomer) {
      return { type: reservationTherapistConstants.SELECTEDCUSTOMER_SUCCESS, selectedCustomer }
    }
}

function resetCustomer() {
    return dispatch => {
        dispatch(successCustomer());
    };
    function successCustomer() {
      return { type: reservationTherapistConstants.RESETCUSTOMER_SUCCESS }
    }
}

function resetEvent() {
    return dispatch => {
        dispatch(successEvent());
    };
    function successEvent() {
      return { type: reservationTherapistConstants.RESETEVENT_SUCCESS }
    }
}

function setCustomerName(customerName) {
    return dispatch => {
        dispatch(save(customerName));
    };
    function save(customerName) { return { type: reservationTherapistConstants.CUSTOMERNAME_SUCCESS, customerName } }
}

function setCustomerContact(customerContact) {
    return dispatch => {
        dispatch(save(customerContact));
    };
    function save(customerContact) { return { type: reservationTherapistConstants.CUSTOMERCONTACT_SUCCESS, customerContact } }
}

function setSendEmail(sendEmail) {
    return dispatch => {
        dispatch(save(sendEmail));
    };
    function save(sendEmail) { return { type: reservationTherapistConstants.SENDEMAIL_SUCCESS, sendEmail } }
}
