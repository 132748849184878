import React from 'react';
import { connect } from 'react-redux';

import { treatmentGroupActions, reservationActions } from '../_actions';
import { SelectedItem, Items } from '.';

class BookingTherapistComponent extends React.Component {

    constructor(props) {
        super(props);

        this.handleClickSelectTherapist = this.handleClickSelectTherapist.bind(this);
        this.handleClickResetTherapist = this.handleClickResetTherapist.bind(this);
    }

    componentDidMount() {
        const { treatmentGroup, reservation } = this.props;
        if (treatmentGroup && !treatmentGroup.requestingTherapists && !treatmentGroup.therapists && reservation.selectedGroup && reservation.selectedTreatment && !reservation.selectedTherapist) {
            this.props.dispatch(treatmentGroupActions.getTherapists(reservation.selectedTreatment.id));
        }
    }

    handleClickSelectTherapist(id) {
        var selectedTherapist;
        var selectedTherapistId = parseInt(id);
        var therapists = this.props.treatmentGroup.therapists;
        if (selectedTherapistId && therapists) {
            for (var i = 0; i < therapists.length; i++) {
                if (selectedTherapistId === therapists[i].id) {
                    selectedTherapist = therapists[i];
                    this.props.dispatch(reservationActions.selectTherapist(selectedTherapist));
                    break;
                }
            }
        }

    }

    handleClickResetTherapist() {
        this.props.dispatch(reservationActions.resetTherapist());
    }

    render() {
        const { treatmentGroup, reservation } = this.props;
        var selectedGroup = reservation.selectedGroup;
        var selectedTreatment = reservation.selectedTreatment;
        var selectedTherapist = reservation.selectedTherapist;
        if (selectedGroup && selectedTreatment && selectedTherapist) {
            return (
                <SelectedItem
                    label="4. Therapist:"
                    value={selectedTherapist.firstName}
                    onClickHandle = {this.handleClickResetTherapist}/>
            );
        } else if (selectedGroup && selectedTreatment && !selectedTherapist) {
            var values = [];
            if (treatmentGroup.therapists) {
                values = treatmentGroup.therapists.map((therapist, index) => {
                        return {id: therapist.id, label: therapist.firstName};
                    }
                );
            }
            return (
                <Items label="4. Pick therapist:"
                    values={!treatmentGroup.requestingTherapists && values}
                    loading={treatmentGroup.requestingTherapists}
                    error={false}
                    onClickHandle = {this.handleClickSelectTherapist}
                    keyPrefix='therapist_'/>
            );
        } else {
            return (
                <fieldset className="form-group">
                    <div className="row mb-1">
                        <legend className="col-form-label col-sm-4 col-md-4 col-lg-3 pt-0 font-weight-bold text-muted">4. Pick therapist:</legend>
                        <div className="col-sm-4 col-md-5 col-lg-7">
                        </div>
                    </div>
                </fieldset>
            );
        }
    }
}

function mapStateToProps(state) {
    const { treatmentGroup, reservation } = state;

    return {
        treatmentGroup,
        reservation
    };
}

const connectedBookingTherapistComponent = connect(mapStateToProps)(BookingTherapistComponent);
export { connectedBookingTherapistComponent as BookingTherapistComponent };
