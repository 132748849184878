import React from 'react';
import { connect } from 'react-redux';

import { reservationActions } from '../_actions';

import { BookingTreatmentGroupComponent, BookingTreatmentComponent, BookingPriceComponent, BookingTherapistComponent, Basket } from '.';

class BookingPage extends React.Component {

    constructor(props) {
        super(props);
        this.handleAddToBasket = this.handleAddToBasket.bind(this);
        this.handleResetBasket = this.handleResetBasket.bind(this);
        this.handlePickADate = this.handlePickADate.bind(this);
        this.handleAddToBasketAndPickADate = this.handleAddToBasketAndPickADate.bind(this);
    }

    componentDidMount() {
      if (!this.props.loggedAs) {
        this.props.history.push("/login");
      }
    }

    handleAddToBasket() {
        this.props.dispatch(reservationActions.addToBasket(this.props.reservation.selectedPrice, this.props.reservation.selectedPrices, this.props.reservation.selectedTherapist));
        this.props.dispatch(reservationActions.resetGroup());
    }

    handleResetBasket() {
        this.props.dispatch(reservationActions.resetBasket());
    }

    handleAddToBasketAndPickADate() {
        this.handleAddToBasket();
        this.handlePickADate();
    }

    handlePickADate() {
        this.props.dispatch(reservationActions.pickDateTime());
    }

    render() {
        const { reservation } = this.props;
        const basketSize = 2;
        var selectedGroup = reservation.selectedGroup;
        var selectedTreatment = reservation.selectedTreatment;
        var selectedPrice = reservation.selectedPrice;
        var selectedTherapist = reservation.selectedTherapist;
        var selectedPrices = reservation.selectedPrices;
        var selectedBasketTherapist = reservation.selectedBasketTherapist;
        return (
            <div className="col-12 col-lg-10">
                <h2 className="text-center">Book your next treatment</h2>
                {(!selectedPrices || (selectedPrices && selectedPrices.length < basketSize)) && <div className="card card-primary card-transparent">
                    <div className="card-body">
                        <form id="bookingForm" className="my-4" noValidate onSubmit={this.handleSubmit}>
                            <BookingTreatmentGroupComponent/>
                            <BookingTreatmentComponent/>
                            <BookingPriceComponent/>
                            <BookingTherapistComponent/>
                            {selectedGroup && selectedTreatment && selectedPrice && selectedTherapist &&
                                <div className="form-group row align-items-center ml-1 mt-4">
                                    {(!selectedPrices || selectedPrices.length === 0) &&
                                        <button type="button" className="btn btn-primary col-md-4 col-xl-3 mr-2 mb-2" onClick={this.handleAddToBasketAndPickADate}>Pick a date</button>
                                    }
                                    <button type="button" className="btn btn-warning col-md-3 col-xl-2 mr-2 mb-2" onClick={this.handleAddToBasket}>Add to basket</button>
                                </div>
                            }
                        </form>
                    </div>
                </div>}
                {selectedPrices && selectedPrices.length > 0 && <div className="card card-primary card-transparent mt-5">
                    <div className="card-body">
                        <form id="basketForm" className="my-4" noValidate>
                            <div className="form-group row">
                                <div className="col-sm-2 ">
                                    <label className="col-form-label">Basket:</label>
                                    <div className="tiny">
                                        {`(${selectedPrices.length} of ${basketSize} treatments)`}
                                    </div>
                                </div>
                                <Basket items={selectedPrices} therapist={selectedBasketTherapist}/>
                            </div>
                            <div className="form-group row align-items-center ml-1 ">
                                <button type="button" className="btn btn-primary col-md-4 col-xl-3 mr-2 mb-2" onClick={this.handlePickADate}>Pick a date</button>
                                <button type="button" className="btn btn-danger col-md-3 col-xl-2 mr-2 mb-2" onClick={this.handleResetBasket}>Reset basket</button>
                            </div>
                        </form>
                    </div>
                </div>}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { loggedAs } = state.user;
    const { treatmentGroup, slotGroup, reservation } = state;
    return {
        loggedAs,
        treatmentGroup,
        slotGroup,
        reservation
    };
}

const connectedBookingPage = connect(mapStateToProps)(BookingPage);
export { connectedBookingPage as BookingPage };
