import { slotGroupConstants } from '../_constants';

const initialState = {};

export function slotGroup(state = initialState, action) {
    switch (action.type) {
        case slotGroupConstants.GETAVAILABLE_REQUEST:
            return Object.assign({}, state, {
                loading: true
            });
        case slotGroupConstants.GETAVAILABLE_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                slotGroups: action.slotGroups
            });
        case slotGroupConstants.GETAVAILABLE_FAILURE:
            return Object.assign({}, state, {
                loading: false,
                error: action.error
            });
        case slotGroupConstants.GETBOOKINGS_REQUEST:
            return Object.assign({}, state, {
                loading: true
            });
        case slotGroupConstants.GETBOOKINGS_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                slotGroups: action.slotGroups
            });
        case slotGroupConstants.GETBOOKINGS_FAILURE:
            return Object.assign({}, state, {
                loading: false,
                error: action.error
            });
        case slotGroupConstants.RESERVE_REQUEST:
            return Object.assign({}, state, {
                loading: true
            });
        case slotGroupConstants.RESERVE_SUCCESS:
            return Object.assign({}, state, {
                loading: false
            });
        case slotGroupConstants.RESERVE_FAILURE:
            return Object.assign({}, state, {
                loading: false,
                error: action.error
            });
        case slotGroupConstants.CONFIRM_REQUEST:
            return Object.assign({}, state, {
                confirming: true
            });
        case slotGroupConstants.CONFIRM_SUCCESS:
            return Object.assign({}, state, {
                confirming: false
            });
        case slotGroupConstants.CONFIRM_FAILURE:
            return Object.assign({}, state, {
                confirming: false,
                error: action.error
            });
        case slotGroupConstants.REJECT_REQUEST:
            return Object.assign({}, state, {
                rejecting: true
            });
        case slotGroupConstants.REJECT_SUCCESS:
            return Object.assign({}, state, {
                rejecting: false
            });
        case slotGroupConstants.REJECT_FAILURE:
            return Object.assign({}, state, {
                rejecting: false,
                error: action.error
            });
        case slotGroupConstants.PAY_REQUEST:
            return Object.assign({}, state, {
                paying: true
            });
        case slotGroupConstants.PAY_SUCCESS:
            return Object.assign({}, state, {
                paying: false
            });
        case slotGroupConstants.PAY_FAILURE:
            return Object.assign({}, state, {
                paying: false,
                error: action.error
            });
        case slotGroupConstants.DELETE_REQUEST:
            return Object.assign({}, state, {
                deleting: true
            });
        case slotGroupConstants.DELETE_SUCCESS:
            return Object.assign({}, state, {
                deleting: false
            });
        case slotGroupConstants.DELETE_FAILURE:
            return Object.assign({}, state, {
                deleting: false,
                error: action.error
            });
        case slotGroupConstants.CANCEL_REQUEST:
            return Object.assign({}, state, {
                canceling: true
            });
        case slotGroupConstants.CANCEL_SUCCESS:
            return Object.assign({}, state, {
                canceling: false
            });
        case slotGroupConstants.CANCEL_FAILURE:
            return Object.assign({}, state, {
                canceling: false,
                error: action.error
            });
        case slotGroupConstants.GETLAST_REQUEST:
            return Object.assign({}, state, {
                loadingLastSlotGroup: true
            });
        case slotGroupConstants.PRICEUPDATE_REQUEST:
            return Object.assign({}, state, {
                priceUpdating: true
            });
        case slotGroupConstants.PRICEUPDATE_SUCCESS:
            return Object.assign({}, state, {
                priceUpdating: false
            });
        case slotGroupConstants.PRICEUPDATE_FAILURE:
            return Object.assign({}, state, {
                priceUpdating: false,
                error: action.error
            });
        case slotGroupConstants.GETLAST_SUCCESS:
            return Object.assign({}, state, {
                loadingLastSlotGroup: false,
                lastSlotGroup: action.slotGroup
            });
        case slotGroupConstants.GETLAST_FAILURE:
            return Object.assign({}, state, {
                loadingLastSlotGroup: false,
                error: action.error
            });
        case slotGroupConstants.GETUPCOMING_REQUEST:
            return Object.assign({}, state, {
                loadingUpcomingSlotGroups: true
            });
        case slotGroupConstants.GETUPCOMING_SUCCESS:
            return Object.assign({}, state, {
                loadingUpcomingSlotGroups: false,
                upcomingSlotGroups: action.slotGroups
            });
        case slotGroupConstants.GETUPCOMING_FAILURE:
            return Object.assign({}, state, {
                loadingUpcomingSlotGroups: false,
                error: action.error
            });
        case slotGroupConstants.RESETUPCOMING_SUCCESS:
            return Object.assign({}, state, {
                loadingUpcomingSlotGroups: false,
                upcomingSlotGroups: null
            });
        case slotGroupConstants.RESETGROUPS_SUCCESS:
            return Object.assign({}, state, {
                slotGroups: null
            });
        default:
            return state;
    }
}
