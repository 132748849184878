import React from 'react';

import { BasketItem } from '.';

export class Basket extends React.Component {

    render() {
        const { items, therapist } = this.props;

        var total = 0;
        if (items && items.length > 0) {
            items.forEach(item => {
                total = total + item.price;
            });
        }
        if (items && items.length > 0) {
            return (
                <div className="col-sm-9">
                    {
                        items.map(function(item, index){
                            return <BasketItem key={`basketItem_${index}`} item = {item}/>
                        })
                    }
                    <hr className="bg-secondary"/>
                    <div className="row mx-1 justify-content-end">
                        <label className="col-4 col-md-3 col-form-label" htmlFor="total">Total</label>
                        <div className="col-8 col-md-4 form-control-plaintext font-weight-bold">
                            {'$'+total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                        </div>
                    </div>
                    <div className="row mx-1 justify-content-end">
                        <label className="col-4 col-md-3 col-form-label" htmlFor="total">Therapist</label>
                        <div className="col-8 col-md-4 form-control-plaintext font-weight-bold">
                            {therapist.firstName}
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div>No items in the basket</div>
            );
        }
    }
}
