import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ResponsiveContainer, PieChart, Pie, ComposedChart, Line, Area, XAxis, YAxis, Cell, Legend, Tooltip, CartesianGrid} from 'recharts';

import { slotGroupActions, statisticsActions } from '../_actions';

import { SlotGroupCard, SlotGroupCards } from '.';

class HomePage extends React.Component {
    constructor(props) {
        super(props);
        const { loggedAs } = this.props;

        this.COLORS = ['#ec008c', '#ee427c', '#ee5f6f', '#eb7663', '#e78858', '#e19850', '#d9a74a', '#cfb347', '#c2be47', '#b4c84c', '#49d78c', '#5ec0a3', '#6cacb5', '#7999c5', '#8588d2', '#9477dc', '#a567e4', '#b854ea', '#cf3bee', '#e800f0'];
        this.canReserveClient = loggedAs && loggedAs.permissions && loggedAs.permissions.includes('canReserveClient');
        this.canReserveTherapist = loggedAs && loggedAs.permissions && loggedAs.permissions.includes('canReserveTherapist');
        this.canModifyFinance = loggedAs && loggedAs.permissions && loggedAs.permissions.includes('canModifyFinance');
    }

    componentDidMount() {
        if (!this.props.loggedAs) {
            this.props.history.push("/login");
        }
        const { slotGroup, statistics } = this.props;
        if (this.canReserveClient) {
            if (!slotGroup) {
                this.props.dispatch(slotGroupActions.getLast());
                this.props.dispatch(slotGroupActions.getUpcoming());
            } else {
                if (!slotGroup.lastSlotGroup) {
                    this.props.dispatch(slotGroupActions.getLast());
                }
                if (!slotGroup.upcomingSlotGroups) {
                    this.props.dispatch(slotGroupActions.getUpcoming());
                }
            }
        }
        if (this.canReserveTherapist) {
            if (!statistics || !statistics.generalStatistics) {
                this.props.dispatch(statisticsActions.getGeneral());
            }
        }
    }

    handleClickTreatmentGroup(id) {
        //return (e) => this.props.dispatch(treatmentGroupActions.getTreatment(id));
    }

    render() {
        const { loggedAs, slotGroup, statistics } = this.props;
        const massageTypes = statistics.generalStatistics ? statistics.generalStatistics[0].values : null;
        const finances = statistics.generalStatistics ? statistics.generalStatistics[1].values : null;
        const summary = statistics.generalStatistics ? statistics.generalStatistics[2].values : null;
        const gradientOffset = () => {
          const dataMax = finances ? Math.max(...finances.map(i => i.values.income)) : 0;
          const dataMin = finances ? Math.min(...finances.map(i => i.values.income)) : 0;

          if (dataMax <= 0) {
            return 0;
          }
          if (dataMin >= 0) {
            return 1;
          }

          return dataMax / (dataMax - dataMin);
        };
        const off = gradientOffset();
        return (
            <div className="col-lg-8 col-lg-offset-2 col-md-10 col-md-offset-1">
                <h1>Hi {loggedAs ? loggedAs.firstName : ''},</h1>
                {this.canReserveClient && <div>
                    <div className="row mt-4">
                        <div className="col">
                            Let's get started and get some <Link to="/booking" className="btn btn-primary">bookings</Link> done.
                        </div>
                    </div>
                    <div className="row mt-4">
                        <SlotGroupCards slotGroups = {slotGroup.upcomingSlotGroups} loading={slotGroup.loadingUpcomingSlotGroups} canceling={slotGroup.canceling}/>
                        <SlotGroupCard slotGroup = {slotGroup.lastSlotGroup} loading={slotGroup.loadingLastSlotGroup} showCancel={false}/>
                    </div>
                </div>}
                {this.canModifyFinance && statistics.requestingGeneralStatistics && <p>Loading some statistics ... </p>}
                {this.canModifyFinance && !statistics.requestingGeneralStatistics && summary && <div className="row">
                        <div className="col-12 col-lg-4 mb-4">
                            <div className="card gradient-ohhappiness card-shade" >
                                <div className="card-body">
                                    <h6 className="text-white">Money coming in - last 8 weeks</h6>
                                    <h4>{summary[0].values.value.toLocaleString('en-NZ', {style: 'currency', currency: 'NZD'})}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 mb-4">
                            <div className="card gradient-ibiza card-shade" >
                                <div className="card-body">
                                    <h6 className="text-white">Expenses - last 8 weeks</h6>
                                    <h4>{summary[1].values.value.toLocaleString('en-NZ', {style: 'currency', currency: 'NZD'})}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 mb-4">
                            <div className="card gradient-deepblue card-shade" >
                                <div className="card-body">
                                    <h6 className="text-white">Drawings - last 8 weeks</h6>
                                    <h4>{summary[2].values.value.toLocaleString('en-NZ', {style: 'currency', currency: 'NZD'})}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {this.canModifyFinance && !statistics.requestingGeneralStatistics && <div className="row">
                        <div className="col-12 col-xl-6 mb-4">
                            <div className="card bg-dark" >
                                <div className="card-header">
                                    Massages - last 8 weeks
                                </div>
                                <div className="card-body" style={{"height": 250}}>
                                    {massageTypes && massageTypes.length > 0 && <ResponsiveContainer>
                                        <PieChart>
                                            <Pie
                                                data={massageTypes}
                                                dataKey="values.value"
                                                innerRadius={60}
                                                outerRadius={80}
                                                fill="#8884d8"
                                                paddingAngle={5}
                                                >
                                                {
                                                    massageTypes.map((entry, index) => <Cell key={`cell-${index}`} fill={this.COLORS[index % this.COLORS.length]} />)
                                                }
                                            </Pie>
                                            <Legend layout="vertical"
                                                verticalAlign="middle"
                                                align="right"
                                                iconSize="10"
                                                iconType="circle"
                                                wrapperStyle={
                                                    {fontSize:'9px'}
                                                }
                                                />
                                            <Tooltip
                                                wrapperStyle={
                                                    {opacity: 0.7}
                                                }
                                                itemStyle={
                                                    {fontSize:'11px'}
                                                }/>
                                        </PieChart>
                                    </ResponsiveContainer>}
                                    {(!massageTypes || (massageTypes && massageTypes.length === 0)) && <p>Here goes nothing. <sub>Lando Carlissian</sub></p>}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-6 mb-4">
                            <div className="card bg-dark" >
                                <div className="card-header">
                                    Finances - last 8 weeks
                                </div>
                                <div className="card-body" style={{"height": 250}}>
                                    {finances && finances.length > 0 && <ResponsiveContainer>
                                        <ComposedChart data={finances}>
                                            <CartesianGrid strokeDasharray="8 3" />
                                            <XAxis
                                                dataKey = 'name'
                                                name = 'Date'
                                                stroke = 'white'
                                                tick={{fontSize: 10}}
                                                padding={{ left: 10, right: 10 }}
                                                />
                                            <YAxis
                                                stroke = 'white'
                                                tick={{fontSize: 10}}
                                                />
                                            <Line
                                                type={"monotone"}
                                                dataKey="values.moneyComingIn"
                                                name='Money coming in'
                                                dot={false}
                                                strokeWidth="3px"
                                                stroke="#7AA82D"
                                                />
                                            <Line
                                                type={"monotone"}
                                                dataKey="values.expenses"
                                                name='Expenses'
                                                dot={false}
                                                strokeWidth="3px"
                                                stroke="#fc296c"
                                                />
                                            <Line
                                                type={"monotone"}
                                                dataKey="values.drawings"
                                                name='Drawings'
                                                dot={false}
                                                strokeWidth="2px"
                                                strokeDasharray="3 3"
                                                stroke="#2575fc"
                                                />
                                            <Tooltip
                                                wrapperStyle={
                                                    {opacity: 0.7}
                                                }
                                                labelStyle={
                                                    {
                                                        color:'black',
                                                        fontSize:'11px'
                                                    }
                                                }
                                                itemStyle={
                                                    {fontSize:'11px'}
                                                }
                                            />
                                        </ComposedChart>
                                    </ResponsiveContainer>}
                                    {(!finances || (finances && finances.length === 0)) && <p>There's nothing to see. I used to live here, you know. <sub>Luke Skywalker</sub></p>}
                                </div>
                            </div>
                        </div>
                </div>}
            </div>

        );
    }
}

function mapStateToProps(state) {
    const { loggedAs } = state.user;
    const { slotGroup, statistics } = state;
    return {
        loggedAs,
        slotGroup,
        statistics
    };
}

const connectedHomePage = connect(mapStateToProps)(HomePage);
export { connectedHomePage as HomePage };
