import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { voucherActions } from '../_actions';

import { Vouchers } from '.';

class VouchersPage extends React.Component {

    constructor(props) {
        super(props);

        this.onFilterChanged = this.onFilterChanged.bind(this);

        this.state = {
			filter: ""
		};
        this.props.dispatch(voucherActions.getVouchers(""));
    }

    componentDidMount() {
        if (!this.props.loggedAs) {
            this.props.history.push("/login");
        }
        if (!this.props.voucher.vouchers) {
            this.props.dispatch(voucherActions.getVouchers(""));
        }
    }

    componentDidUpdate() {
        if (!this.props.loggedAs) {
            this.props.history.push("/login");
        }
        if (!this.props.voucher.vouchers && !this.props.voucher.requestingVouchers) {
            this.props.dispatch(voucherActions.getVouchers(this.state.filter));
        }
    }

    onFilterChanged(event) {
        this.setState({ filter: event.target.value })
        this.props.dispatch(voucherActions.getVouchers(event.target.value));
    }

    render() {
        const { voucher } = this.props;
            return (
                <div className="col-12 col-lg-8">
                    <h2 className="text-center">Vouchers</h2>
                    <div className="card card-primary card-transparent">
                        <div className="card-body">
                            <form id="VouchersForm" noValidate className="needs-validation my-4" onSubmit={this.handleSubmit}>
                                <div className="form-group row align-items-center ml-1">
                                    <div className="form-check form-check-inline col-md-3 mr-2 mb-2">
                                        <Link to="/voucherCreate" className="btn btn-primary btn-block">
                                            Create new voucher
                                        </Link>
                                    </div>
                                </div>
                                {voucher.requestingVouchers &&
                                    <div className="form-row">
                                        <div class="col text-center">
                                            <strong>Loading vouchers ... <i className="fa fa-circle-o-notch fa-spin"></i></strong>
                                        </div>
                                    </div>
                                }
                                <Vouchers vouchers={voucher.vouchers}/>
                            </form>
                        </div>
                    </div>
                </div>
            );
    }
}

function mapStateToProps(state) {
    const { loggedAs } = state.user;
    const { voucher } = state;

    return {
        loggedAs,
        voucher
    };
}

const connectedVouchersPage = connect(mapStateToProps)(VouchersPage);
export { connectedVouchersPage as VouchersPage };
