import React from 'react';
import { Router, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import { history } from '../_helpers';
import { PrivateRoute, Menu, Alert, Footer } from '../_components';
import { HomePage } from '../HomePage';
import { BookingPage, BookingPage2} from '../BookingPage';
import { BookingTherapistCalendarPage, BookingTherapistSlotGroupDetailsPage, BookingTherapistSlotGroupCreatePage, BookingTherapistSlotGroupTypePage, BookingTherapistLeaveDetailsPage } from '../BookingTherapistPage';
import { CustomersPage, CustomerEditPage, CustomerCreatePage } from '../CustomersPage';
import { TherapistsPage, TherapistEditPage } from '../TherapistsPage';
import { VouchersPage, VoucherCreatePage } from '../VouchersPage';
import { TreatmentGroupsPage, TreatmentGroupCreatePage, TreatmentGroupEditPage, TreatmentGroupIncreasePricesPage } from '../TreatmentGroupsPage';
import { TreatmentsPage, TreatmentCreatePage, TreatmentEditPage, TreatmentIncreasePricesPage } from '../TreatmentsPage';
import { PricesPage, PriceCreatePage, PriceEditPage } from '../PricesPage';
import { FinancesPage, TransactionEditPage, TransactionCreatePage } from '../FinancesPage';
import { LoginPage, ResetPasswordPage } from '../Authentication';
import { RegisterPage } from '../RegisterPage';
import { ProfilePage, UpdatePasswordPage, UpdateSecurityQuestionPage } from '../ProfilePage';

class App extends React.Component {
    constructor(props) {
        super(props);

        history.listen((location, action) => {
            // clear alert on location change
            //dispatch(alertActions.clear());
        });
    }

    render() {
        return (
            <Router history={history}>
                <div className="container-fluid">
                    <Menu/>
                    <Alert/>
                    <div className="row justify-content-center my-5">
                        <PrivateRoute exact path="/" component={HomePage} />
                        <PrivateRoute exact path="/booking" component={BookingPage} />
                        <PrivateRoute exact path="/bookingDateTime" component={BookingPage2} />
                        <PrivateRoute exact path="/bookingCalendar" component={BookingTherapistCalendarPage} />
                        <PrivateRoute exact path="/slotGroupDetails" component={BookingTherapistSlotGroupDetailsPage} />
                        <PrivateRoute exact path="/slotGroupCreate" component={BookingTherapistSlotGroupCreatePage} />
                        <PrivateRoute exact path="/slotGroupType" component={BookingTherapistSlotGroupTypePage} />
                        <PrivateRoute exact path="/leaveDetails" component={BookingTherapistLeaveDetailsPage} />
                        <PrivateRoute exact path="/customers" component={CustomersPage} />
                        <PrivateRoute exact path="/customerEdit" component={CustomerEditPage} />
                        <PrivateRoute exact path="/customerCreate" component={CustomerCreatePage} />
                        <PrivateRoute exact path="/therapists" component={TherapistsPage} />
                        <PrivateRoute exact path="/therapistEdit" component={TherapistEditPage} />
                        <PrivateRoute exact path="/vouchers" component={VouchersPage} />
                        <PrivateRoute exact path="/voucherCreate" component={VoucherCreatePage} />
                        <PrivateRoute exact path="/treatmentGroups" component={TreatmentGroupsPage} />
                        <PrivateRoute exact path="/treatmentGroupCreate" component={TreatmentGroupCreatePage} />
                        <PrivateRoute exact path="/treatmentGroupEdit" component={TreatmentGroupEditPage} />
                        <PrivateRoute exact path="/treatmentGroupIncreasePrices" component={TreatmentGroupIncreasePricesPage} />
                        <PrivateRoute exact path="/treatmentIncreasePrices" component={TreatmentIncreasePricesPage} />
                        <PrivateRoute exact path="/treatments" component={TreatmentsPage} />
                        <PrivateRoute exact path="/treatmentCreate" component={TreatmentCreatePage} />
                        <PrivateRoute exact path="/treatmentEdit" component={TreatmentEditPage} />
                        <PrivateRoute exact path="/prices" component={PricesPage} />
                        <PrivateRoute exact path="/priceCreate" component={PriceCreatePage} />
                        <PrivateRoute exact path="/priceEdit" component={PriceEditPage} />
                        <PrivateRoute exact path="/finances" component={FinancesPage} />
                        <PrivateRoute exact path="/financialTransactionEdit" component={TransactionEditPage} />
                        <PrivateRoute exact path="/finacialTransationCreate" component={TransactionCreatePage} />
                        <PrivateRoute exact path="/profile" component={ProfilePage} />
                        <PrivateRoute exact path="/profile/updatePassword" component={UpdatePasswordPage} />
                        <PrivateRoute exact path="/profile/updateSecurityQuestion" component={UpdateSecurityQuestionPage} />
                        <Route exact path="/login" component={LoginPage} />
                        <Route exact path="/login/resetPassword" component={ResetPasswordPage} />
                        <Route path="/register" component={RegisterPage} />
                    </div>
                    <Footer/>
                </div>
            </Router>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App };
