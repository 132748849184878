import axios from 'axios';
import moment from 'moment';
import 'moment/locale/en-nz';

import { http, formatting } from '../_helpers';

const URL = http.BASE_URL + '/api/treatment';

export const treatmentGroupService = {
    getGroups,
    createTreatmentGroup,
    updateTreatmentGroup,
    deleteTreatmentGroup,
    createTreatment,
    updateTreatment,
    deleteTreatment,
    createPrice,
    updatePrice,
    deletePrice,
    getGroupsForPlaceAndDate,
    getTherapists,
    getPrices,
    increasePrices,
    increasePricesForTreatment
};

function getGroups() {
    const url = `${URL}/groups`;
    return axios.get(url, {
        headers: http.HEADERS,
        withCredentials: true
    })
    .then(response => {
        if (response && response.data) {
            return response.data;
        }
    })
    .catch(result=> {
        return http.handleHttpError(result);
    });
}

function createTreatmentGroup(group) {
    const url = `${URL}/groups`
    return axios.post(url,
        group,
        {
            headers: http.HEADERS,
            withCredentials: true
        })
        .then(response => {
            if (response && response.data) {
                return response.data;
            }
        })
        .catch(result=> {
            return http.handleHttpError(result);
        }
    );
}

function updateTreatmentGroup(group) {
    const url = `${URL}/groups`
    return axios.put(url,
        group,
        {
            headers: http.HEADERS,
            withCredentials: true
        })
        .then(response => {
            if (response && response.data) {
                return response.data;
            }
        })
        .catch(result=> {
            return http.handleHttpError(result);
        }
    );
}

function deleteTreatmentGroup(group) {
    const url = `${URL}/groups/` + group.id;
    return axios.delete(url,
        {
            headers: http.HEADERS,
            withCredentials: true
        })
        .then(response => {
            if (response && response.data) {
                return response.data;
            }
        })
        .catch(result=> {
            return http.handleHttpError(result);
        }
    );
}

function createTreatment(treatment) {
    const url = `${URL}`
    return axios.post(url,
        treatment,
        {
            headers: http.HEADERS,
            withCredentials: true
        })
        .then(response => {
            if (response && response.data) {
                return response.data;
            }
        })
        .catch(result=> {
            return http.handleHttpError(result);
        }
    );
}

function updateTreatment(treatment) {
    const url = `${URL}`
    return axios.put(url,
        treatment,
        {
            headers: http.HEADERS,
            withCredentials: true
        })
        .then(response => {
            if (response && response.data) {
                return response.data;
            }
        })
        .catch(result=> {
            return http.handleHttpError(result);
        }
    );
}

function deleteTreatment(treatment) {
    const url = `${URL}/` + treatment.id;
    return axios.delete(url,
        {
            headers: http.HEADERS,
            withCredentials: true
        })
        .then(response => {
            if (response && response.data) {
                return response.data;
            }
        })
        .catch(result=> {
            return http.handleHttpError(result);
        }
    );
}

function createPrice(price) {
    const url = `${URL}/prices`
    return axios.post(url,
        price,
        {
            headers: http.HEADERS,
            withCredentials: true
        })
        .then(response => {
            if (response && response.data) {
                return response.data;
            }
        })
        .catch(result=> {
            return http.handleHttpError(result);
        }
    );
}

function updatePrice(price) {
    const url = `${URL}/prices`
    return axios.put(url,
        price,
        {
            headers: http.HEADERS,
            withCredentials: true
        })
        .then(response => {
            if (response && response.data) {
                return response.data;
            }
        })
        .catch(result=> {
            return http.handleHttpError(result);
        }
    );
}

function deletePrice(price) {
    const url = `${URL}/prices/` + price.id;
    return axios.delete(url,
        {
            headers: http.HEADERS,
            withCredentials: true
        })
        .then(response => {
            if (response && response.data) {
                return response.data;
            }
        })
        .catch(result=> {
            return http.handleHttpError(result);
        }
    );
}

function getGroupsForPlaceAndDate(placeId, date) {
    const url = `${URL}/groups?placeId=` + placeId + `&forDate=` + moment(date).startOf('day').format(formatting.DATE_TIME_FORMATTING);
    return axios.get(url, {
        headers: http.HEADERS,
        withCredentials: true
    })
    .then(response => {
        if (response && response.data) {
            return response.data;
        }
    })
    .catch(result=> {
        return http.handleHttpError(result);
    });
}

function getTherapists(treatmentId) {
    const url = `${URL}/` + treatmentId +`/therapists`;
    return axios.get(url, {
        headers: http.HEADERS,
        withCredentials: true
    })
    .then(response => {
        if (response && response.data) {
            return response.data;
        }
    })
    .catch(result=> {
        return http.handleHttpError(result);
    });
}

function getPrices(treatmentId) {
    const url = `${URL}/` + treatmentId +`/prices`;
    return axios.get(url, {
        headers: http.HEADERS,
        withCredentials: true
    })
    .then(response => {
        if (response && response.data) {
            return response.data;
        }
    })
    .catch(result=> {
        return http.handleHttpError(result);
    });
}

function increasePrices(byHowMuch, fromDate, oldTreatmentSuffix) {
    const url = `${URL}/increaseprices`
    const priceIncrease = {
        'byHowMuch': byHowMuch,
        'fromDate': fromDate.format(formatting.DATE_FORMATTING),
        'oldTreatmentSuffix': oldTreatmentSuffix
    }
    return axios.put(url,
        priceIncrease,
        {
            headers: http.HEADERS,
            withCredentials: true
        })
        .then(response => {
            if (response && response.data) {
                return response.data;
            }
        })
        .catch(result=> {
            return http.handleHttpError(result);
        }
    );
}

function increasePricesForTreatment(treatmentId, byHowMuch, fromDate, oldTreatmentSuffix) {
    const url = `${URL}/` + treatmentId +`/increaseprices`
    const priceIncrease = {
        'byHowMuch': byHowMuch,
        'fromDate': fromDate.format(formatting.DATE_FORMATTING),
        'oldTreatmentSuffix': oldTreatmentSuffix
    }
    return axios.put(url,
        priceIncrease,
        {
            headers: http.HEADERS,
            withCredentials: true
        })
        .then(response => {
            if (response && response.data) {
                return response.data;
            }
        })
        .catch(result=> {
            return http.handleHttpError(result);
        }
    );
}
