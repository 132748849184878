import axios from 'axios';

import { http } from '../_helpers';

const URL = http.BASE_URL + '/api';

export const authenticationService = {
  login,
  logout
};

function login(username, password) {
    const url = `${URL}/login`;
    try {
        return axios.post(url, {
                username: username,
                password: password
            },{
                headers: http.HEADERS,
                withCredentials: true
            }
        ).then(response => {
            if (!response.status === 200) {
                return Promise.reject(response.statusText);
            }

            return;
        }).catch(result=> {
            return http.handleHttpError(result, 401, 'Wrong username or password');
        });
    } catch (exception) {
        console.error("!!!!! exception on login : " + exception);
    }


}

function logout() {
    const url = `${URL}/logout`;
    return axios.post(url, {}, {
        headers: http.HEADERS,
        withCredentials: true
    }).catch(result=> {
        return http.handleHttpError(result);
    });
}
