import React from 'react';

export class SubmitButton extends React.Component {

    render() {
        var { updating, label, labelLoading, className, type, disabled } = this.props;
        if (!type) {
            type = "submit";
        }
        if (updating) {
            return (<span type={type} className={`btn btn-primary ${className}`} disabled>{labelLoading} <i className="fa fa-circle-o-notch fa-spin"></i></span>);
        } else if (disabled) {
            return (<button type={type} className={`btn btn-primary ${className}`} disabled>{label}</button>);
        } else{
            return (<button type={type} className={`btn btn-primary ${className}`}>{label}</button>);
        }
    }
}
