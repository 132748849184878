import { history } from './';

export const http = {
    handleServiceError,
    handleHttpError,
    BASE_URL:process.env.REACT_APP_API_URL,

    CONTENT_URL: process.env.REACT_APP_CONTENT_URL,
    HEADERS: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache',
        'X-Requested-With' :'XMLHttpRequest'
    }
};

function handleServiceError(error, forceProvidedMessage) {
    var message = error;
    if (error.description) {
        message = error.description;
    }
    if (!forceProvidedMessage) {
        if (error.number === 401 || (error.number === 403 && error.description === 'notAuthorised')) {
            message = 'Sorry but you are not logged in';
            history.push('/login');
        }
        if (error.number === 503) {
            message = 'Sorry but the server is down for maintenance. Please try again later';
            history.push('/login');
        }
    }
    return message;
}

function handleHttpError(result, overrideNumber, overrideMessage) {
    if (result.response) {
        var message = result.response.statusText;
        if (result.response.data && result.response.data.generalErrors) {
            message = result.response.data.generalErrors[0].errorDescription;
        }
        if (!message) {
            message = 'Whoops ... This looks like some unknown server error. Please try again and if the error persist then please contact site admin at admin@euromassage.co.nz. Sorry for any inconvinience!'
        }
        if (overrideNumber && overrideNumber === result.response.status) {
            message = overrideMessage;
        }
        return Promise.reject({
            number: result.response.status,
            description: message
        });
    } else {
        return Promise.reject({
            number: 503,
            description: 'Sorry but the server is down for maintenance. Please try again later'
        });
    }
}
