import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/en-nz';

import { userActions, slotGroupActions } from '../_actions';

class BookingTherapistLeaveDetailsPage extends React.Component {

    constructor(props) {
        super(props);

        this.handleBackClick = this.handleBackClick.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
    }


    componentDidMount() {
        if (!this.props.loggedAs) {
            this.props.history.push("/login");
        }
        if (!this.props.reservation.selectedEvent) {
            this.props.history.push("/bookingCalendar");
        }
    }

    handleBackClick() {
        if (this.props.reservation.selectedEvent) {
            this.props.dispatch(slotGroupActions.resetEvent());
        }
        this.props.history.push("/bookingCalendar");
    }

    handleDeleteClick() {
        var slotGroup = this.props.reservation.selectedEvent;
        if (slotGroup) {
            this.props.dispatch(userActions.removeLeave(slotGroup, '/bookingCalendar'));
        }
    }

    render() {
        const { reservation, loggedAs } = this.props;
        var selectedEvent = reservation.selectedEvent;
        var canDeleteLeave = false;
        if (selectedEvent) {
            if (selectedEvent.therapistId === loggedAs.id ||
                (loggedAs && loggedAs.permissions && loggedAs.permissions.includes('canDeleteLeave'))) {
                canDeleteLeave = true;
            }
            return (
                <div className="col-12 col-lg-6">
                    <h2 className="text-center">Selected leave</h2>
                    <div className="card card-primary card-transparent">
                        <div className="card-body">
                                <div className="form-group row">
                                    <label className="col-sm-2 col-form-label" htmlFor="therapist">Therapist</label>
                                    <div className="col-sm-10">
                                        <input type="text" className="form-control-plaintext font-weight-bold" name="therapist" value={selectedEvent.therapist} readonly/>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-2 col-form-label" htmlFor="dateTimeFrom">From</label>
                                    <div className="col-sm-10">
                                        <input type="text" className="form-control-plaintext font-weight-bold" name="dateTimeFrom" value={moment(selectedEvent.start).format('dddd, MMMM Do YYYY, H:mm:ss a')} readonly/>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-2 col-form-label" htmlFor="dateTimeTo">To</label>
                                    <div className="col-sm-10">
                                        <input type="text" className="form-control-plaintext font-weight-bold" name="dateTimeTo" value={moment(selectedEvent.end).format('dddd, MMMM Do YYYY, H:mm:ss a')} readonly/>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <button type="button" className="btn btn-secondary mr-1" onClick={this.handleBackClick}>Back</button>
                                    { canDeleteLeave ? <button className="btn btn-danger mr-1" onClick={this.handleDeleteClick}>Delete</button> : null }
                                </div>

                        </div>
                    </div>
                </div>

            );
        } else {
            return ("");
        }
    }
}

function mapStateToProps(state) {
    const { loggedAs } = state.user;
    const { reservation } = state;

    return {
        loggedAs,
        reservation
    };
}

const connectedBookingTherapistLeaveDetailsPage = connect(mapStateToProps)(BookingTherapistLeaveDetailsPage);
export { connectedBookingTherapistLeaveDetailsPage as BookingTherapistLeaveDetailsPage };
