import { statisticsConstants } from '../_constants';
import { statisticsService } from '../_services';
import { alertActions } from './';
import { history, http, formatting } from '../_helpers';

export const statisticsActions = {
    getGeneral,
    getSpecific,
    resetSpecific
};

function getGeneral() {
    return dispatch => {
        dispatch(request());

        statisticsService.getStatistics(null, null, 0)
            .then(
                statistics => {
                    dispatch(success(statistics));
                    dispatch(alertActions.clear());
                    history.push('/');
                },
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request() { return { type: statisticsConstants.GENERAL_REQUEST } }
    function success(generalStatistics) { return { type: statisticsConstants.GENERAL_SUCCESS, generalStatistics } }
    function failure(message) { return { type: statisticsConstants.GENERAL_FAILURE, message } }
}

function getSpecific(from, to) {
    var tick = 6; //Calendar.DAY_OF_YEAR
    const days = to.diff(from, 'days');
    if (days <= 30) {
        tick = 6; //Calendar.DAY_OF_YEAR
    } else if (days > 30 && days < 210) {
        tick = 3; //Calendar.WEEK_OF_YEAR
    } else if (days >= 210 && days < 1095) {
        tick = 2; //Calendar.MONTH
    }
    return dispatch => {
        dispatch(request());
        statisticsService.getStatistics(from.format(formatting.DATE_TIME_FORMATTING), to.format(formatting.DATE_TIME_FORMATTING), tick)
            .then(
                statistics => {
                    dispatch(success(statistics));
                    dispatch(alertActions.clear());
                },
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request() { return { type: statisticsConstants.SPECIFIC_REQUEST } }
    function success(specificStatistics) { return { type: statisticsConstants.SPECIFIC_SUCCESS, specificStatistics } }
    function failure(message) { return { type: statisticsConstants.SPECIFIC_FAILURE, message } }
}

function resetSpecific() {
    return dispatch => {
        dispatch(reset());
    }

    function reset() { return { type: statisticsConstants.RESETSPECIFIC_SUCCESS } }
}
