import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/en-nz';
import { formatting } from '../_helpers';

import { alertActions, slotGroupActions, reservationActions } from '../_actions';
import { SubmitButton } from '../_components';

import { BookingCalendarComponent, Basket } from '.';

class BookingPage2 extends React.Component {

    constructor(props) {
        super(props);
        this.handleReserve = this.handleReserve.bind(this);
        this.handleAddToBasket = this.handleAddToBasket.bind(this);
        this.handleResetBasket = this.handleResetBasket.bind(this);
    }

    componentDidMount() {
        if (!this.props.loggedAs) {
            this.props.history.push("/login");
        } else if (!this.props.reservation.selectedPrices
          || this.props.reservation.selectedPrices.length === 0) {
              this.props.history.push("/booking");
        }
    }

    componentDidUpdate() {
      if (!this.props.loggedAs) {
          this.props.history.push("/login");
      }
      if (!this.props.reservation.selectedPrices
          || this.props.reservation.selectedPrices.length === 0) {
              this.props.history.push("/booking");
      }
    }

    handleAddToBasket() {
        this.props.dispatch(reservationActions.addToBasket(this.props.reservation.selectedPrice, this.props.reservation.selectedPrices, this.props.reservation.selectedTherapist));
        this.props.dispatch(reservationActions.resetGroup());
    }

    handleResetBasket() {
        this.props.dispatch(reservationActions.resetBasket());
    }

    handleReserve(event) {
        event.preventDefault();
        var form = document.getElementById('bookingForm');
        const { dispatch, reservation } = this.props;
        if (form.checkValidity() === false
            || !reservation.selectedPrices
            || reservation.selectedPrices.length === 0
            || !reservation.selectedEvent
            || reservation.selectedBasketTherapist) {
            event.stopPropagation();
        }
        form.classList.add('was-validated')

        dispatch(alertActions.clear());
        var prices = [];
        reservation.selectedPrices.forEach(price => {
            prices.push(price.id);
        });

        var calendarEvent = {
            id: -1,
            start: moment(reservation.selectedEvent.start).format(formatting.DATE_TIME_FORMATTING),
            stateCode: 'reserved',
            therapistId: reservation.selectedBasketTherapist.id,
            priceIds: prices,
            roomId: reservation.selectedEvent.roomId
        };
        dispatch(slotGroupActions.reserve(calendarEvent));

    }

    render() {
        const { slotGroup, reservation } = this.props;
        var booking = slotGroup.booking;
        var selectedPrices = reservation.selectedPrices;
        var selectedBasketTherapist = reservation.selectedBasketTherapist
        var selectedEvent = reservation.selectedEvent;
        return (
            <div className="col-12 col-lg-10">
                <h2 className="text-center">Book your next treatment</h2>
                <div className="card card-primary card-transparent">
                    <div className="card-body">
                        <form id="bookingForm" className="needs-validation my-4" noValidate onSubmit={this.handleReserve}>
                            {selectedPrices && selectedPrices.length > 0 && <div className="form-group row">
                                    <h5 className="col-sm-3 py-2">Booking :</h5>
                                    <Basket items={selectedPrices} therapist={selectedBasketTherapist}/>
                                </div>
                            }
                            <hr className="bg-secondary"/>
                            <BookingCalendarComponent/>
                            <hr className="bg-secondary"/>
                            {selectedPrices && selectedPrices.length > 0 && selectedBasketTherapist &&
                                <div className="form-group align-items-center">
                                    {selectedEvent &&
                                        <SubmitButton updating={booking} label="Reserve treatment" labelLoading="Reserving ..." className="col-md-4 col-xl-3 mr-2 mb-2"/>
                                    }
                                    <button type="button" className="btn btn-danger col-md-3 col-xl-2 mr-2 mb-2" onClick={this.handleResetBasket}>Reset booking</button>
                                </div>
                            }
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { loggedAs } = state.user;
    const { treatmentGroup, slotGroup, reservation } = state;
    return {
        loggedAs,
        treatmentGroup,
        slotGroup,
        reservation
    };
}

const connectedBookingPage2 = connect(mapStateToProps)(BookingPage2);
export { connectedBookingPage2 as BookingPage2 };
