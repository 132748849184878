import React from 'react';
import { connect } from 'react-redux';

import moment from 'moment';
import 'moment/locale/en-nz';

import { userActions } from '../_actions';
import { SubmitButton, DateField } from '../_components';

class CustomerCreatePage extends React.Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleOnDateOfBirthChanged = this.handleOnDateOfBirthChanged.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleBackClick = this.handleBackClick.bind(this);

        this.state = {
            user: {
                mailer: true
            }
		};

    }

    componentDidMount() {
        if (!this.props.loggedAs) {
            this.props.history.push("/login");
        }
    }

    componentDidUpdate() {
        if (!this.props.loggedAs) {
            this.props.history.push("/login");
        }
    }

    handleChange(event) {
        const { name } = event.target;
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });
    }

    handleOnDateOfBirthChanged(date) {
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                dateOfBirth: date == null ? null : date.format("YYYY-MM-DD")
            }
        });
    }

    handleBackClick() {
        this.props.dispatch(userActions.resetCustomer());
    }

    handleSubmit(event) {
        event.preventDefault();
        var form = document.getElementById('detailsForm');
        if (form.checkValidity() === false) {
            form.classList.add('was-validated')
            event.stopPropagation();
            return;
        }

        this.setState({ submitted: true });
        const { user } = this.state;
        const { dispatch } = this.props;
        if (user.firstName && user.surname) {
            dispatch(userActions.createCustomer(user));
        }
    }

    render() {
        const { creating } = this.props;
        const { user } = this.state;
        return (
            <div className="col-12 col-lg-8">
                <h2 className="text-center">Create new customer</h2>
                <div className="card card-primary card-transparent">
                    <div className="card-body">
                        <form id="detailsForm" className="needs-validation my-4" noValidate onSubmit={this.handleSubmit}>
                            <div className="form-group row">
                                <label className="col-sm-4 col-md-3 col-xl-2 col-form-label" htmlFor="firstName">First Name</label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control" name="firstName" value={user.firstName} required onChange={this.handleChange}/>
                                    <div className="invalid-feedback">
                                        Please enter first name
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-md-3 col-xl-2 col-form-label" htmlFor="surname">Surname</label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control" name="surname" value={user.surname} required onChange={this.handleChange}/>
                                    <div className="invalid-feedback">
                                        Please enter surname
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-md-3 col-xl-2 col-form-label" htmlFor="email">Email</label>
                                <div className="col-sm-8">
                                    <input type="email" className="form-control" name="email" value={user.email} onChange={this.handleChange}/>
                                    <div className="invalid-feedback">
                                        Please enter email
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-md-3 col-xl-2 col-form-label" htmlFor="cellPhone">Mobile</label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control" name="cellPhone" value={user.cellPhone} onChange={this.handleChange}/>
                                    <div className="invalid-feedback">
                                        Please enter mobile
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-md-3 col-xl-2 col-form-label" htmlFor="street">Street</label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control" name="street" value={user.street} onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-md-3 col-xl-2 col-form-label" htmlFor="suburb">Suburb</label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control" name="suburb" value={user.suburb} onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-md-3 col-xl-2 col-form-label" htmlFor="city">City</label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control" name="city" value={user.city} onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-md-3 col-xl-2 col-form-label" htmlFor="dateOfBirth">Date of birth</label>
                                <div className="col-sm-8">
                                    <DateField
                                        id="dateOfBirth"
                                        dateTime={user.dateOfBirth ? moment(user.dateOfBirth) : null}
                                        onChange={this.handleOnDateOfBirthChanged}
                                        required={false}
                                        />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-sm-4 col-md-3 col-xl-2 col-form-label"  htmlFor="note">Note</label>
                                <div className="col-sm-8">
                                    <textarea className="form-control" name="note" value={user.note} onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-4 col-md-3 col-xl-2 col-form-label" >Extra stuff</label>
                                <div className="col-8 pt-2">
                                    <input className="form-check-input ml-2" type="checkbox" checked={user.mailer} name="mailer" onChange={this.handleChange}/>
                                    <label className="form-check-label ml-5" htmlFor="mailer">Send mailer</label>
                                </div>
                            </div>
                            <div className="form-group row align-items-center ml-1">
                                <button type="button" className="btn btn-secondary col-md-2 col-xl-1 mr-2 mb-2" onClick={this.handleBackClick}>Back</button>
                                <SubmitButton updating={creating} className="col-md-3 col-xl-2 mr-2 mb-2" label="Create" labelLoading="Creating ..."/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { selectedCustomer, updating, loggedAs, resettingPasswordByAdmin } = state.user;
    return {
        updating,
        selectedCustomer,
        loggedAs,
        resettingPasswordByAdmin
    };
}

const connectedCustomerCreatePage = connect(mapStateToProps)(CustomerCreatePage);
export { connectedCustomerCreatePage as CustomerCreatePage };
