import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { alertActions, userActions } from '../_actions';
import { SubmitButton } from '../_components';

class UpdatePasswordPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            password: {
                old: '',
                new: '',
                confirm: ''
            }
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const { name, value } = event.target;
        const { password } = this.state;
        this.setState({
            password: {
                ...password,
                [name]: value
            }
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        var form = document.getElementById('updatePasswordForm');
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        form.classList.add('was-validated');

        this.setState({ submitted: true });
        const { password } = this.state;
        const { dispatch } = this.props;
        dispatch(alertActions.clear());
        if (password.old && password.new && password.confirm) {
            dispatch(userActions.updatePassword(password));
        }
    }

    render() {
        const { updating } = this.props;
        const { password } = this.state;
        return (
            <div className="col-lg-8">
                <h2 className="text-center">Update your password</h2>
                <div className="card card-primary card-transparent">
                    <div className="card-body">
                        <form id="updatePasswordForm" noValidate className="needs-validation my-4" onSubmit={this.handleSubmit}>
                            <div className="form-group row">
                                <label className="col-xl-5 col-form-label" htmlFor="old">Please enter your current password</label>
                                <div className="col-xl-7">
                                    <input type="password" className="form-control" required name="old" value={password.old} onChange={this.handleChange} />
                                    <div className="invalid-feedback">
                                        Please enter your current password
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-xl-5 col-form-label" htmlFor="new">Enter your new password</label>
                                <div className="col-xl-7">
                                    <input type="password" className="form-control" required name="new" value={password.new} onChange={this.handleChange} />
                                    <div className="invalid-feedback">
                                        Please enter your new password
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-xl-5 col-form-label" htmlFor="confirm">Re-enter your new password</label>
                                <div className="col-xl-7">
                                    <input type="password" className="form-control" required name="confirm" value={password.confirm} onChange={this.handleChange} />
                                    <div className="invalid-feedback">
                                        Please re-enter your new password
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row align-items-center ml-1">
                                <Link to="/profile" className="col-md-3 col-xl-2 m-0 pl-0 pr-2 nav-link">
                                    <button type="button" className="btn btn-secondary btn-block mb-2">Cancel</button>
                                </Link>
                                <SubmitButton updating={updating} label="Update" labelLoading="Updating ..." className="col-md-3 col-xl-2 mr-2 mb-2"/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { loggedAs } = state.user;
    const { updating } = state.user;
    return {
        updating,
        loggedAs
    };
}

const connectedUpdatePasswordPage = connect(mapStateToProps)(UpdatePasswordPage);
export { connectedUpdatePasswordPage as UpdatePasswordPage };
