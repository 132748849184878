import React from 'react';

import { Price } from '.';

export class Prices extends React.Component {

    render() {
        const { prices } = this.props;

        if (prices && prices.length > 0) {
            return (
                <div className="card-columns">
                    {
                        prices.map((price, index) =>
                            <Price key={"Price_" + price.id} price={price}/>
                        )
                    }
                </div>
            );
        } else {
            return (
                <div className="form-group row">
                    <footer className="blockquote-footer col-sm-12">No prices found</footer>
                </div>
            );
        }
    }
}
