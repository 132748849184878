import React from 'react';
import { connect } from 'react-redux';

import { Modal } from '../_components';

import { financeActions } from '../_actions';

class Transaction extends React.Component {

    constructor(props) {
        super(props);

        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);
        this.hideModal = this.hideModal.bind(this);
        if (props.highlight) {
            this.removeHighlight = this.removeHighlight.bind(this);
            this.transitionHighlight = this.transitionHighlight.bind(this);
            this.startTimerRemoval = this.startTimerRemoval.bind(this);
            this.startTimerTransition = this.startTimerTransition.bind(this);
        }

        this.state = {
			modal: false,
            highlight: props.highlight,
		};
    }

    startTimerRemoval() {
        this.timerID1 = setInterval(
            () => this.removeHighlight(),
            4000
        );
    }
    startTimerTransition() {
        this.timerID2 = setInterval(
            () => this.transitionHighlight(),
            2000
        );
    }

    componentWillUnmount() {
        clearInterval(this.timerID1);
        clearInterval(this.timerID2);
    }

    removeHighlight() {
        this.setState({highlight: false});
        clearInterval(this.timerID1);
    }

    transitionHighlight() {
        let row = document.getElementsByClassName('edited-transaction');
        if (row && row[0]) {
            row[0].style.backgroundColor = 'transparent';
            clearInterval(this.timerID2);
        }
    }

    handleEditClick() {
        var transaction = this.props.transaction;
        if (transaction) {
            this.props.dispatch(financeActions.selectFinancialTransaction(transaction));
        }
    }

    handleDeleteClick() {
        this.setState({ modal: true })
    }

    handleDeleteConfirm() {
        var transaction = this.props.transaction;
        if (transaction) {
            this.props.dispatch(financeActions.deleteFinancialTransaction(transaction));
        }
    }

    hideModal() {
        this.setState({ modal: false })
    }

    render() {
        const { transaction, deletingFinancialTransaction } = this.props;
        const { modal, highlight } = this.state;
        transaction.ref = React.createRef();

        var styleClassHighlight = '';
        if (highlight) {
            styleClassHighlight = "edited-transaction";
            this.startTimerTransition();
            this.startTimerRemoval();
        }

        if (transaction) {
            return (
                <tr className={styleClassHighlight} ref={transaction.ref}>
                    <td>{transaction.date.format('DD/MM/YYYY')}</td>
                    <td>{transaction.amount ? transaction.amount.toLocaleString('en-NZ', {style: 'currency', currency: 'NZD'}) : 'unknown'}</td>
                    <td><small>{transaction.note}; {transaction.transactionCategoryLabel}</small></td>
                    <td>
                        <button type="button" className="btn btn-success btn-sm py-0 mr-1 mb-1" style={{"fontSize": "0.8em"}} onClick={this.handleEditClick}>
                            Edit
                        </button>
                        {transaction.deletable && <button type="button" className="btn btn-danger btn-sm py-0 mr-1 mb-1" style={{"fontSize": "0.8em"}} onClick={this.handleDeleteClick} disabled={deletingFinancialTransaction ? 'disabled' : ''}>{deletingFinancialTransaction ? 'Deleting ...' : 'Delete'}</button>}
                        {modal &&
                            <Modal id="deleteConfirmation"
                                title="Delete confirmation"
                                body= "Are you sure you want to delete the transaction."
                                primaryButtonLabel="Yes"
                                primaryButtonLabelWait="Deleting..."
                                primaryFunction={this.handleDeleteConfirm}
                                primaryFunctionWait={deletingFinancialTransaction}
                                primaryButtonClass="btn-danger"
                                cancelFunction={this.hideModal}/>
                        }
                    </td>
                </tr>
            );
        } else {
            return "";
        }
    }
}

function mapStateToProps(state) {
    const { deletingFinancialTransaction } = state.finance;
    return {
        deletingFinancialTransaction
    };
}

const connectedTransaction = connect(mapStateToProps)(Transaction);
export { connectedTransaction as Transaction };
