import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { userActions } from '../_actions';
import { SubmitButton } from '../_components';

class ProfilePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {
                id: props.loggedAs.id,
                firstName: props.loggedAs.firstName,
                surname: props.loggedAs.surname,
                email: props.loggedAs.email,
                homePhone: props.loggedAs.homePhone,
                cellPhone: props.loggedAs.cellPhone,
                street: props.loggedAs.street,
                suburb: props.loggedAs.suburb,
                city: props.loggedAs.city
            }
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const { name, value } = event.target;
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        var form = document.getElementById('profileForm');
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        form.classList.add('was-validated')

        const { user } = this.state;
        const { dispatch } = this.props;
        if (user.firstName && user.surname && user.email && user.cellPhone) {
            dispatch(userActions.updateLoggedInUser(user));
        }
    }

    render() {
        const { updating } = this.props;
        const { user } = this.state;
        return (
            <div className="col-lg-8">
                <h2 className="text-center">Update your profile information</h2>
                <div className="card card-primary card-transparent">
                    <div className="card-body">
                        <form id="profileForm" className="needs-validation my-4" noValidate onSubmit={this.handleSubmit}>
                            <div className="form-row">
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="firstName">First Name</label>
                                    <input type="text" className="form-control" name="firstName" value={user.firstName} required onChange={this.handleChange}/>
                                    <div className="invalid-feedback">
                                        Please enter your first name
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="surname">Family Name</label>
                                    <input type="text" className="form-control" name="surname" value={user.surname} required onChange={this.handleChange}/>
                                    <div className="invalid-feedback">
                                        Please enter your family name
                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="email">Email</label>
                                    <input type="email" className="form-control" name="email" value={user.email} required onChange={this.handleChange}/>
                                    <div className="invalid-feedback">
                                        Please enter your email
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="cellPhone">Mobile</label>
                                    <input type="text" className="form-control" name="cellPhone" value={user.cellPhone} required onChange={this.handleChange}/>
                                    <div className="invalid-feedback">
                                        Please enter your mobile
                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-4 mb-3">
                                    <label htmlFor="street">Street</label>
                                    <input type="text" className="form-control" name="street" value={user.street} onChange={this.handleChange} />
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label htmlFor="suburb">Suburb</label>
                                    <input type="text" className="form-control" name="suburb" value={user.suburb} onChange={this.handleChange} />
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label htmlFor="city">City</label>
                                    <input type="text" className="form-control" name="city" value={user.city} onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="form-row">

                            </div>
                            <div className="form-group row align-items-center ml-1">
                                <Link to="/" className="col-md-3 col-xl-2 m-0 pl-0 pr-2 nav-link">
                                    <button type="button" className="btn btn-secondary btn-block mb-2">Cancel</button>
                                </Link>
                                <SubmitButton updating={updating} label="Update" labelLoading="Updating ..." className="col-md-3 col-xl-2 mr-2 mb-2"/>
                                <Link to="/profile/updatePassword" className="col-md-4 col-xl-3 m-0 pl-0 pr-2 nav-link">
                                    <button type="button" className="btn btn-warning btn-block mb-2">Change password</button>
                                </Link>
                                <Link to="/profile/updateSecurityQuestion" className="col-md-5 col-xl-4 m-0 pl-0 pr-2 nav-link">
                                    <button type="button" className="btn btn-warning btn-block mb-2">Change security question</button>
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { loggedAs } = state.user;
    const { updating } = state.user;
    return {
        updating,
        loggedAs
    };
}

const connectedProfilePage = connect(mapStateToProps)(ProfilePage);
export { connectedProfilePage as ProfilePage };
