import React from 'react';
import { connect } from 'react-redux';

import {Therapists} from '.';

import { userActions } from '../_actions';

class TherapistsPage extends React.Component {

    componentDidMount() {
        if (!this.props.loggedAs) {
            this.props.history.push("/login");
        }
        this.props.dispatch(userActions.getTherapists());
    }

    componentDidUpdate() {
        if (!this.props.loggedAs) {
            this.props.history.push("/login");
        }

    }

    render() {
        const { user } = this.props;
        return (
            <div className="col-lg-10">
                <h2 className="text-center">Therapists</h2>
                <div className="card card-primary card-transparent">
                    <div className="card-body">
                        <form id="customersForm" noValidate className="needs-validation my-4" onSubmit={this.handleSubmit}>
                            <Therapists therapists={user.therapists} searching={user.requestingTherapists}/>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { loggedAs } = state.user;
    const { user } = state;

    return {
        loggedAs,
        user
    };
}

const connectedTherapistsPage = connect(mapStateToProps)(TherapistsPage);
export { connectedTherapistsPage as TherapistsPage };
