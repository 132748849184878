import axios from 'axios';

import { http } from '../_helpers';

const URL = http.BASE_URL + '/api/places';

export const placeService = {
    getPlaces,
    getRooms
};

function getPlaces() {
    const url = `${URL}`;
    return axios.get(url, {
        headers: http.HEADERS,
        withCredentials: true
    })
    .then(response => {
        if (response && response.data) {
            return response.data.placeViewList;
        }
    })
    .catch(result=> {
        return http.handleHttpError(result);
    });
}

function getRooms(placeId) {
    const url = `${URL}/` + placeId +`/rooms`;
    return axios.get(url, {
        headers: http.HEADERS,
        withCredentials: true
    })
    .then(response => {
        if (response && response.data) {
            return response.data.roomViewList;
        }
    })
    .catch(result=> {
        return http.handleHttpError(result);
    });
}
