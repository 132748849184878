import React from 'react';
import { connect } from 'react-redux';

import moment from 'moment';
import 'moment/locale/en-nz';
import { formatting } from '../_helpers';

import { userActions } from '../_actions';

class SlotGroup extends React.Component {

    constructor(props) {
        super(props);

        this.handleSlotGroupDetailClick = this.handleSlotGroupDetailClick.bind(this);
    }

    handleSlotGroupDetailClick() {
        this.props.dispatch(userActions.viewSlotGroup(this.props.slotGroup));
    }

    render() {
        const { slotGroup } = this.props;
        if (slotGroup) {
            return(
                <div className="row mx-0 d-flex align-items-center border-bottom">
                    <div className="col-3 px-2">
                        {(slotGroup.start && slotGroup.start !== "null") ? moment(slotGroup.start).locale(formatting.CULTURE).format('D/M/YYYY h:mA') : "Unknown date"}
                    </div>
                    <div className="col-3 px-2">
                        {slotGroup.title}
                    </div>
                    <div className="col-2 px-2">
                        {slotGroup.totalPrice}
                    </div>
                    <div className="col-2 px-2">
                        {slotGroup.totalDuration}
                    </div>
                    <button type="button" className="btn btn-info btn-sm col-2 px-2 my-1" onClick={this.handleSlotGroupDetailClick}>Edit</button>
                </div>
            );
        } else {
            return "";
        }
    }
}

function mapStateToProps(state) {
    return {};
}

const connectedSlotGroup = connect(mapStateToProps)(SlotGroup);
export { connectedSlotGroup as SlotGroup };
