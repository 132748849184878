import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { store } from './_helpers';

import { App } from './App';

// setup fake backend
// import { configureFakeBackend } from './_helpers';
// configureFakeBackend();


const Root = () => {
  return (
    <Provider store={store}>
        <App />
    </Provider>
  )
}

ReactDOM.render(<Root />, document.getElementById('app'));
