import React from 'react';
import { connect } from 'react-redux';

import moment from 'moment';
import 'moment/locale/en-nz';

import { Holidays } from '.';
import { userActions } from '../_actions';
import { SubmitButton, DateField } from '../_components';

class TherapistEditPage extends React.Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeWorkingHour = this.handleChangeWorkingHour.bind(this);
        this.handleOnDateOfBirthChanged = this.handleOnDateOfBirthChanged.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleBackClick = this.handleBackClick.bind(this);
        this.handleResetPasswordClick = this.handleResetPasswordClick.bind(this);

        if (!props.selectedTherapist) {
            this.state = {
                user: {},
                submitted: false
            };
            this.props.history.push("/therapists");
        } else {
            this.state = {
                user: {
                    id: props.selectedTherapist.id,
                    firstName: props.selectedTherapist.firstName,
                    surname: props.selectedTherapist.surname,
                    email: props.selectedTherapist.email,
                    homePhone: props.selectedTherapist.homePhone,
                    mobilePhoneNumber: props.selectedTherapist.mobilePhoneNumber,
                    street: props.selectedTherapist.street,
                    suburb: props.selectedTherapist.suburb,
                    city: props.selectedTherapist.city,
                    totalSpent: props.selectedTherapist.totalSpent,
                    lastVisit: props.selectedTherapist.lastVisit,
                    dateOfBirth: props.selectedTherapist.dateOfBirth,
                    mailer: props.selectedTherapist.mailer,
                    monday: props.selectedTherapist.monday,
                    tuesday: props.selectedTherapist.tuesday,
                    wednesday: props.selectedTherapist.wednesday,
                    thursday: props.selectedTherapist.thursday,
                    friday: props.selectedTherapist.friday,
                    saturday: props.selectedTherapist.saturday,
                    sunday: props.selectedTherapist.sunday,
                },
                submitted: false
    		};
        }
    }

    componentDidMount() {
        if (!this.props.loggedAs) {
            this.props.history.push("/login");
        }
        if (!this.props.selectedTherapist || !this.state.user) {
            this.props.history.push("/therapists");
        }
    }

    componentDidUpdate() {
        if (!this.props.loggedAs) {
            this.props.history.push("/login");
        }
        if (!this.props.selectedTherapist || !this.state.user) {
            this.props.history.push("/therapists");
        }
    }

    handleChange(event) {
        const { name } = event.target;
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });
    }

    handleChangeWorkingHour(event) {
        const { name } = event.target;
        const names = name.split("_");
        const day = names[0];
        const unit = names[1];
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        const { user } = this.state;
        user[day][unit] = value;
        this.setState({user});
    }

    handleOnDateOfBirthChanged(date) {
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                dateOfBirth: date == null ? null : date.format("YYYY-MM-DD")
            }
        });
    }

    handleResetPasswordClick() {
        this.props.dispatch(userActions.resetPasswordByAdmin(this.state.user));
    }

    handleBackClick() {
        this.props.dispatch(userActions.resetTherapist());
    }

    handleSubmit(event) {
        event.preventDefault();
        var form = document.getElementById('detailsForm');
        if (form.checkValidity() === false) {
            form.classList.add('was-validated')
            event.stopPropagation();
            return;
        }

        this.setState({ submitted: true });
        const { user } = this.state;
        const { dispatch } = this.props;
        if (user.firstName && user.surname) {
            dispatch(userActions.updateTherapist(user));
        }
    }

    render() {
        const { updating, resettingPasswordByAdmin, selectedTherapist, leaveViews } = this.props;
        const { user } = this.state;
        return (
            <div className="col-lg-8">
                <h2 className="text-center">Update therapist information</h2>
                <div className="card card-primary card-transparent">
                    <div className="card-body">
                        <form id="detailsForm" className="needs-validation my-4" noValidate onSubmit={this.handleSubmit}>
                            <div className="form-group row">
                                <label className="col-4 col-md-3 col-xl-2 col-form-label" htmlFor="id">Id</label>
                                <div className="col-8">
                                    <input type="text" className="form-control-plaintext font-weight-bold" name="id" value={user.id} readOnly/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-md-3 col-xl-2 col-form-label" htmlFor="firstName">First Name</label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control" name="firstName" value={user.firstName} required onChange={this.handleChange}/>
                                    <div className="invalid-feedback">
                                        Please enter first name
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-md-3 col-xl-2 col-form-label" htmlFor="surname">Surname</label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control" name="surname" value={user.surname} required onChange={this.handleChange}/>
                                    <div className="invalid-feedback">
                                        Please enter surname
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-md-3 col-xl-2 col-form-label" htmlFor="email">Email</label>
                                <div className="col-sm-8">
                                    <input type="email" className="form-control" name="email" value={user.email} onChange={this.handleChange}/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-md-3 col-xl-2 col-form-label" htmlFor="mobilePhoneNumber">Mobile</label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control" name="mobilePhoneNumber" value={user.mobilePhoneNumber} onChange={this.handleChange}/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-md-3 col-xl-2 col-form-label" htmlFor="street">Street</label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control" name="street" value={user.street} onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-md-3 col-xl-2 col-form-label" htmlFor="suburb">Suburb</label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control" name="suburb" value={user.suburb} onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-md-3 col-xl-2 col-form-label" htmlFor="city">City</label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control" name="city" value={user.city} onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-4 col-md-3 col-xl-2 col-form-label" htmlFor="dateOfBirth">Date of birth</label>
                                <div className="col-8">
                                    <DateField
                                        id="dateOfBirth"
                                        dateTime={user.dateOfBirth ? moment(user.dateOfBirth) : null}
                                        onChange={this.handleOnDateOfBirthChanged}
                                        required={false}
                                        />
                                </div>
                            </div>
                            <div className="row">
                                <label className="col-sm-4 col-md-3 col-xl-2 col-form-label"  htmlFor="note">Working hours</label>
                                <div className="col-sm-8">
                                    <table className="table table-sm rotate">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="rotate"></th>
                                                <th scope="col" className="rotate"><div><span>Monday</span></div></th>
                                                <th scope="col" className="rotate"><div><span>Tuesday</span></div></th>
                                                <th scope="col" className="rotate"><div><span>Wednesday</span></div></th>
                                                <th scope="col" className="rotate"><div><span>Thursday</span></div></th>
                                                <th scope="col" className="rotate"><div><span>Friday</span></div></th>
                                                <th scope="col" className="rotate"><div><span>Saturday</span></div></th>
                                                <th scope="col" className="rotate"><div><span>Sunday</span></div></th>
                                            </tr>

                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">07:00-07:30</th>
                                                <td><input type="checkbox" id="mondayUnit0700" checked={user.monday.unit0700} name="monday_unit0700" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="tuesdayUnit0700" checked={user.tuesday.unit0700} name="tuesday_unit0700" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="wednesdayUnit0700" checked={user.wednesday.unit0700} name="wednesday_unit0700" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="thursdayUnit0700" checked={user.thursday.unit0700} name="thursday_unit0700" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="fridayUnit0700" checked={user.friday.unit0700} name="friday_unit0700" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="saturdayUnit0700" checked={user.saturday.unit0700} name="saturday_unit0700" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="sundayUnit0700" checked={user.sunday.unit0700} name="sunday_unit0700" onChange={this.handleChangeWorkingHour}/></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">07:30-08:00</th>
                                                <td><input type="checkbox" id="mondayUnit0730" checked={user.monday.unit0730} name="monday_unit0730" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="tuesdayUnit0730" checked={user.tuesday.unit0730} name="tuesday_unit0730" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="wednesdayUnit0730" checked={user.wednesday.unit0730} name="wednesday_unit0730" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="thursdayUnit0730" checked={user.thursday.unit0730} name="thursday_unit0730" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="fridayUnit0730" checked={user.friday.unit0730} name="friday_unit0730" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="saturdayUnit0730" checked={user.saturday.unit0730} name="saturday_unit0730" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="sundayUnit0730" checked={user.sunday.unit0730} name="sunday_unit0730" onChange={this.handleChangeWorkingHour}/></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">08:00-08:30</th>
                                                <td><input type="checkbox" id="mondayUnit0800" checked={user.monday.unit0830} name="monday_unit0800" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="tuesdayUnit0800" checked={user.tuesday.unit0830} name="tuesday_unit0800" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="wednesdayUnit0800" checked={user.wednesday.unit0830} name="wednesday_unit0800" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="thursdayUnit0800" checked={user.thursday.unit0830} name="thursday_unit0800" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="fridayUnit0800" checked={user.friday.unit0830} name="friday_unit0800" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="saturdayUnit0800" checked={user.saturday.unit0830} name="saturday_unit0800" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="sundayUnit0800" checked={user.sunday.unit0830} name="sunday_unit0800" onChange={this.handleChangeWorkingHour}/></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">08:30-09:00</th>
                                                <td><input type="checkbox" id="mondayUnit0830" checked={user.monday.unit0830} name="monday_unit0830" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="tuesdayUnit0830" checked={user.tuesday.unit0830} name="tuesday_unit0830" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="wednesdayUnit0830" checked={user.wednesday.unit0830} name="wednesday_unit0830" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="thursdayUnit0830" checked={user.thursday.unit0830} name="thursday_unit0830" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="fridayUnit0830" checked={user.friday.unit0830} name="friday_unit0830" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="saturdayUnit0830" checked={user.saturday.unit0830} name="saturday_unit0830" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="sundayUnit0830" checked={user.sunday.unit0830} name="sunday_unit0830" onChange={this.handleChangeWorkingHour}/></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">09:00-09:30</th>
                                                <td><input type="checkbox" id="mondayUnit0900" checked={user.monday.unit0900} name="monday_unit0900" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="tuesdayUnit0900" checked={user.tuesday.unit0900} name="tuesday_unit0900" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="wednesdayUnit0900" checked={user.wednesday.unit0900} name="wednesday_unit0900" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="thursdayUnit0900" checked={user.thursday.unit0900} name="thursday_unit0900" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="fridayUnit0900" checked={user.friday.unit0900} name="friday_unit0900" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="saturdayUnit0900" checked={user.saturday.unit0900} name="saturday_unit0900" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="sundayUnit0900" checked={user.sunday.unit0900} name="sunday_unit0900" onChange={this.handleChangeWorkingHour}/></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">09:30-10:00</th>
                                                <td><input type="checkbox" id="mondayUnit0930" checked={user.monday.unit0930} name="monday_unit0930" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="tuesdayUnit0930" checked={user.tuesday.unit0930} name="tuesday_unit0930" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="wednesdayUnit0930" checked={user.wednesday.unit0930} name="wednesday_unit0930" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="thursdayUnit0930" checked={user.thursday.unit0930} name="thursday_unit0930" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="fridayUnit0930" checked={user.friday.unit0930} name="friday_unit0930" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="saturdayUnit0930" checked={user.saturday.unit0930} name="saturday_unit0930" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="sundayUnit0930" checked={user.sunday.unit0930} name="sunday_unit0930" onChange={this.handleChangeWorkingHour}/></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">10:00-10:30</th>
                                                <td><input type="checkbox" id="mondayUnit1000" checked={user.monday.unit1000} name="monday_unit1000" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="tuesdayUnit1000" checked={user.tuesday.unit1000} name="tuesday_unit1000" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="wednesdayUnit1000" checked={user.wednesday.unit1000} name="wednesday_unit1000" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="thursdayUnit1000" checked={user.thursday.unit1000} name="thursday_unit1000" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="fridayUnit1000" checked={user.friday.unit1000} name="friday_unit1000" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="saturdayUnit1000" checked={user.saturday.unit1000} name="saturday_unit1000" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="sundayUnit1000" checked={user.sunday.unit1000} name="sunday_unit1000" onChange={this.handleChangeWorkingHour}/></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">10:30-11:00</th>
                                                <td><input type="checkbox" id="mondayUnit1030" checked={user.monday.unit1030} name="monday_unit1030" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="tuesdayUnit1030" checked={user.tuesday.unit1030} name="tuesday_unit1030" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="wednesdayUnit1030" checked={user.wednesday.unit1030} name="wednesday_unit1030" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="thursdayUnit1030" checked={user.thursday.unit1030} name="thursday_unit1030" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="fridayUnit1030" checked={user.friday.unit1030} name="friday_unit1030" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="saturdayUnit1030" checked={user.saturday.unit1030} name="saturday_unit1030" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="sundayUnit1030" checked={user.sunday.unit1030} name="sunday_unit1030" onChange={this.handleChangeWorkingHour}/></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">11:00-11:30</th>
                                                <td><input type="checkbox" id="mondayUnit1100" checked={user.monday.unit1100} name="monday_unit1100" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="tuesdayUnit1100" checked={user.tuesday.unit1100} name="tuesday_unit1100" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="wednesdayUnit1100" checked={user.wednesday.unit1100} name="wednesday_unit1100" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="thursdayUnit1100" checked={user.thursday.unit1100} name="thursday_unit1100" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="fridayUnit1100" checked={user.friday.unit1100} name="friday_unit1100" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="saturdayUnit1100" checked={user.saturday.unit1100} name="saturday_unit1100" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="sundayUnit1100" checked={user.sunday.unit1100} name="sunday_unit1100" onChange={this.handleChangeWorkingHour}/></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">11:30-12:00</th>
                                                <td><input type="checkbox" id="mondayUnit1130" checked={user.monday.unit1130} name="monday_unit1130" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="tuesdayUnit1130" checked={user.tuesday.unit1130} name="tuesday_unit1130" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="wednesdayUnit1130" checked={user.wednesday.unit1130} name="wednesday_unit1130" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="thursdayUnit1130" checked={user.thursday.unit1130} name="thursday_unit1130" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="fridayUnit1130" checked={user.friday.unit1130} name="friday_unit1130" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="saturdayUnit1130" checked={user.saturday.unit1130} name="saturday_unit1130" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="sundayUnit1130" checked={user.sunday.unit1130} name="sunday_unit1130" onChange={this.handleChangeWorkingHour}/></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">12:00-12:30</th>
                                                <td><input type="checkbox" id="mondayUnit1200" checked={user.monday.unit1200} name="monday_unit1200" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="tuesdayUnit1200" checked={user.tuesday.unit1200} name="tuesday_unit1200" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="wednesdayUnit1200" checked={user.wednesday.unit1200} name="wednesday_unit1200" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="thursdayUnit1200" checked={user.thursday.unit1200} name="thursday_unit1200" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="fridayUnit1200" checked={user.friday.unit1200} name="friday_unit1200" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="saturdayUnit1200" checked={user.saturday.unit1200} name="saturday_unit1200" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="sundayUnit1200" checked={user.sunday.unit1200} name="sunday_unit1200" onChange={this.handleChangeWorkingHour}/></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">12:30-13:00</th>
                                                <td><input type="checkbox" id="mondayUnit1230" checked={user.monday.unit1230} name="monday_unit1230" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="tuesdayUnit1230" checked={user.tuesday.unit1230} name="tuesday_unit1230" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="wednesdayUnit1230" checked={user.wednesday.unit1230} name="wednesday_unit1230" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="thursdayUnit1230" checked={user.thursday.unit1230} name="thursday_unit1230" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="fridayUnit1230" checked={user.friday.unit1230} name="friday_unit1230" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="saturdayUnit1230" checked={user.saturday.unit1230} name="saturday_unit1230" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="sundayUnit1230" checked={user.sunday.unit1230} name="sunday_unit1230" onChange={this.handleChangeWorkingHour}/></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">13:00-13:30</th>
                                                <td><input type="checkbox" id="mondayUnit1300" checked={user.monday.unit1300} name="monday_unit1300" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="tuesdayUnit1300" checked={user.tuesday.unit1300} name="tuesday_unit1300" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="wednesdayUnit1300" checked={user.wednesday.unit1300} name="wednesday_unit1300" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="thursdayUnit1300" checked={user.thursday.unit1300} name="thursday_unit1300" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="fridayUnit1300" checked={user.friday.unit1300} name="friday_unit1300" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="saturdayUnit1300" checked={user.saturday.unit1300} name="saturday_unit1300" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="sundayUnit1300" checked={user.sunday.unit1300} name="sunday_unit1300" onChange={this.handleChangeWorkingHour}/></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">13:30-14:00</th>
                                                <td><input type="checkbox" id="mondayUnit1330" checked={user.monday.unit1330} name="monday_unit1330" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="tuesdayUnit1330" checked={user.tuesday.unit1330} name="tuesday_unit1330" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="wednesdayUnit1330" checked={user.wednesday.unit1330} name="wednesday_unit1330" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="thursdayUnit1330" checked={user.thursday.unit1330} name="thursday_unit1330" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="fridayUnit1330" checked={user.friday.unit1330} name="friday_unit1330" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="saturdayUnit1330" checked={user.saturday.unit1330} name="saturday_unit1330" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="sundayUnit1330" checked={user.sunday.unit1330} name="sunday_unit1330" onChange={this.handleChangeWorkingHour}/></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">14:00-14:30</th>
                                                <td><input type="checkbox" id="mondayUnit1400" checked={user.monday.unit1400} name="monday_unit1400" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="tuesdayUnit1400" checked={user.tuesday.unit1400} name="tuesday_unit1400" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="wednesdayUnit1400" checked={user.wednesday.unit1400} name="wednesday_unit1400" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="thursdayUnit1400" checked={user.thursday.unit1400} name="thursday_unit1400" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="fridayUnit1400" checked={user.friday.unit1400} name="friday_unit1400" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="saturdayUnit1400" checked={user.saturday.unit1400} name="saturday_unit1400" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="sundayUnit1400" checked={user.sunday.unit1400} name="sunday_unit1400" onChange={this.handleChangeWorkingHour}/></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">14:30-15:00</th>
                                                <td><input type="checkbox" id="mondayUnit1430" checked={user.monday.unit1430} name="monday_unit1430" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="tuesdayUnit1430" checked={user.tuesday.unit1430} name="tuesday_unit1430" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="wednesdayUnit1430" checked={user.wednesday.unit1430} name="wednesday_unit1430" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="thursdayUnit1430" checked={user.thursday.unit1430} name="thursday_unit1430" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="fridayUnit1430" checked={user.friday.unit1430} name="friday_unit1430" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="saturdayUnit1430" checked={user.saturday.unit1430} name="saturday_unit1430" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="sundayUnit1430" checked={user.sunday.unit1430} name="sunday_unit1430" onChange={this.handleChangeWorkingHour}/></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">15:00-15:30</th>
                                                <td><input type="checkbox" id="mondayUnit1500" checked={user.monday.unit1500} name="monday_unit1500" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="tuesdayUnit1500" checked={user.tuesday.unit1500} name="tuesday_unit1500" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="wednesdayUnit1500" checked={user.wednesday.unit1500} name="wednesday_unit1500" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="thursdayUnit1500" checked={user.thursday.unit1500} name="thursday_unit1500" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="fridayUnit1500" checked={user.friday.unit1500} name="friday_unit1500" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="saturdayUnit1500" checked={user.saturday.unit1500} name="saturday_unit1500" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="sundayUnit1500" checked={user.sunday.unit1500} name="sunday_unit1500" onChange={this.handleChangeWorkingHour}/></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">15:30-16:00</th>
                                                <td><input type="checkbox" id="mondayUnit1530" checked={user.monday.unit1530} name="monday_unit1530" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="tuesdayUnit1530" checked={user.tuesday.unit1530} name="tuesday_unit1530" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="wednesdayUnit1530" checked={user.wednesday.unit1530} name="wednesday_unit1530" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="thursdayUnit1530" checked={user.thursday.unit1530} name="thursday_unit1530" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="fridayUnit1530" checked={user.friday.unit1530} name="friday_unit1530" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="saturdayUnit1530" checked={user.saturday.unit1530} name="saturday_unit1530" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="sundayUnit1530" checked={user.sunday.unit1530} name="sunday_unit1530" onChange={this.handleChangeWorkingHour}/></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">16:00-16:30</th>
                                                <td><input type="checkbox" id="mondayUnit1600" checked={user.monday.unit1600} name="monday_unit1600" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="tuesdayUnit1600" checked={user.tuesday.unit1600} name="tuesday_unit1600" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="wednesdayUnit1600" checked={user.wednesday.unit1600} name="wednesday_unit1600" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="thursdayUnit1600" checked={user.thursday.unit1600} name="thursday_unit1600" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="fridayUnit1600" checked={user.friday.unit1600} name="friday_unit1600" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="saturdayUnit1600" checked={user.saturday.unit1600} name="saturday_unit1600" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="sundayUnit1600" checked={user.sunday.unit1600} name="sunday_unit1600" onChange={this.handleChangeWorkingHour}/></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">16:30-17:00</th>
                                                <td><input type="checkbox" id="mondayUnit1630" checked={user.monday.unit1630} name="monday_unit1630" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="tuesdayUnit1630" checked={user.tuesday.unit1630} name="tuesday_unit1630" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="wednesdayUnit1630" checked={user.wednesday.unit1630} name="wednesday_unit1630" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="thursdayUnit1630" checked={user.thursday.unit1630} name="thursday_unit1630" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="fridayUnit1630" checked={user.friday.unit1630} name="friday_unit1630" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="saturdayUnit1630" checked={user.saturday.unit1630} name="saturday_unit1630" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="sundayUnit1630" checked={user.sunday.unit1630} name="sunday_unit1630" onChange={this.handleChangeWorkingHour}/></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">17:00-17:30</th>
                                                <td><input type="checkbox" id="mondayUnit1700" checked={user.monday.unit1700} name="monday_unit1700" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="tuesdayUnit1700" checked={user.tuesday.unit1700} name="tuesday_unit1700" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="wednesdayUnit1700" checked={user.wednesday.unit1700} name="wednesday_unit1700" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="thursdayUnit1700" checked={user.thursday.unit1700} name="thursday_unit1700" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="fridayUnit1700" checked={user.friday.unit1700} name="friday_unit1700" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="saturdayUnit1700" checked={user.saturday.unit1700} name="saturday_unit1700" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="sundayUnit1700" checked={user.sunday.unit1700} name="sunday_unit1700" onChange={this.handleChangeWorkingHour}/></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">17:30-18:00</th>
                                                <td><input type="checkbox" id="mondayUnit1730" checked={user.monday.unit1730} name="monday_unit1730" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="tuesdayUnit1730" checked={user.tuesday.unit1730} name="tuesday_unit1730" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="wednesdayUnit1730" checked={user.wednesday.unit1730} name="wednesday_unit1730" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="thursdayUnit1730" checked={user.thursday.unit1730} name="thursday_unit1730" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="fridayUnit1730" checked={user.friday.unit1730} name="friday_unit1730" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="saturdayUnit1730" checked={user.saturday.unit1730} name="saturday_unit1730" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="sundayUnit1730" checked={user.sunday.unit1730} name="sunday_unit1730" onChange={this.handleChangeWorkingHour}/></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">18:00-18:30</th>
                                                <td><input type="checkbox" id="mondayUnit1800" checked={user.monday.unit1800} name="monday_unit1800" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="tuesdayUnit1800" checked={user.tuesday.unit1800} name="tuesday_unit1800" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="wednesdayUnit1800" checked={user.wednesday.unit1800} name="wednesday_unit1800" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="thursdayUnit1800" checked={user.thursday.unit1800} name="thursday_unit1800" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="fridayUnit1800" checked={user.friday.unit1800} name="friday_unit1800" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="saturdayUnit1800" checked={user.saturday.unit1800} name="saturday_unit1800" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="sundayUnit1800" checked={user.sunday.unit1800} name="sunday_unit1800" onChange={this.handleChangeWorkingHour}/></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">18:30-19:00</th>
                                                <td><input type="checkbox" id="mondayUnit1830" checked={user.monday.unit1830} name="monday_unit1830" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="tuesdayUnit1830" checked={user.tuesday.unit1830} name="tuesday_unit1830" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="wednesdayUnit1830" checked={user.wednesday.unit1830} name="wednesday_unit1830" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="thursdayUnit1830" checked={user.thursday.unit1830} name="thursday_unit1830" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="fridayUnit1830" checked={user.friday.unit1830} name="friday_unit1830" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="saturdayUnit1830" checked={user.saturday.unit1830} name="saturday_unit1830" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="sundayUnit1830" checked={user.sunday.unit1830} name="sunday_unit1830" onChange={this.handleChangeWorkingHour}/></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">19:00-19:30</th>
                                                <td><input type="checkbox" id="mondayUnit1900" checked={user.monday.unit1900} name="monday_unit1900" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="tuesdayUnit1900" checked={user.tuesday.unit1900} name="tuesday_unit1900" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="wednesdayUnit1900" checked={user.wednesday.unit1900} name="wednesday_unit1900" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="thursdayUnit1900" checked={user.thursday.unit1900} name="thursday_unit1900" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="fridayUnit1900" checked={user.friday.unit1900} name="friday_unit1900" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="saturdayUnit1900" checked={user.saturday.unit1900} name="saturday_unit1900" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="sundayUnit1900" checked={user.sunday.unit1900} name="sunday_unit1900" onChange={this.handleChangeWorkingHour}/></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">19:30-20:00</th>
                                                <td><input type="checkbox" id="mondayUnit1930" checked={user.monday.unit1930} name="monday_unit1930" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="tuesdayUnit1930" checked={user.tuesday.unit1930} name="tuesday_unit1930" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="wednesdayUnit1930" checked={user.wednesday.unit1930} name="wednesday_unit1930" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="thursdayUnit1930" checked={user.thursday.unit1930} name="thursday_unit1930" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="fridayUnit1930" checked={user.friday.unit1930} name="friday_unit1930" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="saturdayUnit1930" checked={user.saturday.unit1930} name="saturday_unit1930" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="sundayUnit1930" checked={user.sunday.unit1930} name="sunday_unit1930" onChange={this.handleChangeWorkingHour}/></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">20:00-20:30</th>
                                                <td><input type="checkbox" id="mondayUnit2000" checked={user.monday.unit2000} name="monday_unit2000" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="tuesdayUnit2000" checked={user.tuesday.unit2000} name="tuesday_unit2000" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="wednesdayUnit2000" checked={user.wednesday.unit2000} name="wednesday_unit2000" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="thursdayUnit2000" checked={user.thursday.unit2000} name="thursday_unit2000" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="fridayUnit2000" checked={user.friday.unit2000} name="friday_unit2000" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="saturdayUnit2000" checked={user.saturday.unit2000} name="saturday_unit2000" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="sundayUnit2000" checked={user.sunday.unit2000} name="sunday_unit2000" onChange={this.handleChangeWorkingHour}/></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">20:30-21:00</th>
                                                <td><input type="checkbox" id="mondayUnit2030" checked={user.monday.unit2030} name="monday_unit2030" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="tuesdayUnit2030" checked={user.tuesday.unit2030} name="tuesday_unit2030" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="wednesdayUnit2030" checked={user.wednesday.unit2030} name="wednesday_unit2030" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="thursdayUnit2030" checked={user.thursday.unit2030} name="thursday_unit2030" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="fridayUnit2030" checked={user.friday.unit2030} name="friday_unit2030" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="saturdayUnit2030" checked={user.saturday.unit2030} name="saturday_unit2030" onChange={this.handleChangeWorkingHour}/></td>
                                                <td><input type="checkbox" id="sundayUnit2030" checked={user.sunday.unit2030} name="sunday_unit2030" onChange={this.handleChangeWorkingHour}/></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-md-3 col-xl-2 col-form-label">Holiday</label>
                                <div className="col-sm-8">
                                    <Holidays
                                        ref={e => (this.listComponent = e)}
                                        holidays={leaveViews}/>
                                </div>
                            </div>

                            <div className="form-group row align-items-center ml-1">
                                <button type="button" className="btn btn-secondary col-md-2 col-xl-1 mr-2 mb-2" onClick={this.handleBackClick}>Back</button>
                                {resettingPasswordByAdmin && <button type="button" className="btn btn-warning col-md-4 col-xl-3 mr-2 mb-2 mr-1 mb-1" disabled >Resetting password... <i className="fa fa-circle-o-notch fa-spin"></i></button>}
                                {!resettingPasswordByAdmin && <button type="button" className="btn btn-warning col-md-4 col-xl-3 mr-2 mb-2 mr-1 mb-1" onClick={this.handleResetPasswordClick}>Reset password</button>}
                                <SubmitButton updating={updating} className="col-md-3 col-xl-2 mr-2 mb-2" label="Update" labelLoading="Updating ..."/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { selectedTherapist, updating, loggedAs, resettingPasswordByAdmin } = state.user;
    return {
        updating,
        selectedTherapist,
        loggedAs,
        resettingPasswordByAdmin,
        leaveViews: selectedTherapist?.leavesView?.leaveViews || null
    };
}

const connectedTherapistEditPage = connect(mapStateToProps)(TherapistEditPage);
export { connectedTherapistEditPage as TherapistEditPage };
