export const statisticsConstants = {
    GENERAL_REQUEST: 'STATISTICS_GENERAL_REQUEST',
    GENERAL_SUCCESS: 'STATISTICS_GENERAL_SUCCESS',
    GENERAL_FAILURE: 'STATISTICS_GENERAL_FAILURE',

    SPECIFIC_REQUEST: 'STATISTICS_SPECIFIC_REQUEST',
    SPECIFIC_SUCCESS: 'STATISTICS_SPECIFIC_SUCCESS',
    SPECIFIC_FAILURE: 'STATISTICS_SPECIFIC_FAILURE',

    RESETSPECIFIC_SUCCESS: 'STATISTICS_RESETSPECIFIC_SUCCESS',
}
