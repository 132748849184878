import { alertConstants } from '../_constants';

export function alert(state = {}, action) {
    switch (action.type) {
        case alertConstants.SUCCESS:
            return Object.assign({}, state, {
                type: 'alert-success',
                message: action.message
            });
        case alertConstants.INFO:
            return Object.assign({}, state, {
                type: 'alert-info',
                message: action.message
            });
        case alertConstants.ERROR:
            return Object.assign({}, state, {
                type: 'alert-danger',
                message: action.message
            });
        case alertConstants.CLEAR:
            return Object.assign({}, state, {
                type: null,
                message: null
            });
        default:
          return state;
    }
}
