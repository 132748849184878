import React from 'react';
import { connect } from 'react-redux';

import { treatmentGroupActions, reservationActions } from '../_actions';
import { SelectedItem, Items } from '.';

class BookingTreatmentGroupComponent extends React.Component {

    constructor(props) {
        super(props);

        this.handleClickSelectTreatmentGroup = this.handleClickSelectTreatmentGroup.bind(this);
        this.handleClickResetTreatmentGroup = this.handleClickResetTreatmentGroup.bind(this);
    }

    componentDidMount() {
        const { treatmentGroup } = this.props;
        if (!treatmentGroup
            || (!treatmentGroup.treatmentGroups)
            || (!treatmentGroup.treatmentGroups)) {
                // 5 is id of Euromassage - TODO fetch the real Id eventually
                this.props.dispatch(treatmentGroupActions.getGroupsForPlaceAndDate(5, new Date()));
        }
    }

    handleClickSelectTreatmentGroup(id) {
        var selectedGroup;
        var selectedGroupId = parseInt(id);
        var treatmentGroupWrapper = this.props.treatmentGroup;
        if (selectedGroupId && treatmentGroupWrapper.treatmentGroups) {
            for (var i = 0; i < treatmentGroupWrapper.treatmentGroups.length; i++) {
                if (selectedGroupId === treatmentGroupWrapper.treatmentGroups[i].id) {
                    selectedGroup = treatmentGroupWrapper.treatmentGroups[i];
                    break;
                }
            }
        }
        this.props.dispatch(reservationActions.selectGroup(selectedGroup));
    }

    handleClickResetTreatmentGroup() {
        this.props.dispatch(reservationActions.resetGroup());
    }

    render() {
        const { treatmentGroup, reservation } = this.props;
        var selectedGroup = reservation.selectedGroup;
        if (selectedGroup) {
            return (
                <SelectedItem
                    label="1. Treatment group:"
                    value={selectedGroup.label}
                    onClickHandle = {this.handleClickResetTreatmentGroup}/>
            );
        } else {
            return (
                <Items label="1. Pick treatment group:"
                    values={treatmentGroup.treatmentGroups}
                    loading={treatmentGroup.requestingTreatmentGroups}
                    error={treatmentGroup.error}
                    onClickHandle = {this.handleClickSelectTreatmentGroup}
                    keyPrefix='treatmentGroup_'/>
            );
        }
    }
}

function mapStateToProps(state) {
    const { treatmentGroup, reservation } = state;

    return {
        treatmentGroup,
        reservation
    };
}

const connectedBookingTreatmentGroupComponent = connect(mapStateToProps)(BookingTreatmentGroupComponent);
export { connectedBookingTreatmentGroupComponent as BookingTreatmentGroupComponent };
