import React from 'react';
import { connect } from 'react-redux';
import { alertActions } from '../_actions';

class Alert extends React.Component {

    constructor(props) {
        super(props);

        this.timerID = null;
        this.onXClick = this.onXClick.bind(this);
        this.startTimer = this.startTimer.bind(this);
    }

    startTimer() {
        if (this.timerID) {
            clearInterval(this.timerID);
        }
        var timerDelay = this.props.alert.message.split(' ').length / 3 * 1000 // 1sec for each 3 words
        if (timerDelay < 3000) {
            timerDelay = 3000 // at least 3 seconds
        }
        this.timerID = setInterval(
            () => {
                this.onXClick()
            },
            timerDelay
        );
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    onXClick() {
        if (this.props.alert.type) {
            this.props.dispatch(alertActions.clear());
        }
        clearInterval(this.timerID);
    }

    render() {
        const { alert } = this.props;
        if (alert.type) {
            this.startTimer();
            return (
                <div className="position-fixed top-0 right-0 p-3" style={{"zIndex": 5, "right": 0, "top": 0,}}>
                    <div className={`alert alert-dismissible m-4 ${alert.type} display-linebreak`} role="alert">
                        <button type="button" className="close" data-dismiss="alert" onClick={this.onXClick}>×</button>
                        {alert.message}
                    </div>
                </div>
            );
        } else {
            return '';
        }
    }
}

function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert
    };
}

const connectedAlert = connect(mapStateToProps)(Alert);
export { connectedAlert as Alert };
