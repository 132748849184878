import React from 'react';
import { connect } from 'react-redux';
import { http } from '../_helpers';

import moment from 'moment';
import 'moment/locale/en-nz';

import { Modal } from '../_components';

import { voucherActions } from '../_actions';

class Voucher extends React.Component {

    constructor(props) {
        super(props);

        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);
        this.hideModal = this.hideModal.bind(this);

        this.state = {
			modal: false
		};
    }

    handleDeleteClick() {
        this.setState({ modal: true })
    }

    handleDeleteConfirm() {
        var voucher = this.props.voucher;
        if (voucher) {
            this.props.dispatch(voucherActions.deleteVoucher(voucher));
        }
    }

    hideModal() {
        this.setState({ modal: false })
    }


    render() {
        const { voucher, deletingVoucher } = this.props;
        const { modal } = this.state;
        if (voucher) {
            return (
                <div className="card bg-dark">
                    <div className="card-header">
                        <strong>{voucher.code ? voucher.code : 'Unknown'}</strong> - <strong>{voucher.forWho ? voucher.forWho : 'Unknown'}</strong>
                    </div>
                    <div className="card-body">
                        <div className="card-text">
                            <div>Id - <strong>{voucher.id}</strong></div>
                            <div>By - <strong>{voucher.orderedByWhom}</strong></div>
                            <div>For - <strong>{voucher.forWho}</strong></div>
                            <div>Issued - <strong>{voucher.issueDate ? moment(voucher.issueDate, "YYYY-MM-DD").format('Do MMMM YYYY') : 'never'}</strong></div>
                            <div>Entitles - <strong>{voucher.entitlesTo}</strong></div>
                            <div>Amount - <strong>{voucher.amount ? '$' + voucher.amount.toLocaleString(undefined) : 'unknown'}</strong></div>
                            {voucher.note && <div>Note - <strong>{voucher.note}</strong></div>}
                        </div>
                    </div>
                    <div className="card-footer">
                        <a className="btn btn-info btn-sm mr-1 mb-1" href={`${http.BASE_URL}/api/voucher/${voucher.id}/pdf`} download>Pdf</a>
                        <a className="btn btn-info btn-sm mr-1 mb-1" href={`${http.BASE_URL}/api/voucher/${voucher.id}/invoice`} download>Invoice</a>
                        <button type="button" className="btn btn-danger btn-sm mr-1 mb-1" onClick={this.handleDeleteClick}>Delete</button>
                    </div>
                    {modal &&
                        <Modal id="deleteConfirmation"
                            title="Delete confirmation"
                            body= {`Are you sure you want to delete the voucher. ${voucher.payed ? 'That includes the financial transaction as this voucher has been already payed.' : ''}`}
                            primaryButtonLabel="Yes"
                            primaryButtonLabelWait="Deleting..."
                            primaryFunction={this.handleDeleteConfirm}
                            primaryFunctionWait={deletingVoucher}
                            primaryButtonClass="btn-danger"
                            cancelFunction={this.hideModal}/>
                    }
                </div>

            );
        } else {
            return "";
        }
    }
}

function mapStateToProps(state) {
    const { deletingVoucher } = state.voucher;
    return {
        deletingVoucher
    };
}

const connectedVoucher = connect(mapStateToProps)(Voucher);
export { connectedVoucher as Voucher };
