import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/en-nz';

import { treatmentGroupActions, alertActions } from '../_actions';
import { SubmitButton, DateField } from '../_components';

class TreatmentGroupIncreasePricesPage extends React.Component {

    constructor(props) {
        super(props);

        this.handleBackClick = this.handleBackClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleOnFromDateChanged = this.handleOnFromDateChanged.bind(this);

        this.state = {
            byHowMuch: 10,
            fromDate: moment().endOf('month').add(1, 'd'),
            oldTreatmentSuffix: '',
            submitted: false
        };
    }

    componentDidMount() {
        if (!this.props.loggedAs) {
            this.props.history.push("/login");
        }
    }

    componentDidUpdate() {
        if (!this.props.loggedAs) {
            this.props.history.push("/login");
        }
    }

    handleBackClick() {
        this.props.history.push("/treatmentGroups");
    }

    handleSubmit(e) {
        e.preventDefault();
        var form = document.getElementById('treatmentGroupIncreasePricesForm');
        if (form.checkValidity() === false) {
            form.classList.add('was-validated');
            e.stopPropagation();
            return;
        }

        const { dispatch } = this.props;
        dispatch(alertActions.clear());
        dispatch(treatmentGroupActions.increasePrices(this.state.byHowMuch, this.state.fromDate, this.state.oldTreatmentSuffix));
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        });
    }

    handleOnFromDateChanged(date) {
        this.setState({
            fromDate: date == null ? null : date
        });
    }

    render() {
        const { treatmentGroup } = this.props;
        return (
            <div className="col-lg-8">
                <h2 className="text-center">Increase prices</h2>
                <div className="card card-primary card-transparent">
                    <div className="card-body">
                        <form id="treatmentGroupIncreasePricesForm" noValidate className="needs-validation my-4" onSubmit={this.handleSubmit}>
                            <div className="form-group row">
                                <label className="col-sm-5 col-md-4 col-xl-3 col-form-label" htmlFor="byHowMuch">By how much</label>
                                <div className="col-sm-7">
                                    <input type="text" className="form-control" name="byHowMuch" value={this.state.byHowMuch} required onChange={this.handleChange}/>
                                    <div className="invalid-feedback">
                                        Please enter by how much you want to increase prices
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-sm-5 col-md-4 col-xl-3 col-form-label" htmlFor="label">From date</label>
                                <div className="col-sm-7">
                                    <DateField
                                        id="validFrom"
                                        dateTime={this.state.fromDate}
                                        onChange={this.handleOnFromDateChanged}
                                        required={true}
                                        />
                                    <div className="invalid-feedback">
                                        Please enter from date
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-sm-5 col-md-4 col-xl-3 col-form-label" htmlFor="oldTreatmentSuffix">Suffix for old treatments</label>
                                <div className="col-sm-7">
                                    <input type="text" className="form-control" name="oldTreatmentSuffix" value={this.state.oldTreatmentSuffix} required onChange={this.handleChange}/>
                                    <div className="invalid-feedback">
                                        Please enter suffix used for renaming the old treatments
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row align-items-center ml-1">
                                <button type="button" className="btn btn-secondary col-md-2 col-xl-1 mr-2 mb-2" onClick={this.handleBackClick}>Back</button>
                                <SubmitButton updating={treatmentGroup.updatingPrices} label="Update prices" labelLoading="Updating prices ..." className="col-md-3 col-xl-2 mr-2 mb-2"/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { loggedAs } = state.user;
    const { treatmentGroup } = state;

    return {
        loggedAs,
        treatmentGroup
    };
}

const connectedTreatmentGroupIncreasePricesPage = connect(mapStateToProps)(TreatmentGroupIncreasePricesPage);
export { connectedTreatmentGroupIncreasePricesPage as TreatmentGroupIncreasePricesPage };
