import React from 'react';

import { Customer } from '.';

export class Customers extends React.Component {

    render() {
        const { customers, searchString, searching } = this.props;
        if (customers && customers.length > 0) {
            return (
                <div className="row row-cols-3">
                    {customers.map((customer, index) =>
                        <Customer key={"Customer_" + customer.id} customer={customer}/>
                    )}
                </div>
            );
        } else if (searching) {
            return (<footer className="blockquote-footer col-sm-12">Searching ... </footer>);
        } else {
            return (
                <div className="form-group row">
                    {(!searchString || searchString.length === 0) && <footer className="blockquote-footer col-sm-12">Start typing and the result will automatically appear</footer>}
                    {!!searchString && searchString.length > 0 && searchString.length < 3 && <footer className="blockquote-footer col-sm-12">You need at least 3 characters</footer>}
                    {!!searchString && searchString.length >= 3 && <footer className="blockquote-footer col-sm-12">Nothing found for given string</footer>}
                </div>
            );
        }
    }
}
