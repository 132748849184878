import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/en-nz';

import { formatting } from '../_helpers';
import { slotGroupActions } from '../_actions';
import { SubmitButton } from '../_components';

class SlotGroupCard extends React.Component {

    constructor(props) {
        super(props);

        this.handleCancel = this.handleCancel.bind(this);
        this.handleCancelWithReason = this.handleCancelWithReason.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.state = {
            showCancelForm: false,
            reason: ''
        };
    }

    handleCancel(slotGroup) {
        console.log(slotGroup);
        this.setState({ showCancelForm: true });
    }

    handleCancelWithReason(e) {
        const {dispatch, slotGroup} = this.props;
        e.preventDefault();
        var form = document.getElementById('slotGroupCancel'+ slotGroup.id);
        if (form.checkValidity() === false) {
            form.classList.add('was-validated')
            e.stopPropagation();
            return;
        }
        slotGroup.reason = this.state.reason;
        slotGroup.stateCode = 'canceled';
        console.log(slotGroup);
        dispatch(slotGroupActions.cancel(slotGroup));
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    render() {
        const { slotGroup, loading, showCancel, canceling } = this.props;
        const { reason, showCancelForm } = this.state;
        const culture = formatting.CULTURE;
        const today = !loading && slotGroup && moment(slotGroup.start).isSame(moment(), 'd');
        const upcoming = !loading && slotGroup && moment(slotGroup.start).isAfter(moment());
        const borderClass = today ? 'border-primary' : (upcoming ? 'border-success' : 'border-warning');
        const headerText = today ? 'Today' : (upcoming ? 'Upcoming' : 'Previous');
        if (loading) {
            return (
                <div className="col col-md-6 my-1">
                    <div className="card card-shade">
                        <div className="card-body">
                            <p className="card-text">
                                Loading ...
                            </p>
                        </div>
                    </div>
                </div>
            );
        } else if (slotGroup) {
            return (
                <div className="col-sm-12 col-md-6 my-1">
                    <div className={`card card-shade ${borderClass}`}>
                        <div className="card-header">
                            {headerText} booking
                        </div>
                        <div className="card-body">
                            <div>Date - <strong>{moment(slotGroup.start).locale(culture).format('ddd D/M/YYYY')}</strong> at <strong>{moment(slotGroup.start).locale(culture).format('h:mm A')}</strong></div>
                            <div>Therapist - <strong>{slotGroup.therapist}</strong></div>
                            <div>Treatment(s)
                                <ul >
                                    {
                                        slotGroup.sessions.map(function(session, index){
                                            return <li key={index}>{session.treatmentLabel + " - " + session.priceFormatted + " - " + session.durationFormatted}</li>
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="row mb-1">
                                <div className={`col-4 ${slotGroup.cancelVisible ? `mt-2` : ``}`}>Total - <strong>{slotGroup.totalPrice}</strong></div>
                                {showCancel && !showCancelForm && slotGroup.cancelVisible &&
                                    <div className="col-8">
                                        <button type="button" className="btn float-right btn-outline-danger btn-sm" onClick={() => this.handleCancel(slotGroup)}>Cancel</button>
                                    </div>
                                }
                                {showCancel && !slotGroup.cancelVisible && <div className="col-8 mt-1 text-right"><small className="float-right">Call us on <a className="text-center" href="tel:+64211680430">021 168 0430</a> to cancel this booking</small></div>}
                            </div>
                            {showCancel && showCancelForm  && slotGroup.cancelVisible &&
                                <div className="row mb-1">
                                    <form noValidate id={`slotGroupCancel` + slotGroup.id} className="col mt-1" onSubmit={this.handleCancelWithReason}>
                                        <div className="form-group">
                                            <label htmlFor="reason">Reason</label>
                                            <input className="form-control" id="reason" required name="reason" type="text" value={reason} onChange={this.handleChange}/>
                                            <div className="invalid-feedback">
                                                Please enter the reason
                                            </div>
                                        </div>
                                        <SubmitButton updating={canceling} label="Cancel booking" className="btn-danger" labelLoading="Canceling ..."/>
                                    </form>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            );
        } else if (!slotGroup) {
            return (
                <div className="col-sm-12 col-md-6 my-1">
                    <div className="card card-shade border-success">
                        <div className="card-header">No treatment yet</div>
                        <div className="card-body">
                            <p className="card-text">
                                It looks like you did not have a treatment with us. Why not book one now?
                            </p>
                            <Link to="/booking" className="btn btn-primary">Book now</Link>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

function mapStateToProps(state) {
    return {

    };
}

const connectedSlotGroupCard = connect(mapStateToProps)(SlotGroupCard);
export { connectedSlotGroupCard as SlotGroupCard };
