import React from 'react';
import { connect } from 'react-redux';

import moment from 'moment';
import 'moment/locale/en-nz';

import { financeActions, alertActions } from '../_actions';
import { SubmitButton, DateField } from '../_components';

class TransactionCreatePage extends React.Component {

    constructor(props) {
        super(props);

        this.handleBackClick = this.handleBackClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleOnDateChanged = this.handleOnDateChanged.bind(this);
        this.categoryChanged = this.categoryChanged.bind(this);
        this.onCategoryChanged = this.onCategoryChanged.bind(this);
        this.typeChanged = this.typeChanged.bind(this);
        this.onTypeChanged = this.onTypeChanged.bind(this);
        this.populateData = this.populateData.bind(this);
        this.handleSlotGroupDetailClick = this.handleSlotGroupDetailClick.bind(this);

        this.state = {
            newTransaction: {
                date: null,
                transactionCategoryId: null,
                financialTransactionTypeCode: null
            },
            submitted: false,
            date: null,
            focusedInputStart: false,
            focusedInputEnd: false
        };
    }

    componentDidMount() {
        if (!this.props.loggedAs) {
            this.props.history.push("/login");
        }
        this.populateData();
    }

    componentDidUpdate() {
        if (!this.props.loggedAs) {
            this.props.history.push("/login");
        }
        this.populateData();
    }

    handleBackClick() {
        this.props.history.push("/finances");
    }

    handleSlotGroupDetailClick() {
        this.props.dispatch(financeActions.viewSlotGroup(this.props.selectedFinancialTransaction.slotGroup));
    }

    populateData() {
        const { dispatch,
            financialTransactionCategories,
            requestingFinancialTransactionCategories,
            financialTransactionTypes,
            requestingFinancialTransactionTypes,
            lastUsedDate
        } = this.props;
        const { newTransaction } = this.state;
        if (!financialTransactionCategories && !requestingFinancialTransactionCategories) {
            dispatch(financeActions.getFinancialTransactionCategories());
        } else if (financialTransactionCategories && !newTransaction.transactionCategoryId) {
            this.categoryChanged(financialTransactionCategories[0].id);
        }
        if (!financialTransactionTypes && !requestingFinancialTransactionTypes) {
            dispatch(financeActions.getFinancialTransactionTypes());
        } else if (financialTransactionTypes && !newTransaction.financialTransactionTypeCode) {
            this.typeChanged(financialTransactionTypes[0].code);
        }
        if (lastUsedDate && !this.state.newTransaction.date) {
            this.setState({
                newTransaction: {
                    ...newTransaction,
                    date: lastUsedDate
                }
            });
        } else if (!lastUsedDate && !this.state.newTransaction.date) {
            this.setState({
                newTransaction: {
                    ...newTransaction,
                    date: moment()
                }
            });
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        var form = document.getElementById('transactionForm');
        if (form.checkValidity() === false) {
            form.classList.add('was-validated');
            e.stopPropagation();
            return;
        }
        const { dispatch } = this.props;
        dispatch(alertActions.clear());
        dispatch(financeActions.createFinancialTransaction(this.state.newTransaction));
    }

    handleChange(e) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const { newTransaction } = this.state;
        this.setState({
            newTransaction: {
                ...newTransaction,
                [name]: value
            }
        });
    }

    handleOnDateChanged(dateMoment) {
        let localState = {...this.state};
        localState.newTransaction.date = dateMoment;
        this.setState(localState);
    }

    categoryChanged(categoryId) {
        var category = this.props.financialTransactionCategories.find(function(category) {
            return category.id === parseInt(categoryId);
        }, categoryId);
        const { newTransaction } = this.state;
        this.setState({
            newTransaction: {
                ...newTransaction,
                transactionCategoryId: category.id,
                transactionCategoryLabel: category.label
            }
        });
    }

    onCategoryChanged(event) {
        if (event.currentTarget.value) {
            this.categoryChanged(event.currentTarget.value);
        }
    }

    typeChanged(typeCode) {
        var type = this.props.financialTransactionTypes.find(function(type) {
            return type.code === typeCode;
        }, typeCode);
        const { newTransaction } = this.state;
        this.setState({
            newTransaction: {
                ...newTransaction,
                financialTransactionTypeCode: type.code,
                financialTransactionTypeLabel: type.label
            }
        });
    }

    onTypeChanged(event) {
        if (event.currentTarget.value) {
            this.typeChanged(event.currentTarget.value);
        }
    }

    render() {
        const {
            financialTransactionCategories,
            requestingFinancialTransactionCategories,
            financialTransactionTypes,
            requestingFinancialTransactionTypes,
            updatingFinancialTransaction
        } = this.props;
        const { newTransaction } = this.state;
            return (
                <div className="col-lg-10">
                    <h2 className="text-center">Create transaction</h2>
                    <div className="card card-primary card-transparent">
                        <div className="card-body">
                            <form id="transactionForm" noValidate className="needs-validation my-4" onSubmit={this.handleSubmit}>
                                <div className="form-group row">
                                    <label className="col-3 col-lg-2 col-form-label" >Date</label>
                                    <div className="col-9">
                                        <DateField
                                            id="date"
                                            dateTime={newTransaction.date}
                                            onChange={this.handleOnDateChanged}
                                            required={true}
                                            />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-3 col-lg-2 col-form-label" htmlFor="amount">Amount</label>
                                    <div className="col-9">
                                        <input type="number" className="form-control" name="amount" value={newTransaction.amount} required onChange={this.handleChange}/>
                                        <div className="invalid-feedback">
                                            Please enter amount
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-3 col-lg-2 col-form-label" htmlFor="category">Category</label>
                                    <div className="col-9">
                                        {(requestingFinancialTransactionCategories) && <span>Loading categories ...</span>}
                                        {financialTransactionCategories && !requestingFinancialTransactionCategories && <select className="form-control" name="category" id="category" value={newTransaction.transactionCategoryId} onChange={this.onCategoryChanged}>
                                                {financialTransactionCategories.map((item, index) =>
                                                    <option key={`category_${index}`} value={item.id}>{item.label}</option>
                                                )}
                                            </select>
                                        }
                                        <div className="invalid-feedback">
                                            Please pick a category
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-3 col-lg-2 col-form-label" htmlFor="type">Type</label>
                                    <div className="col-9">
                                        {(requestingFinancialTransactionTypes) && <span>Loading types ...</span>}
                                        {financialTransactionTypes && !requestingFinancialTransactionTypes && <select className="form-control" name="type" id="type" value={newTransaction.financialTransactionTypeCode} onChange={this.onTypeChanged}>
                                                {financialTransactionTypes.map((item, index) =>
                                                    <option key={`type_${index}`} value={item.code}>{item.label}</option>
                                                )}
                                            </select>
                                        }
                                        <div className="invalid-feedback">
                                            Please pick a type
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-3 col-lg-2 col-form-label" htmlFor="note">Note</label>
                                    <div className="col-sm-8">
                                        <textarea className="form-control" name="note" rows="5" value={newTransaction.note} onChange={this.handleChange}/>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-3 col-lg-2 col-form-label" >Extra stuff</label>
                                    <div className="col-9 pt-2">
                                        <input className="form-check-input ml-2" type="checkbox" checked={!!newTransaction.transfer} name="transfer" onChange={this.handleChange}/>
                                        <label className="form-check-label ml-4" htmlFor="transfer">Is transfer</label>
                                    </div>
                                </div>

                                <div className="form-group row align-items-center ml-1">
                                    <button type="button" className="btn btn-secondary col-md-2 col-xl-1 mr-2 mb-2" onClick={this.handleBackClick}>Back</button>
                                    <SubmitButton disabled={requestingFinancialTransactionTypes || requestingFinancialTransactionCategories} updating={updatingFinancialTransaction} label="Save transaction" labelLoading="Saving ..." className="col-md-3 col-xl-2 mr-2 mb-2"/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            );
    }
}

function mapStateToProps(state) {
    const { loggedAs } = state.user;
    const {
        selectedFinancialTransaction,
        financialTransactionCategories,
        requestingFinancialTransactionCategories,
        financialTransactionTypes,
        requestingFinancialTransactionTypes,
        requestingSlotGroup,
        updatingFinancialTransaction,
        lastUsedDate
    } = state.finance;

    return {
        loggedAs,
        selectedFinancialTransaction,
        financialTransactionCategories,
        requestingFinancialTransactionCategories,
        financialTransactionTypes,
        requestingFinancialTransactionTypes,
        requestingSlotGroup,
        updatingFinancialTransaction,
        lastUsedDate
    };
}

const connectedTransactionCreatePage = connect(mapStateToProps)(TransactionCreatePage);
export { connectedTransactionCreatePage as TransactionCreatePage };
