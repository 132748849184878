import React from 'react';
import { connect } from 'react-redux';

import { reservationActions } from '../_actions';

class BasketItem extends React.Component {

    constructor(props) {
        super(props);

        this.handleRemoveFromBasket = this.handleRemoveFromBasket.bind(this);
    }

    handleRemoveFromBasket(){
        this.props.dispatch(reservationActions.removeFromBasket(this.props.item, this.props.reservation.selectedPrices, this.props.reservation.selectedBasketTherapist));
    }

    render() {
        const { item } = this.props;
        if (item) {
            return(
                <div className="row mx-1 mb-1">
                    <div className="col-sm-12 col-md-5 form-control-plaintext font-weight-bold">
                        {item.treatmentLabel}
                    </div>
                    <div className="col-4 col-md-3 form-control-plaintext">
                        {item.durationFormatted}
                    </div>
                    <div className="col-4 col-md-2 form-control-plaintext font-weight-bold">
                        {item.priceFormatted}
                    </div>
                    <button type="button" className="col-4 col-md-2 btn btn-danger btn-block btn-sm" onClick={this.handleRemoveFromBasket}>Remove</button>
                </div>
            );
        }
    }
}

function mapStateToProps(state) {
    const { reservation } = state;
    return {
        reservation
    };
}

const connectedBasketItem = connect(mapStateToProps)(BasketItem);
export { connectedBasketItem as BasketItem };
