import React from 'react';
import { connect } from 'react-redux';

import { voucherActions, alertActions } from '../_actions';
import { SubmitButton } from '../_components';

class VoucherCreatePage extends React.Component {

    constructor(props) {
        super(props);

        this.handleBackClick = this.handleBackClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleAmountChange = this.handleAmountChange.bind(this);

        this.state = {
            newVoucher: {},
            submitted: false
        };
    }

    componentDidMount() {
        if (!this.props.loggedAs) {
            this.props.history.push("/login");
        }
    }

    componentDidUpdate() {
        if (!this.props.loggedAs) {
            this.props.history.push("/login");
        }
    }

    handleBackClick() {
        this.props.history.push("/vouchers");
    }

    handleSubmit(e) {
        e.preventDefault();
        var form = document.getElementById('voucherForm');
        const { newVoucher } = this.state;
        if (form.checkValidity() === false
            || newVoucher.amount > 99999999.99 || isNaN(newVoucher.amount)) {
            form.classList.add('was-validated');
            e.stopPropagation();
            return;
        }

        const { dispatch } = this.props;
        dispatch(alertActions.clear());
        dispatch(voucherActions.createVoucher(this.state.newVoucher));
    }

    handleChange(e) {
        const { name, value } = e.target;
        const { newVoucher } = this.state;
        this.setState({
            newVoucher: {
                ...newVoucher,
                [name]: value
            }
        });
    }

    handleAmountChange(e) {
        const { name, value } = e.target;
        if (value > 99999999.99 || isNaN(value)) {
            e.target.classList.add('is-invalid');
        } else {
             e.target.classList.remove('is-invalid');
        }
        const { newVoucher } = this.state;
        this.setState({
            newVoucher: {
                ...newVoucher,
                [name]: value
            }
        });
    }

    render() {
        const { voucher } = this.props;
        const { newVoucher } = this.state;
            return (
                <div className="col-12 col-lg-8">
                    <h2 className="text-center">New voucher</h2>
                    <div className="card card-primary card-transparent">
                        <div className="card-body">
                            <form id="voucherForm" noValidate className="needs-validation my-4" onSubmit={this.handleSubmit}>
                                <div className="form-group row">
                                    <label className="col-sm-2 col-form-label" htmlFor="orderedByWhom">Ordered by</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control font-weight-bold" name="orderedByWhom" value={newVoucher.orderedByWhom} required onChange={this.handleChange}/>
                                        <div className="invalid-feedback">
                                            Please enter who ordered the voucher.
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-2 col-form-label" htmlFor="forWho">For who</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control font-weight-bold" name="forWho" value={newVoucher.forWho} required onChange={this.handleChange}/>
                                        <div className="invalid-feedback">
                                            Please enter for whom the voucher is.
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-2 col-form-label" htmlFor="entitlesTo">Entitles</label>
                                    <div className="col-sm-8">
                                        <textarea className="form-control font-weight-bold" name="entitlesTo" value={newVoucher.entitlesTo} required onChange={this.handleChange}/>
                                        <div className="invalid-feedback">
                                            Please enter what the voucher is for.
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-2 col-form-label" htmlFor="amount">Amount</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control font-weight-bold" name="amount" value={newVoucher.amount} required onChange={this.handleAmountChange}/>
                                        <div className="invalid-feedback">
                                            Please enter amount. Maximum amount is $99,999,999.99 .
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-2 col-form-label" htmlFor="note">Note</label>
                                    <div className="col-sm-8">
                                        <textarea className="form-control font-weight-bold" name="note" value={newVoucher.note} onChange={this.handleChange}/>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <button type="button" className="btn btn-secondary mr-1 mb-1" onClick={this.handleBackClick}>Back</button>
                                    <SubmitButton updating={voucher.savingVoucher} label="Create voucher" labelLoading="Saving ..." className="mr-1 mb-1"/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            );
    }
}

function mapStateToProps(state) {
    const { loggedAs } = state.user;
    const { voucher } = state;

    return {
        loggedAs,
        voucher
    };
}

const connectedVoucherCreatePage = connect(mapStateToProps)(VoucherCreatePage);
export { connectedVoucherCreatePage as VoucherCreatePage };
