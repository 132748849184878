import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import 'moment/locale/en-nz';

class Footer extends React.Component {

    render() {
        const currentLocalTime = moment().format();
        const nzLocalTime = moment().tz("Pacific/Auckland").format()
        return (
            <footer className="text-center text-sm-start tiny">
                <div className="text-center mb-2">
                    © 2009-{moment().format("YYYY")} Copyright: Karolina Vyklicka, Euro massage - Wellington, New Zealand
                </div>
                <div className="text-center mb-2">
                    <span>v: {process.env.REACT_APP_VERSION}; webmaster: </span>
                    <a href="mailto:admin@euromassage.co.nz">admin@euromassage.co.nz</a>
                </div>
                <div className="text-center mb-2">
                    Local time: {currentLocalTime}; NZ time: {nzLocalTime}
                </div>
            </footer>

        );
    }
}

function mapStateToProps(state) {
    return {};
}

const connectedFooter = connect(mapStateToProps)(Footer);
export { connectedFooter as Footer };
