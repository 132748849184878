export const reservationTherapistConstants = {
  SELECTEDGROUP_SUCCESS: 'RESERVATIONTHERAPIST_SELECTEDGROUP_SUCCESS',
  RESETGROUP_SUCCESS: 'RESERVATIONTHERAPIST_RESETGROUP_SUCCESS',

  SELECTEDTREATMENT_SUCCESS: 'RESERVATIONTHERAPIST_SELECTEDTREATMENT_SUCCESS',
  RESETTREATMENT_SUCCESS: 'RESERVATIONTHERAPIST_RESETTREATMENT_SUCCESS',

  SELECTEDTHERAPIST_SUCCESS: 'RESERVATIONTHERAPIST_SELECTEDTHERAPIST_SUCCESS',
  RESETTHERAPIST_SUCCESS: 'RESERVATIONTHERAPIST_RESETTHERAPIST_SUCCESS',

  SELECTEDPRICE_SUCCESS: 'RESERVATIONTHERAPIST_SELECTEDPRICE_SUCCESS',
  RESETPRICE_SUCCESS: 'RESERVATIONTHERAPIST_RESETPRICE_SUCCESS',

  MODIFYBASKET_SUCCESS: 'RESERVATIONTHERAPIST_MODIFYBASKET_SUCCESS',
  RESETBASKET_SUCCESS: 'RESERVATIONTHERAPIST_RESETBASKET_SUCCESS',

  SELECTEDCUSTOMER_SUCCESS: 'RESERVATIONTHERAPIST_SELECTEDCUSTOMER_SUCCESS',
  RESETCUSTOMER_SUCCESS: 'RESERVATIONTHERAPIST_RESETCUSTOMER_SUCCESS',
  CUSTOMERNAME_SUCCESS: 'RESERVATIONTHERAPIST_CUSTOMERNAME_SUCCESS',
  CUSTOMERCONTACT_SUCCESS: 'RESERVATIONTHERAPIST_CUSTOMERCONTACT_SUCCESS',

  SELECTEDEVENT_SUCCESS: 'RESERVATIONTHERAPIST_SELECTEDEVENT_SUCCESS',
  RESETEVENT_SUCCESS: 'RESERVATIONTHERAPIST_RESETEVENT_SUCCESS',

  SENDEMAIL_SUCCESS: 'RESERVATIONTHERAPIST_SENDEMAIL_SUCCESS'
}
