import React from 'react';
import { connect } from 'react-redux';
import { formatting } from '../_helpers';
import moment from 'moment';
import 'moment/locale/en-nz';

import { slotGroupActions } from '../_actions';
import { MyCalendar } from '../_components';

import { SelectedItem } from '.';

class BookingCalendarComponent extends React.Component {

    constructor(props) {
        super(props);

        this.handleClickResetTime = this.handleClickResetTime.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSelected = this.handleSelected.bind(this);
        this.calendarDate=new Date();
    }

    handleClickResetTime() {
        this.props.dispatch(slotGroupActions.resetEvent());
    }

    handleDateChange(date) {
        var selectedBasketTherapist = this.props.reservation.selectedBasketTherapist;
        var selectedBasketPriceIds = this.props.reservation.selectedPrices && this.props.reservation.selectedPrices.map(price => price.id);
        this.calendarDate = date;
        const start = moment(date).startOf('isoweek').format('YYYY-MM-DD');
        const end = moment(date).endOf('isoweek').format('YYYY-MM-DD');

        if (selectedBasketTherapist && selectedBasketPriceIds && selectedBasketPriceIds.length > 0) {
            this.props.dispatch(slotGroupActions.getAvailable(selectedBasketTherapist.id, selectedBasketPriceIds, start, end));
        }
    }

    handleSelected(event) {
        this.props.dispatch(slotGroupActions.selectEvent(event));
    }

    render() {
        const { slotGroup, reservation } = this.props;
        var selectedGroup = reservation.selectedGroup;
        var selectedTreatment = reservation.selectedTreatment;
        var selectedPrice = reservation.selectedPrice;
        var selectedTherapist = reservation.selectedTherapist;
        var selectedEvent = reservation.selectedEvent;
        var selectedPrices = reservation.selectedPrices;
        var selectedBasketTherapist = reservation.selectedBasketTherapist;
        if (((selectedGroup && selectedTreatment && selectedPrice && selectedTherapist) ||
            (selectedPrices && selectedPrices.length > 0 && selectedBasketTherapist)) && selectedEvent) {
            return (
                <div className="form-group row">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-9">
                        <div className="row mx-1">
                            <label className="col-sm-12 col-md-5 col-form-label">Date and time:</label>
                            <div className="col-8 col-md-5 form-control-plaintext font-weight-bold">
                                {moment(selectedEvent.start).locale(formatting.CULTURE).format('D/M/YYYY h:mm A')}
                            </div>
                            <button type="button" className="col-4 col-md-2 btn btn-secondary btn-block btn-sm" onClick={this.handleClickResetTime}>Change this</button>
                        </div>
                    </div>
                </div>
            );
        } else if (selectedPrices && selectedPrices.length > 0 && selectedBasketTherapist && !selectedEvent) {
            return (
                <fieldset key="BookingCalendarComponent" className="form-group">
                    <div className="row mb-1">
                        {slotGroup.loading &&
                            <div className="col big-calendar-height">
                                Loading ...
                            </div>
                        }
                        {!slotGroup.loading && slotGroup.slotGroups && slotGroup.slotGroups.slotGroupViews &&
                            <div className="col">
                                {slotGroup.slotGroups.slotGroupViews.length == 0 &&
                                    <div className="alert alert-warning">
                                        No available time slots in this week. Press the "Next" button below (in the calendar navigation bar) to check the following week.
                                    </div>
                                }
                                <div className="big-calendar-height">
                                    <MyCalendar
                                        events={slotGroup.slotGroups.slotGroupViews}
                                        date={this.calendarDate}
                                        onNavigate={this.handleDateChange}
                                        onSelectEvent={this.handleSelected}
                                        min={moment("09:00:00", "HH:mm:ss").toDate()}
                                        max={moment("19:00:00", "HH:mm:ss").toDate()}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                </fieldset>
            );
        } else {
            return (
                <fieldset className="form-group">
                    <div className="row mb-1">
                        <legend className="col-form-label col-sm-4 col-md-4 col-lg-3 pt-0 font-weight-bold text-muted">5. Pick date and time:</legend>
                        <div className="col-sm-4 col-md-5 col-lg-7">
                        </div>
                    </div>
                </fieldset>
            );
        }
    }
}

function mapStateToProps(state) {
    const { treatmentGroup, slotGroup, reservation } = state;

    return {
        treatmentGroup,
        slotGroup,
        reservation
    };
}

const connectedBookingCalendarComponent = connect(mapStateToProps)(BookingCalendarComponent);
export { connectedBookingCalendarComponent as BookingCalendarComponent };
