import React from 'react';
import { connect } from 'react-redux';

import { Modal } from '../_components';

import { treatmentGroupActions } from '../_actions';

class Treatment extends React.Component {

    constructor(props) {
        super(props);

        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.handleEditClick = this.handleEditClick.bind(this);
        this.handlePricesClick = this.handlePricesClick.bind(this);
        this.handleIncreasePricesClick = this.handleIncreasePricesClick.bind(this);
        this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.handleShowClick = this.handleShowClick.bind(this);

        this.state = {
			modal: false,
            show: false
		};
    }

    handleDeleteClick() {
        this.setState({ modal: true })
    }

    handleEditClick() {
        var treatment = this.props.treatment;
        if (treatment) {
            this.props.dispatch(treatmentGroupActions.selectTreatment(treatment));
        }
    }

    handlePricesClick() {
        var treatment = this.props.treatment;
        if (treatment) {
            this.props.dispatch(treatmentGroupActions.showPrices(treatment));
        }
    }

    handleIncreasePricesClick() {
        var treatment = this.props.treatment;
        if (treatment) {
            this.props.dispatch(treatmentGroupActions.showIncreasePrices(treatment));
        }
    }

    handleDeleteConfirm() {
        var treatment = this.props.treatment;
        if (treatment) {
            this.props.dispatch(treatmentGroupActions.deleteTreatment(treatment));
        }
        this.setState({ modal: false })
    }

    hideModal() {
        this.setState({ modal: false })
    }

    handleShowClick() {
        this.setState({ show: !this.state.show })
    }


    render() {
        const { treatment, deletingTreatment } = this.props;
        const { modal, show } = this.state;
        if (treatment) {
            var styleClassExpired = '';
            if (treatment.current) {
                styleClassExpired = 'bg-dark';
            } else {
                styleClassExpired = 'text-dark bg-light';
            }
            var styleClassShowBody = '';
            var styleClassShowIcon = '';
            if (show) {
                styleClassShowBody = 'show';
                styleClassShowIcon = 'fa-angle-up';
            } else {
                styleClassShowIcon = 'fa-angle-down';
            }
            return (
                <div className={`card ${styleClassExpired}`}>
                    <div className="card-header">
                        <strong>{treatment.label}</strong>
                        <button type="button" className="btn btn-sm btn-outline-secondary float-right " onClick={this.handleShowClick}><span className={`fa ${styleClassShowIcon}`}></span></button>
                    </div>
                    <div className={`card-body collapse ${styleClassShowBody}`}>
                        <div className="card-text">
                            <div className="row">
                                <div className="col-4">Id</div>
                                <div className="col-8"><strong>{treatment.id}</strong></div>
                            </div>
                            <div className="row">
                                <div className="col-xl-4">Prices</div>
                                <ul className="col-xl-8 list-unstyled">
                                    {
                                        treatment.prices.map((price, index) =>
                                            <li key={"Price_" + price.id} className="">
                                                <strong>{price.durationFormatted + ' - ' + price.priceFormatted}</strong>
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>

                            <div className="row">
                                <div className="col-xl-4">Content</div>
                                { treatment.contentAbbreviated &&
                                    <div className="col-xl-8"><strong>{treatment.contentAbbreviated}</strong></div>
                                }
                                { !treatment.contentAbbreviated &&
                                    <div className="col-xl-8 small"><i>none</i></div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <button type="button" className="btn btn-primary btn-sm mr-1 mb-1" onClick={this.handleEditClick}>Edit</button>
                        <button type="button" className="btn btn-info btn-sm mr-1 mb-1" onClick={this.handlePricesClick}>Prices</button>
                        <button type="button" className="btn btn-warning btn-sm mr-1 mb-1" onClick={this.handleIncreasePricesClick}>Increase prices</button>
                        <button type="button" className="btn btn-danger btn-sm mr-1 mb-1" onClick={this.handleDeleteClick}>Delete</button>
                    </div>
                    {modal &&
                        <Modal id="deleteConfirmation"
                            title="Delete confirmation"
                            body= {`Are you sure you want to delete the treatment?`}
                            primaryButtonLabel="Yes"
                            primaryButtonLabelWait="Deleting..."
                            primaryFunction={this.handleDeleteConfirm}
                            primaryFunctionWait={deletingTreatment}
                            primaryButtonClass="btn-danger"
                            cancelFunction={this.hideModal}/>
                    }
                </div>

            );
        } else {
            return "";
        }
    }
}

function mapStateToProps(state) {
    const { deletingTreatment } = state.treatmentGroup;
    return {
        deletingTreatment
    };
}

const connectedTreatment = connect(mapStateToProps)(Treatment);
export { connectedTreatment as Treatment };
