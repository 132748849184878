import React from 'react';

import { BasketItem } from '.';

export class Basket extends React.Component {

    render() {
        const { items } = this.props;
        var total = 0;
        if (items && items.length > 0) {
            items.forEach(item => {
                total = total + item.price;
            });
        }
        if (items && items.length > 0) {
            return (
                <div className="col-sm-9">
                    {
                        items.map(function(item, index){
                            return <BasketItem key={`basketItem_${index}`} item = {item}/>
                        })
                    }
                    <hr className="bg-secondary"/>
                    <div className="row mx-1">
                        <div className="col-6 col-md-8 form-control-plaintext" htmlFor="total">Total</div>
                        <div className="col-6 col-md-2 form-control-plaintext">
                            {'$'+total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div>No items in this booking</div>
            );
        }
    }
}
