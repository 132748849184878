import React from 'react';

import { SlotGroup } from '.';

export class SlotGroups extends React.Component {

    render() {
        const { slotGroups } = this.props;
        if (slotGroups && slotGroups.length > 0) {
            return (
                <div className="col-md-8">
                    {slotGroups.map((slotGroup, index) =>
                        <SlotGroup key={"SlotGroup_" + slotGroup.id} slotGroup={slotGroup}/>
                    )}
                </div>
            );
        } else {
            return (
                <div className="row">
                    <div className="col form-control-plaintext">
                        No sessions
                    </div>
                </div>
            );
        }
    }
}
