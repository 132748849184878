import axios from 'axios';

import { http } from '../_helpers';

const URL = http.BASE_URL + '/ping';

export const pingService = {
  ping
};

function ping() {
    const url = `${URL}`;
    try {
        return axios.get(url, {
                headers: http.HEADERS,
                withCredentials: true
            }
        ).then(response => {
            if (!response.status === 200) {
                return Promise.reject(response.statusText);
            }

            return;
        }).catch(result=> {
            return http.handleHttpError(result, 401, 'Session is gone');
        });
    } catch (exception) {
        console.error("!!!!! exception on login : " + exception);
    }


}
