import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/en-nz';

import { financeActions, alertActions } from '../_actions';
import { SubmitButton, DateField } from '../_components';

class TransactionEditPage extends React.Component {

    constructor(props) {
        super(props);

        this.handleBackClick = this.handleBackClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleOnDateChanged = this.handleOnDateChanged.bind(this);
        this.categoryChanged = this.categoryChanged.bind(this);
        this.onCategoryChanged = this.onCategoryChanged.bind(this);
        this.typeChanged = this.typeChanged.bind(this);
        this.onTypeChanged = this.onTypeChanged.bind(this);
        this.populateData = this.populateData.bind(this);
        this.handleSlotGroupDetailClick = this.handleSlotGroupDetailClick.bind(this);

        if (!props.selectedFinancialTransaction) {
            this.state = {
                newTransaction: {
                    date: moment()
                },
                submitted: false,
                date: null,
                focusedInputStart: false,
                focusedInputEnd: false
            };
            this.props.history.push("/finances");
        } else {
            this.state = {
                newTransaction: {
                    id: props.selectedFinancialTransaction.id,
                    slotGroupId: props.selectedFinancialTransaction.slotGroupId,
                    date: props.selectedFinancialTransaction.date,
                    amount: props.selectedFinancialTransaction.amount,
                    note: props.selectedFinancialTransaction.note,
                    financialTransactionTypeLabel: props.selectedFinancialTransaction.financialTransactionTypeLabel,
                    financialTransactionTypeCode: props.selectedFinancialTransaction.financialTransactionTypeCode,
                    transactionCategoryId: props.selectedFinancialTransaction.transactionCategoryId,
                    transactionCategoryLabel: props.selectedFinancialTransaction.transactionCategoryLabel,
                    transfer: props.selectedFinancialTransaction.transfer,
                    deletable: props.selectedFinancialTransaction.deletable,
                    deletableSlotGroup: props.selectedFinancialTransaction.deletableSlotGroup,
                    editableSlotGroup: props.selectedFinancialTransaction.editableSlotGroup
                },
                submitted: false,
                date: null,
                focusedInputStart: false,
                focusedInputEnd: false
            };
        }
    }

    componentDidMount() {
        if (!this.props.loggedAs) {
            this.props.history.push("/login");
        }
        this.populateData();
    }

    componentDidUpdate() {
        if (!this.props.loggedAs) {
            this.props.history.push("/login");
        }
        this.populateData();
    }

    handleBackClick() {
        this.props.history.push("/finances");
    }

    handleSlotGroupDetailClick() {
        this.props.dispatch(financeActions.viewSlotGroup(this.props.selectedFinancialTransaction.slotGroup));
    }

    populateData() {
        const { dispatch,
            selectedFinancialTransaction,
            financialTransactionCategories,
            requestingFinancialTransactionCategories,
            financialTransactionTypes,
            requestingFinancialTransactionTypes,
            requestingSlotGroup
        } = this.props;
        if (!financialTransactionCategories && !requestingFinancialTransactionCategories) {
            dispatch(financeActions.getFinancialTransactionCategories());
        }
        if (!financialTransactionTypes && !requestingFinancialTransactionTypes) {
            dispatch(financeActions.getFinancialTransactionTypes());
        }
        if (selectedFinancialTransaction && selectedFinancialTransaction.slotGroupId !== -1 && !selectedFinancialTransaction.slotGroup && !requestingSlotGroup) {
            dispatch(financeActions.getSlotGroup(selectedFinancialTransaction.slotGroupId));
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        var form = document.getElementById('transactionForm');
        if (form.checkValidity() === false) {
            form.classList.add('was-validated');
            e.stopPropagation();
            return;
        }

        const { dispatch } = this.props;
        dispatch(alertActions.clear());
        // Convert the date into string
        // const { newTransaction } = this.state;
        // var transaction = {
        //     ...newTransaction,
        //     date: newTransaction.date == null ? null : newTransaction.date.format("YYYY-MM-DD")
        // };
        dispatch(financeActions.updateFinancialTransaction(this.state.newTransaction));
    }

    handleChange(e) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const { newTransaction } = this.state;
        this.setState({
            newTransaction: {
                ...newTransaction,
                [name]: value
            }
        });
    }

    handleOnDateChanged(dateMoment) {
        let state = {...this.state};
        state.newTransaction.date = dateMoment;
        this.setState(state);
    }

    categoryChanged(categoryId) {
        var category = this.props.financialTransactionCategories.find(function(category) {
            return category.id === parseInt(categoryId);
        }, categoryId);
        const { newTransaction } = this.state;
        this.setState({
            newTransaction: {
                ...newTransaction,
                transactionCategoryId: category.id,
                transactionCategoryLabel: category.label
            }
        });
    }

    onCategoryChanged(event) {
        if (event.currentTarget.value) {
            this.categoryChanged(event.currentTarget.value);
        }
    }

    typeChanged(typeCode) {
        var type = this.props.financialTransactionTypes.find(function(type) {
            return type.code === typeCode;
        }, typeCode);
        const { newTransaction } = this.state;
        this.setState({
            newTransaction: {
                ...newTransaction,
                financialTransactionTypeCode: type.code,
                financialTransactionTypeLabel: type.label
            }
        });
    }

    onTypeChanged(event) {
        if (event.currentTarget.value) {
            this.typeChanged(event.currentTarget.value);
        }
    }

    render() {
        const {
            selectedFinancialTransaction,
            financialTransactionCategories,
            requestingFinancialTransactionCategories,
            financialTransactionTypes,
            requestingFinancialTransactionTypes,
            requestingSlotGroup,
            updatingFinancialTransaction
        } = this.props;
        const { newTransaction } = this.state;
            return (
                <div className="col-lg-10">
                    <h2 className="text-center">Edit transaction</h2>
                    <div className="card card-primary card-transparent">
                        <div className="card-body">
                            <form id="transactionForm" noValidate className="needs-validation my-4" onSubmit={this.handleSubmit}>
                                <div className="form-group row">
                                    <label className="col-3 col-lg-2 col-form-label" htmlFor="id">Id</label>
                                    <div className="col-9">
                                        <input type="text" className="form-control-plaintext font-weight-bold" name="id" value={newTransaction.id} readOnly/>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-3 col-lg-2 col-form-label" >Date</label>
                                    {newTransaction.slotGroupId === -1 && <div className="col-9">
                                        <DateField
                                            id="date"
                                            dateTime={newTransaction.date}
                                            onChange={this.handleOnDateChanged}
                                            required={true}
                                            />
                                    </div>}
                                    {newTransaction.slotGroupId !== -1 && <div className="col-9">
                                        <input type="text" className="form-control-plaintext font-weight-bold" name="date" value={newTransaction.date.format('DD/MM/YYYY')} readOnly/>
                                    </div>}
                                </div>

                                <div className="form-group row">
                                    <label className="col-3 col-lg-2 col-form-label" htmlFor="amount">Amount</label>
                                    <div className="col-9">
                                        <input type="text" className={newTransaction.slotGroupId !== -1 ? 'form-control-plaintext font-weight-bold' : 'form-control'} name="amount" value={newTransaction.amount} required onChange={this.handleChange} readOnly={newTransaction.slotGroupId !== -1}/>
                                        <div className="invalid-feedback">
                                            Please enter amount
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-3 col-lg-2 col-form-label" htmlFor="category">Category</label>
                                    <div className="col-9">
                                        {(requestingFinancialTransactionCategories) && <span>Loading categories ...</span>}
                                        {newTransaction.slotGroupId === -1 && financialTransactionCategories && !requestingFinancialTransactionCategories && <select class="form-control" name="category" id="category" value={newTransaction.transactionCategoryId ? newTransaction.transactionCategoryId : -1} onChange={this.onCategoryChanged}>
                                                {financialTransactionCategories.map((item, index) =>
                                                    <option key={`category_${index}`} value={item.id}>{item.label}</option>
                                                )}
                                            </select>
                                        }
                                        {newTransaction.slotGroupId !== -1 && <input type="text" className="form-control-plaintext font-weight-bold" name="category" value={newTransaction.transactionCategoryLabel} readOnly/>}
                                        <div className="invalid-feedback">
                                            Please pick a category
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-3 col-lg-2 col-form-label" htmlFor="type">Type</label>
                                    <div className="col-9">
                                        {(requestingFinancialTransactionTypes) && <span>Loading types ...</span>}
                                        {newTransaction.slotGroupId === -1 && financialTransactionTypes && !requestingFinancialTransactionTypes && <select class="form-control" name="type" id="type" value={newTransaction.financialTransactionTypeCode ? newTransaction.financialTransactionTypeCode : -1} onChange={this.onTypeChanged}>
                                                {financialTransactionTypes.map((item, index) =>
                                                    <option key={`type_${index}`} value={item.code}>{item.label}</option>
                                                )}
                                            </select>
                                        }
                                        {newTransaction.slotGroupId !== -1 && <input type="text" className="form-control-plaintext font-weight-bold" name="type" value={newTransaction.financialTransactionTypeLabel} readOnly/>}
                                        <div className="invalid-feedback">
                                            Please pick a type
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-3 col-lg-2 col-form-label" htmlFor="note">Note</label>
                                    <div className="col-sm-9">
                                        <textarea className="form-control" name="note" rows="5" value={newTransaction.note} onChange={this.handleChange}/>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-3 col-lg-2 col-form-label" >Extra stuff</label>
                                    <div className="col-9 pt-2">
                                        <input className="form-check-input ml-2" type="checkbox" checked={!!newTransaction.transfer} name="transfer" onChange={this.handleChange}/>
                                        <label className="form-check-label ml-4" htmlFor="transfer">Is transfer</label>
                                    </div>
                                </div>

                                { selectedFinancialTransaction && selectedFinancialTransaction.slotGroupId !== -1 &&
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-lg-2 col-form-label" htmlFor="session">Session</label>
                                        <div className="col-sm-9">
                                            {requestingSlotGroup && <input type="text" className="form-control-plaintext font-weight-bold" name="session" value="Loading ..." readOnly/>}
                                            {selectedFinancialTransaction.slotGroup && !requestingSlotGroup && <input type="text" className="form-control-plaintext font-weight-bold" name="session" value={selectedFinancialTransaction.slotGroup.customerName + " " + selectedFinancialTransaction.slotGroup.sessions[0].treatmentLabel} readOnly/>}
                                        </div>
                                    </div>
                                }
                                <div className="form-group row align-items-center ml-1">
                                    <button type="button" className="btn btn-secondary col-md-2 col-xl-1 mr-2 mb-2" onClick={this.handleBackClick}>Back</button>
                                    <SubmitButton updating={updatingFinancialTransaction} label="Save transaction" labelLoading="Saving ..." className="col-md-3 col-xl-2 mr-2 mb-2"/>
                                    {selectedFinancialTransaction && selectedFinancialTransaction.slotGroup && !requestingSlotGroup &&
                                        <button type="button" className="btn btn-info col-md-3 col-xl-2 mr-2 mb-2" onClick={this.handleSlotGroupDetailClick}>Edit linked session</button>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            );
    }
}

function mapStateToProps(state) {
    const { loggedAs } = state.user;
    const {
        selectedFinancialTransaction,
        financialTransactionCategories,
        requestingFinancialTransactionCategories,
        financialTransactionTypes,
        requestingFinancialTransactionTypes,
        requestingSlotGroup,
        updatingFinancialTransaction
    } = state.finance;

    return {
        loggedAs,
        selectedFinancialTransaction,
        financialTransactionCategories,
        requestingFinancialTransactionCategories,
        financialTransactionTypes,
        requestingFinancialTransactionTypes,
        requestingSlotGroup,
        updatingFinancialTransaction
    };
}

const connectedTransactionEditPage = connect(mapStateToProps)(TransactionEditPage);
export { connectedTransactionEditPage as TransactionEditPage };
