import React from 'react';
import { financeConstants, reservationConstants, statisticsConstants } from '../_constants';
import { financeService, slotGroupService } from '../_services';
import { alertActions } from './';
import moment from 'moment';
import 'moment/locale/en-nz';
import { history, http, formatting } from '../_helpers';

export const financeActions = {
    getFinancialTransactions,
    resetFinancialTransactions,
    getSlotGroup,
    getFinancialTransactionCategories,
    getFinancialTransactionTypes,
    createFinancialTransaction,
    updateFinancialTransaction,
    deleteFinancialTransaction,
    selectFinancialTransaction,
    viewSlotGroup
};

function getFinancialTransactions(start, end) {
    return dispatch => {
        dispatch(request(start, end));

        financeService.getFinancialTransactions(start.format(formatting.DATE_TIME_FORMATTING), end.format(formatting.DATE_TIME_FORMATTING))
            .then(
                financialTransactions => {
                    financialTransactions.financialTransactionViews.map((financialTransaction, i) => {
                        financialTransaction.date = moment(financialTransaction.date, "YYYY-MM-DD");
                        return financialTransaction;
                    });
                    dispatch(success(financialTransactions));
                },
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request(fromDate, toDate) { return { type: financeConstants.GETFINANCIALTRANSACTIONS_REQUEST, fromDate, toDate } }
    function success(financialTransactions) { return { type: financeConstants.GETFINANCIALTRANSACTIONS_SUCCESS, financialTransactions } }
    function failure(message) { return { type: financeConstants.GETFINANCIALTRANSACTIONS_FAILURE, message } }
}

function resetFinancialTransactions(start, end) {
    return dispatch => {
        dispatch(reset(start, end));
    }

    function reset(fromDate, toDate) { return { type: financeConstants.RESETFINANCIALTRANSACTIONS_SUCCESS, fromDate, toDate } }
}

function getSlotGroup(id) {
    return dispatch => {
        dispatch(request());

        slotGroupService.getSlotGroup(id)
            .then(
                slotGroup => dispatch(success(slotGroup)),
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request() { return { type: financeConstants.GETSLOTGROUP_REQUEST } }
    function success(slotGroup) { return { type: financeConstants.GETSLOTGROUP_SUCCESS, slotGroup } }
    function failure(message) { return { type: financeConstants.GETSLOTGROUP_FAILURE, message } }
}

function getFinancialTransactionCategories() {
    return dispatch => {
        dispatch(request());

        financeService.getFinancialTransactionCategories()
            .then(
                financialTransactionCategories => dispatch(success(financialTransactionCategories)),
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request() { return { type: financeConstants.GETFINANCIALTRANSACTIONCATEGORIES_REQUEST } }
    function success(financialTransactionCategories) { return { type: financeConstants.GETFINANCIALTRANSACTIONCATEGORIES_SUCCESS, financialTransactionCategories } }
    function failure(message) { return { type: financeConstants.GETFINANCIALTRANSACTIONCATEGORIES_FAILURE, message } }
}

function getFinancialTransactionTypes() {
    return dispatch => {
        dispatch(request());

        financeService.getFinancialTransactionTypes()
            .then(
                financialTransactionTypes => dispatch(success(financialTransactionTypes)),
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request() { return { type: financeConstants.GETFINANCIALTRANSACTIONTYPES_REQUEST } }
    function success(financialTransactionTypes) { return { type: financeConstants.GETFINANCIALTRANSACTIONTYPES_SUCCESS, financialTransactionTypes } }
    function failure(message) { return { type: financeConstants.GETFINANCIALTRANSACTIONTYPES_FAILURE, message } }
}

function createFinancialTransaction(financialTransaction) {
    return dispatch => {
        dispatch(rememberDate(financialTransaction.date))
        dispatch(request());
        financialTransaction.ref = React.createRef();
        // Convert the date into string so it can be posted to the server
        var financialTransactionForServer = {
            ...financialTransaction,
            date: financialTransaction.date == null ? null : financialTransaction.date.startOf('day').format(formatting.DATE_TIME_FORMATTING)
        };
        financeService.createFinancialTransaction(financialTransactionForServer)
            .then(
                financialTransactionReceived => {
                    financialTransactionReceived.date = moment(financialTransactionReceived.date, "YYYY-MM-DD");
                    dispatch(success(financialTransactionReceived));
                    dispatch(resetFinancialTransaction());
                    dispatch(resetStatistics());
                    history.push('/finances');
                    dispatch(alertActions.success('The transaction has been created.'));
                },
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function rememberDate(financialTransactionDate) { return { type: financeConstants.CREATEFINANCIALTRANSACTION_REMEMBERDATE, financialTransactionDate } }
    function request() { return { type: financeConstants.CREATEFINANCIALTRANSACTION_REQUEST } }
    function resetFinancialTransaction() { return { type: financeConstants.RESETFINANCIALTRANSACTION_SUCCESS } }
    function resetStatistics() { return { type: statisticsConstants.RESETSPECIFIC_SUCCESS } }
    function success(financialTransaction) { return { type: financeConstants.CREATEFINANCIALTRANSACTION_SUCCESS, financialTransaction } }
    function failure(message) { return { type: financeConstants.CREATEFINANCIALTRANSACTION_FAILURE, message } }
}

function updateFinancialTransaction(financialTransaction) {
    return dispatch => {
        dispatch(request());
        // Convert the date into string so it can be posted to the server
        var financialTransactionForServer = {
            ...financialTransaction,
            date: financialTransaction.date == null ? null : financialTransaction.date.startOf('day').format(formatting.DATE_TIME_FORMATTING)
        };
        financeService.updateFinancialTransaction(financialTransactionForServer)
            .then(
                financialTransactionReceived => {
                    financialTransactionReceived.date = moment(financialTransactionReceived.date, "YYYY-MM-DD");
                    dispatch(success(financialTransactionReceived));
                    dispatch(resetFinancialTransaction());
                    dispatch(resetStatistics());
                    history.push('/finances');
                    dispatch(alertActions.success('The transaction has been updated.'));
                },
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request() { return { type: financeConstants.UPDATEFINANCIALTRANSACTION_REQUEST } }
    function resetFinancialTransaction() { return { type: financeConstants.RESETFINANCIALTRANSACTION_SUCCESS } }
    function resetStatistics() { return { type: statisticsConstants.RESETSPECIFIC_SUCCESS } }
    function success(financialTransaction) { return { type: financeConstants.UPDATEFINANCIALTRANSACTION_SUCCESS, financialTransaction } }
    function failure(message) { return { type: financeConstants.UPDATEFINANCIALTRANSACTION_FAILURE, message } }
}

function deleteFinancialTransaction(financialTransaction) {
    return dispatch => {
        dispatch(request());
        financeService.deleteFinancialTransaction(financialTransaction)
            .then(
                event => {
                    dispatch(success(financialTransaction));
                    var formatter = new Intl.NumberFormat('en-NZ', {
                      style: 'currency',
                      currency: 'NZD',
                    });
                    dispatch(alertActions.success('The transaction for ' + formatter.format(financialTransaction.amount) + ' on ' + financialTransaction.date.format('Do MMM YYYY') + ' has been deleted.'));
                },
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request() { return { type: financeConstants.DELETEFINANCIALTRANSACTION_REQUEST } }
    function success() { return { type: financeConstants.DELETEFINANCIALTRANSACTION_SUCCESS, financialTransaction } }
    function failure(message) { return { type: financeConstants.DELETEFINANCIALTRANSACTION_FAILURE, message } }
}

function selectFinancialTransaction(selectedFinancialTransaction) {
    return dispatch => {
        dispatch(success(selectedFinancialTransaction));
        history.push('/financialTransactionEdit');
    };
    function success(selectedFinancialTransaction) {
      return { type: financeConstants.SELECTEDFINANCIALTRANSACTION_SUCCESS, selectedFinancialTransaction }
    }
}

function viewSlotGroup(selectedSlotGroup) {
    return dispatch => {
        dispatch(success(selectedSlotGroup));
        dispatch(setBackDestination('/financialTransactionEdit'));
        history.push('/slotGroupDetails');
    };
    function success(selectedEvent) {
        return { type: reservationConstants.SELECTEDEVENT_SUCCESS, selectedEvent }
    }
    function setBackDestination(backDestination) {
        return { type: reservationConstants.SELECTEDEVENTBACKDESTINATION_SUCCESS, backDestination }
    }
}
