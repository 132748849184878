import { voucherConstants } from '../_constants';

const initialState = {};

export function voucher(state = initialState, action) {
    switch (action.type) {
        case voucherConstants.GETVOUCHERS_REQUEST:
            return Object.assign({}, state, { requestingVouchers: true });
        case voucherConstants.GETVOUCHERS_SUCCESS:
            return Object.assign({}, state, {
                requestingVouchers: false,
                vouchers: action.vouchers.voucherViews
            });
        case voucherConstants.GETVOUCHERS_FAILURE:
            return Object.assign({}, state, { requestingVouchers: false});
        case voucherConstants.CREATE_REQUEST:
            return Object.assign({}, state, { creatingVoucher: true });
        case voucherConstants.CREATE_SUCCESS:
            return Object.assign({}, state, { creatingVoucher: false });
        case voucherConstants.CREATE_FAILURE:
            return Object.assign({}, state, { creatingVoucher: false});
        case voucherConstants.DELETE_REQUEST:
            return Object.assign({}, state, { deletingVoucher: true });
        case voucherConstants.DELETE_SUCCESS:
            return Object.assign({}, state, { deletingVoucher: false });
        case voucherConstants.DELETE_FAILURE:
            return Object.assign({}, state, { deletingVoucher: false});
        case voucherConstants.RESETVOUCHERS_SUCCESS:
            return Object.assign({}, state, {
                requestingVouchers: false,
                deletingVoucher: false,
                vouchers: null
            });
        case voucherConstants.PAYVOUCHER_REQUEST:
            return Object.assign({}, state, { payingVoucher: true });
        case voucherConstants.PAYVOUCHER_SUCCESS:
            return Object.assign({}, state, {
                payingVoucher: false,
                vouchers: state.vouchers.map(
                    (voucher, i) => voucher.id === action.voucher.id ? {...voucher, payed: action.voucher.payed} : voucher
                )
            });
        case voucherConstants.PAYVOUCHER_FAILURE:
            return Object.assign({}, state, { payingVoucher: false});
        default:
            return state;
    }
}
