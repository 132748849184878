import React from 'react';
import { connect } from 'react-redux';
import { formatting } from '../_helpers';
import moment from 'moment';
import 'moment/locale/en-nz';

import { slotGroupActions, userActions, reservationTherapistActions } from '../_actions';

import { MyCalendar } from '../_components';

class BookingTherapistCalendarPage extends React.Component {

    constructor(props) {
        super(props);

        this.handleClickResetTime = this.handleClickResetTime.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSelectEvent = this.handleSelectEvent.bind(this);
        this.handleSelectSlot = this.handleSelectSlot.bind(this);
        this.handleView = this.handleView.bind(this);
        this.fetchSlotGroups = this.fetchSlotGroups.bind(this);
        this.fetchTherapistLeave = this.fetchTherapistLeave.bind(this);
        this.handleResetBasket = this.handleResetBasket.bind(this);
        this.handleResetCustomer = this.handleResetCustomer.bind(this);
    }

    componentDidMount() {
        if (!this.props.loggedAs) {
            this.props.history.push("/login");
        }
        this.fetchSlotGroups();
        this.fetchTherapistLeave();
        this.handleResetBasket();
        this.handleResetCustomer();
    }

    componentDidUpdate() {
        console.log("componentDidUpdate");
        this.fetchSlotGroups();
        this.fetchTherapistLeave();
    }

    fetchSlotGroups() {
        console.log("fetchSlotGroups");
        const { slotGroup, reservation } = this.props;
        if ((!slotGroup
            || (!slotGroup.slotGroups)
            || (!slotGroup.slotGroups.slotGroupViews))
            && !slotGroup.loading) {
                console.log("handleDateChange - reservation.selectedDate: " + reservation.selectedDate);
                var date = reservation.selectedDate;
                if (!date) {
                    date = new Date();
                }
                console.log("handleDateChange - date: " + date);
                const start = moment(date).startOf('isoweek').startOf('day').format(formatting.DATE_TIME_FORMATTING);
                const end = moment(date).endOf('isoweek').endOf('day').format(formatting.DATE_TIME_FORMATTING);
                this.props.dispatch(slotGroupActions.selectDate(date));
                this.props.dispatch(slotGroupActions.getBookings(start, end));
        } else {
            console.log("fetchSlotGroups - nothing to fetch - loading: " + slotGroup.loading + "; slotGroup: " + slotGroup);
        }
    }

    fetchTherapistLeave() {
        console.log("fetchTherapistLeave");
        const { user, reservation } = this.props;
        if ((!user
            || (!user.leave))
            && !user.loadingLeave) {
                var date = reservation.selectedDate;
                if (!date) {
                    date = new Date();
                }
                const start = moment(date).startOf('isoweek').startOf('day').format(formatting.DATE_TIME_FORMATTING);
                const end = moment(date).endOf('isoweek').endOf('day').format(formatting.DATE_TIME_FORMATTING);
                this.props.dispatch(userActions.findLeave(start, end));
        } else {
            console.log("fetchTherapistLeave - nothing to fetch - loading: " + user.loadingLeave);
        }
    }

    handleSelectSlot(slot) {
        var event = {
            start: slot.start,
            end: slot.end
        };
        if (slot.action === 'click' || slot.action === 'select') {
            this.props.dispatch(slotGroupActions.pickSlotGroupType(event));
        }
    }

    handleClickResetTime() {
        this.props.dispatch(slotGroupActions.resetEvent());
    }

    handleDateChange(date, view) {
        console.log("handleDateChange - date: " + date + ', view: ' + view);
        var start = moment(date).startOf('isoweek').startOf('day').format(formatting.DATE_TIME_FORMATTING);
        var end = moment(date).endOf('isoweek').endOf('day').format(formatting.DATE_TIME_FORMATTING);
        if (view === 'day') {
            start = moment(date).startOf('day').startOf('day').format(formatting.DATE_TIME_FORMATTING);
            end = moment(date).endOf('day').endOf('day').format(formatting.DATE_TIME_FORMATTING);
        }
        this.props.dispatch(slotGroupActions.selectDate(date));
        this.props.dispatch(slotGroupActions.getBookings(start, end));
        this.props.dispatch(userActions.findLeave(start, end));
    }

    handleSelectEvent(event) {
        this.props.dispatch(slotGroupActions.selectSlotGroup(event, this.props.loggedAs));
    }

    handleView(view) {
        console.log(JSON.stringify(view));
    }

    handleResetBasket() {
        this.props.dispatch(reservationTherapistActions.resetBasket());
    }

    handleResetCustomer() {
        this.props.dispatch(reservationTherapistActions.resetCustomer());
    }

    eventStyleGetter(event, start, end, isSelected) {
        var style = {
            color: event.textColor,
            backgroundColor: event.backgroundColor,
            opacity: 0.8,
            fontSize: '9px',
            fontWeight: 'bold',
            display: 'block'
        };
        return {
            style: style
        };
    }

    eventTitleAccessor(event) {
        return event.title;
    }

    tooltipAccessor(event) {
        var toolTip = "";
        if (event.stateCode === 'notWorking') {
            toolTip = "\n" + event.therapist + "\n" + event.place + "\n";
        } else if (event.eventType === 'leave') {
            toolTip = "\n" + event.therapist + "\n" + event.start + "\n" + event.end;
        } else {
            toolTip = "\n" + event.therapist + "\n" + event.place + "\n"
            toolTip = toolTip + event.customerName + " - " + event.customerContact;
            for (var i = 0; i < event.sessions.length; i++) {
                toolTip = toolTip + "\n" + event.sessions[i].treatmentLabel + " - " + event.sessions[i].priceFormatted + " - " + event.sessions[i].durationFormatted;
            }
            toolTip = toolTip + "\nTotal: " + event.totalPrice + " - " + event.totalDuration;
        }
        return toolTip;
    }

    render() {
        const { reservation, slotGroup, user } = this.props;

        if (slotGroup.slotGroups) {
            var events = slotGroup.slotGroups.slotGroupViews;
            if (user.leave) {
                events = events.concat(user.leave);
            }
            return (
                <div className="col-12 col-lg-10">
                    <h2 className="text-center">Calendar</h2>
                    <div className="card card-primary card-transparent">
                        <div className="card-body p-1">
                            <fieldset key="BookingCalendarComponent" className="form-group">
                                <div className="row mb-1">
                                    {slotGroup.loading &&
                                        <div className="col big-calendar-height">
                                            Loading ...
                                        </div>
                                    }
                                    {!slotGroup.loading && events &&
                                        <div className="col big-calendar-therapist-height">
                                            <MyCalendar
                                                events={events}
                                                date={reservation.selectedDate}
                                                onNavigate={this.handleDateChange}
                                                eventPropGetter={this.eventStyleGetter}
                                                titleAccessor={this.eventTitleAccessor}
                                                tooltipAccessor={this.tooltipAccessor}
                                                onSelectEvent={this.handleSelectEvent}
                                                onSelectSlot={this.handleSelectSlot}
                                                min={moment("06:00:00", "HH:mm:ss").toDate()}
                                                max={moment("22:00:00", "HH:mm:ss").toDate()}
                                            />
                                        </div>
                                    }
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
             );
         } else {
             return ("");
         }
    }
}

function mapStateToProps(state) {
    const { loggedAs } = state.user;
    const { reservation, slotGroup, user } = state;

    return {
        loggedAs,
        reservation,
        slotGroup,
        user
    };
}

const connectedBookingTherapistCalendarPage = connect(mapStateToProps)(BookingTherapistCalendarPage);
export { connectedBookingTherapistCalendarPage as BookingTherapistCalendarPage };
