import { treatmentGroupConstants } from '../_constants';

const initialState = {};

export function treatmentGroup(state = initialState, action) {
    switch (action.type) {
        case treatmentGroupConstants.GETGROUPS_REQUEST:
            return Object.assign({}, state, {
                requestingTreatmentGroups: true
            });
        case treatmentGroupConstants.GETGROUPS_SUCCESS:
            return Object.assign({}, state, {
                requestingTreatmentGroups: false,
                treatmentGroups: action.treatmentGroups.treatmentGroupViews
            });
        case treatmentGroupConstants.GETGROUPS_FAILURE:
            return Object.assign({}, state, {
                requestingTreatmentGroups: false,
                error: action.message
            });
        case treatmentGroupConstants.CREATEGROUP_REQUEST:
            return Object.assign({}, state, { creatingTreatmentGroup: true });
        case treatmentGroupConstants.CREATEGROUP_SUCCESS:
            return Object.assign({}, state, { creatingTreatmentGroup: false });
        case treatmentGroupConstants.CREATEGROUP_FAILURE:
            return Object.assign({}, state, {
                creatingTreatmentGroup: false,
                error: action.message
            });
        case treatmentGroupConstants.DELETEGROUP_REQUEST:
            return Object.assign({}, state, { deletingTreatmentGroup: true });
        case treatmentGroupConstants.DELETEGROUP_SUCCESS:
            return Object.assign({}, state, { deletingTreatmentGroup: false });
        case treatmentGroupConstants.DELETEGROUP_FAILURE:
            return Object.assign({}, state, {
                deletingTreatmentGroup: false,
                error: action.message
            });
        case treatmentGroupConstants.SELECTEDGROUP_SUCCESS:
            return Object.assign({}, state, {
                selectedTreatmentGroup: action.selectedTreatmentGroup,
                updating: false,
            });
        case treatmentGroupConstants.RESETGROUP_SUCCESS:
            return Object.assign({}, state, {
                selectedTreatmentGroup: null
            });
        case treatmentGroupConstants.CREATETREATMENT_REQUEST:
            return Object.assign({}, state, { creatingTreatment: true });
        case treatmentGroupConstants.CREATETREATMENT_SUCCESS: {
                state.selectedTreatmentGroup.treatmentViewList.push(action.treatment);
                return Object.assign({}, state, {
                    creatingTreatment: false,
                    treatmentGroups: state.treatmentGroups.map(
                        (treatmentGroup, i) => treatmentGroup.id === state.selectedTreatmentGroup.id ? state.selectedTreatmentGroup : treatmentGroup
                    )
                });
            }
        case treatmentGroupConstants.CREATETREATMENT_FAILURE:
            return Object.assign({}, state, {
                creatingTreatment: false,
                error: action.message
            });
        case treatmentGroupConstants.UPDATETREATMENT_REQUEST:
            return Object.assign({}, state, { updatingTreatment: true });
        case treatmentGroupConstants.UPDATETREATMENT_SUCCESS: {
                var updatedSelectedTreatmentGroup = {
                    ...state.selectedTreatmentGroup, treatmentViewList: state.selectedTreatmentGroup.treatmentViewList.map(
                        (treatment, i) => treatment.id === action.treatment.id ? action.treatment : treatment
                    )
                };
                return Object.assign({}, state, {
                    updatingTreatment: false,
                    selectedTreatmentGroup: updatedSelectedTreatmentGroup,
                    treatmentGroups: state.treatmentGroups.map(
                        (treatmentGroup, i) => treatmentGroup.id === state.selectedTreatmentGroup.id ? updatedSelectedTreatmentGroup : treatmentGroup
                    )
                });
            }
        case treatmentGroupConstants.UPDATETREATMENT_FAILURE:
            return Object.assign({}, state, {
                updatingTreatment: false,
                error: action.message
            });
        case treatmentGroupConstants.DELETETREATMENT_REQUEST:
            return Object.assign({}, state, { deletingTreatment: true });
        case treatmentGroupConstants.DELETETREATMENT_SUCCESS: {
                let indexTreatment = state.selectedTreatmentGroup.treatmentViewList.findIndex(treatment => treatment.id === action.treatment.id);
                // Don't use filter or splice as they are mutable ... have to use slice
                let filteredTreatments = [...state.selectedTreatmentGroup.treatmentViewList.slice(0, indexTreatment), ...state.selectedTreatmentGroup.treatmentViewList.slice(indexTreatment + 1)]

                let updatedSelectedTreatmentGroup = {...state.selectedTreatmentGroup};
                updatedSelectedTreatmentGroup.treatmentViewList = filteredTreatments;

                return Object.assign({}, state, {
                    selectedTreatmentGroup: updatedSelectedTreatmentGroup,
                    treatmentGroups: state.treatmentGroups.map(
                        (treatmentGroup, i) => treatmentGroup.id === state.selectedTreatmentGroup.id ? updatedSelectedTreatmentGroup : treatmentGroup
                    ),
                    deletingTreatment: false
                });
            }
        case treatmentGroupConstants.DELETETREATMENT_FAILURE:
            return Object.assign({}, state, {
                deletingTreatment: false,
                error: action.message
            });
        case treatmentGroupConstants.SELECTEDTREATMENT_SUCCESS:
            return Object.assign({}, state, {
                selectedTreatment: action.selectedTreatment,
                updating: false,
            });
        case treatmentGroupConstants.RESETTREATMENT_SUCCESS:
            return Object.assign({}, state, {
                selectedTreatment: null
            });
        case treatmentGroupConstants.CREATEPRICE_REQUEST:
            return Object.assign({}, state, { creatingPrice: true });
        case treatmentGroupConstants.CREATEPRICE_SUCCESS: {
                state.selectedTreatment.prices.push(action.price);
                let updatedSelectedTreatmentGroup = {
                    ...state.selectedTreatmentGroup, treatmentViewList: state.selectedTreatmentGroup.treatmentViewList.map(
                        (treatment, i) => treatment.id === state.selectedTreatment.id ? state.selectedTreatment : treatment
                    )
                };
                return Object.assign({}, state, {
                    creatingPrice: false,
                    selectedTreatmentGroup: updatedSelectedTreatmentGroup,
                    treatmentGroups: state.treatmentGroups.map(
                        (treatmentGroup, i) => treatmentGroup.id === state.selectedTreatmentGroup.id ? updatedSelectedTreatmentGroup : treatmentGroup
                    )
                });
            }
        case treatmentGroupConstants.CREATEPRICE_FAILURE:
            return Object.assign({}, state, {
                creatingPrice: false,
                error: action.message
            });
        case treatmentGroupConstants.UPDATEPRICE_REQUEST:
            return Object.assign({}, state, { updatingPrice: true });
        case treatmentGroupConstants.UPDATEPRICE_SUCCESS: {
                var updatedSelectedTreatment = {
                    ...state.selectedTreatment, prices: state.selectedTreatment.prices.map(
                        (price, i) => price.id === action.price.id ? action.price : price
                    )
                };
                let updatedSelectedTreatmentGroup = {
                    ...state.selectedTreatmentGroup, treatmentViewList: state.selectedTreatmentGroup.treatmentViewList.map(
                        (treatment, i) => treatment.id === updatedSelectedTreatment.id ? updatedSelectedTreatment : treatment
                    )
                };
                return Object.assign({}, state, {
                    updatingPrice: false,
                    selectedTreatment: updatedSelectedTreatment,
                    selectedTreatmentGroup: updatedSelectedTreatmentGroup,
                    treatmentGroups: state.treatmentGroups.map(
                        (treatmentGroup, i) => treatmentGroup.id === state.selectedTreatmentGroup.id ? updatedSelectedTreatmentGroup : treatmentGroup
                    )
                });
            }
        case treatmentGroupConstants.UPDATEPRICE_FAILURE:
            return Object.assign({}, state, {
                updatingPrice: false,
                error: action.message
            });
        case treatmentGroupConstants.DELETEPRICE_REQUEST:
            return Object.assign({}, state, { deletingPrice: true });
        case treatmentGroupConstants.DELETEPRICE_SUCCESS: {
                let indexPrice = state.selectedTreatment.prices.findIndex(price => price.id === action.price.id);
                // Don't use filter or splice as they are mutable ... have to use slice
                let filteredPrices = [...state.selectedTreatment.prices.slice(0, indexPrice), ...state.selectedTreatment.prices.slice(indexPrice + 1)]

                let updatedSelectedTreatment = {...state.selectedTreatment};
                updatedSelectedTreatment.prices = filteredPrices;
                let updatedSelectedTreatmentGroup = {
                    ...state.selectedTreatmentGroup, treatmentViewList: state.selectedTreatmentGroup.treatmentViewList.map(
                        (treatment, i) => treatment.id === updatedSelectedTreatment.id ? updatedSelectedTreatment : treatment
                    )
                };
                return Object.assign({}, state, {
                    selectedTreatment: updatedSelectedTreatment,
                    selectedTreatmentGroup: updatedSelectedTreatmentGroup,
                    treatmentGroups: state.treatmentGroups.map(
                        (treatmentGroup, i) => treatmentGroup.id === state.selectedTreatmentGroup.id ? updatedSelectedTreatmentGroup : treatmentGroup
                    ),
                    deletingPrice: false
                });
            }
        case treatmentGroupConstants.DELETEPRICE_FAILURE:
            return Object.assign({}, state, {
                deletingPrice: false,
                error: action.message
            });
        case treatmentGroupConstants.SELECTEDPRICE_SUCCESS:
            return Object.assign({}, state, {
                selectedPrice: action.selectedPrice,
                updating: false,
            });
        case treatmentGroupConstants.RESETPRICE_SUCCESS:
            return Object.assign({}, state, {
                selectedPrice: null
            });

        case treatmentGroupConstants.RESETGROUPS_SUCCESS:
            return Object.assign({}, state, {
                requestingTreatmentGroups: false,
                deletingTreatmentGroups: false,
                treatmentGroups: null
            });
        case treatmentGroupConstants.GETGROUPSFORPLACEANDDATE_REQUEST:
            return Object.assign({}, state, {
                requestingTreatmentGroups: true
            });
        case treatmentGroupConstants.GETGROUPSFORPLACEANDDATE_SUCCESS:
            return Object.assign({}, state, {
                requestingTreatmentGroups: false,
                treatmentGroups: action.treatmentGroups.treatmentGroupViews
            });
        case treatmentGroupConstants.GETGROUPSFORPLACEANDDATE_FAILURE:
            return Object.assign({}, state, {
                requestingTreatmentGroups: false,
                error: action.error
            });
        case treatmentGroupConstants.GETTHERAPISTS_REQUEST:
            return Object.assign({}, state, {
                requestingTherapists: true
            });
        case treatmentGroupConstants.GETTHERAPISTS_SUCCESS:
            return Object.assign({}, state, {
                requestingTherapists: false,
                therapists: action.therapists.therapistViews
            });
        case treatmentGroupConstants.GETTHERAPISTS_FAILURE:
            return Object.assign({}, state, {
                requestingTherapists: false,
                error: action.error
            });
        case treatmentGroupConstants.RESETTHERAPISTS_SUCCESS:
            return Object.assign({}, state, {
                therapists: null
            });
        case treatmentGroupConstants.GETPRICES_REQUEST:
            return Object.assign({}, state, {
                requestingPrices: true
            });
        case treatmentGroupConstants.GETPRICES_SUCCESS:
            return Object.assign({}, state, {
                requestingPrices: false,
                prices: action.prices.priceViews
            });
        case treatmentGroupConstants.GETPRICES_FAILURE:
            return Object.assign({}, state, {
                requestingPrices: false,
                error: action.error
            });
        case treatmentGroupConstants.RESETPRICES_SUCCESS:
            return Object.assign({}, state, {
                prices: null
            });
        case treatmentGroupConstants.UPDATEPRICES_REQUEST:
            return Object.assign({}, state, {
                updatingPrices: true
            });
        case treatmentGroupConstants.UPDATEPRICES_SUCCESS:
            return Object.assign({}, state, {
                updatingPrices: false,
                treatmentGroups: null
            });
        case treatmentGroupConstants.UPDATEPRICES_FAILURE:
            return Object.assign({}, state, {
                updatingPrices: false,
                error: action.error
            });
        default: {
            return state;
        }
    }
}
