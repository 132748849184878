import axios from 'axios';

import { http } from '../_helpers';

const URL = http.BASE_URL + '/api/voucher';

export const voucherService = {
    getVouchers,
    createVoucher,
    deleteVoucher
};

function getVouchers(filter) {
    const url = `${URL}?filter=` + filter;
    return axios.get(url, {
        headers: http.HEADERS,
        withCredentials: true
    })
    .then(response => {
        if (response && response.data) {
            return response.data;
        }
    })
    .catch(result=> {
        if (result.__CANCEL__ === undefined) {
            return http.handleHttpError(result);
        }
    });
}

function createVoucher(voucher) {
    const url = `${URL}`
    return axios.post(url,
        voucher,
        {
            headers: http.HEADERS,
            withCredentials: true
        })
        .then(response => {
            if (response && response.data) {
                return response.data;
            }
        })
        .catch(result=> {
            return http.handleHttpError(result);
        }
    );
}

function deleteVoucher(voucher) {
    const url = `${URL}/` + voucher.id;
    return axios.delete(url,
        {
            headers: http.HEADERS,
            withCredentials: true
        })
        .then(response => {
            if (response && response.data) {
                return response.data;
            }
        })
        .catch(result=> {
            return http.handleHttpError(result);
        }
    );
}
