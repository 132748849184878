import React from 'react';
import posed from 'react-pose';

const ValuesDiv = posed.div({
    open: {
        x: '0%',
        delayChildren: 200,
        staggerChildren: 50
    },
    closed: { x: '-100%', delay: 300, staggerChildren: 20 },
    initialPose: 'closed'
});

const ValueDiv = posed.div({
    open: { y: 0, opacity: 1 },
    closed: { y: -20, opacity: 0,
    transition: { type: 'spring', stiffness: 100 } }
});

export class Items extends React.Component {
    state = { isVisible: false };

    constructor(props) {
        super(props);

        this.sleep = this.sleep.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        this.setState({
            isVisible: true
        });
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async handleClick(e) {
        var id = e.target.alt;
        this.setState({
            isVisible: false
        });
        await this.sleep(300);
        this.props.onClickHandle(id);
    }

    render() {
        const { label, values, loading, error, keyPrefix } = this.props;
        const { isVisible } = this.state;
        return (
            <fieldset className="form-group">
                <div className="row mb-1">
                    <legend className="col-form-label col-sm-4 col-md-4 col-lg-3 pt-0">{label}</legend>
                    <ValuesDiv className="col-sm-4 col-md-5 col-lg-7" pose={isVisible ? 'open' : 'closed'}>
                    {loading && <em>Loading ...</em>}
                    {error && <span className="text-danger">ERROR: {error}</span>}
                    {!loading && values && values.map((item, index) =>
                        <ValueDiv key={keyPrefix + index} className="">
                            <input className="form-check-input" type="radio" name="treatmentGroup"
                                   id={keyPrefix + item.id}
                                   onChange={this.handleClick}
                                   alt={item.id}/>
                            <label className="form-check-label font-weight-bold" htmlFor={keyPrefix + item.id}>{item.label}</label>
                        </ValueDiv>
                    )}
                    </ValuesDiv>
                </div>
            </fieldset>
        );
    }
}
