import { statisticsConstants } from '../_constants';

const initialState = {};

export function statistics(state = initialState, action) {
    switch (action.type) {
        case statisticsConstants.GENERAL_REQUEST:
            return Object.assign({}, state, {
                requestingGeneralStatistics: true,
            });
        case statisticsConstants.GENERAL_SUCCESS:
            return Object.assign({}, state, {
                requestingGeneralStatistics: false,
                generalStatistics: action.generalStatistics
            });
        case statisticsConstants.GENERAL_FAILURE:
            return Object.assign({}, state, {
                requestingGeneralStatistics: false
            });
        case statisticsConstants.SPECIFIC_REQUEST:
            return Object.assign({}, state, {
                requestingSpecificStatistics: true,
                specificStatistics: null,
                specificStatisticsFailure: null
            });
        case statisticsConstants.SPECIFIC_SUCCESS:
            return Object.assign({}, state, {
                requestingSpecificStatistics: false,
                specificStatistics: action.specificStatistics,
                specificStatisticsFailure: null
            });
        case statisticsConstants.SPECIFIC_FAILURE:
            return Object.assign({}, state, {
                requestingSpecificStatistics: false,
                specificStatistics: 'failure',
                specificStatisticsFailure: action.message
            });
        case statisticsConstants.RESETSPECIFIC_SUCCESS:
            return Object.assign({}, state, {
                specificStatistics: null,
                requestingSpecificStatistics: false,
                specificStatisticsFailure: null
            });

        default:
            return state;
    }
}
