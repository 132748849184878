export const reservationConstants = {
  SELECTEDGROUP_SUCCESS: 'RESERVATION_SELECTEDGROUP_SUCCESS',
  RESETGROUP_SUCCESS: 'RESERVATION_RESETGROUP_SUCCESS',

  SELECTEDTREATMENT_SUCCESS: 'RESERVATION_SELECTEDTREATMENT_SUCCESS',
  RESETTREATMENT_SUCCESS: 'RESERVATION_RESETTREATMENT_SUCCESS',

  SELECTEDTHERAPIST_SUCCESS: 'RESERVATION_SELECTEDTHERAPIST_SUCCESS',
  RESETTHERAPIST_SUCCESS: 'RESERVATION_RESETTHERAPIST_SUCCESS',

  SELECTEDPRICE_SUCCESS: 'RESERVATION_SELECTEDPRICE_SUCCESS',
  RESETPRICE_SUCCESS: 'RESERVATION_RESETPRICE_SUCCESS',

  SELECTEDEVENT_SUCCESS: 'RESERVATION_SELECTEDEVENT_SUCCESS',
  RESETEVENT_SUCCESS: 'RESERVATION_RESETEVENT_SUCCESS',
  SELECTEDEVENTBACKDESTINATION_SUCCESS: 'RESERVATION_SELECTEDEVENTBACKDESTINATION_SUCCESS',
  RESETEVENTBACKDESTINATION_SUCCESS: 'RESERVATION_RESETEVENTBACKDESTINATION_SUCCESS',

  SELECTEDDATE_SUCCESS: 'RESERVATION_SELECTEDDATE_SUCCESS',

  MODIFYBASKET_SUCCESS: 'RESERVATION_MODIFYBASKET_SUCCESS',
  RESETBASKET_SUCCESS: 'RESERVATION_RESETBASKET_SUCCESS',
}
